import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgOutlook({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 14 14"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M13.563 3.063H7.875V.875a.436.436 0 00-.518-.43l-7 1.313a.436.436 0 00-.357.43v9.625c0 .21.15.39.357.43l7 1.312a.436.436 0 00.518-.43v-2.187h5.688A.438.438 0 0014 10.5v-7a.438.438 0 00-.438-.437zm-9.626 7c-1.205 0-2.187-1.178-2.187-2.625s.982-2.625 2.188-2.625c1.205 0 2.187 1.178 2.187 2.625s-.982 2.625-2.188 2.625zm3.938-6.125h4.413L9.6 6.028 7.875 4.932v-.994zm5.25 6.125h-5.25V5.969l1.515.963a.44.44 0 00.504-.024l3.231-2.513v5.668z" />
      <path d="M3.938 9.188c.724 0 1.312-.784 1.312-1.75 0-.967-.588-1.75-1.313-1.75-.724 0-1.312.783-1.312 1.75 0 .966.588 1.75 1.313 1.75z" />
    </svg>
  );
}

export default forwardRef(SvgOutlook);
