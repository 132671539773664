import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgAttestations(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 40"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M7.705.25H23.75V11h-1.5V1.75H8.295L1.75 7.827V33.25H18v1.5H.25V7.173L7.705.25zm8.295 15H8v-1.5h8v1.5zm-1.5 4H8v-1.5h6.5v1.5zm.5 4H8v-1.5h7v1.5z" />
      <path d="M8.75 1v7.25H1v-1.5h6.25V1h1.5zm8.5 25h1.5v10.33l3.75-3.334 3.75 3.334V26h1.5v13.67l-5.25-4.666-5.25 4.666V26z" />
      <path d="M22.5 11.75a7.25 7.25 0 100 14.5 7.25 7.25 0 000-14.5zM13.75 19a8.75 8.75 0 1117.5 0 8.75 8.75 0 01-17.5 0z" />
      <path d="M22.5 16.058a2.942 2.942 0 100 5.884 2.942 2.942 0 000-5.884zM18.058 19a4.442 4.442 0 118.884 0 4.442 4.442 0 01-8.884 0z" />
    </svg>
  );
}

export default forwardRef(SvgAttestations);
