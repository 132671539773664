function initializeFirebase() {
  if (!global.firebase) {
    throw new Error('Firebase SDK was not imported');
  }

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  global.firebase.initializeApp(firebaseConfig);
}

try {
  initializeFirebase();
} catch (error) {
  console.error(error);
}

function initializeMessaging() {
  try {
    return global.firebase.messaging();
  } catch (error) {
    console.error(error);

    throw error;
  }
}

export async function getMessagingToken() {
  const messaging = initializeMessaging();

  // Throws if permission already blocked
  const token = await messaging.getToken();

  if (token) {
    return token;
  }
}

export function onMessagingTokenRefresh(handler) {
  const messaging = initializeMessaging();

  return messaging.onTokenRefresh(async () => {
    try {
      if (handler) {
        handler();
      }
    } catch (error) {
      console.error(error);
    }
  });
}

export function onMessagingMessage(handler) {
  const messaging = initializeMessaging();

  return messaging.onMessage((payload) => {
    if (handler) {
      handler(payload);
    }
  });
}

export async function deleteMessagingToken() {
  const messaging = initializeMessaging();

  const token = await messaging.getToken();

  if (token) {
    return await messaging.deleteToken(token);
  }
}
