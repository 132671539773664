import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addSteps, removeSteps } from '../../actions/helpActions';
import { HelpStep } from '../../models';

function isInViewport(element: Element) {
  const rect = element.getBoundingClientRect();
  const html = window.document.documentElement;

  // FIXME: this is a quick fix for #254.
  // Avoid to test vertical offset to not break help in the homepage.
  return (
    // rect.top >= 0 &&
    // rect.bottom <= (window.innerHeight || html.clientHeight) &&
    rect.right >= 0 && rect.left <= (window.innerWidth || html.clientWidth)
  );
}

function useHelpStep(step?: HelpStep) {
  const dispatch = useDispatch();

  useEffect(
    function onMount() {
      if (step) {
        // We want to wait a little for children to be rendered
        const timeout = setTimeout(function addTimeout() {
          // Check if the selected element is visible,
          const targetElement = window.document.querySelector(step.selector);

          if (!targetElement) {
            return;
          }

          if (isInViewport(targetElement)) {
            dispatch(addSteps([step]));
          }
        }, 500);

        // eslint-disable-next-line consistent-return
        return function onUnmount() {
          clearTimeout(timeout);
          dispatch(removeSteps([step]));
        };
      }
    },
    [step, dispatch],
  );
}

export default useHelpStep;
