import useResize, { UseResizeState } from 'components/hooks/useResize';
import React, { useCallback, useRef } from 'react';
import './HeaderNavigation.scss';

type HeaderNavigationProps = {
  children?: React.ReactNode;
};

function HeaderNavigation({ children }: HeaderNavigationProps): JSX.Element {
  const rootRef = useRef<HTMLDivElement>(null);

  // We need to set the navigation width, it may be used by the Search component to size its panel properly
  const handleResize = useCallback((state: UseResizeState) => {
    if (rootRef.current) {
      const headerElement = rootRef.current.closest<HTMLElement>('.Header');

      if (headerElement && state.rect) {
        headerElement.style.setProperty('--header-navigationWidth', `${state.rect.width}px`);
      }
    }
  }, []);

  useResize(rootRef, handleResize);

  return (
    <div ref={rootRef} className="HeaderNavigation">
      {children}
    </div>
  );
}

export default HeaderNavigation;
