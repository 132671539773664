import NodeIterator from 'components/tree/NodeIterator';
import { NodeProps } from 'components/tree/Node';
import Footer from 'components/ui/Footer';

export type FooterZoneProps = NodeProps;

function FooterZone({ content }: FooterZoneProps): JSX.Element {
  return (
    <Footer>
      <NodeIterator nodes={content} />
    </Footer>
  );
}

export default FooterZone;
