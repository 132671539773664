import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import './Backdrop.scss';

export type BackdropProps = React.ComponentPropsWithoutRef<'span'> & {
  portalElement?: Element;
};

function Backdrop({
  className,
  portalElement = document.body,
  ...props
}: BackdropProps): JSX.Element {
  const [nodeElement] = useState(document.createElement('div'));

  useEffect(() => {
    portalElement.appendChild(nodeElement);

    return () => {
      portalElement.removeChild(nodeElement);
    };
  }, [nodeElement, portalElement]);

  const root = <div className={classNames('Backdrop', className)} {...props} />;

  return ReactDOM.createPortal(root, nodeElement);
}

export default Backdrop;
