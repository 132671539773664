import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgEng({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M26.9 1.69H5.12A3.45 3.45 0 001.59 5v16.58a3.41 3.41 0 003.54 3.32h2.31v4.92a.82.82 0 00.5.72.7.7 0 00.33.06 1.07 1.07 0 00.55-.17l7.29-5.53H27a3.45 3.45 0 003.54-3.32V5a3.5 3.5 0 00-3.64-3.31zm1.88 19.84a1.8 1.8 0 01-1.88 1.71H15.73a1.07 1.07 0 00-.55.17L9 28v-4a.8.8 0 00-.83-.77h-3.1a1.8 1.8 0 01-1.88-1.71V5.06a1.8 1.8 0 011.88-1.72H27a1.8 1.8 0 011.88 1.71l-.06 16.47z" />
      <path d="M8.49 13.35h3.04v-1.11H8.49v-1.33h3.32V9.75H7.17v6.36h4.81v-1.16H8.49v-1.6zm8.4.82l-2.54-4.42h-1.38v6.36h1.27v-4.53l2.54 4.53h1.33V9.75h-1.22v4.42zm5.42-.44h1.49a1.57 1.57 0 01-.5 1 1.51 1.51 0 01-1.11.39 1.71 1.71 0 01-1.22-.5 2.29 2.29 0 01-.54-1.62 2.54 2.54 0 01.5-1.71 1.53 1.53 0 011.27-.55 2.8 2.8 0 01.72.11 1.16 1.16 0 01.72.88H25a2.28 2.28 0 00-.83-1.55 2.72 2.72 0 00-1.93-.61 3 3 0 00-2.27.94A3.56 3.56 0 0019.1 13a3.27 3.27 0 00.83 2.38 2.8 2.8 0 002 .88 2.29 2.29 0 001.27-.33 2.46 2.46 0 00.66-.66L24 16h.88v-3.37h-2.63l.06 1.11z" />
    </svg>
  );
}

export default forwardRef(SvgEng);
