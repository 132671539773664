import { RegistrationStatus } from 'components/tree/behavior/candidate/registration/RegistrationWorkflow';
import { OptionModel } from 'models';

export type CandidateSuperviewerModel = {
  program: string | null;
  status: string | null;
};

export type CandidateStatusModel = {
  code: string;
  label: string;
};

export type CandidateDocumentModel = {
  url: string;
  label: string;
};

export type CandidateMenuModel = {
  id: number | string;
  title: string;
  code: string | null;
  children: CandidateMenuModel[];
  documents: CandidateDocumentModel[];
};

export type CandidateProgramModel = {
  statuses: CandidateStatusModel[];
  title: string;
  description: {
    processed: string;
  };
  button: any;
  contact: any[];
};

export type CandidateCandidacyModel = {
  candidateId: number;
  candidacyId: number;
  candidateStatus: string;
  competitionCode: string;
  programCode: string;
  status: CandidateStatusModel['code'];
  sbtActive: boolean | null;
  sbtComplete: boolean | null;
  sbtToken: string | null;
  applicationProgress: number | null;
  applicationComplete: boolean | null;
  applicationUrl: string | null;
  withRegistration: boolean;
  program?: CandidateProgramModel | null;
  menu?: CandidateMenuModel | null;
  ready: boolean;
  codeModa?: string;
};

export type CandidateParagraphModel = {
  title: string | null;
  appearance: string | null;
  semantic: string | null;
  type: string | null;
  media: null | {
    url: string | null;
    alt: string | null;
    title: string | null;
  };
  text: null | {
    processed: string | null;
  };
  link: null | {
    uri: string | null;
    title: string | null;
  };
  alignment: string | null;
  paragraphs: CandidateParagraphModel[];
};

export type CandidateContentModel = {
  id: number;
  title: string;
  content: CandidateParagraphModel[];
  sidebar: CandidateParagraphModel[];
};

export type CandidateProgramContactModel = {
  title: string;
  text: {
    processed: string;
  };
  link: any;
};

export function isCandidacyModel(candidacy: any): candidacy is CandidateCandidacyModel {
  return (
    null !== candidacy &&
    typeof candidacy === 'object' &&
    'candidateId' in candidacy &&
    candidacy.candidateId !== null &&
    'competitionCode' in candidacy &&
    candidacy.competitionCode !== null &&
    'programCode' in candidacy &&
    candidacy.programCode !== null &&
    'candidateStatus' in candidacy &&
    candidacy.candidateStatus !== null &&
    'status' in candidacy &&
    candidacy.status !== null
  );
}

export type RegistrationSignerParentModel = {
  status: string | null;
  lastName: string | null;
  firstName: string | null;
  email: string | null;
  telephone: string | null;
};

export type RegistrationSignerModel = {
  status: string | null;
  email: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  telephone: string;
  nationality: string | null;
  civility: string | null;
  birthLocation: string | null;
  address1: string | null;
  address2: string | null;
  zipCode: string | null;
  city: string | null;
  country: string | null;
  parents: RegistrationSignerParentModel[] | null;
  moda: string | null;
  campus: string | null;
  language: string | null;
  year: string | null;
};

export type RegistrationModel = {
  candidateId: string;
  candidacyId: string;
  status: RegistrationStatus;
  signatureShn: boolean;
  signatureDate: string | null;
  signatureMethod: string | null;
  refuseReason: string | null;
  refuseText: string | null;
  paymentBypass: boolean | null;
  paymentPrice: number | null;
  paymentCurrency: string;
  paymentMethod: string | null;
  paymentOnline: boolean | null;
  paymentIban: string | null;
  paymentCheckText: string | null;
  signatureTextDescriptionTop: string | null;
  signatureTextDescriptionMiddle: string | null;
  signatureTextDescriptionBottom: string | null;
  signatureFormTitle: string | null;
  signatureFormDescriptionTop: string | null;
  signatureFormDescriptionBottom: string | null;
  paymentDate: string | null;
  paymentReference: string | null;
  paymentUrl: string | null;
  logs: {
    status: string;
    createdAt: string;
    payload?: any;
  }[];
  signatureSigner: RegistrationSignerModel | null;
};

export function isRegistrationModel(subject: any): subject is RegistrationModel {
  return (
    null !== subject &&
    typeof subject === 'object' &&
    'candidateId' in subject &&
    typeof subject.candidateId === 'string' &&
    'candidacyId' in subject &&
    typeof subject.candidacyId === 'string' &&
    'status' in subject &&
    typeof subject.status === 'string'
  );
}

export type RegistrationSignatureGlossaryModel = {
  fields: (keyof RegistrationSignerModel)[];
  campusOptions: OptionModel[];
  modaOptions: OptionModel[];
};

export function isDummyCandidacyModel(candidacy: CandidateCandidacyModel) {
  return candidacy.candidateStatus === 'dummy';
}

export function isPotentialCandidacyModel(candidacy: CandidateCandidacyModel) {
  return candidacy.candidateStatus === 'potential';
}
