import {
  CandidateSuperviewerModel,
  CandidateCandidacyModel,
  CandidateContentModel,
} from 'models/candidate';
import { ErrorModel } from 'models';

export function updateSuperviewer<T extends keyof CandidateSuperviewerModel>(
  field: T,
  value: CandidateSuperviewerModel[T],
) {
  return {
    type: updateSuperviewer.type,
    payload: { field, value },
  } as const;
}

updateSuperviewer.type = 'candidate/superviewer/update' as const;

export function requestCandidacies() {
  return {
    type: requestCandidacies.type,
    payload: {},
  } as const;
}

requestCandidacies.type = 'candidate/candidacies/request' as const;

export function receiveCandidacies(candidacies: CandidateCandidacyModel[]) {
  return {
    type: receiveCandidacies.type,
    payload: {
      candidacies,
    },
  } as const;
}

receiveCandidacies.type = 'candidate/candidacies/receive' as const;

export function errorCandidacies(error: ErrorModel) {
  return {
    type: errorCandidacies.type,
    payload: {
      error,
    },
  } as const;
}

errorCandidacies.type = 'candidate/candidacies/error' as const;

export function requestCandidateContent(id: number) {
  return {
    type: requestCandidateContent.type,
    payload: { id },
  } as const;
}

requestCandidateContent.type = 'candidate/content/request' as const;

export function receiveCandidateContent(id: number, content: CandidateContentModel) {
  return {
    type: receiveCandidateContent.type,
    payload: {
      id,
      content,
    },
  } as const;
}

receiveCandidateContent.type = 'candidate/content/receive' as const;

export function errorCandidateContent(id: number, error: ErrorModel) {
  return {
    type: errorCandidateContent.type,
    payload: {
      id,
      error,
    },
  } as const;
}

errorCandidateContent.type = 'candidate/content/error' as const;
