import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgEarlymakers(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 121 121"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      <style type="text/css">
        {`.st0{fill:#E2001A;}
	.st1{fill:#FFFFFF;}`}
      </style>
      {title ? <title id={titleId}>{title}</title> : null}
      <rect className="st0" width="121" height="121" />
      <g>
        <path
          className="st1"
          d="M18.5,99c-0.1,0-0.3-0.1-0.4-0.2l-4.6-4.6c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.8,0l4.1,4.2l4.1-4.2
		c0.2-0.2,0.6-0.2,0.8,0c0.2,0.2,0.2,0.6,0,0.8l-4.6,4.6C18.8,99,18.6,99,18.5,99"
        />
        <path
          className="st1"
          d="M18.5,94.6c-0.1,0-0.3-0.1-0.4-0.2L15.7,92c-0.2-0.2-0.2-0.6,0-0.8s0.6-0.2,0.8,0l2,2l2-2
		c0.2-0.2,0.6-0.2,0.8,0c0.2,0.2,0.2,0.6,0,0.8l-2.4,2.4C18.8,94.5,18.6,94.6,18.5,94.6"
        />
        <path
          className="st1"
          d="M25.4,101.6c-0.1,0-0.3-0.1-0.4-0.2l-6.5-6.7l-6.5,6.7c-0.2,0.2-0.6,0.2-0.8,0c-0.2-0.2-0.2-0.6,0-0.8l7-7.1
		c0.2-0.2,0.6-0.2,0.8,0l7,7.1c0.2,0.2,0.2,0.6,0,0.8C25.7,101.5,25.6,101.6,25.4,101.6"
        />
        <path
          className="st1"
          d="M18.5,108.2c-0.1,0-0.3-0.1-0.4-0.2l-7-7.1c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.8,0l6.5,6.7l6.5-6.7
		c0.2-0.2,0.6-0.2,0.8,0c0.2,0.2,0.2,0.6,0,0.8l-7,7.1C18.8,108.1,18.6,108.2,18.5,108.2"
        />
        <path
          className="st1"
          d="M23,108.3c-0.1,0-0.3-0.1-0.4-0.2l-4.1-4.2l-4.1,4.2c-0.2,0.2-0.6,0.2-0.8,0c-0.2-0.2-0.2-0.6,0-0.8l4.6-4.6
		c0.2-0.2,0.6-0.2,0.8,0l4.6,4.6c0.2,0.2,0.2,0.6,0,0.8C23.3,108.2,23.2,108.3,23,108.3"
        />
        <path
          className="st1"
          d="M20.9,110.5c-0.1,0-0.3-0.1-0.4-0.2l-2-2l-2,2c-0.2,0.2-0.6,0.2-0.8,0s-0.2-0.6,0-0.8l2.4-2.4
		c0.2-0.2,0.6-0.2,0.8,0l2.4,2.4c0.2,0.2,0.2,0.6,0,0.8C21.2,110.5,21,110.5,20.9,110.5"
        />
        <path
          className="st1"
          d="M25.3,106c-0.1,0-0.3-0.1-0.4-0.2l-4.6-4.6c-0.2-0.2-0.2-0.6,0-0.8l4.6-4.6c0.2-0.2,0.6-0.2,0.8,0
		c0.2,0.2,0.2,0.6,0,0.8l-4.1,4.2l4.1,4.2c0.2,0.2,0.2,0.6,0,0.8C25.6,105.9,25.5,106,25.3,106"
        />
        <path
          className="st1"
          d="M27.5,103.8c-0.1,0-0.3-0.1-0.4-0.2l-2.4-2.4c-0.1-0.1-0.2-0.3-0.2-0.4s0.1-0.3,0.2-0.4l2.4-2.4
		c0.2-0.2,0.6-0.2,0.8,0c0.2,0.2,0.2,0.6,0,0.8l-2,2l2,2c0.2,0.2,0.2,0.6,0,0.8C27.8,103.7,27.7,103.8,27.5,103.8"
        />
        <path
          className="st1"
          d="M11.6,106c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.6,0-0.8l4.1-4.2l-4.1-4.2c-0.2-0.2-0.2-0.6,0-0.8
		c0.2-0.2,0.6-0.2,0.8,0l4.6,4.6c0.2,0.2,0.2,0.6,0,0.8l-4.6,4.6C11.9,105.9,11.8,106,11.6,106"
        />
        <path
          className="st1"
          d="M9.4,103.8c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.6,0-0.8l2-2l-2-2c-0.2-0.2-0.2-0.6,0-0.8
		c0.2-0.2,0.6-0.2,0.8,0l2.4,2.4c0.1,0.1,0.2,0.3,0.2,0.4s-0.1,0.3-0.2,0.4l-2.4,2.4C9.7,103.7,9.5,103.8,9.4,103.8"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M44.9,98.1l-1.7-5.7c-0.1-0.3-0.2-0.5-0.3-0.5c-0.1-0.1-0.3-0.1-0.6-0.1h0v-0.5h3.2v0.5h-0.2
		c-0.3,0-0.5,0-0.6,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3l0.6,2.2c0.1,0.2,0.1,0.4,0.2,0.7
		c0.1,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.4,0.1,0.6h0c0-0.2,0.1-0.5,0.2-0.9c0.1-0.4,0.2-0.7,0.3-1l1.4-3.9h0.8l1.3,3.8
		c0.1,0.2,0.1,0.4,0.2,0.7s0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.4,0.1,0.5h0c0.1-0.4,0.2-1.1,0.5-1.9l0.7-2.1c0-0.1,0.1-0.2,0.1-0.4
		c0-0.1,0-0.2,0-0.3c0-0.4-0.3-0.6-0.9-0.6h-0.1v-0.5h2.8v0.5h-0.2c-0.3,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.3,0.4-0.4,0.8l-1.7,5.4h-1
		l-1.8-5.4l-1.8,5.4H44.9z"
        />
        <path
          className="st1"
          d="M57.2,98.2c-0.9,0-1.7-0.3-2.2-0.9c-0.5-0.6-0.8-1.5-0.8-2.6c0-1.2,0.2-2.1,0.7-2.7s1.2-0.9,2.1-0.9
		c0.8,0,1.5,0.3,2,0.8c0.5,0.5,0.7,1.3,0.7,2.3v0.6h-4.3c0,0.9,0.2,1.6,0.5,2.1c0.3,0.4,0.8,0.6,1.4,0.6c0.5,0,0.8-0.1,1.2-0.3
		c0.3-0.2,0.6-0.4,0.7-0.6c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.4-0.2,0.6s-0.4,0.4-0.8,0.5
		C58.2,98.1,57.8,98.2,57.2,98.2z M55.5,94h3c0-0.7-0.1-1.2-0.3-1.7s-0.6-0.6-1.1-0.6c-0.5,0-0.8,0.2-1.1,0.6S55.6,93.2,55.5,94z"
        />
        <path
          className="st1"
          d="M66.8,98.2c-0.6,0-1.1-0.2-1.4-0.5c-0.4-0.3-0.6-0.9-0.6-1.6c0-0.7,0.2-1.2,0.7-1.5c0.5-0.3,1.2-0.5,2.2-0.5
		l1.1,0v-0.8c0-0.3,0-0.6-0.1-0.8c-0.1-0.2-0.2-0.4-0.3-0.6s-0.4-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.3,0.3-0.3,0.5
		c-0.1,0.2-0.1,0.5-0.1,0.7c-0.4,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.3-0.3-0.3-0.6c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.4
		c0.4-0.1,0.7-0.1,1.2-0.1c0.8,0,1.4,0.2,1.8,0.5c0.4,0.3,0.6,0.9,0.6,1.7v3.4c0,0.4,0.1,0.6,0.2,0.7c0.1,0.1,0.3,0.2,0.6,0.2h0v0.5
		h-1.8L68.9,97h-0.1c-0.2,0.2-0.4,0.5-0.5,0.6c-0.2,0.2-0.4,0.3-0.6,0.4C67.4,98.2,67.1,98.2,66.8,98.2z M67.1,97.4
		c0.5,0,0.9-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3v-1.1l-0.8,0c-0.7,0-1.2,0.2-1.5,0.4c-0.3,0.3-0.4,0.7-0.4,1.2
		C66,97,66.4,97.4,67.1,97.4z"
        />
        <path
          className="st1"
          d="M72,98.1v-0.5h0c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.3-0.4,0.3-0.8v-4.1c0-0.4-0.1-0.6-0.3-0.7
		c-0.2-0.1-0.5-0.2-0.7-0.2h0v-0.5h2l0.2,1.3h0.1c0.1-0.3,0.2-0.5,0.4-0.7c0.1-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.2
		c0.5,0,0.8,0.1,1.1,0.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.3-0.9,0.3c0-0.4-0.1-0.6-0.2-0.8
		c-0.1-0.2-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.6,0.3s-0.3,0.4-0.4,0.7c-0.1,0.3-0.2,0.6-0.2,0.9s-0.1,0.6-0.1,0.9v2
		c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.7,0.1h0.4v0.5H72z"
        />
        <path
          className="st1"
          d="M81.5,98.2c-0.9,0-1.7-0.3-2.2-0.9c-0.5-0.6-0.8-1.5-0.8-2.6c0-1.2,0.2-2.1,0.7-2.7s1.2-0.9,2.1-0.9
		c0.8,0,1.5,0.3,2,0.8c0.5,0.5,0.7,1.3,0.7,2.3v0.6h-4.3c0,0.9,0.2,1.6,0.5,2.1c0.3,0.4,0.8,0.6,1.4,0.6c0.5,0,0.8-0.1,1.2-0.3
		c0.3-0.2,0.6-0.4,0.7-0.6c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.4-0.2,0.6s-0.4,0.4-0.8,0.5
		C82.5,98.1,82,98.2,81.5,98.2z M79.8,94h3c0-0.7-0.1-1.2-0.3-1.7s-0.6-0.6-1.1-0.6c-0.5,0-0.8,0.2-1.1,0.6S79.9,93.2,79.8,94z"
        />
        <path
          className="st1"
          d="M47.6,110.1v-4.3c0-0.3,0-0.5-0.1-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.3-0.3-0.3
		c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4,0.1-0.5,0.2s-0.3,0.3-0.3,0.4c-0.1,0.2-0.1,0.4-0.2,0.6s-0.1,0.5-0.1,0.8v2.5
		c0,0.2,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3s0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.4,0h0v0.7h-4v-0.7h0c0.1,0,0.3,0,0.4,0
		c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.3s0.1-0.3,0.1-0.5v-3.9c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3
		c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.4,0h0v-0.7h2.7l0.2,0.9h0.1c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.3-0.3,0.5-0.3
		s0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.6,0c0.5,0,0.9,0.1,1.2,0.3c0.3,0.2,0.6,0.5,0.7,0.8h0.1c0.1-0.2,0.3-0.4,0.4-0.6
		c0.2-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.5,0.2,0.7,0.5
		c0.2,0.2,0.3,0.5,0.4,0.8s0.1,0.7,0.1,1.1v3c0,0.2,0,0.4,0.1,0.5c0,0.1,0.1,0.2,0.2,0.3s0.2,0.1,0.3,0.1s0.2,0,0.4,0h0v0.7h-2.9
		v-4.3c0-0.6-0.1-1-0.2-1.3c-0.2-0.3-0.4-0.5-0.8-0.5c-0.2,0-0.4,0-0.5,0.1s-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.6
		s-0.1,0.5-0.1,0.7v2.6c0,0.2,0,0.4,0.1,0.5c0,0.1,0.1,0.2,0.2,0.3s0.2,0.1,0.3,0.1s0.2,0,0.4,0h0v0.7H47.6z"
        />
        <path
          className="st1"
          d="M58.9,103.7c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.3s-0.1,0.3-0.1,0.4c0,0.2,0,0.4,0,0.6
		c-0.6,0-1-0.1-1.3-0.2c-0.3-0.1-0.4-0.4-0.4-0.7c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.3,0.6-0.4c0.2-0.1,0.5-0.2,0.9-0.2
		c0.3,0,0.7-0.1,1-0.1c0.5,0,0.9,0,1.2,0.1c0.3,0.1,0.6,0.2,0.9,0.4c0.2,0.2,0.4,0.4,0.5,0.7s0.2,0.6,0.2,1v3.3c0,0.2,0,0.3,0,0.4
		c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.1s0.2,0,0.4,0h0.1v0.7h-2.4l-0.3-0.9h-0.1c-0.1,0.2-0.3,0.3-0.4,0.5
		s-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.5,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.5-0.2-0.6-0.4
		c-0.2-0.2-0.3-0.4-0.4-0.7s-0.2-0.6-0.2-0.9c0-0.7,0.2-1.2,0.7-1.6c0.5-0.3,1.2-0.5,2.2-0.6l1.1,0v-0.7c0-0.2,0-0.4,0-0.6
		s-0.1-0.3-0.1-0.5s-0.1-0.2-0.2-0.3C59.2,103.8,59.1,103.7,58.9,103.7z M57.7,108.1c0,0.4,0.1,0.7,0.2,0.8c0.1,0.2,0.3,0.3,0.6,0.3
		c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.1-0.7v-1l-0.6,0c-0.3,0-0.5,0.1-0.7,0.1
		c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.3-0.2,0.5S57.7,107.9,57.7,108.1z"
        />
        <path
          className="st1"
          d="M71.3,110.1h-0.2c-0.5,0-0.9,0-1.2,0c-0.3,0-0.6-0.1-0.8-0.1s-0.4-0.2-0.6-0.3s-0.3-0.4-0.5-0.6l-1.2-1.8
		l-0.5,0.4v1.1c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0h0.2v0.7h-4.1v-0.7h0.2
		c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.3c0.1-0.1,0.1-0.3,0.1-0.4v-6.9c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0h-0.2v-0.7h3.1v5c0,0.1,0,0.3,0,0.4c0,0.2,0,0.3,0,0.4
		c0,0.2,0,0.3,0,0.5l1.6-1.8c0.1-0.2,0.2-0.3,0.3-0.4c0-0.1,0.1-0.2,0.1-0.2c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.3-0.1-0.5-0.1v-0.7
		h3.3v0.7c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.2-0.6,0.5-0.9,0.8l-0.8,0.9l1.7,2.6c0.2,0.3,0.5,0.6,0.7,0.8s0.5,0.2,0.7,0.2h0V110.1z"
        />
        <path
          className="st1"
          d="M75.2,110.2c-0.6,0-1-0.1-1.5-0.2c-0.4-0.2-0.8-0.4-1.1-0.7s-0.5-0.7-0.6-1.1s-0.2-0.9-0.2-1.5
		c0-0.6,0.1-1.1,0.2-1.6s0.4-0.8,0.6-1.2s0.6-0.5,1-0.7C74,103,74.5,103,75,103c0.5,0,0.9,0.1,1.3,0.2c0.4,0.1,0.7,0.3,1,0.6
		c0.3,0.3,0.5,0.6,0.6,1c0.1,0.4,0.2,0.8,0.2,1.4v0.7h-4.3c0,0.4,0.1,0.8,0.1,1.1c0.1,0.3,0.2,0.6,0.3,0.8c0.1,0.2,0.3,0.4,0.5,0.5
		c0.2,0.1,0.4,0.1,0.7,0.1c0.2,0,0.4,0,0.6-0.1s0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.4
		c0.2,0.1,0.3,0.3,0.3,0.6c0,0.2-0.1,0.4-0.2,0.5s-0.3,0.3-0.5,0.4s-0.5,0.2-0.8,0.3C76,110.2,75.6,110.2,75.2,110.2z M75,103.8
		c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.4-0.3,0.9-0.4,1.6h2.2c0-0.3,0-0.6-0.1-0.9c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.2-0.3-0.3-0.4
		C75.4,103.8,75.2,103.8,75,103.8z"
        />
        <path
          className="st1"
          d="M83.3,110.1H79v-0.7h0c0.1,0,0.3,0,0.4,0s0.2-0.1,0.3-0.1s0.2-0.2,0.2-0.3c0-0.1,0.1-0.3,0.1-0.5v-3.8
		c0-0.2,0-0.3-0.1-0.5c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.4,0h0v-0.7h2.6l0.3,1H82
		c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4-0.1,0.7-0.1c0.5,0,0.9,0.1,1.1,0.3
		s0.4,0.4,0.4,0.8c0,0.4-0.1,0.7-0.4,0.9s-0.7,0.3-1.2,0.3c0-0.2,0-0.3,0-0.5c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2
		c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.4,0.1s-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.2-0.1,0.3-0.1,0.5
		c0,0.2,0,0.3-0.1,0.4c0,0.1,0,0.3,0,0.4v2.2c0,0.2,0,0.3,0.1,0.5s0.1,0.2,0.2,0.3s0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.4,0h0.4V110.1z"
        />
        <path
          className="st1"
          d="M88.7,110.2c-0.5,0-0.9,0-1.2-0.1c-0.3-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.4-0.3-0.5-0.5
		c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.1,0.4-0.1s0.3,0,0.4,0c0,0.3,0,0.5,0.1,0.7
		s0.2,0.4,0.3,0.5s0.3,0.3,0.4,0.3c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.3
		c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.4s-0.1-0.2-0.3-0.3s-0.3-0.2-0.5-0.3c-0.2-0.1-0.5-0.2-0.8-0.3
		c-0.4-0.1-0.7-0.3-0.9-0.4c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0.1-0.7,0.2-0.9
		c0.1-0.3,0.3-0.5,0.6-0.6c0.2-0.2,0.5-0.3,0.9-0.4s0.7-0.1,1.1-0.1c0.4,0,0.8,0,1,0.1c0.3,0.1,0.5,0.2,0.7,0.3s0.3,0.2,0.4,0.4
		c0.1,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.3,0.7s-0.5,0.2-1,0.2c0-0.4-0.1-0.8-0.3-1s-0.5-0.4-0.9-0.4c-0.1,0-0.3,0-0.4,0.1
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.3,0.3
		c0.1,0.1,0.3,0.2,0.5,0.3s0.5,0.2,0.9,0.3c0.3,0.1,0.6,0.2,0.8,0.3s0.5,0.3,0.6,0.4s0.3,0.4,0.4,0.6c0.1,0.2,0.1,0.5,0.1,0.7
		c0,0.4-0.1,0.7-0.2,1s-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.9,0.4S89.1,110.2,88.7,110.2z"
        />
      </g>
    </svg>
  );
}

export default forwardRef(SvgEarlymakers);
