import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'components/ui/Button';
import Dialog from 'components/ui/Dialog';
import { getLocaleCodes, LocaleCode } from 'services/localeHelper';
import { updateUser } from 'actions/userActions';
import useMessages from 'components/hooks/useMessages';
import FlagFr from 'components/ui/Icon/common/FlagFr';
import FlagEn from 'components/ui/Icon/common/FlagEn';

const ICONS = {
  fr: FlagFr,
  en: FlagEn,
} as const;

export type DialogLocaleProps = {
  open: boolean;
};

function DialogLocale({ open }: DialogLocaleProps): JSX.Element {
  const dispatch = useDispatch();
  const availableLocales = getLocaleCodes();
  const messages = useMessages((t) => ({
    label: t('dialog.locale.title'),
    overtitle: t('dialog.locale.overtitle'),
    locale: (locale: LocaleCode) => t(`enum.locale.${locale}`),
  }));

  const handleLocaleChange = useCallback(
    (locale: LocaleCode) => {
      dispatch(updateUser({ locale }));
    },
    [dispatch],
  );

  const actions = availableLocales.map(function renderButton(locale) {
    return (
      <Button key={locale} icon={ICONS[locale]} onClick={() => handleLocaleChange(locale)}>
        {messages.locale(locale)}
      </Button>
    );
  });

  return (
    <Dialog
      open={open}
      width="sm"
      actions={actions}
      messages={messages}
      overtitle={messages.overtitle}
      title={messages.label}
    />
  );
}

export default DialogLocale;
