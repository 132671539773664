import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgElearning(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 128 128"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M117 23.613h-13.75V13.274a1.75 1.75 0 00-1.75-1.75h-6.1c-2.224-.684-16.613-4.73-31.4-.081-14.786-4.648-29.173-.6-31.4.081h-6.1a1.75 1.75 0 00-1.75 1.75v10.339H11a1.75 1.75 0 00-1.75 1.75v69.673a1.75 1.75 0 001.75 1.75h41.089l-3.119 12.4h-.278A7.825 7.825 0 0040.875 117a1.75 1.75 0 001.75 1.75h42.75a1.75 1.75 0 001.75-1.75 7.826 7.826 0 00-7.816-7.816h-.277l-3.119-12.4H117a1.75 1.75 0 001.75-1.75V25.363a1.75 1.75 0 00-1.75-1.75zM26.5 69.6h75a1.75 1.75 0 001.75-1.75V32.878h5.812v48.8H18.938v-48.8h5.812v34.971A1.75 1.75 0 0026.5 69.6zm73.25-3.5h-34v-1.118a66.848 66.848 0 0128.968.324 1.75 1.75 0 002.164-1.7V15.024h2.868zm-6.368-51.5v46.819A70.168 70.168 0 0065.75 61.4V14.581c11.991-3.524 23.887-.981 27.632.013zm-31.132-.037V61.4a70.131 70.131 0 00-27.632.023V14.594c3.745-.994 15.645-3.556 27.632-.031zm-34 .462h2.868v48.581a1.75 1.75 0 002.164 1.7 67.069 67.069 0 0128.968-.322V66.1h-34zm55 100.226H44.746a4.323 4.323 0 013.946-2.566h30.617a4.321 4.321 0 013.945 2.565zm-7.832-6.066h-22.84l3.119-12.4H72.3zm39.828-15.9H12.75V27.113h12v2.265h-7.562a1.75 1.75 0 00-1.75 1.75v52.3a1.75 1.75 0 001.75 1.75h93.623a1.75 1.75 0 001.75-1.75v-52.3a1.75 1.75 0 00-1.75-1.75h-7.561v-2.265h12z" />
      <path d="M39.073 25.8c.094-.031 9.509-3.03 18.721 0a1.75 1.75 0 101.093-3.324c-10.333-3.4-20.478-.141-20.9 0a1.75 1.75 0 001.086 3.324zm0 9.013c.094-.03 9.509-3.029 18.721 0a1.75 1.75 0 101.093-3.324c-10.333-3.4-20.478-.141-20.9 0a1.75 1.75 0 001.091 3.325zm0 9.014c.094-.031 9.509-3.03 18.721 0a1.75 1.75 0 101.092-3.327c-10.333-3.4-20.478-.141-20.9 0a1.75 1.75 0 001.091 3.325zm.002 9.014c.094-.03 9.466-3.041 18.719 0a1.75 1.75 0 101.093-3.324c-10.333-3.4-20.478-.141-20.9 0a1.75 1.75 0 101.093 3.324zM69.66 25.885a1.754 1.754 0 00.547-.088c9.193-3.021 18.587-.041 18.72 0a1.75 1.75 0 001.092-3.325c-.428-.141-10.569-3.4-20.905 0a1.75 1.75 0 00.547 3.412zm0 9.015a1.754 1.754 0 00.547-.088c9.193-3.021 18.587-.041 18.72 0a1.75 1.75 0 001.092-3.325c-.428-.141-10.569-3.4-20.905 0a1.75 1.75 0 00.546 3.413zm0 9.014a1.754 1.754 0 00.547-.088c9.193-3.022 18.587-.042 18.72 0a1.75 1.75 0 001.092-3.326c-.428-.141-10.569-3.4-20.905 0a1.75 1.75 0 00.547 3.412zm20.359 5.603c-.428-.141-10.569-3.4-20.905 0a1.75 1.75 0 101.094 3.324c9.192-3.021 18.589-.042 18.72 0a1.75 1.75 0 001.092-3.325z" />
    </svg>
  );
}

export default forwardRef(SvgElearning);
