import NodeIterator from '../NodeIterator';
import Overlay from '../../ui/Overlay';
import { NodeProps } from '../Node';

export type PropsType = NodeProps;

function OverlayZone({ content }: PropsType): JSX.Element {
  return (
    <Overlay>
      <NodeIterator nodes={content} />
    </Overlay>
  );
}

export default OverlayZone;
