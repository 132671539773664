import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgAffluence(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 35 22"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M26.833 14.532H16.436a.868.868 0 110-1.735h10.397a.868.868 0 010 1.735" />
      <path d="M34.697 21.425H29.55l-.807-.55-7.167-18.178-7.062 18.175-.808.553H7.98V19.69h5.133L20.762.004 22.377 0l7.764 19.69h4.556zm-30.187 0h2.256V19.69H4.511zm-4.51 0h2.95V19.69H0z" />
    </svg>
  );
}

export default forwardRef(SvgAffluence);
