import { persistReducer } from 'redux-persist';
import {
  authRequest,
  authReceive,
  authError,
  setIdentity,
  resetIdentity,
  authRefreshStart,
  authRefreshStop,
  loginWithCredentials,
  resetAuth,
} from 'actions/authActions';
import { AuthCredentialsModel, ErrorModel, Identity } from 'models';
import { createReducer, createPersistConfig } from './reducerUtils';

const METHODS = ['email', 'token', 'codeToken'] as const;

export type AuthState = Readonly<{
  method: (typeof METHODS)[number] | undefined;
  identity: Identity;
  credentials: AuthCredentialsModel | undefined;
  loading: boolean;
  loaded: boolean;
  error: ErrorModel | null;
  isRefreshing: boolean;
}>;

export const initialState: AuthState = {
  identity: {
    sub: null,
    exp: null,
    accessToken: null,
    scope: null,
  },
  credentials: undefined,
  method: undefined,
  loading: false,
  loaded: true,
  error: null,
  isRefreshing: false,
};

function setIdentityReducer(state: AuthState, action: ReturnType<typeof setIdentity>) {
  return {
    ...state,
    identity: {
      ...state.identity,
      ...action.payload.identity,
    },
  };
}

function resetIdentityReducer(state: AuthState) {
  return {
    ...state,
    identity: { ...initialState.identity },
  };
}

function resetAuthReducer(state: AuthState) {
  return {
    ...state,
    ...initialState,
  };
}

export function authRequestReducer(state: AuthState, action: ReturnType<typeof authRequest>) {
  const keys = Object.keys(action.payload.credentials);
  const method = METHODS.find((method) => keys.includes(method));

  return {
    ...state,
    method,
    error: null,
    loading: true,
    loaded: false,
  };
}

export function authReseiveReducer(state: AuthState) {
  return {
    ...state,
    error: null,
    loading: false,
    loaded: true,
  };
}

export function authErrorReducer(state: AuthState, action: ReturnType<typeof authError>) {
  return {
    ...state,
    error: action.payload.error,
    loading: false,
    loaded: true,
  };
}

function authRefreshStartReducer(state: AuthState) {
  return {
    ...state,
    isRefreshing: true,
  };
}

function authRefreshStopReducer(state: AuthState) {
  return {
    ...state,
    isRefreshing: false,
  };
}

function loginWithCredentialsReducer(
  state: AuthState,
  action: ReturnType<typeof loginWithCredentials>,
): AuthState {
  const credentials = { ...action.payload.credentials };

  if ('password' in credentials) {
    credentials.password = '';
  }

  return {
    ...state,
    credentials,
  };
}

export const persistConfig = createPersistConfig('auth', {
  whitelist: ['identity'],
});

export default persistReducer(
  persistConfig,
  createReducer(initialState, {
    [resetAuth.type]: resetAuthReducer,
    [setIdentity.type]: setIdentityReducer,
    [resetIdentity.type]: resetIdentityReducer,
    [authRequest.type]: authRequestReducer,
    [authReceive.type]: authReseiveReducer,
    [authError.type]: authErrorReducer,
    [authRefreshStart.type]: authRefreshStartReducer,
    [authRefreshStop.type]: authRefreshStopReducer,
    [loginWithCredentials.type]: loginWithCredentialsReducer,
  }),
);
