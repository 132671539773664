import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgLearningHub(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 25 33"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.409 2.409A2.25 2.25 0 014 1.75h19.25v20.5H4a3.75 3.75 0 00-2.25.75V4c0-.597.237-1.169.659-1.591zM.25 26V4A3.75 3.75 0 014 .25h20.75v23.5H24c-.224 0-.518.136-.79.545-.27.403-.46 1.004-.46 1.705 0 .701.19 1.302.46 1.705.272.409.566.545.79.545h.5v1.5H13.75v2.716L9.5 29.4l-4.25 3.067V29.75H4A3.75 3.75 0 01.25 26zm5-22v15h1.5V4h-1.5zM4 23.75a2.25 2.25 0 000 4.5h1.25v-4.5H4zm17.789 4.5H13.75v-4.5h8.039c-.347.638-.539 1.423-.539 2.25s.192 1.612.539 2.25zM6.75 23.75h5.5v5.784L9.5 27.549l-2.75 1.985V23.75z"
      />
    </svg>
  );
}

export default forwardRef(SvgLearningHub);
