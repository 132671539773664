import { Fragment } from 'react';

function partMapper(part: string, key: number): JSX.Element {
  return (
    <Fragment key={key}>
      {part}
      <br />
    </Fragment>
  );
}

export type PropsType = {
  text: string;
};

function Nl2br({ text }: PropsType): JSX.Element {
  return <>{text.split('\n').map(partMapper)}</>;
}

export default Nl2br;
