import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgZoom({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 20 11"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 .558v7.413c.007 1.68 1.357 3.033 3.014 3.026h10.679c.3 0 .543-.246.55-.55v-7.42C14.236 1.347 12.886-.007 11.229 0H.55A.564.564 0 000 .558zm14.914 2.895L19.321.188c.386-.318.679-.246.679.34v9.955c0 .659-.364.586-.679.34l-4.407-3.265V3.453z"
      />
    </svg>
  );
}

export default forwardRef(SvgZoom);
