import { useEffect, useRef } from 'react';

function useEscapeKey(onEscapeKey?: () => void): void {
  const onEscapeKeyRef = useRef(onEscapeKey);

  useEffect(() => {
    onEscapeKeyRef.current = onEscapeKey;
  }, [onEscapeKey]);

  useEffect(() => {
    function onKeydown(event: KeyboardEvent): void {
      if (onEscapeKeyRef.current && (event.key === 'Escape' || event.key === 'Esc')) {
        onEscapeKeyRef.current();
      }
    }

    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, []);
}

export default useEscapeKey;
