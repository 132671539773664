import React from 'react';
import classNames from 'classnames';
import { useDisclosureState, Disclosure, DisclosureContent } from 'ariakit';
import Icon from 'components/ui/Icon';
import { Override } from 'types';
import './SideMenuItem.scss';
import Arrowright from '../Icon/common/Arrowright';

type SideMenuItemProps = Override<
  React.ComponentPropsWithoutRef<'li'>,
  {
    title: React.ReactNode;
    link?: React.ReactElement;
    active?: boolean;
  }
>;

function SideMenuItem({
  title,
  link = <button />,
  active,
  children,
  ...props
}: SideMenuItemProps): JSX.Element {
  const disclosure = useDisclosureState({ open: active });

  return (
    <li className="SideMenuItem" {...props}>
      {!children ? (
        <link.type
          {...link.props}
          className={classNames('SideMenuItem-link', link.props.className)}
          aria-current={active ? 'page' : undefined}
        >
          {title}
          {children ? <Icon as={Arrowright} /> : null}
        </link.type>
      ) : (
        <Disclosure
          state={disclosure}
          as={link.type}
          {...link.props}
          className={classNames('SideMenuItem-link', link.props.className)}
          aria-current={active ? 'page' : undefined}
        >
          {title}
          {children ? <Icon as={Arrowright} /> : null}
        </Disclosure>
      )}
      {children ? (
        <DisclosureContent state={disclosure} as="ul">
          {children}
        </DisclosureContent>
      ) : null}
    </li>
  );
}

export default SideMenuItem;
