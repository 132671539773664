import useAutoId from 'components/hooks/useAutoId';
import FormControl from './FormControl';
import FormLabel from './FormLabel';

export type FormFieldsetProps = React.ComponentPropsWithRef<typeof FormControl>;

/**
 * We are not using fieldset/legend because the `display: grid` is not properly supported, and borders are tricky.
 */
function FormFieldset({
  children,
  label,
  labelProps,
  gap = 'lg',
  ...props
}: FormFieldsetProps): JSX.Element {
  const labelId = useAutoId(labelProps?.id, props.id);

  return (
    <FormControl role="group" aria-labelledby={label ? labelId : undefined} gap={gap} {...props}>
      {label ? (
        <FormLabel {...({ as: 'div' } as any)} id={labelId} label={label} {...labelProps} />
      ) : null}
      {children}
    </FormControl>
  );
}

export default FormFieldset;
