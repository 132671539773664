import classNames from 'classnames';
import { OverridableComponentProps } from 'components/OverridableComponent';
import { CSSProperties } from 'react';
import './FlexCell.scss';

export type BaseProps = {
  basis?: CSSProperties['flexBasis'];
  shrink?: CSSProperties['flexShrink'];
  grow?: CSSProperties['flexGrow'];
  align?: CSSProperties['alignSelf'];
  sm?: number | string;
  md?: number | string;
};

export type FlexCellProps<A> = OverridableComponentProps<A, 'div', BaseProps>;

function FlexCell<A>({
  as: As = 'div',
  basis,
  shrink,
  grow,
  align,
  sm,
  md,
  children,
  className,
  style,
  ...props
}: FlexCellProps<A>): JSX.Element {
  const rootStyle = {
    '--flexCell-basis': basis,
    '--flexCell-shrink': shrink,
    '--flexCell-grow': grow,
    '--flexCell-align': align,
    '--flexCell-sm': sm,
    '--flexCell-md': md,
    ...style,
  } as CSSProperties;

  return (
    <As className={classNames('FlexCell', className)} style={rootStyle} {...props}>
      {children}
    </As>
  );
}

export default FlexCell;
