import React from 'react';
import './Main.scss';

export type MainProps = {
  children?: React.ReactNode;
};

function Main({ children = null }: MainProps): JSX.Element {
  return <main className="Main">{children}</main>;
}

export default Main;
