import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgInfos({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 15 15"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.438 0a7.438 7.438 0 100 14.875A7.438 7.438 0 007.438 0zm0 3.188a.797.797 0 110 1.593.797.797 0 110-1.594v.001zm2.124 8.566h-4.25v-1.195H6.84V7.504h-.996V6.309h2.191v4.25h1.527v1.195z"
      />
    </svg>
  );
}

export default forwardRef(SvgInfos);
