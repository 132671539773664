import { ConfigState } from 'reducers/configReducer';
import { AppStore } from '../store';

export function selectConfig(store: AppStore): ConfigState {
  return store.config;
}

export function selectResetUrl(store: AppStore): string | undefined {
  return selectConfig(store).resetUrl;
}

export function selectApiBaseUrl(store: AppStore): string | undefined {
  return selectConfig(store).apiBaseUrl;
}

export function selectChatbotSecret(store: AppStore): string | undefined {
  return store.config.chatbotSecret;
}
