import { useEffect, useRef, useState } from 'react';
import type AzureSearchApi from 'services/azureSearchApi';
import type { AzureSearchParams, AzureSearchResponse } from 'services/azureSearchApi';

type ResultsType<D> = AzureSearchResponse<D> | undefined;
type LoadingType = boolean;
type ErrorType = Error | undefined;

export type UseAzureSearchParams = {
  searchText: string;
  host: string;
  queryKey: string;
  environment: string;
  indexName: string;
} & AzureSearchParams;

export type UseAzureSearchReturn<D> = [ResultsType<D>, LoadingType, ErrorType];

function useAzureSearch<D extends Record<string, any>>(
  params?: UseAzureSearchParams,
): UseAzureSearchReturn<D> {
  const azureSearchApiRef = useRef<AzureSearchApi<D>>();

  const [results, setResults] = useState<ResultsType<D>>(undefined);
  const [loading, setLoading] = useState<LoadingType>(false);
  const [error, setError] = useState<ErrorType>(undefined);

  useEffect(() => {
    if (!params) {
      return;
    }

    const { searchText, host, queryKey, environment, indexName, ...options } = params;

    if (!searchText) {
      return;
    }

    let mounted = true;

    handle();

    return () => {
      mounted = false;
    };

    async function handle(): Promise<void> {
      setLoading(true);

      if (!azureSearchApiRef.current) {
        // Lazy load the Azure Search library
        const azureSearchApi = await import(
          /* webpackChunkName: "azure-search" */ 'services/azureSearchApi'
        );
        const AzureSearchApi = azureSearchApi.default;

        azureSearchApiRef.current = new AzureSearchApi<D>(host, queryKey, environment, indexName);
      }

      try {
        const results = await azureSearchApiRef.current.search({ searchText, ...options });

        if (mounted) {
          setResults(results);
          setError(undefined);
        }
      } catch (error) {
        console.error(error);

        if (error instanceof Error) {
          if (mounted) {
            setError(error);
          }
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }
  }, [params]);

  return [results, loading, error];
}

export default useAzureSearch;
