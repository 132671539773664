import { createSelector } from 'reselect';
import { AppStore } from 'store';
import { CandidateCandidacyModel, CandidateMenuModel } from 'models/candidate';
import { SuperviewerState, initialContentState } from 'reducers/candidateReducer';

export function selectCandidacies(store: AppStore) {
  return store.candidate.candidacies.items;
}

export function selectCandidacyList(store: AppStore) {
  return store.candidate.candidacies;
}

export function selectContents(store: AppStore) {
  return store.candidate.contents.items;
}

export function selectContentById(store: AppStore, id: number) {
  return selectContents(store)[id] ?? initialContentState;
}

export function selectDefaultContentId(menu: CandidateMenuModel | undefined | null) {
  return menu?.children?.[0]?.id ?? null;
}

export function selectStatus(candidacy: CandidateCandidacyModel) {
  return candidacy.program
    ? candidacy.program.statuses.find((status) => status.code === candidacy.status)
    : null;
}

export function selectSuperviewer(store: AppStore): SuperviewerState {
  return store.candidate.superviewer;
}

export function selectLoading(store: AppStore) {
  return store.candidate.candidacies.loading;
}

export function makeSelectCandidacy(code: string) {
  return createSelector(selectCandidacies, selectLoading, function (candidacies, loading) {
    const candidacy = candidacies.find(
      (candidacy: CandidateCandidacyModel) => candidacy.programCode === code,
    );

    return {
      candidacy,
      loading,
    };
  });
}
