import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setConfig } from '../../actions/configActions';
import { setBaseUrl } from '../../services/cdmApi';
import { ConfigModel } from '../../models';

const baseUrl = process.env.REACT_APP_FRONT_USE_MOCK
  ? process.env.REACT_APP_API_MOCK_URL
  : process.env.REACT_APP_CDM_URL;

export const CONFIG: ConfigModel = {
  baseUrl: `${process.env.PUBLIC_URL}/`,
  resetUrl: '/password', // Previously was `${baseUrl}/api/v1/application/masters?action=password_reset`
  useStatic: process.env.REACT_APP_FRONT_USE_STATIC === 'true',
  home: process.env.REACT_APP_CDM_HOME || '',
  chatbotSecret: process.env.REACT_APP_CHATBOT_SECRET,
  apiBaseUrl: `${baseUrl}/api/v1`,
};

export type ConfigProviderProps = {
  children: React.ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps): JSX.Element {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const config = CONFIG;

    dispatch(setConfig(config));
    setBaseUrl(config.apiBaseUrl);

    setReady(true);
  }, [dispatch]);

  if (ready) {
    return <>{children}</>;
  }

  return <>{null}</>;
}

export default ConfigProvider;
