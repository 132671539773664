import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import {
  requestCandidacies,
  receiveCandidacies,
  errorCandidacies,
  requestCandidateContent,
  receiveCandidateContent,
  errorCandidateContent,
  updateSuperviewer,
} from 'actions/candidateActions';
import {
  CandidateSuperviewerModel,
  CandidateContentModel,
  CandidateCandidacyModel,
} from 'models/candidate';
import { ErrorModel } from 'models';
import { createPersistConfig, createReducer } from './reducerUtils';

export type SuperviewerState = Readonly<CandidateSuperviewerModel>;

const initialSuperviewerState: SuperviewerState = {
  program: null,
  status: null,
};

function updateSuperviewerReducer(
  state: SuperviewerState,
  action: ReturnType<typeof updateSuperviewer>,
) {
  return {
    ...state,
    [action.payload.field]: action.payload.value,
  };
}

export type CandidaciesState = Readonly<{
  items: CandidateCandidacyModel[];
  loading: boolean;
  error: ErrorModel | null;
}>;

const initialCandidaciesState: CandidaciesState = {
  items: [],
  loading: true,
  error: null,
};

function requestCandidaciesReducer(state: CandidaciesState) {
  return {
    ...state,
    loading: true,
    error: null,
  };
}

function receiveCandidaciesReducer(
  state: CandidaciesState,
  action: ReturnType<typeof receiveCandidacies>,
) {
  return {
    ...state,
    items: action.payload.candidacies,
    loading: false,
  };
}

function errorCandidaciesReducer(
  state: CandidaciesState,
  action: ReturnType<typeof errorCandidacies>,
) {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  };
}

export type ContentState = Readonly<{
  content: CandidateContentModel | null;
  error: ErrorModel | null;
  loading: boolean;
}>;

export type ContentsState = Readonly<{
  items: {
    [id: number]: undefined | ContentState;
  };
}>;

const initialContentsState: ContentsState = {
  items: {},
};

export const initialContentState: ContentState = {
  content: null,
  error: null,
  loading: false,
};

function requestContentReducer(
  state: ContentsState,
  action: ReturnType<typeof requestCandidateContent>,
) {
  return {
    ...state,
    items: {
      ...state.items,
      [action.payload.id]: {
        ...initialContentState,
        ...state.items[action.payload.id],
        loading: true,
        error: null,
      },
    },
  };
}

function receiveContentReducer(
  state: ContentsState,
  action: ReturnType<typeof receiveCandidateContent>,
) {
  return {
    ...state,
    items: {
      ...state.items,
      [action.payload.id]: {
        ...initialContentState,
        ...state.items[action.payload.id],
        content: action.payload.content,
        loading: false,
        error: null,
      },
    },
  };
}

function errorContentReducer(
  state: ContentsState,
  action: ReturnType<typeof errorCandidateContent>,
) {
  return {
    ...state,
    items: {
      ...state.items,
      [action.payload.id]: {
        ...initialContentState,
        ...state.items[action.payload.id],
        loading: false,
        error: action.payload.error,
      },
    },
  };
}

const candidaciesReducer = createReducer(initialCandidaciesState, {
  [requestCandidacies.type]: requestCandidaciesReducer,
  [receiveCandidacies.type]: receiveCandidaciesReducer,
  [errorCandidacies.type]: errorCandidaciesReducer,
});

const contentsReducer = createReducer(initialContentsState, {
  [requestCandidateContent.type]: requestContentReducer,
  [receiveCandidateContent.type]: receiveContentReducer,
  [errorCandidateContent.type]: errorContentReducer,
});

export const superviewerPersistConfig = createPersistConfig('superviewer');

const superviewerReducer = persistReducer(
  superviewerPersistConfig,
  createReducer(initialSuperviewerState, {
    [updateSuperviewer.type]: updateSuperviewerReducer,
  }),
);

export default combineReducers({
  superviewer: superviewerReducer,
  candidacies: candidaciesReducer,
  contents: contentsReducer,
});
