import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgExternalLink(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 1792 1536"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M1408 928v320c0 79.3-28.2 147.2-84.5 203.5S1199.3 1536 1120 1536H288c-79.3 0-147.2-28.2-203.5-84.5S0 1327.3 0 1248V416c0-79.3 28.2-147.2 84.5-203.5S208.7 128 288 128h704c9.3 0 17 3 23 9s9 13.7 9 23v64c0 9.3-3 17-9 23s-13.7 9-23 9H288c-44 0-81.7 15.7-113 47s-47 69-47 113v832c0 44 15.7 81.7 47 113s69 47 113 47h832c44 0 81.7-15.7 113-47s47-69 47-113V928c0-9.3 3-17 9-23s13.7-9 23-9h64c9.3 0 17 3 23 9s9 13.7 9 23zm384-864v512c0 17.3-6.3 32.3-19 45s-27.7 19-45 19-32.3-6.3-45-19l-176-176-652 652c-6.7 6.7-14.3 10-23 10s-16.3-3.3-23-10L695 983c-6.7-6.7-10-14.3-10-23s3.3-16.3 10-23l652-652-176-176c-12.7-12.7-19-27.7-19-45s6.3-32.3 19-45 27.7-19 45-19h512c17.3 0 32.3 6.3 45 19s19 27.7 19 45z" />
    </svg>
  );
}

export default forwardRef(SvgExternalLink);
