import { DateTime, LocaleOptions, DateTimeFormatOptions } from 'luxon';

export type ParsableDate = DateTime | Date | string | number;

export function dateEquals(date1?: DateTime | null, date2?: DateTime | null): boolean {
  if (date1 === date2) {
    return true;
  }

  return DateTime.isDateTime(date1) && DateTime.isDateTime(date2) && date1.equals(date2);
}

export type DateParseOptions = {
  format?: string;
  setZone?: boolean;
};

export function dateParse(
  date: ParsableDate,
  { format, setZone = false }: DateParseOptions = {},
): DateTime {
  if (DateTime.isDateTime(date)) {
    return date;
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  if (typeof date === 'number') {
    return DateTime.fromMillis(date);
  }

  if (format) {
    return DateTime.fromFormat(date, format, { setZone });
  }

  return DateTime.fromISO(date, { setZone });
}

type OnlyPresetKeys<T> = {
  [K in keyof T]: T[K] extends DateTimeFormatOptions ? K : never;
}[keyof T];
type OnlyPresets<T> = Pick<T, OnlyPresetKeys<T>>;

export type DateFormatPreset =
  | keyof OnlyPresets<typeof DateTime>
  | (LocaleOptions & Intl.DateTimeFormatOptions);

export function dateFormat(
  date: ParsableDate,
  preset?: DateFormatPreset,
  format?: string,
  parseOptions?: DateParseOptions,
): string {
  const dt = dateParse(date, parseOptions);

  if (preset) {
    const options = typeof preset === 'string' ? DateTime[preset] : preset;

    return dt.toLocaleString(options as LocaleOptions);
  }

  if (format) {
    return dt.toFormat(format);
  }

  return dt.toISO();
}

export type DateFormatProps = {
  date: ParsableDate;
  preset?: DateFormatPreset;
  format?: string;
  setZone?: boolean;
};

function DateFormat({ date, preset, format, setZone, ...props }: DateFormatProps): JSX.Element {
  return (
    <time title={dateFormat(date, 'DATETIME_SHORT')} dateTime={String(date)} {...props}>
      {dateFormat(date, preset, format, { setZone })}
    </time>
  );
}

export default DateFormat;
