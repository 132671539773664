import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgAgenda({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 35 34"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 2.25V0h-1.5v2.25h-8v31.5H32A2.75 2.75 0 0034.75 31V2.25h-8V0h-1.5v2.25h-7V0h-1.5v2.25h-7zm-1.5 1.5V8h1.5V3.75h7V8h1.5V3.75h7V8h1.5V3.75h6.5V31A1.25 1.25 0 0132 32.25H1.75V3.75h6.5zm3.5 8.5h-6.5v6.5h6.5v-6.5zm-5 5v-3.5h3.5v3.5h-3.5zm14-5h-6.5v6.5h6.5v-6.5zm-5 5v-3.5h3.5v3.5h-3.5zm14-5h-6.5v6.5h6.5v-6.5zm-5 5v-3.5h3.5v3.5h-3.5zm-13 4h-6.5v6.5h6.5v-6.5zm-5 5v-3.5h3.5v3.5h-3.5zm14-5h-6.5v6.5h6.5v-6.5zm-5 5v-3.5h3.5v3.5h-3.5zm14-5h-6.5v6.5h6.5v-6.5zm-5 5v-3.5h3.5v3.5h-3.5z"
      />
    </svg>
  );
}

export default forwardRef(SvgAgenda);
