import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgEasyrecrue(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 512 512"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M256 40c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10z" />
      <path d="M466 210C466 94.206 371.794 0 256 0S46 94.206 46 210c0 96.488 66.579 180.855 159.516 203.859-1.591 14.119-6.958 31.441-13.568 38.051l-.131.131c-18.899.353-32.638 3.149-42.999 8.73C133.677 468.949 126 482.82 126 502c0 5.522 4.478 10 10 10h240c5.522 0 10-4.478 10-10 0-19.187-7.68-33.058-22.824-41.229-10.344-5.58-24.082-8.378-42.992-8.731l-.132-.132c-6.61-6.609-11.977-23.931-13.568-38.05C399.423 390.853 466 306.486 466 210zM316 472c33.23 0 45.303 7.689 48.794 20H147.226c2.172-7.762 6.862-11.345 11.087-13.626C166.274 474.085 178.603 472 196 472h120zm-100.483-20c5.068-10.601 8.238-23.466 9.638-34.27A211.376 211.376 0 00256 420c10.342 0 20.674-.768 30.845-2.27 1.401 10.804 4.57 23.67 9.638 34.27h-80.966zm78.498-55.821l-.056.011c-24.788 5.056-51.127 5.057-75.922-.001l-.052-.01C129.918 378.227 66 299.929 66 210c0-104.767 85.233-190 190-190s190 85.233 190 190c0 89.929-63.918 168.227-151.985 186.179z" />
      <path d="M389.606 104.994c-23.072-29.303-55.544-50.505-91.434-59.701-5.355-1.374-10.799 1.855-12.17 7.205-1.37 5.35 1.855 10.798 7.205 12.169 31.66 8.112 60.314 26.828 80.686 52.7 3.426 4.352 9.716 5.077 14.043 1.67 4.339-3.416 5.087-9.704 1.67-14.043zM256 100c-60.654 0-110 49.346-110 110s49.346 110 110 110 110-49.346 110-110-49.346-110-110-110zm0 200c-49.626 0-90-40.374-90-90s40.374-90 90-90 90 40.374 90 90-40.374 90-90 90z" />
      <path d="M256 140c-38.598 0-70 31.402-70 70s31.402 70 70 70 70-31.402 70-70-31.402-70-70-70zm0 120c-27.57 0-50-22.43-50-50s22.43-50 50-50 50 22.43 50 50-22.43 50-50 50z" />
    </svg>
  );
}

export default forwardRef(SvgEasyrecrue);
