import React from 'react';
import classNames from 'classnames';
import { Override } from 'types';
import useTranslate, { TranslatorId, TranslatorValues } from 'components/hooks/useTranslate';
import './FormError.scss';

type Error = string | { id: TranslatorId; values?: TranslatorValues };

function isError(error: unknown): error is Error {
  return (
    typeof error === 'string' || (typeof error === 'object' && null !== error && 'id' in error)
  );
}

export type FormErrorsProps = Override<
  React.ComponentPropsWithoutRef<'p'>,
  {
    error?: Error;
  }
>;

function FormError({ error, children, className, ...props }: FormErrorsProps): JSX.Element | null {
  const t = useTranslate();

  return children || error ? (
    <div className={classNames('FormError', className)} {...props}>
      {isError(error)
        ? typeof error === 'string'
          ? t(error)
          : t(error.id, error.values)
        : children}
    </div>
  ) : null;
}

export default FormError;
