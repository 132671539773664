import React from 'react';
import './Page.scss';

export type PageProps = {
  children?: React.ReactNode;
};

function Page({ children = null }: PageProps): JSX.Element {
  return <div className="Page">{children}</div>;
}

export default Page;
