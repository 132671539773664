import { receivePage, receiveBlock, requestBlock } from '../actions/treeActions';
import { NodeModel, PageBehaviorModel } from '../models';
import { createReducer } from './reducerUtils';

export type NodeState<B> =
  | undefined
  | (NodeModel<B> & {
      parameters: object;
      loading: boolean;
      error: string | null;
    });

export type NodesMap = {
  root?: NodeState<PageBehaviorModel>;
  [identifier: number]: NodeState<unknown>;
};

export type NodesState = Readonly<NodesMap>;

export const initialState: NodesState = {};

const DEFAULT_PARAMS = {};
const DEFAULT_LOADING = false;

function isNode(node: NodeModel<unknown> | unknown): node is NodeModel<unknown> {
  return (
    typeof node === 'object' &&
    node !== null &&
    'identifier' in node &&
    'code' in node &&
    'behavior' in node
  );
}

function hasBehavior(node: NodeModel<unknown>): node is NodeModel<object> {
  return node.behavior !== null && typeof node.behavior === 'object' && node.family !== null;
}

function isPage(node: NodeState<any>): node is NodeState<PageBehaviorModel> {
  return node !== undefined && node.family === 'Page';
}

/**
 * Used to flatten a block/page which result in a registry based on the nodes identifier.
 * Something like :
 * [
 *  10: {...},
 *  23: {...},
 *  678: {...},
 *  1893: {...},
 * ]
 */
function flattenNode(
  node: NodeModel<any> | unknown,
  parameters = DEFAULT_PARAMS,
  loading = DEFAULT_LOADING,
  error = null,
) {
  const nodes: NodesMap = {};

  // Check if its really a node
  if (!isNode(node)) {
    return nodes;
  }

  nodes[node.identifier] = {
    ...node,
    parameters,
    loading,
    error,
  };

  function flattener(node: NodeModel<any>) {
    Object.assign(nodes, flattenNode(node));
  }

  // Flatten its children
  if (Array.isArray(node.content)) {
    node.content.forEach(flattener);
  }

  // Loop through every behavior properties to check if there are nodes to be flattened.
  if (hasBehavior(node)) {
    Object.values(node.behavior).forEach(function mapper(maybeNodes: unknown) {
      if (Array.isArray(maybeNodes)) {
        maybeNodes.forEach(flattener);
      }
    });
  }

  return nodes;
}

/**
 * Find node object in state from identifier or initialize it
 */
function resolveNode(state: NodesState, identifier: NodeModel['identifier'], node?: NodeModel) {
  if (node) {
    return node;
  }

  if (undefined !== state[identifier]) {
    return state[identifier];
  }

  return { id: identifier };
}

function requestBlockReducer(
  state: NodesState,
  action: ReturnType<typeof requestBlock>,
): NodesState {
  const node = resolveNode(state, action.payload.identifier);
  const flattenedNode = flattenNode(node, action.payload.parameters, true);

  return {
    ...state,
    ...flattenedNode,
  };
}

function receiveBlockReducer(
  state: NodesState,
  action: ReturnType<typeof receiveBlock>,
): NodesState {
  const node = resolveNode(state, action.payload.identifier, action.payload.node);
  const flattenedNode = flattenNode(node, action.payload.parameters, false);

  return {
    ...state,
    ...flattenedNode,
  };
}

function receivePageReducer(state: NodesState, action: ReturnType<typeof receivePage>): NodesState {
  const { tree } = action.payload;

  // If received tree has a zone (hence doesn't have the layout), use the previous content and zones
  if (tree.behavior.zone && state.root) {
    const { content } = state.root;

    tree.content = content.map((child) => {
      const newChild = tree.content.find((newChild) => newChild.code === child.code);

      if (newChild) {
        return {
          ...child,
          content: newChild.content,
        };
      }

      return child;
    });
  }

  const flattenedTree = flattenNode(tree);
  const root = flattenedTree[tree.identifier];

  if (isPage(root)) {
    return {
      ...state,
      ...flattenedTree,
      root,
    };
  }

  return {
    ...state,
    ...flattenedTree,
  };
}

export default createReducer(initialState, {
  [requestBlock.type]: requestBlockReducer,
  [receiveBlock.type]: receiveBlockReducer,
  [receivePage.type]: receivePageReducer,
});
