import { Children, ReactNode } from 'react';
import './Footer.scss';

export type FooterSocialsProps = {
  children?: ReactNode;
  findUs?: string;
};

function FooterSocials({ children, findUs }: FooterSocialsProps): JSX.Element {
  return (
    <div className="Footer-socials">
      {findUs ? <span className="Footer-findUs">{findUs}</span> : null}
      <ul className="Footer-socialsList">
        {Children.map(children, (child, i) => (
          <li className="Footer-linkItem" key={i}>
            <div className={`Footer-dividerIcon ${i === 0 ? 'Footer-firstIconDivider' : ''}`}></div>
            {child}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FooterSocials;
