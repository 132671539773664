import classNames from 'classnames';
import React from 'react';
import { Override } from 'types';
import './SidebarItem.scss';

type BaseProps = {
  startAdornement?: React.ReactNode;
  children?: React.ReactNode;
  multiline?: boolean;
};

export type SidebarItemProps = Override<React.ComponentPropsWithoutRef<'li'>, BaseProps>;

function SidebarItem({
  startAdornement,
  multiline,
  children,
  className,
  ...props
}: SidebarItemProps): JSX.Element {
  return (
    <li
      className={classNames('SidebarItem', className, {
        [`is-multiline`]: multiline,
      })}
      {...props}
    >
      {startAdornement ? <div className="SidebarItem-start">{startAdornement}</div> : null}
      <div className="SidebarItem-content">{children}</div>
    </li>
  );
}

export default SidebarItem;
