import React from 'react';
import Link from 'components/utils/Link';
import Button from 'components/ui/Button';
import { LinkViewProps } from './LinkBehavior';

type LinkProps = React.ComponentPropsWithRef<typeof Link>;
type ButtonProps = React.ComponentPropsWithRef<typeof Button>;

export type DefaultLinkViewProps = LinkViewProps & (LinkProps | ButtonProps | {});

function isLink(props: LinkProps | ButtonProps): props is LinkProps {
  return 'to' in props && typeof props.to === 'string';
}

function isButton(props: LinkProps | ButtonProps): props is ButtonProps {
  return 'onClick' in props && typeof props.onClick === 'function';
}

function DefaultLinkView({
  identifier,
  title,
  titleLong,
  description,
  icon,
  content,
  type,
  ...props
}: DefaultLinkViewProps): JSX.Element {
  if (isLink(props)) {
    return <Link {...props}>{title}</Link>;
  }

  if (isButton(props)) {
    return <Button {...props}>{title}</Button>;
  }

  return <span {...props}>{title}</span>;
}

export default DefaultLinkView;
