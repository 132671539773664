import { MutableRefObject, useEffect, useRef } from 'react';

type ClickOutsideHandler = (e: MouseEvent) => void;

function useClickOutside<T extends HTMLElement>(
  onClickOutside: ClickOutsideHandler,
  initialContainer?: T,
  async?: boolean,
): MutableRefObject<T | null> {
  const containerRef = useRef<T>(initialContainer || null);

  useEffect(() => {
    const container = containerRef.current;

    const handleClickOutside = (e: MouseEvent): void => {
      if (e.target instanceof Node && container && !container.contains(e.target)) {
        onClickOutside(e);
      }
    };

    window.document.addEventListener('click', handleClickOutside);

    return () => {
      if (async) {
        // An async thread is sometimes needed to prevent removing the listener when clicking on a link which reload the page (like home link),
        // which would clean this effect before executing the onClickOutside callback.
        setTimeout(() => {
          window.document.removeEventListener('click', handleClickOutside);
        }, 1);
      } else {
        window.document.removeEventListener('click', handleClickOutside);
      }
    };
  }, [onClickOutside, async]);

  return containerRef;
}

export default useClickOutside;
