import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgSyllabusBuilder(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 508 508"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M319.5 164.8l-95.2-42.4c-4.3-1.9-9.3-1-12.6 2.3-3.3 3.3-4.3 8.3-2.3 12.6l42.4 95.2c2.5 5.7 9.3 8.4 15 5.7 5.4-2.5 8-9.2 5.6-14.9l-9.6-21.5 25.9-25.9 21.5 9.6c5.7 2.5 12.4 0 15-5.7 2.5-5.7 0-12.4-5.7-15zm-66.6 14.8L242 155.2l24.5 10.9-13.6 13.5z" />
      <path d="M453.9 239.9h-83l34.5-34.5c21.1-21.1 21.1-55.4 0-76.5L292.2 15.8C282 5.6 268.4 0 254 0c-14.4 0-28 5.6-38.2 15.8L102.7 128.9c-21.1 21.1-21.1 55.4 0 76.5l34.5 34.5h-83C24.3 239.9 0 264.2 0 294v159.9C0 483.7 24.3 508 54.1 508H214c15.8 0 30.1-6.9 40-17.8 9.9 10.9 24.1 17.8 40 17.8h159.9c29.8 0 54.1-24.3 54.1-54.1V294c0-29.8-24.3-54.1-54.1-54.1zm-214 214c0 14.3-11.6 25.9-25.9 25.9H54.1c-14.3 0-25.9-11.6-25.9-25.9V294c0-14.3 11.6-25.9 25.9-25.9h111.3l50.4 50.4c6.8 6.8 15.1 11.6 24.1 14v121.4zM254 306.1c-6.9 0-13.4-2.7-18.3-7.6l-113.1-113c-10.1-10.1-10.1-26.5 0-36.6L235.7 35.8c4.9-4.9 11.4-7.6 18.3-7.6s13.4 2.7 18.3 7.6l113.1 113.1c10.1 10.1 10.1 26.5 0 36.6L272.3 298.6c-4.9 4.8-11.4 7.5-18.3 7.5zm225.8 147.8c0 14.3-11.6 25.9-25.9 25.9H294c-14.3 0-25.9-11.6-25.9-25.9V332.5c9-2.4 17.3-7.2 24.1-14l50.4-50.4h111.3c14.3 0 25.9 11.6 25.9 25.9v159.9z" />
      <path d="M170.3 349.8c0-19.8-16-35.8-35.6-35.8h-25.5c-6.3 0-11.3 5.1-11.3 11.3v97.4c0 6.3 5.1 11.3 11.3 11.3h25.5c19.6 0 35.6-16 35.6-35.6 0-9.4-3.7-18-9.8-24.3 6-6.4 9.8-14.9 9.8-24.3zm-35.6 61.4h-14.2v-25.9h14.2c7.1 0 12.9 5.8 12.9 12.9 0 7.2-5.8 13-12.9 13zm0-48.7h-14.2v-25.9h14.2c7.1 0 12.9 5.8 12.9 12.9 0 7.2-5.8 13-12.9 13zm271.4 36.8c-7 7.7-17 12-27.4 12-20.6 0-37.3-16.7-37.3-37.3s16.7-37.3 37.3-37.3c12 0 22.9 5.5 30 15.1 3.7 5 10.8 6.1 15.9 2.4 5-3.7 6.1-10.8 2.4-15.9-11.3-15.2-29.4-24.3-48.3-24.3-33.1 0-60 26.9-60 60s26.9 60 60 60c16.7 0 32.8-7.1 44.1-19.4 4.2-4.6 3.9-11.8-.7-16-4.6-4.2-11.8-3.9-16 .7z" />
    </svg>
  );
}

export default forwardRef(SvgSyllabusBuilder);
