import React from 'react';
import Main from 'components/ui/Main/Main';
import useTranslate from 'components/hooks/useTranslate';
import Header from 'components/ui/Header/Header';

function MessagePage(props: React.ComponentProps<'div'>): JSX.Element {
  const t = useTranslate();

  return (
    <>
      <Header homeLink={<a href="/">{t('identity.home')}</a>} title={t('identity.baseline')} />
      <Main>
        <div {...props} />
      </Main>
    </>
  );
}

export default MessagePage;
