import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Settings } from 'luxon';
import { findDefaultTimezone } from '../../services/localeHelper';
import { selectTimezone } from '../../selectors/userSelectors';

export const TimezoneContext = React.createContext<string | null>(null);

export type TimezoneProviderProps = {
  children: React.ReactNode;
};

function TimezoneProvider({ children }: TimezoneProviderProps): JSX.Element {
  const timezone = useSelector(selectTimezone) || findDefaultTimezone();

  useEffect(() => {
    if (timezone) {
      Settings.defaultZoneName = timezone;
    }
  }, [timezone]);

  return <TimezoneContext.Provider value={timezone}>{children}</TimezoneContext.Provider>;
}

export default TimezoneProvider;
