import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgConciergeriePedagogique(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 100 125"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M73.05 56.23A10.8 10.8 0 1084 67a10.88 10.88 0 00-10.95-10.77zm0 16.59A5.8 5.8 0 1179 67a5.88 5.88 0 01-5.95 5.82zm19.29 16.6v7.08a2.5 2.5 0 01-5 0v-7.08a4.86 4.86 0 00-4.91-4.79h-1.66l-5.16 5.08a3.67 3.67 0 01-5.12 0l-5.16-5.08h-1.66a4.86 4.86 0 00-4.91 4.79v7.08a2.5 2.5 0 01-5 0v-7.08a9.86 9.86 0 019.91-9.79h2.68a2.5 2.5 0 011.75.72l4.95 4.86L78 80.36a2.5 2.5 0 011.75-.72h2.68a9.86 9.86 0 019.91 9.78zm-65.39-11.6A10.8 10.8 0 1016 67a10.88 10.88 0 0010.95 10.82zm0-16.59A5.8 5.8 0 1121 67a5.88 5.88 0 015.95-5.77zm19.29 28.2v7.07a2.5 2.5 0 01-5 0v-7.08a4.86 4.86 0 00-4.91-4.79h-1.66l-5.16 5.08a3.67 3.67 0 01-5.12 0l-5.16-5.08h-1.66a4.86 4.86 0 00-4.91 4.79v7.08a2.5 2.5 0 01-5 0v-7.08a9.86 9.86 0 019.91-9.79h2.68a2.5 2.5 0 011.75.72l4.95 4.86 4.95-4.86a2.5 2.5 0 011.75-.72h2.68a9.86 9.86 0 019.91 9.79zm15.13-46.69l11.44 9.18a2.5 2.5 0 004.06-2v-7.18h5.27a7.1 7.1 0 007.09-7.09V8.09A7.1 7.1 0 0082.14 1H54.59A6.59 6.59 0 0050 2.72 7.06 7.06 0 0045.41 1H17.85a7.1 7.1 0 00-7.09 7.09v27.56a7.1 7.1 0 007.09 7.09h5.25V50a2.5 2.5 0 004.06 1.95l11.47-9.21h6.77A7.06 7.06 0 0050 41a6.71 6.71 0 004.56 1.72zM51.35 24a3.85 3.85 0 00-3.85 3.85v7.84a2.1 2.1 0 01-2.09 2.09h-7.65a2.5 2.5 0 00-1.56.55l-8.1 6.46v-4.55a2.5 2.5 0 00-2.5-2.5h-7.75a2.09 2.09 0 01-2.09-2.09V8.09A2.09 2.09 0 0117.85 6h27.56a2.1 2.1 0 012.09 2.09v7.84a3.85 3.85 0 003.85 3.85h.29A3.91 3.91 0 0054 19a3.55 3.55 0 012.14-.73 3.62 3.62 0 01.94 7.13 3.52 3.52 0 01-3.08-.64 3.88 3.88 0 00-2.35-.8zm1.15 11.69v-6a8.49 8.49 0 005.79.54 8.62 8.62 0 00-2.17-17 8.45 8.45 0 00-3.62.81v-6A2.09 2.09 0 0154.59 6h27.55a2.1 2.1 0 012.09 2.09v27.56a2.1 2.1 0 01-2.09 2.09h-7.76a2.5 2.5 0 00-2.5 2.5v4.52l-8.06-6.47a2.5 2.5 0 00-1.56-.55h-7.67a2.09 2.09 0 01-2.09-2.05z" />
      <text
        y={115}
        fontSize={5}
        fontWeight="bold"
        fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
      >
        {'Created by Gregor Cresnar'}
      </text>
      <text
        y={120}
        fontSize={5}
        fontWeight="bold"
        fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
      >
        {'from the Noun Project'}
      </text>
    </svg>
  );
}

export default forwardRef(SvgConciergeriePedagogique);
