import React, { useState, useCallback, useEffect } from 'react';
import Cta from 'components/ui/Cta/Cta';
import ChevronUp from 'components/ui/Icon/actions/ChevronUp';
import { Override } from 'types';
import './TopButton.scss';
import classNames from 'classnames';

export type TopButtonProps = Override<
  React.ComponentPropsWithoutRef<'div'>,
  {
    children: React.ReactNode;
    fixed?: boolean;
  }
>;

function TopButton({ children, fixed, ...props }: TopButtonProps): JSX.Element {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    function onScroll(): void {
      setScroll(window.pageYOffset > 0);
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const handleClick = useCallback(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div
      className={classNames('TopButton', { 'is-fixed': fixed })}
      aria-hidden={scroll ? 'false' : 'true'}
      {...props}
    >
      <span className="TopButton-content">{children}</span>
      <Cta icon={ChevronUp} iconOnly children="up-page" onClick={handleClick} kind="secondary" />
    </div>
  );
}

export default TopButton;
