import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgSearch({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M10 2.25a7.75 7.75 0 015.985 12.674l6.045 6.046-1.06 1.06-6.046-6.045A7.75 7.75 0 1110 2.25zm0 1.5a6.25 6.25 0 100 12.5 6.25 6.25 0 000-12.5z" />
    </svg>
  );
}

export default forwardRef(SvgSearch);
