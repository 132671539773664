import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgGoodies({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="-90 0 583.344 493.344"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M379.146 109.951c-7.619 0-15.066.94-22.263 2.645V69.523C356.883 21.9 264.381.21 178.432.21 92.501.209 0 21.9 0 69.523v354.319c0 47.61 92.501 69.292 178.433 69.292 85.949 0 178.451-21.682 178.451-69.31v-43.273c7.196 1.71 14.644 2.646 22.263 2.646 62.974 0 114.197-61.29 114.197-136.624 0-75.333-51.224-136.622-114.198-136.622zM178.433 33.348c94.396 0 145.312 25.678 145.312 36.175 0 10.493-50.916 36.158-145.312 36.158-94.379 0-145.295-25.665-145.295-36.158 0-10.497 50.916-36.175 145.295-36.175zm200.713 300.144c-7.847 0-15.289-2.173-22.263-5.656V165.311c6.974-3.478 14.416-5.656 22.263-5.656 35.564 0 64.491 38.995 64.491 86.918.001 47.924-28.927 86.919-64.491 86.919z" />
    </svg>
  );
}

export default forwardRef(SvgGoodies);
