import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { Override } from 'types';
import './Editorial.scss';

export type EditorialProps = Override<
  React.ComponentPropsWithoutRef<'div'>,
  {
    scroll?: boolean;
  }
>;

function Editorial(
  { className, scroll, children, ...props }: EditorialProps,
  ref: React.Ref<HTMLDivElement>,
): JSX.Element {
  return (
    <div
      ref={ref}
      className={classNames('Editorial', className, {
        'has-scroll': scroll,
      })}
      {...props}
    >
      {children}
    </div>
  );
}

export default forwardRef(Editorial);
