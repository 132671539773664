import { NotificationModel, NotificationListParams } from 'models/notification';
import { ErrorModel, PagerModel } from 'models';

export type NotificationListName = 'simple' | 'full';

export function requestNotificationList(
  list: NotificationListName,
  params?: NotificationListParams,
) {
  return {
    type: String(requestNotificationList),
    payload: {
      list,
      params,
    },
  };
}
requestNotificationList.toString = () => 'notification/list/request';

export function receiveNotificationList(
  list: NotificationListName,
  data: PagerModel<NotificationModel>,
) {
  return {
    type: String(receiveNotificationList),
    payload: {
      list,
      data,
    },
  };
}
receiveNotificationList.toString = () => 'notification/list/receive';

export function errorNotificationList(list: NotificationListName, error: ErrorModel) {
  return {
    type: String(errorNotificationList),
    payload: {
      list,
      error,
    },
  };
}
errorNotificationList.toString = () => 'notification/list/error';

export function receiveNotificationDetail(data: NotificationModel) {
  return {
    type: String(receiveNotificationDetail),
    payload: {
      data,
    },
  };
}
receiveNotificationDetail.toString = () => 'notification/detail/receive';
