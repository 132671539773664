import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgComiteEntreprise(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 380.65 380.65"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M310.805 268.65h-44.84a53.568 53.568 0 00-26.44 6.96 54.529 54.529 0 00-17.4 15.64 53.75 53.75 0 00-9.28-.8h-44.88a53.651 53.651 0 00-9.28.8 54.484 54.484 0 00-17.4-15.64 53.568 53.568 0 00-26.44-6.96h-44.84c-29.796.066-53.934 24.204-54 54v30.44a5.8 5.8 0 005.8 5.76h92v16a5.8 5.8 0 005.8 5.8h141.44a5.8 5.8 0 005.8-5.8v-16h92a5.8 5.8 0 005.8-5.8v-30.4c-.066-29.734-24.107-53.846-53.84-54zm-196.4 70.6v8h-86.8v-24.6c.066-23.39 19.01-42.334 42.4-42.4h44.84a41.998 41.998 0 0120.72 5.44 43.083 43.083 0 0111.24 9.16 54.281 54.281 0 00-32.4 44.4zm140.72 5.2v24.64h-129.56v-33.72l.24-.96.4-1.44.28-.96c5.746-17.415 21.982-29.206 40.32-29.28l1.16-.64h44.88c21.297.078 39.258 15.885 42.04 37 0 .88 0 1.76.24 2.64v2.72zm98.08 2.84h-86.4v-16.8l-.28-1v-1.96l-.56-1.56a54.44 54.44 0 00-29.44-30.84 43.081 43.081 0 018.68-9.44 41.998 41.998 0 0120.72-5.44h44.88c23.389.066 42.334 19.01 42.4 42.4v24.64z" />
      <circle cx={190.645} cy={77.85} r={6.56} />
      <path d="M121.285 189.17a40.682 40.682 0 00-28.88-12c-22.555-.02-40.856 18.248-40.876 40.804-.02 22.555 18.248 40.856 40.804 40.876s40.856-18.248 40.876-40.804a40.841 40.841 0 00-11.924-28.876zm-8.2 49.48c-11.417 11.421-29.93 11.425-41.352.008-11.421-11.417-11.425-29.93-.008-41.352 11.417-11.421 29.93-11.425 41.352-.008a29.24 29.24 0 018.568 20.672 29.157 29.157 0 01-8.56 20.68z" />
      <circle cx={233.045} cy={77.85} r={6.56} />
      <path d="M258.725 22.73A109.64 109.64 0 00190.405.01a109.642 109.642 0 00-68.32 22.72c-17.92 14.32-29 34.24-29 56.28a66.478 66.478 0 008.28 32 80.804 80.804 0 0020.36 24l-4.6 30.6a5.76 5.76 0 008.6 5.84l31.28-18.24a114.317 114.317 0 0015.12 3.4 120.053 120.053 0 0018.32 1.4 109.64 109.64 0 0068.32-22.72c17.88-14.36 28.96-34.24 28.96-56.28s-11.08-41.96-29-56.28zm-7.2 103.6a98.038 98.038 0 01-61.12 20c-5.53.008-11.053-.406-16.52-1.24a102.766 102.766 0 01-16-3.72 5.76 5.76 0 00-4.64.52l-22.84 13.48 3.32-22a5.799 5.799 0 00-2.36-5.56 70.206 70.206 0 01-20-22.4 54.964 54.964 0 01-6.8-26.4c0-18.4 9.4-35.12 24.6-47.28a98.038 98.038 0 0161.12-20 98.038 98.038 0 0161.12 20c15.2 12 24.6 28.88 24.6 47.28 0 18.4-9.28 35.12-24.48 47.32zm36.913 50.8c-22.555-.02-40.856 18.248-40.876 40.804a40.835 40.835 0 0011.924 28.876 40.682 40.682 0 0028.88 12c22.555.02 40.856-18.248 40.876-40.804s-18.249-40.856-40.804-40.876zm20.647 61.503c-11.417 11.421-29.93 11.425-41.352.008a29.24 29.24 0 01-8.568-20.672 29.157 29.157 0 018.56-20.68c11.417-11.421 29.93-11.425 41.352-.008 11.421 11.417 11.425 29.931.008 41.352z" />
      <circle cx={148.245} cy={77.85} r={6.56} />
      <path d="M219.225 210.83a40.8 40.8 0 00-28.82-11.9 40.682 40.682 0 00-28.88 12c-15.906 15.961-15.861 41.794.1 57.7 15.961 15.906 41.794 15.861 57.7-.1s15.861-41.794-.1-57.7zm-28.828 58.212c-16.149-.005-29.236-13.1-29.232-29.248s13.1-29.236 29.248-29.232a29.24 29.24 0 0120.672 8.568 29.157 29.157 0 018.56 20.68c-.005 16.149-13.1 29.236-29.248 29.232z" />
    </svg>
  );
}

export default forwardRef(SvgComiteEntreprise);
