import React, { useMemo, useState, useEffect } from 'react';
import { DateTime, Duration } from 'luxon';
import { useIntl } from 'react-intl';
import { Override } from 'types';
import { ParsableDate, dateParse, dateFormat } from './DateFormat';

const UNIT_MAPPING = [
  'years',
  //'quarters',
  'months',
  'weeks',
  'days',
  'hours',
  'minutes',
  'seconds',
  //'milliseconds',
] as const;

type DateAgoObject = {
  unit: (typeof UNIT_MAPPING)[number];
  value: number;
  refreshAfter: Duration;
};

function getDateAgo(date: DateTime): DateAgoObject {
  const now = DateTime.local();
  const duration = dateParse(date).diff(now, [...UNIT_MAPPING]);

  for (const i in UNIT_MAPPING) {
    const unit = UNIT_MAPPING[i];
    if (duration[unit]) {
      return {
        unit,
        value: Math.round(duration[unit]),
        refreshAfter: Duration.fromObject({ [unit]: 1 }),
      };
    }
  }

  // Defaults to seconds
  return {
    unit: 'seconds',
    value: 0,
    refreshAfter: Duration.fromObject({ seconds: 1 }),
  };
}

function useDateAgo(date: DateTime): string {
  const intl = useIntl();
  const [count, setCount] = useState(1); // Trick to force refresh

  const dateAgo: DateAgoObject = useMemo(() => {
    // The trick is here...
    if (count) {
      return getDateAgo(date);
    } else {
      return getDateAgo(date);
    }
  }, [count, date]);

  // Update the dateAgo object
  useEffect(() => {
    const seconds = Math.max(dateAgo.refreshAfter.as('seconds'), 60) + 5; // bit of leeway
    const timer = setTimeout(() => {
      setCount((count) => count + 1);
    }, seconds * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dateAgo]);

  return intl.formatRelativeTime(dateAgo.value, dateAgo.unit, { numeric: 'auto' });
}

export type DateAgoProps = Override<
  React.ComponentPropsWithoutRef<'time'>,
  {
    date: ParsableDate;
  }
>;

function DateAgo({ date, ...props }: DateAgoProps): JSX.Element {
  const datetime: DateTime = useMemo(() => dateParse(date), [date]);
  const dateAgo = useDateAgo(datetime);

  return (
    <time title={dateFormat(datetime, 'DATETIME_SHORT')} dateTime={String(datetime)} {...props}>
      {dateAgo}
    </time>
  );
}

export default DateAgo;
