import React, { forwardRef } from 'react';
import { useFormikContext, FormikContextType, FormikProvider } from 'formik';
import { Override } from 'types';

type BaseProps<V> = {
  formik?: FormikContextType<V>;
};

export type FormProps<V> = Override<React.ComponentPropsWithRef<'form'>, BaseProps<V>>;

// iOS needs an "action" attribute for nice input: https://stackoverflow.com/a/39485162/406725
// We default the action to "#" in case the preventDefault fails (just updates the URL hash)
function Form<V>(
  { action = '#', children, formik: formikProps, ...props }: FormProps<V>,
  ref: React.Ref<HTMLFormElement>,
): JSX.Element {
  const formikContext = useFormikContext<V>();
  const { handleSubmit, handleReset } = formikProps ?? formikContext ?? {};

  const form = (
    <form
      ref={ref}
      action={action}
      onSubmit={handleSubmit}
      onReset={handleReset}
      noValidate
      {...props}
    >
      {children}
    </form>
  );

  // If the formik was passed as props, use it in a provider to allow child to use this formik
  if (formikProps) {
    return <FormikProvider value={formikProps}>{form}</FormikProvider>;
  }

  return form;
}

// 'as' needed because forwardRef does not keep the generic type...
export default forwardRef(Form) as <V>(
  props: FormProps<V> & { ref?: React.Ref<HTMLFormElement> },
) => JSX.Element;
