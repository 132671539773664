import { useState, useMemo, Dispatch, SetStateAction } from 'react';

export type ToggableType = [
  boolean,
  () => void,
  () => void,
  () => void,
  Dispatch<SetStateAction<boolean>>,
];

export default function useToggable(initial = false): ToggableType {
  const [open, setOpen] = useState(initial);

  // Memoize the handlers to keep same function ref and prevent any unneeded rendering
  const { handleToggle, handleOpen, handleClose } = useMemo(() => {
    return {
      handleToggle: () => setOpen((open) => !open),
      handleOpen: () => setOpen(true),
      handleClose: () => setOpen(false),
    };
  }, []);

  return [open, handleToggle, handleOpen, handleClose, setOpen];
}
