import { takeEvery, call, put, fork } from 'redux-saga/effects';
import cdmApi, { resolveError } from 'services/cdmApi';
import {
  createEvent,
  deleteEvent,
  createEventSuccess,
  createEventError,
  deleteEventSuccess,
  deleteEventError,
  createBooking,
  deleteBooking,
  createBookingSuccess,
  createBookingError,
  deleteBookingSuccess,
  deleteBookingError,
} from 'actions/eventActions';

export function* createEventTask(action: ReturnType<typeof createEvent>): any {
  const { event } = action.payload;
  try {
    const response = yield call(cdmApi.post, '/user/event', {
      event,
    });
    yield put(createEventSuccess(event, response.status));
  } catch (error) {
    const err = resolveError(error);
    yield put(createEventError(event, err));
  }
}

export function* deleteEventTask(action: ReturnType<typeof deleteEvent>): any {
  const { event } = action.payload;
  try {
    const response = yield call(cdmApi.delete, `/user/event/${event.uid}`);
    yield put(deleteEventSuccess(event, response.status));
  } catch (error) {
    const err = resolveError(error);
    yield put(deleteEventError(event, err));
  }
}

export function* watchCreateEvent() {
  yield takeEvery(createEvent.type, createEventTask);
}

export function* watchDeleteEvent() {
  yield takeEvery(deleteEvent.type, deleteEventTask);
}

export function* createBookingTask(action: ReturnType<typeof createBooking>): any {
  const { booking } = action.payload;
  try {
    const response = yield call(cdmApi.post, '/bookings', {
      booking,
    });
    yield put(createBookingSuccess(booking, response.status));
  } catch (error) {
    const err = resolveError(error);
    yield put(createBookingError(booking, err));
  }
}

export function* deleteBookingTask(action: ReturnType<typeof deleteBooking>): any {
  const { booking } = action.payload;
  if (booking.id) {
    try {
      const response = yield call(cdmApi.delete, `/bookings/${booking.id}`);
      yield put(deleteBookingSuccess(booking, response.status));
    } catch (error) {
      const err = resolveError(error);
      yield put(deleteBookingError(booking, err));
    }
  }
}

export function* watchCreateBooking() {
  yield takeEvery(createBooking.type, createBookingTask);
}

export function* watchDeleteBooking() {
  yield takeEvery(deleteBooking.type, deleteBookingTask);
}

export default function* eventSaga() {
  yield fork(watchCreateEvent);
  yield fork(watchDeleteEvent);
  yield fork(watchCreateBooking);
  yield fork(watchDeleteBooking);
}
