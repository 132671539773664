import { requestData, receiveData, errorData } from 'actions/dataActions';
import { ErrorModel, DataModel } from 'models';
import { createReducer } from './reducerUtils';

export type DataState = Readonly<{
  data: DataModel;
  error?: ErrorModel;
}>;

const initialState: DataState = {
  data: {},
  error: undefined,
};

function requestDataReducer(state: DataState, action: ReturnType<typeof requestData>) {
  return {
    ...state,
    error: undefined,
  };
}

function receiveDataReducer(state: DataState, action: ReturnType<typeof receiveData>) {
  return {
    ...state,
    data: {
      ...state.data,
      ...action.payload.data,
    },
  };
}

function errorDataReducer(state: DataState, action: ReturnType<typeof errorData>) {
  return {
    ...state,
    error: action.payload.error,
  };
}

export default createReducer(initialState, {
  [requestData.type]: requestDataReducer,
  [receiveData.type]: receiveDataReducer,
  [errorData.type]: errorDataReducer,
});
