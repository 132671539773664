import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Disclosure, DisclosureContent, useDisclosureState } from 'ariakit';
import { useSelector } from 'react-redux';
import { LinkModel } from 'models';
import nl2br from 'lib/nl2br';
import { selectTreeRoot } from 'selectors/treeSelectors';
import Link, { isExternalUrl } from 'components/utils/Link';
import Editorial from 'components/ui/Editorial';
import Banner from 'components/ui/Banner/Banner';
import TwoColumnsLayout from 'components/ui/compositions/TwoColumnsLayout/TwoColumnsLayout';
import Separator from 'components/ui/Separator/Separator';
import Sidebar from 'components/ui/Sidebar/Sidebar';
import useMessages from 'components/hooks/useMessages';
import SidebarSection from 'components/ui/Sidebar/SidebarSection';
import SidebarItem from 'components/ui/Sidebar/SidebarItem';
import Cta from 'components/ui/Cta/Cta';
import Mail from 'components/ui/Icon/service/Mail';
import Download from 'components/ui/Icon/service/Download';
import EditorialHeader from 'components/static/components/EditorialHeader/EditorialHeader';
import Title from 'components/ui/Title';
import TopButton from 'components/ui/TopButton';
import SidebarList from 'components/ui/Sidebar/SidebarList';
import BreadcrumbItem from 'components/ui/Breadcrumb/BreadcrumbItem';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';
import Container from 'components/ui/Container';
import ExternalLink from 'components/ui/Icon/service/ExternalLink';
import DateFormat from 'components/utils/DateFormat';
import Node, { NodeProps } from '../Node';
import { useTransparentHeader } from '../../ui/Header/HeaderProvider';
import NodeIterator from '../NodeIterator';
import Plus from '../../ui/Icon/actions/Plus';
import Close from '../../ui/Icon/actions/Close';
import ServiceLinkIcon from './link/ServiceLinkIcon';

export const DEFAULT_ITEM_DISPLAY = 3;

export type DrupalContentBehaviorProps = NodeProps<{
  showTitle: boolean | null;
  showModifyDate: boolean | null;
  showBreadcrumb: boolean | null;
  isContained: boolean | null;
  icon: string | null;
  introduction: string | null;
  contact: string | null;
  drupalData: { content: string | null } | null;
  sharepointFiles: LinkModel[] | null;
  renderer: 'default' | 'banner' | null;
  updatedAt: string | null;
}>;

const RENDERERS = {
  banner: BannerRenderer,
  default: DefaultRenderer,
};

function DrupalContentBehavior(props: DrupalContentBehaviorProps): JSX.Element {
  const renderer = props.behavior.renderer ?? 'default';
  const Renderer = RENDERERS[renderer] ?? RENDERERS.default;

  return <Renderer {...props} />;
}

function DefaultRenderer({
  behavior: {
    showTitle,
    showModifyDate,
    showBreadcrumb,
    isContained,
    icon,
    introduction,
    contact,
    drupalData,
    sharepointFiles,
    updatedAt,
  },
  content,
}: DrupalContentBehaviorProps): JSX.Element {
  const disclosure = useDisclosureState();
  const messages = useMessages((t) => ({
    home: t('identity.home'),
    topText: t('sidebar.topText'),
    introduction: t('sidebar.introduction'),
    documents: t('sidebar.documents'),
    links: t('sidebar.links'),
    contact: t('sidebar.contact'),
    download: (name: string) => t('sidebar.download', { name }),
    toggleShowMore: (length: number) => t('sidebar.toggle.show_more', { length }),
    toggleShowLess: t('sidebar.toggle.show_less'),
    updatedAt: t('content.updated_at'),
  }));
  const page = useSelector(selectTreeRoot);
  const editorialRef = useRef<HTMLDivElement | null>(null);
  const [contentFiles, setContentFiles] = useState<LinkModel[]>([]);
  const [contentLinks, setContentLinks] = useState<LinkModel[]>([]);

  useLayoutEffect(() => {
    if (editorialRef.current) {
      // Modify anchor elements to open links in new window
      const externalLinkElements = editorialRef.current.querySelectorAll('a');
      externalLinkElements.forEach((linkElement) => {
        if (isExternalUrl(linkElement.href)) {
          linkElement.target = '_blank';
          linkElement.rel = 'external noopener noreferrer';
        }
      });

      // Find file anchor elements to also show them in the sidebar
      const contentFiles: LinkModel[] = [];
      const fileElements =
        editorialRef.current.querySelectorAll<HTMLAnchorElement>('.Paragraph-file a');
      fileElements.forEach((fileElement) => {
        const contentFile: LinkModel = {
          label: fileElement.textContent,
          url: fileElement.href,
        };

        contentFiles.push(contentFile);
      });

      setContentFiles(contentFiles);

      // Find file anchor elements to also show them in the sidebar
      const contentLinks: LinkModel[] = [];
      const linkElements = editorialRef.current.querySelectorAll<HTMLAnchorElement>(
        '.Paragraph-linkVariant a',
      );
      linkElements.forEach((fileElement) => {
        const contentLink: LinkModel = {
          label: fileElement.textContent,
          url: fileElement.href,
        };

        contentLinks.push(contentLink);
      });

      setContentLinks(contentLinks);
    }
  }, []);

  const drupalContent = drupalData?.content;

  const title = page?.behavior?.title;
  const breadcrumbItems = page?.behavior?.breadcrumbItems;
  const files = useMemo(() => {
    return [...contentFiles, ...(sharepointFiles ?? [])];
  }, [sharepointFiles, contentFiles]);

  return (
    <>
      {(showTitle && title) || (showBreadcrumb && breadcrumbItems) ? (
        <Banner>
          <EditorialHeader>
            {showBreadcrumb && breadcrumbItems ? (
              <Breadcrumb>
                <BreadcrumbItem to="/" label={messages.home} />
                {breadcrumbItems.map((item, i) => (
                  <BreadcrumbItem
                    key={item.id}
                    to={i < breadcrumbItems.length - 1 && item.slug ? `/${item.slug}` : undefined}
                    label={item.title}
                  />
                ))}
              </Breadcrumb>
            ) : null}
            {showTitle && title ? (
              <Title
                icon={icon ? <ServiceLinkIcon name={icon} /> : undefined}
                kind={icon ? 'primary' : undefined}
                subtitle={
                  showModifyDate &&
                  updatedAt && (
                    <>
                      {messages.updatedAt}
                      <DateFormat date={updatedAt} preset={'DATETIME_MED'}></DateFormat>
                    </>
                  )
                }
              >
                {title}
              </Title>
            ) : null}
          </EditorialHeader>
        </Banner>
      ) : null}
      <Separator />
      {isContained ? (
        <TwoColumnsLayout main={renderMain()} aside={renderAside()}></TwoColumnsLayout>
      ) : (
        <Container>{renderMain()}</Container>
      )}
    </>
  );

  function renderMain(): JSX.Element | null {
    if (!introduction && !content.length && !drupalContent) {
      return null;
    }

    return (
      <>
        {introduction ? (
          <Editorial>
            <header dangerouslySetInnerHTML={{ __html: nl2br(introduction) }} />
          </Editorial>
        ) : null}
        <NodeIterator nodes={content} />
        {drupalContent && drupalContent.trim().length > 0 ? (
          <Editorial ref={editorialRef} dangerouslySetInnerHTML={{ __html: drupalContent }} />
        ) : null}
      </>
    );
  }

  function renderAside(): JSX.Element | null {
    if (!contact && files.length <= 0) {
      return null;
    }

    return (
      <Sidebar afterChildren={<TopButton>{messages.topText}</TopButton>}>
        {files.length > 0 ? (
          files.length > DEFAULT_ITEM_DISPLAY ? (
            <SidebarSection title={messages.documents}>
              <SidebarList>
                {files.slice(0, DEFAULT_ITEM_DISPLAY).map((file, i) => (
                  <SidebarItem
                    key={i}
                    startAdornement={
                      <Cta as={Link} icon={Download} to={file.url} kind="secondary" iconOnly>
                        {messages.download(file.label ?? file.url)}
                      </Cta>
                    }
                  >
                    {file.label ?? file.url}
                  </SidebarItem>
                ))}
                <>
                  <DisclosureContent state={disclosure}>
                    {files.slice(DEFAULT_ITEM_DISPLAY).map((file: any, i: any) => (
                      <SidebarItem
                        key={i}
                        startAdornement={
                          <Cta as={Link} icon={Download} to={file.url} kind="secondary" iconOnly>
                            {messages.download(file.label ?? file.url)}
                          </Cta>
                        }
                      >
                        {file.label}
                      </SidebarItem>
                    ))}
                  </DisclosureContent>
                  <SidebarItem
                    startAdornement={
                      <Disclosure
                        state={disclosure}
                        as={Cta}
                        icon={disclosure.open ? Close : Plus}
                        iconOnly
                      >
                        {disclosure.open
                          ? messages.toggleShowLess
                          : messages.toggleShowMore(files.length)}
                      </Disclosure>
                    }
                  >
                    {disclosure.open
                      ? messages.toggleShowLess
                      : messages.toggleShowMore(files.length)}
                  </SidebarItem>
                </>
              </SidebarList>
            </SidebarSection>
          ) : (
            <SidebarSection title={messages.documents}>
              <SidebarList>
                {files.map((file, i) => (
                  <SidebarItem
                    key={i}
                    startAdornement={
                      <Cta as={Link} icon={Download} to={file.url} kind="secondary" iconOnly>
                        {messages.download(file.label ?? file.url)}
                      </Cta>
                    }
                  >
                    {file.label ?? file.url}
                  </SidebarItem>
                ))}
              </SidebarList>
            </SidebarSection>
          )
        ) : null}
        {contentLinks.length > 0 ? (
          <SidebarSection title={messages.links}>
            <SidebarList>
              {contentLinks.map((link, i) => (
                <SidebarItem
                  key={i}
                  startAdornement={
                    <Cta as={Link} icon={ExternalLink} to={link.url} kind="secondary" iconOnly>
                      {link.label ?? link.url}
                    </Cta>
                  }
                >
                  {link.label ?? link.url}
                </SidebarItem>
              ))}
            </SidebarList>
          </SidebarSection>
        ) : null}
        {contact ? (
          <SidebarSection title={messages.contact}>
            <SidebarList>
              <SidebarItem
                multiline
                startAdornement={<Cta as="span" icon={Mail} kind="secondary" iconOnly />}
              >
                <Editorial dangerouslySetInnerHTML={{ __html: nl2br(contact) }} />
              </SidebarItem>
            </SidebarList>
          </SidebarSection>
        ) : null}
      </Sidebar>
    );
  }
}

function BannerRenderer({
  behavior: { drupalData },
  content,
}: DrupalContentBehaviorProps): JSX.Element {
  useTransparentHeader();

  const drupalContent = drupalData?.content;

  const img = drupalContent?.trim()?.length ? (
    <div dangerouslySetInnerHTML={{ __html: drupalContent }} />
  ) : undefined;

  return (
    <Banner img={img}>
      {content.length
        ? content.map((node) => <Node key={node.identifier} identifier={node.identifier} />)
        : null}
    </Banner>
  );
}

export default DrupalContentBehavior;
