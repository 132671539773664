import { AzureContentDocument } from 'models/search';
import MiniTeaser from 'components/ui/MiniTeaser/MiniTeaser';
import MiniTeaserList from 'components/ui/MiniTeaser/MiniTeaserList';
import Link from 'components/utils/Link';
import Icon from 'components/ui/Icon';
import News from 'components/ui/Icon/service/News';
import Page from 'components/ui/Icon/document/Page';
import Teaser from 'components/ui/Teaser/Teaser';
import DateInterval from 'components/utils/DateInterval';
import DateFormatOrRelative from 'components/utils/DateFormatOrRelative';
import Label from 'components/ui/Label';
import { TagModel } from 'models';
import Star from 'components/ui/Icon/actions/Star';
import useMessages from 'components/hooks/useMessages';
import EventDayDate from '../event/EventDayDate';
import { useAzureSearchContext } from './AzureSearchProvider';

export type SearchTeaserListProps = {
  shrunk?: boolean;
};

function SearchTeaserList({ shrunk }: SearchTeaserListProps): JSX.Element {
  const { teaserResponse, loading } = useAzureSearchContext();

  const messages = useMessages(
    (t) => ({
      family: (family: string) => t(`search.facet.family.${family}`),
    }),
    [],
  );

  return (
    <>
      {!loading && teaserResponse?.documents?.length ? (
        shrunk ? (
          <MiniTeaserList maxVisible={10} gap="lg">
            {teaserResponse.documents.map(renderMiniTeaser)}
          </MiniTeaserList>
        ) : (
          teaserResponse.documents.map(renderTeaser)
        )
      ) : null}
    </>
  );

  function renderTeaser(document: AzureContentDocument): JSX.Element {
    const content = document['@highlights']?.content?.[0];

    return (
      <Teaser
        key={document.id}
        title={document.title}
        link={document.url ? <Link to={document.url}>{document.title}</Link> : undefined}
        media={renderTeaserMedia(document)}
        subtitle={
          <>
            {renderTeaserDate(document)}
            {document.location ? ` • ${document.location}` : null}
          </>
        }
        tags={document.tags.map(renderTag)}
        description={
          content ? (
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          ) : (
            document.description
          )
        }
      />
    );
  }

  function renderMiniTeaser(document: AzureContentDocument): JSX.Element {
    return (
      <MiniTeaser
        key={document.id}
        title={document.title}
        link={document.url ? <Link to={document.url}>{document.title}</Link> : undefined}
        media={renderTeaserMedia(document)}
        subtitle={renderTeaserDate(document)}
      />
    );
  }

  function renderTag(tag: TagModel): JSX.Element {
    return (
      <Label key={tag.slug} kind="primary">
        {tag.important ? <Icon as={Star} size="xs" /> : null} {tag.title}
      </Label>
    );
  }

  function renderTeaserMedia(document: AzureContentDocument): JSX.Element | null {
    if (document.startAt) {
      return <EventDayDate date={document.startAt} />;
    }

    const title = messages.family(document.family);
    const size = shrunk ? 'sm' : 'md';

    if (document.publishedAt) {
      return <Icon as={News} size={size} title={title} />;
    }

    return <Icon as={Page} size={size} title={title} />;
  }

  function renderTeaserDate(document: AzureContentDocument): JSX.Element | null {
    if (document.startAt && document.endAt) {
      return (
        <DateInterval start={document.startAt} end={document.endAt} fullPreset="DATETIME_MED" />
      );
    }

    if (document.publishedAt) {
      return <DateFormatOrRelative preset="DATETIME_MED" date={document.publishedAt} max="P1M" />;
    }

    if (document.updatedAt) {
      return <DateFormatOrRelative preset="DATETIME_MED" date={document.updatedAt} max="P1M" />;
    }

    return null;
  }
}

export default SearchTeaserList;
