import { ReactNode } from 'react';
import Icon from '../Icon';
import Earlymakers from '../Icon/common/Earlymakers';
import Separator from '../Separator';
import './Footer.scss';

export type FooterProps = {
  children?: ReactNode;
};

function Footer({ children }: FooterProps): JSX.Element {
  return (
    <>
      <Separator />
      <footer className="Footer">
        <div className="Footer-content">{children}</div>
        <div className="Footer-logo">
          <Icon className="Footer-icon" as={Earlymakers} />
        </div>
      </footer>
    </>
  );
}

export default Footer;
