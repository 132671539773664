import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from 'components/ui/Dialog';
import Button from 'components/ui/Button';
import useMessages from 'components/hooks/useMessages';
import { logoutWithAll } from 'actions/authActions';
import { selectAuthRefreshing } from 'selectors/authSelectors';

function AuthRefresh(): JSX.Element {
  const dispatch = useDispatch();
  const show = useSelector(selectAuthRefreshing);

  const { title, close, button, content } = useMessages((t) => {
    return {
      title: t('auth.refresh.title'),
      close: t('auth.refresh.close'),
      button: t('auth.refresh.login'),
      content: t('auth.refresh.content'),
    };
  }, []);

  const handleLogin = useCallback(
    function handleLogin() {
      dispatch(logoutWithAll());
    },
    [dispatch],
  );

  const actions = (
    <Button kind="secondary" onClick={handleLogin}>
      {button}
    </Button>
  );

  return (
    <Dialog open={show} title={title} messages={{ label: title, close }} actions={actions}>
      {content}
    </Dialog>
  );
}

export default AuthRefresh;
