import { cancelled, select, put, call, fork, takeLatest } from 'redux-saga/effects';
import cdmApi, { createCancelSource, resolveError } from 'services/cdmApi';
import {
  receiveCandidacies,
  errorCandidacies,
  requestCandidacies,
  requestCandidateContent,
  receiveCandidateContent,
  errorCandidateContent,
} from 'actions/candidateActions';
import { selectSuperviewer } from 'selectors/candidateSelectors';
import { isCandidacyModel } from 'models/candidate';

export function* requestCandidaciesTask(): any {
  const cancelSource = createCancelSource();

  const { program, status } = yield select(selectSuperviewer);
  const params: {
    program?: string;
    status?: string;
  } = {};

  if (program && status) {
    params.program = program;
    params.status = status;
  }

  try {
    const response = yield call(cdmApi.get, '/candidate/candidacies', {
      cancelToken: cancelSource.token,
      params,
    });

    const candidacies = response.data.filter(isCandidacyModel);

    yield put(receiveCandidacies(candidacies));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorCandidacies(err));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

export function* watchRequestCandidacies() {
  yield takeLatest(requestCandidacies.type, requestCandidaciesTask);
}

export function* requestCandidateContentTask(
  action: ReturnType<typeof requestCandidateContent>,
): any {
  const { id } = action.payload;
  try {
    const response = yield call(cdmApi.get, `/candidate/contents/${id}`);
    const content = response.data;
    yield put(receiveCandidateContent(id, content));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorCandidateContent(id, err));
  }
}

export function* watchRequestCandidateContent() {
  yield takeLatest(requestCandidateContent.type, requestCandidateContentTask);
}

export default function* candidateSaga() {
  yield fork(watchRequestCandidacies);
  yield fork(watchRequestCandidateContent);
}
