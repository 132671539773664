import { memo, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'qs';
import { selectTreeError, selectTreeRoot } from 'selectors/treeSelectors';
import { requestPage } from 'actions/treeActions';
import { selectLocale, selectCampusCode, selectProfile } from 'selectors/userSelectors';
import { selectIdentity } from 'selectors/authSelectors';
import ErrorPage from 'components/pages/ErrorPage';
import PageNotFound from 'components/errors/PageNotFound';
import UserNotFound from 'components/errors/UserNotFound';
import Root from './Root';

const ERRORS = {
  404: PageNotFound,
  default: UserNotFound,
};

export type PageProviderProps = {
  slug: string;
  query: string;
};

function PageProvider({ slug, query }: PageProviderProps): JSX.Element {
  const dispatch = useDispatch();
  const root = useSelector(selectTreeRoot);
  const error = useSelector(selectTreeError);
  const locale = useSelector(selectLocale);
  const campus = useSelector(selectCampusCode);
  const profile = useSelector(selectProfile);
  const identity = useSelector(selectIdentity);

  const parameters = useMemo(() => {
    return qs.parse(query);
  }, [query]);

  const currentSlug = root?.behavior.slug;
  const currentLayout = root?.behavior.layout;

  // If the previous page had a layout, send it to prevent loading the full page in case it has the same layout.
  const layout = currentLayout;

  // Add lazy parameter only if no zone nor layout was specified and only on first fetch.
  const lazy = !parameters.layout && !parameters.zone && !currentSlug;

  // Fetch page tree
  // Profile is added in dependencies because then the page is reload when the user changes profile via the profile switcher.
  useEffect(() => {
    if (identity.accessToken) {
      dispatch(
        requestPage(
          slug,
          {
            ...parameters,
            layout: layout || undefined,
            lazy: lazy || undefined,
          },
          locale,
          campus,
        ),
      );
    }
  }, [dispatch, slug, locale, campus, profile, layout, lazy, parameters, identity.accessToken]);

  // Render error if the fetch failed
  if (error && error.code !== 'auth:unauthenticated') {
    const ErrorComponent = ERRORS[error.status as keyof typeof ERRORS] ?? ERRORS.default;

    return (
      <ErrorPage>
        <ErrorComponent error={error} />
      </ErrorPage>
    );
  }

  return <Root />;
}

export default memo(PageProvider);
