import localForage from 'localforage';

type Action<T extends string, P extends object = object> = Readonly<{ type: T; payload: P }>;

export type Reducer<S, A> = (state: S, action: A) => S;

export type Reducers<S, T extends string, P extends object> = {
  readonly [K in T]: Reducer<S, Action<K, P>>;
};

// Reducer utility to match a function to an action name
export function createReducer<S, T extends string>(initialState: S, reducers: Reducers<S, T, any>) {
  return function reducer(state = initialState, action: Action<T, any>): S {
    if (action.type in reducers) {
      return reducers[action.type](state, action);
    }

    return state;
  };
}

export function createPersistConfig(key: string, options?: object) {
  return {
    key,
    storage: localForage,
    ...options,
  };
}
