import { useSelector } from 'react-redux';
import MessagePage from 'components/pages/MessagePage';
import PageLoader from 'components/ui/PageLoader';
import useTranslate from 'components/hooks/useTranslate';
import { selectTreeRoot } from 'selectors/treeSelectors';
import { selectUser } from 'selectors/userSelectors';
import NodeIterator from './NodeIterator';

function Root(): JSX.Element {
  const t = useTranslate();

  const root = useSelector(selectTreeRoot);
  const user = useSelector(selectUser);

  if (root && user.userId) {
    return <NodeIterator nodes={[root]} />;
  }

  return (
    <MessagePage>
      <PageLoader full label={t('content.fetching')} />
    </MessagePage>
  );
}

export default Root;
