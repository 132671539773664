import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from 'components/ui/Dialog';
import Button from 'components/ui/Button';
import FormField from 'components/form/FormField';
import TextInput from 'components/form/TextInput';
import Form from 'components/form/Form';
import { setToast, closeDialog, DIALOG_EVENT_REGISTER } from 'actions/uiActions';
import { registerEvent } from 'services/makerslabApi';
import { selectUser } from 'selectors/userSelectors';
import { EventModel } from 'models/event';
import useMessages from 'components/hooks/useMessages';
import { selectDialogByName } from 'selectors/uiSelectors';
import { AppStore } from 'store';
import { createEvent } from 'actions/eventActions';
import Typography from 'components/ui/Typography';

const DEFAULT_DIALOG = {
  open: false,
  event: undefined,
  onSuccess: undefined,
};

function selectDialog(store: AppStore) {
  return selectDialogByName(store, DIALOG_EVENT_REGISTER);
}

type DialogEventRegisterProps = {
  event?: EventModel;
  onSuccess?(): void;
  open?: boolean;
};

function DialogEventRegister() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { open, event, onSuccess }: DialogEventRegisterProps =
    useSelector(selectDialog) || DEFAULT_DIALOG;
  const name = [user.firstName, user.lastName].filter(Boolean).join(' ');
  const email = user.upn;

  const messages = useMessages(
    (t) => ({
      success: t('dialog.event_register.success'),
      title: t('dialog.event_register.title'),
      content: t('dialog.event_register.content'),
      phone: t('dialog.event_register.phone'),
      phoneRequired: t('dialog.event_register.phone_required'),
      close: t('label.close'),
      submit: t('label.submit'),
    }),
    [],
  );

  const [error, setError] = useState<keyof typeof messages | null>(null);
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    phone: '',
  });

  useEffect(() => {
    const error = touched && !values.phone.length ? 'phoneRequired' : null;
    setError(error);
  }, [touched, values.phone]);

  const handleExit = useCallback(() => {
    dispatch(closeDialog(DIALOG_EVENT_REGISTER));
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (!error && touched && event) {
        setLoading(true);

        try {
          // Register to the external service
          await registerEvent(event, name, email ?? '', values.phone);

          // Automatically subscribe to the event
          dispatch(createEvent(event));

          // Quit the dialog
          setLoading(false);
          handleExit();
          dispatch(
            setToast({
              kind: 'secondary',
              messageRenderer: 'html',
              message: messages.success,
              uid: `makerslab-${Date.now()}`,
              show: true,
              sticky: true,
            }),
          );

          // Execute the callback if needed
          if (onSuccess) {
            onSuccess();
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    },
    [
      dispatch,
      email,
      error,
      event,
      handleExit,
      messages.success,
      name,
      onSuccess,
      touched,
      values.phone,
    ],
  );

  const handleValueChange = useCallback((value: string | null, name?: string | null) => {
    setTouched(true);

    if (name) {
      setValues((values) => ({
        ...values,
        [name]: value || '',
      }));
    }
  }, []);

  return (
    <Dialog
      width="md"
      open={open}
      as={Form}
      onSubmit={handleSubmit}
      title={messages.title}
      messages={{ label: messages.title, close: messages.close }}
      onExit={handleExit}
      actions={
        <>
          <Button onClick={handleExit} style={{ marginRight: 'auto' }}>
            {messages.close}
          </Button>
          <Button disabled={loading || Boolean(error) || !touched} type="submit" kind="secondary">
            {messages.submit}
          </Button>
        </>
      }
    >
      {error ? (
        <Typography as="p" kind="primary">
          {messages[error]}
        </Typography>
      ) : null}
      <p>{messages.content}</p>
      <FormField row label={messages.phone}>
        <TextInput name="phone" value={values.phone} onValueChange={handleValueChange} />
      </FormField>
    </Dialog>
  );
}

export default DialogEventRegister;
