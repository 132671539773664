import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgCanel({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 123 138"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M.119 67.663c0-8.074 1.514-16.085 4.541-24.032 3.028-8.074 7.443-15.264 13.246-21.572C23.71 15.752 30.774 10.642 39.1 6.732 47.425 2.822 56.887.866 67.484.866c12.615 0 23.527 2.712 32.736 8.136 9.335 5.425 16.273 12.49 20.815 21.194L97.192 46.848c-1.514-3.532-3.469-6.434-5.866-8.705-2.27-2.396-4.794-4.289-7.569-5.676-2.775-1.514-5.614-2.523-8.515-3.028-2.902-.63-5.74-.946-8.515-.946-5.93 0-11.102 1.198-15.517 3.595-4.415 2.397-8.074 5.488-10.975 9.272-2.902 3.785-5.046 8.074-6.434 12.868a52.152 52.152 0 00-2.081 14.57c0 5.299.82 10.408 2.46 15.328 1.64 4.92 3.973 9.272 7.001 13.056 3.154 3.785 6.875 6.812 11.164 9.083 4.416 2.145 9.336 3.217 14.76 3.217 2.775 0 5.614-.315 8.515-.946a38.292 38.292 0 008.515-3.217 33.02 33.02 0 007.57-5.677c2.27-2.396 4.1-5.298 5.487-8.704l25.356 14.949c-2.018 4.92-5.046 9.335-9.082 13.246-3.911 3.91-8.452 7.19-13.625 9.84-5.172 2.649-10.66 4.667-16.462 6.055-5.803 1.387-11.48 2.081-17.03 2.081-9.714 0-18.671-1.955-26.871-5.866-8.074-4.037-15.075-9.335-21.004-15.895-5.803-6.56-10.345-14.003-13.624-22.328C1.696 84.694.119 76.24.119 67.663z" />
    </svg>
  );
}

export default forwardRef(SvgCanel);
