import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { loginWithAccessToken, loginWithCredentials } from 'actions/authActions';
import { setAccessToken } from 'services/cdmApi';
import { selectIdentity } from 'selectors/authSelectors';
import { errorUser } from 'actions/userActions';

export type AuthProviderProps = {
  children: React.ReactNode;
};

/**
 * Provide auth by parsing location query.
 */
function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const identity = useSelector(selectIdentity);

  const { token, codeToken, accessToken, error, ...params } = qs.parse(
    window.location.search.replace('?', ''),
  );

  // Remove the tokens and error from current location query
  const query = qs.stringify(params);
  useLayoutEffect(() => {
    const cleanedUrl = `${window.location.pathname}${query ? '?' : ''}${query}`;
    window.history.replaceState(null, '', cleanedUrl);
    setReady(true);
  }, [query]);

  // Update the Authentication header for CDM API when the access token changes
  useLayoutEffect(() => {
    if (codeToken) {
      // When a new codeToken is received, reset the accessToken to ensure we are not calling the API
      setAccessToken(null);
    } else {
      setAccessToken(identity.accessToken);
    }
  }, [identity.accessToken, codeToken]);

  // Check for credentials in query to authenticate the user.
  useEffect(() => {
    // Show error as user error
    if (typeof error === 'string') {
      const err = {
        code: error,
        status: null,
        error: null,
        original: error,
      };

      dispatch(errorUser(err));
    }

    // 'token' is Firebase token
    if (typeof token === 'string') {
      dispatch(loginWithCredentials({ token }));
    }

    // 'codeToken' is CDM code token
    if (typeof codeToken === 'string') {
      dispatch(loginWithCredentials({ codeToken }));
    }

    // 'accessToken' is CDM access token
    if (typeof accessToken === 'string') {
      dispatch(loginWithAccessToken(accessToken));
    }
  }, [dispatch, token, codeToken, accessToken, error]);

  if (ready) {
    return <>{children}</>;
  }

  return <>{null}</>;
}

export default AuthProvider;
