import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgEmlyon({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 18 18"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M16.566 8.138l-3.372 3.371a4.766 4.766 0 01-6.741 0 4.762 4.762 0 01-.724-.961L7.296 8.98c.074-.075.166-.118.254-.169.108.37.297.72.588 1.012.93.93 2.442.928 3.37 0l3.372-3.371c.93-.93.93-2.442 0-3.371a2.386 2.386 0 00-3.371 0l-1.199 1.2a5.98 5.98 0 00-3.04-.332l2.554-2.554a4.768 4.768 0 016.742 6.742zm-8.914 5.543l-1.2 1.2a2.388 2.388 0 01-3.37 0 2.387 2.387 0 010-3.372l3.37-3.37c.93-.93 2.442-.93 3.372 0 .29.29.48.64.588 1.01.089-.052.18-.094.254-.168l1.567-1.566a4.64 4.64 0 00-.724-.962 4.767 4.767 0 00-6.742 0l-3.37 3.37a4.768 4.768 0 006.741 6.743l2.555-2.555a5.977 5.977 0 01-3.04-.33z" />
    </svg>
  );
}

export default forwardRef(SvgEmlyon);
