import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgDownload(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M8.818.636v9.046l2.639-2.64.742.743-3.906 3.908-3.907-3.908.742-.742 2.64 2.64V.636h1.05zM1.525 14.475V11H.475v4.525h15.05V11h-1.05v3.475H1.525z" />
    </svg>
  );
}

export default forwardRef(SvgDownload);
