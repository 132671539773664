import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgLang({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M9.24 21.45v9h13.52v-9zm12.92.61v1.19H9.82v-1.2zm0 1.77V28H9.82v-4.18zm-12.32 6v-1.19h12.34v1.19zm2.81-2.44A1.32 1.32 0 0013.93 26v-1.5h-2.56V26a1.32 1.32 0 001.28 1.38zM12 25.1h1.36v.9a.72.72 0 01-.68.73A.7.7 0 0112 26v-.9zM22.33 7.25l.21-.25v.21zm.21 2.84H9.41V7.42h13.13v2.67zM9.61 4.59l-.21.21v-.21zM22.54 5v.29l-2 2h-.29zm0-.35v.25l-2.36 2.35h-.29zm-.32 0h.29l-2.68 2.6h-.29zm-.35 0h.29l-2.67 2.6h-.29zm-.35 0h.29l-2.67 2.6h-.29zm-.35 0h.29l-2.67 2.6h-.29zm-.35 0h.29l-2.67 2.6h-.29zm-.35 0h.29l-2.67 2.6h-.29zm-.35 0h.29l-2.66 2.6h-.29zm-.35 0h.29l-2.66 2.6h-.29zm-.35 0h.29L17 7.25h-.29zm-.35 0h.29l-2.66 2.6h-.29zm-.35 0H19l-2.65 2.6h-.29zm-.34 0h.29L16 7.25h-.29zm-.35 0h.29l-2.67 2.6h-.29zm-.35 0H18l-2.7 2.6H15zm-.35 0h.29L15 7.25h-.29zm-.35 0h.29l-2.66 2.6h-.29zm-.35 0h.29l-2.66 2.6H14zm-.35 0h.29l-2.66 2.6h-.29zm-.35 0h.29l-2.65 2.6h-.29zm-.35 0h.29l-2.65 2.6h-.29zm-.35 0h.29l-2.65 2.6h-.29zm-.35 0h.29l-2.65 2.6h-.29zm-.35 0h.29l-2.65 2.6h-.29zm-.35 0h.29l-2.64 2.6h-.29zm-.35 0h.29l-2.64 2.6h-.29zm-.35 0h.29l-2.64 2.6h-.29zm-.35 0h.29l-2.64 2.6h-.29zm-.35 0h.29l-2.64 2.6h-.29zm-.35 0h.29l-2.64 2.6H9.8zm-.35 0h.29l-2.64 2.6h-.29zm-2.7 2l2-2h.29l-2.3 2.3zm1.95-2L9.4 6.53v-.28l1.66-1.66zm-.35 0L9.4 6.18V5.9l1.31-1.31zm-.35 0L9.4 5.83v-.28l1-1zm-.35 0l-.9.9V5.2l.62-.61zm-.9.27l.27-.27H10l-.55.55zm0 2.09l2.35-2.35h.29L9.41 7.23zm13.16-1.42l-1.66 1.66h-.29l1.95-1.95zm0 .76v.29l-.61.62h-.29zm0-.35v.29l-1 1h-.29zm0-.06l-1.31 1.31h-.29l1.6-1.6zM22 7.25l.56-.56V7l-.27.27zm.64-5.68H9.32a.09.09 0 00-.09.09v8.52a.09.09 0 00.09.09h13.3a.09.09 0 00.09-.09V1.66a.08.08 0 00-.09-.09m-.23 10.27H9.61a.37.37 0 00-.36.36v7.31a.37.37 0 00.36.36h12.78a.37.37 0 00.36-.36v-7.3a.34.34 0 00-.36-.37zm-4.46 4.68H16.7v.12l4.37 2.48h-4.7v-2.89h5.69v2.65zm-3.83-1.37h1.23V15L11 12.54h4.7v3H10v-2.7zm3.83 0l4.12-2.36v2.73h-5.69v-3h4.7l-4.36 2.53v.12zm-3.83 1.37L10 18.89v-2.66h5.69v2.92H11l4.37-2.51v-.12z" />
    </svg>
  );
}

export default forwardRef(SvgLang);
