import { useCallback, useEffect, useRef } from 'react';
import useAutoId from 'components/hooks/useAutoId';
import useClickOutside from 'components/hooks/useClickOutside';
import useEscapeKey from 'components/hooks/useEscapeKey';
import Icon from 'components/ui/Icon';
import SrOnly from 'components/ui/SrOnly/SrOnly';
import Search from 'components/ui/Icon/actions/Search';
import Close from 'components/ui/Icon/actions/Close';
import Backdrop from 'components/ui/Backdrop';
import './SearchPanel.scss';

export type SearchPanelProps = {
  showButton: boolean;
  showPanel: boolean;
  onOpen(): void;
  onClose(): void;
  form: React.ReactNode;
  children: React.ReactNode;
  messages: {
    openButton: string;
    closeButton: string;
  };
};

function SearchPanel({
  showButton,
  showPanel,
  onOpen,
  onClose,
  form,
  children,
  messages,
}: SearchPanelProps): JSX.Element {
  const contentId = useAutoId(undefined, 'SearchPanel-content-');

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  // Close panel when hitting escape key or clicking outside the panel
  const containerRef = useClickOutside<HTMLDivElement>(handleClose, undefined, true);
  useEscapeKey(handleClose);

  // Close panel when clicking on a link inside the panel
  const panelRef = useRef<HTMLDivElement | null>(null);
  const panelElement = panelRef.current;
  useEffect(() => {
    if (panelElement) {
      const onClick = (e: MouseEvent): void => {
        const link = (e.target as HTMLElement).closest('a');
        if (link) {
          onClose();
        }
      };

      panelElement.addEventListener('click', onClick);

      return () => {
        panelElement.removeEventListener('click', onClick);
      };
    }
  }, [panelElement, onClose]);

  const handleClick = (): void => {
    onOpen();
  };

  return (
    <div className="SearchPanel" ref={containerRef}>
      {form}
      {showButton ? (
        <button
          type="button"
          className="SearchPanel-openButton"
          onClick={handleClick}
          aria-expanded={showPanel}
          aria-controls={contentId}
        >
          <Icon as={showPanel ? Close : Search} />
          <SrOnly>{showPanel ? messages.closeButton : messages.openButton}</SrOnly>
        </button>
      ) : null}
      <div ref={panelRef} id={contentId} className="SearchPanel-content" aria-hidden={!showPanel}>
        {children}
      </div>
      <Backdrop aria-hidden={!showPanel} />
    </div>
  );
}

export default SearchPanel;
