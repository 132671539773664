import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgClose({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M18.187 17.127l-1.06 1.06L5.813 6.873l1.06-1.06z" />
      <path d="M17.127 5.813l1.06 1.06L6.873 18.187l-1.06-1.06z" />
    </svg>
  );
}

export default forwardRef(SvgClose);
