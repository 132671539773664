import classNames from 'classnames';
import { OverridableComponentProps } from 'components/OverridableComponent';
import { Gutter } from 'components/ui/types.d';
import { CSSProperties } from 'react';
import './Flex.scss';

type BaseProps = {
  gap?: Gutter;
  /** @deprecated Should only be used where support for iPhone with Safari <14.1 is mandatory, as it uses margins on the element and its children instead of gap */
  gutter?: boolean;
  inline?: boolean;
  wrap?: React.CSSProperties['flexWrap'];
  direction?: React.CSSProperties['flexDirection'];
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
};

export type FlexProps<A> = OverridableComponentProps<A, 'div', BaseProps>;

function Flex<A>({
  as: As = 'div',
  gap,
  gutter,
  inline,
  wrap,
  direction,
  alignItems,
  justifyContent,
  children,
  className,
  style,
  ...props
}: FlexProps<A>): JSX.Element {
  const classes = classNames('Flex', className, {
    'has-gutter': gutter,
  });

  const rootStyle = {
    '--flex-gap': gap ? `var(--t-gutter-${gap})` : undefined,
    '--flex-display': inline ? `inline-flex` : undefined,
    '--flex-wrap': wrap,
    '--flex-direction': direction,
    '--flex-alignItems': alignItems ?? (direction === 'column' ? 'initial' : undefined),
    '--flex-justifyContent': justifyContent,
    ...style,
  } as CSSProperties;

  return (
    <As className={classes} style={rootStyle} {...props}>
      {children}
    </As>
  );
}

export default Flex;
