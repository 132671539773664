import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectResetUrl } from 'selectors/configSelectors';
import { selectAuth } from 'selectors/authSelectors';
import LoginForm from 'components/ui/compositions/LoginForm/LoginForm';
import LandingLayout from 'components/ui/compositions/LandingLayout/LandingLayout';
import useMessages from 'components/hooks/useMessages';
import useTranslate from 'components/hooks/useTranslate';
import { formatError } from 'components/hooks/useErrorMessage';
import { loginWithCredentials, loginWithAdfs } from 'actions/authActions';
import { AuthCredentialsModel } from 'models';

function LoginPage(): JSX.Element {
  const t = useTranslate();
  const dispatch = useDispatch();
  const resetUrl = useSelector(selectResetUrl);
  const { error, loading } = useSelector(selectAuth);
  const messages = useMessages((t) => {
    const translateValues = {
      span: (chunks: any) => <span>{chunks}</span>,
      strong: (chunks: any) => (
        <span>
          <strong>{chunks}</strong>
        </span>
      ),
    };

    return {
      // Landing page messages
      title: t('login.title'),
      caption: t('login.caption'),
      baseline: t('identity.baseline'),
      home: t('identity.home'),
      // Login form messages
      internal: <div>{t('login.internal', translateValues)}</div>,
      external: <div>{t('login.external', translateValues)}</div>,
      instructionText: t('login.instruction_text', { email: 'compte_candidat@em-lyon.com' }),
      email: t('login.email'),
      password: t('login.password'),
      submit: t('login.submit'),
      passwordForgot: t('login.password_forgot'),
      loading: t('label.loading'),
    };
  }, []);

  const handleLogin = useCallback(
    (credentials: AuthCredentialsModel) => {
      dispatch(loginWithCredentials(credentials));
    },
    [dispatch],
  );

  const handleExternalLogin = useCallback(() => {
    dispatch(loginWithAdfs());
  }, [dispatch]);

  const errors = [];

  if (error) {
    const errorMessage = formatError(t, error);
    const errorElement =
      typeof errorMessage === 'string' ? (
        <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
      ) : (
        <div>{errorMessage}</div>
      );

    errors.push(errorElement);
  }

  // Add info message due to masters issue with passwords being double-encrypted.
  // Could be removed actually now...
  if (error && error.error === 'auth:firebase_invalid_password') {
    errors.push(<p>{t('error.login:password_reset')}</p>);
  }

  return (
    <LandingLayout side="right" messages={messages} className={'loginPage'}>
      <LoginForm
        resetUrl={resetUrl}
        submitting={loading}
        onSubmit={handleLogin}
        onExternalSubmit={handleExternalLogin}
        errors={errors || null}
        messages={messages}
      />
    </LandingLayout>
  );
}

export default LoginPage;
