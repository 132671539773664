import { NodeModel } from 'models';
import Node, { ViewType } from './Node';

export type PropsType<V> = {
  nodes: NodeModel<any>[];
  view?: ViewType<V>;
};

function renderNode<V>(node: NodeModel<any>, view?: ViewType<V>): JSX.Element {
  return <Node key={node.identifier} identifier={node.identifier} view={view} />;
}

export { renderNode };

function NodeIterator<V>({ nodes, view }: PropsType<V>): JSX.Element {
  function nodeMapper(node: NodeModel): JSX.Element {
    return renderNode(node, view);
  }

  return <>{nodes.map(nodeMapper)}</>;
}

export default NodeIterator;
