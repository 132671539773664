import classNames from 'classnames';
import './Overlay.scss';

export type OverlayProps = React.ComponentPropsWithoutRef<'span'>;

/**
 * @deprecated Prefer Backdrop
 */
function Overlay({ className, ...props }: OverlayProps): JSX.Element {
  return <div className={classNames('Overlay', className)} {...props} />;
}

export default Overlay;
