import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgKiosqueRh(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M27.387 24.421a4 4 0 11-6.098 5.179l-9.351-11.011c-.104-.124-.188-.26-.276-.394V28a4 4 0 01-8 0V4a4 4 0 018 0v9.805c.088-.134.172-.27.276-.394l9.351-11.01a4 4 0 116.098 5.178L20.236 16l7.151 8.421z" />
    </svg>
  );
}

export default forwardRef(SvgKiosqueRh);
