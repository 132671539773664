import classNames from 'classnames';
import './Teaser.scss';

type TeaserProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  tags?: React.ReactNode;
  campuses?: React.ReactNode;
  link?: React.ReactElement;
  description?: React.ReactNode;
  actions?: React.ReactNode;
  media?: React.ReactNode;
  highlight?: boolean;
  className?: string;
};

function Teaser({
  title,
  subtitle,
  description,
  link,
  tags,
  campuses,
  highlight,
  className,
  actions,
  media,
}: TeaserProps): JSX.Element {
  const rootClasses = classNames('Teaser', className, {
    [`is-highlight`]: highlight,
  });

  return (
    <div className={rootClasses}>
      {media ? <div className="Teaser-media">{media}</div> : null}
      <div className="Teaser-inner">
        <div className="Teaser-text">
          {title || subtitle ? (
            <div className="Teaser-header">
              {link && title ? (
                <link.type
                  {...link.props}
                  className={classNames('Teaser-title', link.props.className)}
                >
                  {title}
                </link.type>
              ) : (
                <div className="Teaser-title">{title}</div>
              )}
              <div className="Teaser-subtitle">{subtitle}</div>
            </div>
          ) : null}
          <div className="Teaser-content">
            <div className="Teaser-description">{description}</div>
            <div className="Teaser-tags">
              {tags}
              {campuses}
            </div>
          </div>
        </div>
        {actions ? <div className="Teaser-actions">{actions}</div> : null}
      </div>
    </div>
  );
}

export default Teaser;
