import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgEmlyonx({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 77.3 96.2"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M72.9 13.9c-.8-.8-1.8-1.2-2.9-1.2h-.2L39.2 19c-.1 1.5-.4 3.5-1.3 5.6l30.9-6.4v40.2l-47.4-1.3-.4-20.6-3.7.2v26.9c0 2.2 1.7 4 3.9 4.1l16.7 2.9c.6 1.1 1.1 2.6 1.4 3.9l.2.8 8.1.2c1.1 3.2 1 5 .5 5.7-1.9-1.3-4.6-2.7-7.5-3.2l-.7-.1-.4.6v.1c-1 1.1-6.9 1.9-10.5 1.9h-.7l-.1.3c-.2.1-.3.3-.4.5-.1.3-.1.7 0 1 .2.3.5.6.8.7l8 2.2h.2l.4.1c.1 0 .2.1.3.1h.3c6-.3 8.5-.4 9.6-.6v.1c.4-.1.8-.3 1.3-.5.8-.4 1.4-.8 1.8-1.3.3-.4.6-.8.7-1.5l.1-.6c.3-2.1-.5-4.8-1.3-6.8-.2-.4-.3-.9-.5-1.3l19.8 3.5h.4c2.3 0 4.2-1.9 4.2-4.2V16.9c.2-1.2-.2-2.2-1-3zM37.3 65.3l-15.6-2.6v-2.2l15.8 1.4c-.3.5-.5 1.2-.5 1.9l.3 1.5zm31.6 5.5l-28.5-5c.3-.5.5-1.2.5-2 0-.6-.1-1.2-.3-1.6l28.3 2.6v6zM11.2 29.5l-.1-.5h.1v-.4l.1.1c1.7-.3 9.2-1.9 14.3-6.8 4.3-4.2 5.7-8.7 6.1-10.5l-.1-.1 2.8-3.6c.6-.7.6-1.8 0-2.5s-1.6-.9-2.4-.5l-15.8 8.5c-.2.1-.4.3-.6.5L3.7 28.1c-.6.7-.6 1.8 0 2.5s1.6.9 2.4.5l2-1.1v7.2l-1.9 5.2H13l-1.9-5.2v-7.7z" />
      <path d="M32.6 12.6c-.6 2.2-2.1 6.2-6.2 10.1-4.9 4.7-11.8 6.6-14.3 7.1l4.1 5c7.8-.2 13.6-2.4 17.1-6.4 3.1-3.5 3.8-7.7 3.9-10.2l-4.6-5.6z" />
    </svg>
  );
}

export default forwardRef(SvgEmlyonx);
