import Icon from 'components/ui/Icon';
import Title from 'components/ui/Title';
import Search from 'components/ui/Icon/service/Search';
import './SearchResultEmpty.scss';

export type SearchResultEmptyProps = {
  title: React.ReactNode;
  children: React.ReactNode;
};

function SearchResultEmpty({ title, children }: SearchResultEmptyProps): JSX.Element {
  return (
    <div className="SearchResultEmpty">
      <Icon as={Search} className="SearchResultEmpty-icon" />
      <Title as="h4" className="SearchResultEmpty-title">
        {title}
      </Title>
      {children ? <div className="SearchResultEmpty-text">{children}</div> : null}
    </div>
  );
}

export default SearchResultEmpty;
