import useTranslate from 'components/hooks/useTranslate';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';
import BreadcrumbItem from 'components/ui/Breadcrumb/BreadcrumbItem';
import { BreadcrumbItemModel } from 'models';
import { useSelector } from 'react-redux';
import { selectTreeRoot } from 'selectors/treeSelectors';
import { NodeProps } from '../Node';

export type PropsType = NodeProps<{
  items?: BreadcrumbItemModel[];
}>;

function BreadcrumbBehavior({ behavior: { items } }: PropsType): JSX.Element | null {
  const page = useSelector(selectTreeRoot);
  const t = useTranslate();
  const breadcrumbItems = items ?? page?.behavior.breadcrumbItems;

  return breadcrumbItems ? (
    <Breadcrumb>
      <BreadcrumbItem to="/" label={t('identity.home')} />
      {breadcrumbItems.map((item, i) => (
        <BreadcrumbItem
          key={item.id}
          to={i < breadcrumbItems.length - 1 && item.slug ? `/${item.slug}` : undefined}
          label={item.title}
        />
      ))}
    </Breadcrumb>
  ) : null;
}

export default BreadcrumbBehavior;
