import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgBrightspace(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 30 26"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M.79.28h11.78a9.33 9.33 0 016.65 2.23A5.85 5.85 0 0121 6.84v.09a5.93 5.93 0 01-3.35 5.49c2.95 1.12 4.78 2.81 4.78 6.25v.09c0 4.64-3.79 7-9.51 7H.79V.28zm10.62 10.27c2.45 0 4-.8 4-2.68v-.09c0-1.65-1.29-2.63-3.66-2.63H6.23v5.35h5.18v.05zm1.47 10.22c2.45 0 4-.89 4-2.77v-.09c0-1.7-1.25-2.77-4.15-2.77h-6.5v5.58h6.65v.05zm10.44-.9h5.89v5.8h-5.89v-5.8z" />
    </svg>
  );
}

export default forwardRef(SvgBrightspace);
