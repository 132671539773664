import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgEmquery({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M10.5 6.5C8.019 6.5 6 8.519 6 11s2.019 4.5 4.5 4.5S15 13.481 15 11s-2.019-4.5-4.5-4.5zm0 7C9.122 13.5 8 12.379 8 11s1.122-2.5 2.5-2.5S13 9.621 13 11s-1.122 2.5-2.5 2.5z" />
      <circle cx={50} cy={9.5} r={1} />
      <circle cx={46} cy={9.5} r={1} />
      <circle cx={52} cy={12.5} r={1} />
      <circle cx={48} cy={12.5} r={1} />
      <circle cx={42} cy={9.5} r={1} />
      <circle cx={44} cy={12.5} r={1} />
      <circle cx={38} cy={9.5} r={1} />
      <circle cx={40} cy={12.5} r={1} />
      <circle cx={34} cy={9.5} r={1} />
      <circle cx={36} cy={12.5} r={1} />
      <path d="M60 16.892V5.108A4.613 4.613 0 0055.392.5H4.608A4.613 4.613 0 000 5.108v11.783c0 1.469.703 2.764 1.777 3.608C.703 21.344 0 22.64 0 24.108v11.783c0 1.469.703 2.764 1.777 3.608C.703 40.344 0 41.64 0 43.108v11.783A4.613 4.613 0 004.608 59.5h50.783a4.613 4.613 0 004.608-4.608V43.108c0-1.469-.703-2.764-1.777-3.608C59.297 38.656 60 37.36 60 35.892V24.108c0-1.469-.703-2.764-1.777-3.608C59.297 19.656 60 18.36 60 16.892zm-2 26.216v11.783a2.611 2.611 0 01-2.608 2.608H4.608A2.61 2.61 0 012 54.892V43.108A2.611 2.611 0 014.608 40.5h50.783A2.611 2.611 0 0158 43.108zm0-19v11.783a2.611 2.611 0 01-2.608 2.608H4.608A2.61 2.61 0 012 35.892V24.108A2.611 2.611 0 014.608 21.5h50.783A2.611 2.611 0 0158 24.108zM4.608 19.5A2.611 2.611 0 012 16.892V5.108A2.611 2.611 0 014.608 2.5h50.783A2.611 2.611 0 0158 5.108v11.783a2.611 2.611 0 01-2.608 2.608H4.608z" />
      <path d="M10.5 34.5c2.481 0 4.5-2.019 4.5-4.5s-2.019-4.5-4.5-4.5S6 27.519 6 30s2.019 4.5 4.5 4.5zm0-7c1.378 0 2.5 1.121 2.5 2.5s-1.122 2.5-2.5 2.5S8 31.379 8 30s1.122-2.5 2.5-2.5z" />
      <circle cx={50} cy={28.5} r={1} />
      <circle cx={46} cy={28.5} r={1} />
      <circle cx={52} cy={31.5} r={1} />
      <circle cx={48} cy={31.5} r={1} />
      <circle cx={42} cy={28.5} r={1} />
      <circle cx={44} cy={31.5} r={1} />
      <circle cx={38} cy={28.5} r={1} />
      <circle cx={40} cy={31.5} r={1} />
      <circle cx={34} cy={28.5} r={1} />
      <circle cx={36} cy={31.5} r={1} />
      <path d="M10.5 53.5c2.481 0 4.5-2.019 4.5-4.5s-2.019-4.5-4.5-4.5S6 46.519 6 49s2.019 4.5 4.5 4.5zm0-7c1.378 0 2.5 1.121 2.5 2.5s-1.122 2.5-2.5 2.5S8 50.379 8 49s1.122-2.5 2.5-2.5z" />
      <circle cx={50} cy={47.5} r={1} />
      <circle cx={46} cy={47.5} r={1} />
      <circle cx={52} cy={50.5} r={1} />
      <circle cx={48} cy={50.5} r={1} />
      <circle cx={42} cy={47.5} r={1} />
      <circle cx={44} cy={50.5} r={1} />
      <circle cx={38} cy={47.5} r={1} />
      <circle cx={40} cy={50.5} r={1} />
      <circle cx={34} cy={47.5} r={1} />
      <circle cx={36} cy={50.5} r={1} />
    </svg>
  );
}

export default forwardRef(SvgEmquery);
