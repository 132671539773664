import slugify from 'lib/slugify';
import { EventModel } from 'models/event';

export async function registerEvent(event: EventModel, name: string, mail: string, phone: string) {
  if (!event.sheet) {
    throw new Error(`Event ${event.title} has no "sheet" attribute`);
  }

  const params: Record<string, string> = {
    sheet: slugify(event.sheet),
    name,
    phone,
    mail,
  };

  if (!process.env.REACT_APP_API_MAKERSLAB_REGISTER) {
    throw new Error('Missing REACT_APP_API_MAKERSLAB_REGISTER env variable');
  }

  const response = await fetch(process.env.REACT_APP_API_MAKERSLAB_REGISTER, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: Object.keys(params)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&'),
  });

  const json = await response.json();

  if (json?.result === 'success') {
    return json;
  }

  throw new Error('Registration failed');
}

export function listEvents() {}
