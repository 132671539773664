import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgCourseAdvisor(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M7.72 1.8H1.09a.49.49 0 00-.49.49V9a.49.49 0 00.49.49h6.63A.49.49 0 008.21 9V2.29a.52.52 0 00-.49-.49zm-.49 6.68H1.58v-5.7h5.65v5.7zm3.41-4.05a.5.5 0 00.49.54h19.74a.5.5 0 00.49-.54.5.5 0 00-.49-.54H11.13a.57.57 0 00-.49.54zm0 2.49a.5.5 0 00.49.54h11.46a.5.5 0 00.49-.54.5.5 0 00-.49-.54H11.13a.53.53 0 00-.49.54zm0 7.46a.5.5 0 00.49.54h19.74a.54.54 0 000-1.07H11.13a.53.53 0 00-.49.54zm0 2.48a.5.5 0 00.49.54h11.46a.54.54 0 000-1.07H11.13a.53.53 0 00-.49.54zm0 7.46a.5.5 0 00.49.54h19.74a.54.54 0 000-1.07H11.13a.53.53 0 00-.49.54zm0 2.49a.5.5 0 00.49.54h11.46a.54.54 0 000-1.07H11.13a.53.53 0 00-.49.54zM7.72 12H1.09a.49.49 0 00-.49.49v6.68a.49.49 0 00.49.49h6.63a.49.49 0 00.49-.49v-6.69a.49.49 0 00-.49-.48zm-.49 6.63H1.58V13h5.65v5.61zm.49 3.5H1.09a.49.49 0 00-.49.49v6.68a.49.49 0 00.49.49h6.63a.49.49 0 00.49-.49v-6.63a.53.53 0 00-.49-.54zm-.49 6.68H1.58v-5.66h5.65v5.66z" />
      <path d="M3.57 17.74a.44.44 0 00.34.15.49.49 0 00.34-.2l2.35-2.82a.48.48 0 00-.73-.63l-2 2.39-1.12-1.18a.48.48 0 00-.68.68z" />
    </svg>
  );
}

export default forwardRef(SvgCourseAdvisor);
