import React from 'react';
import classNames from 'classnames';
import { Override } from 'types';
import './Spacer.scss';

export type SpacerProps = Override<
  React.ComponentPropsWithoutRef<'div'>,
  {
    inline?: string;
    block?: string;
  }
>;

function Spacer({ inline, block, ...props }: SpacerProps): JSX.Element {
  const style = {
    '--spacer-inline': inline ? `var(--t-gutter-${inline})` : undefined,
    '--spacer-block': block ? `var(--t-gutter-${block})` : undefined,
    ...props.style,
  } as React.CSSProperties;

  return <div {...props} className={classNames('Spacer', props.className)} style={style} />;
}

export default Spacer;
