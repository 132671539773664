import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgPds({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 475.293 475.293"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M401.029 29.706H288.796C278.492 12.034 259.543 0 237.647 0c-21.897 0-40.846 12.034-51.149 29.706H74.265c-8.21 0-14.853 6.643-14.853 14.853V460.44c0 8.21 6.643 14.853 14.853 14.853h326.764c8.21 0 14.853-6.643 14.853-14.853V44.559c0-8.21-6.644-14.853-14.853-14.853zm-163.382 0c8.202 0 14.853 6.649 14.853 14.853 0 8.202-6.65 14.853-14.853 14.853-8.202 0-14.853-6.65-14.853-14.853 0-8.205 6.65-14.853 14.853-14.853zm148.529 415.881H89.117V59.412h89.117v14.853c0 8.21 6.643 14.853 14.853 14.853h89.117c8.21 0 14.853-6.643 14.853-14.853V59.412h89.117v386.175z" />
      <path d="M207.941 347.767l-84.766-84.766 21.003-21.003 63.763 63.763 123.175-123.175 21.003 21.003z" />
    </svg>
  );
}

export default forwardRef(SvgPds);
