import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgHome({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M18.75 9.69L12 2.94 5.25 9.69v10.06h5.5v-5h2.5v5h5.5V9.69zM12 5.06l5.25 5.25v7.939h-2.5v-5h-5.5v5h-2.5v-7.94L12 5.061z" />
    </svg>
  );
}

export default forwardRef(SvgHome);
