import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgFinance({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 28 39"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.455 0H23.5v31.75a2.75 2.75 0 01-2.75 2.75H0V6.923L7.455 0zM2.66 6.5L7 2.47V6.5H2.66zM1.5 33V8h7V1.5H22v30.25A1.25 1.25 0 0120.75 33H1.5zm23.548 3.048A3.25 3.25 0 0026 33.75v-23.5h1.5v23.5a4.75 4.75 0 01-4.75 4.75h-15V37h15a3.25 3.25 0 002.298-.952zM12.703 12c-3.1 0-5.592 2.374-6.414 5.5H4.75V19h1.281a8.945 8.945 0 000 1.5H4.75V22h1.54c.821 3.126 3.313 5.5 6.413 5.5 1.78 0 3.381-.8 4.562-2.068l-1.098-1.022c-.935 1.004-2.153 1.59-3.464 1.59-2.144 0-4.091-1.611-4.858-4h4.905v-1.5H7.536a7.67 7.67 0 010-1.5h5.214v-1.5H7.845c.766-2.389 2.714-4 4.858-4 1.326 0 2.555.599 3.495 1.622l1.104-1.014C16.118 12.818 14.503 12 12.703 12z"
      />
    </svg>
  );
}

export default forwardRef(SvgFinance);
