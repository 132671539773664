import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgMedia({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 77.65 60.03"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M1.76 0A1.83 1.83 0 000 1.78v56.48A1.83 1.83 0 001.76 60h74.12a1.83 1.83 0 001.76-1.76V1.78A1.83 1.83 0 0075.89 0H1.76zm1.77 3.55h11v7.64h-11V3.55zm14.5 0h41.8v52.94H18V3.55zm45.33 0h10.76v7.64H63.37V3.55zM3.53 14.72h11v7.86h-11v-7.86zm59.84 0h10.75v7.86H63.37v-7.86zM3.53 26.1h11v7.83h-11V26.1zm59.84 0h10.75v7.83H63.37V26.1zM3.53 37.47h11v7.83h-11v-7.83zm59.84 0h10.75v7.83H63.37v-7.83zM3.53 48.83h11v7.67h-11v-7.67zm59.84 0h10.75v7.67H63.37v-7.67z" />
      <path d="M28.16 15.38a1.76 1.76 0 00-1.69 1.76v26.44a1.77 1.77 0 002.7 1.49l10.61-6.61 10.61-6.61a1.76 1.76 0 000-3l-10.61-6.6-10.61-6.61a1.77 1.77 0 00-1-.27zM30 20.32l7.9 4.93 8.21 5.12-8.21 5.11L30 40.4V20.32z" />
    </svg>
  );
}

export default forwardRef(SvgMedia);
