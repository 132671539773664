import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgPrint({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 34 28"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M31.386 4.268h-6.303V.715A.716.716 0 0024.367 0H9.397a.716.716 0 00-.715.715v3.553H2.378A2.38 2.38 0 000 6.643v11.383a2.38 2.38 0 002.378 2.376h6.304v6.883c0 .395.32.715.716.715h14.969c.395 0 .716-.32.716-.715v-6.883h6.303a2.38 2.38 0 002.379-2.376V6.643a2.38 2.38 0 00-2.379-2.375zm-6.303 1.43h1.708v2.001h-1.708V5.698zM10.113 1.43h13.538V7.7H10.114V1.43zM6.975 5.698h1.708v2.001H6.974V5.698zM23.651 26.57H10.114v-9.417H23.65v9.417zm8.682-8.544a.947.947 0 01-.947.946h-6.303v-1.819h1.306a.716.716 0 100-1.43H7.376a.716.716 0 100 1.43h1.306v1.819H2.378a.947.947 0 01-.946-.946V6.643c0-.521.425-.945.946-.945h3.164v2.716c0 .395.32.715.716.715h21.249c.395 0 .716-.32.716-.715V5.698h3.163c.522 0 .947.424.947.945v11.383z" />
      <circle cx={4.118} cy={11.529} r={1} />
      <path d="M16.553 22.235h-3.459c-.41 0-.741.369-.741.824 0 .455.332.823.741.823h3.46c.408 0 .74-.368.74-.823 0-.455-.332-.824-.74-.824zm3.344-3.294h-6.853c-.382 0-.691.369-.691.824 0 .455.31.823.691.823h6.853c.382 0 .691-.368.691-.823 0-.455-.31-.824-.69-.824z" />
    </svg>
  );
}

export default forwardRef(SvgPrint);
