import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgCareerCenter(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 91.7 100"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M54.2 100c0-10.4 8.4-18.8 18.8-18.8s18.8 8.4 18.8 18.8" />
      <circle cx={18.8} cy={66.7} r={10.4} />
      <path d="M0 100c0-10.4 8.4-18.8 18.8-18.8s18.8 8.4 18.8 18.8m31.2-48.2L53.5 41.7H50c-5.3 0-8.3-3-8.3-8.3v-25C41.7 3 44.7 0 50 0h33.3c5.3 0 8.3 3 8.3 8.3v25c0 5.3-3 8.3-8.3 8.3H68.8v10.2zM50 4.2c-3 0-4.2 1.2-4.2 4.2v25c0 3 1.2 4.2 4.2 4.2h4.8l9.8 6.5v-6.5h18.8c3 0 4.2-1.2 4.2-4.2v-25c0-3-1.2-4.2-4.2-4.2H50z" />
      <path d="M50 45.8c-2.5.1-5-.6-7.2-1.9l-9.5 6.3v-6.5H16.7c-3 0-4.2-1.2-4.2-4.2v-27c0-3 1.2-4.2 4.2-4.2h20.8c0-1.4.2-2.8.6-4.2H16.7c-5.3 0-8.3 3-8.3 8.3v27.1c0 5.3 3 8.3 8.3 8.3h12.5v10.1l15.2-10.1h1.5c2.2.1 4.3-.6 6-2.1l-1.9.1zm29.2-31.2H73c0-4 0-6.2-4.2-6.2h-4.2c-4.2 0-4.2 2.2-4.2 6.2h-6.2c-1.1 0-2 .8-2.1 1.9v14.8c0 1.1.8 2 1.9 2.1h25.2c1.1 0 2-.8 2.1-1.9V16.7c.1-1.1-.8-2-1.9-2.1h-.2zm-14.6 0v-2.1h4.1v2.1h-4.1z" />
      <path d="M27.1 33.4c-1.1 0-2.1-.9-2.1-2.1V27c0-1.1.9-2.1 2.1-2.1 2.1.2 4-1.3 4.2-3.4v-.7c-.1-2.3-2.1-4.1-4.4-4-2.1.1-3.9 1.8-4 4 0 1.2-.9 2.1-2.1 2.1-1.2 0-2.1-.9-2.1-2.1 0-4.6 3.7-8.3 8.3-8.3s8.3 3.7 8.3 8.3c0 4.2-2.4 7.3-6.2 8.1v2.4c.1 1.2-.9 2.1-2 2.1z" />
      <circle cx={27.1} cy={37.5} r={2.1} />
      <circle cx={72.9} cy={66.7} r={10.4} />
    </svg>
  );
}

export default forwardRef(SvgCareerCenter);
