import { AppStore } from '../store';

export function selectNodes(store: AppStore) {
  return store.nodes;
}

export function selectNode(identifier: number) {
  return function selectNode(store: AppStore) {
    return store.nodes[identifier];
  };
}

export function selectTreeRoot(store: AppStore) {
  return store.nodes.root;
}

export function selectTreeError(store: AppStore) {
  return store.tree.error;
}
