import React from 'react';
import classNames from 'classnames';
import { Override } from 'types';
import useAutoId from 'components/hooks/useAutoId';
import './RadioInput.scss';

export type RadioInputProps = Override<
  React.ComponentPropsWithoutRef<'div'>,
  {
    label: React.ReactNode;
    children?: React.ReactNode;
    inputProps?: React.ComponentPropsWithoutRef<'input'>;
    kind?: 'default' | 'primary' | 'secondary';
  }
>;

function RadioInput({
  label,
  children,
  kind = 'secondary',
  inputProps,
  ...props
}: RadioInputProps): JSX.Element {
  const id = useAutoId(undefined, props.id);

  return (
    <div
      {...props}
      className={classNames('RadioInput', props.className, { [`is-${kind}`]: Boolean(kind) })}
    >
      <input
        {...inputProps}
        className={classNames('RadioInput-input', inputProps?.className)}
        id={id}
        type="radio"
      />
      <label className="RadioInput-label" htmlFor={id}>
        {label}
        {children}
      </label>
    </div>
  );
}

export default RadioInput;
