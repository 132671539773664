import { createContext, useContext } from 'react';
import { DisclosureState } from 'ariakit';

export type MiniTeaserContextValue = {
  visible: boolean;
  registerDisclosure(disclosure: DisclosureState): void;
  unregisterDisclosure(disclosure: DisclosureState): void;
};

const MiniTeaserContext = createContext<MiniTeaserContextValue>({
  visible: true,
  registerDisclosure: () => {},
  unregisterDisclosure: () => {},
});

export default MiniTeaserContext;

export function useMiniTeaserContext(): MiniTeaserContextValue {
  return useContext(MiniTeaserContext);
}
