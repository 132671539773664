import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgElearning1(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="-5 0 477 477.649"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M391.031 140.629c-2.594-51.633-47.87-92.879-103.156-92.879a113.429 113.429 0 00-24.309 2.91c-32.238-47.11-93.882-63.887-145.55-39.617-51.664 24.273-78.11 82.43-62.434 137.316C21.782 164.977.324 198.348.324 235.031c0 53.801 46.328 97.715 103.274 97.715H225.39v51.512c-20 3.472-35.508 19.402-38.434 39.492h-89.19a8 8 0 000 16h89.558c4.23 21 23.274 37.898 46.067 37.898s41.836-16.898 46.066-37.898h89.559a8 8 0 000-16h-89.192c-2.992-20.035-18.476-35.906-38.433-39.39v-51.61H363.18c56.949 0 103.277-43.918 103.277-97.719a95.291 95.291 0 00-23.348-62.289 103.824 103.824 0 00-52.078-32.113zM264.344 430.566c0 17.094-13.86 30.95-30.953 30.95s-30.953-13.856-30.953-30.95 13.859-30.953 30.953-30.953c17.086.02 30.933 13.867 30.953 30.953zM363.18 316.75H103.598c-48.121 0-87.274-36.742-87.274-81.719 0-32.539 20.438-61.949 52.067-74.816a8.042 8.042 0 004.523-10.13C55.684 101.653 77.82 48.028 124.2 25.849c46.38-22.176 102.016-5.743 128.899 38.074a8.004 8.004 0 008.761 3.578c10.532-2.629 18.43-3.75 26.137-3.75 48.121 0 87.395 36.586 87.395 81.566v1.711a7.897 7.897 0 006.328 7.848 87.873 87.873 0 0149.36 28.273 78.39 78.39 0 0119.405 51.887c.008 44.973-39.18 81.715-87.304 81.715zm0 0" />
      <path d="M315.39 128.648c0-13.199-10.699-23.898-23.898-23.898H175.29c-13.2 0-23.898 10.7-23.898 23.898v137.2c0 13.199 10.699 23.902 23.898 23.902h116.203c13.2 0 23.899-10.703 23.899-23.902zm-148 137.801V128.895a8.269 8.269 0 018-7.895v153.34a8.268 8.268 0 01-8-7.89zm132 0a8.34 8.34 0 01-7.98 8.301H191.39v-154h100.02a8.199 8.199 0 017.98 8.145zm0 0" />
      <path d="M291.39 135.75a8 8 0 00-8-8h-81a8 8 0 00-8 8v99a8 8 0 008 8h81a8 8 0 008-8zm-81 8h65v83h-65zm73.465 107H202.79a8 8 0 000 16h81.066a8 8 0 000-16zm0 0" />
    </svg>
  );
}

export default forwardRef(SvgElearning1);
