import Container from 'components/ui/Container';
import { Override } from 'types';
import './TwoColumnsLayout.scss';

export type TwoColumnsLayoutProps = Override<
  React.ComponentPropsWithRef<'div'>,
  {
    main?: React.ReactNode;
    aside?: React.ReactNode;
  }
>;

function TwoColumnsLayout({ children, main, aside, ...props }: TwoColumnsLayoutProps): JSX.Element {
  return (
    <Container className="TwoColumnsLayout">
      <div className="TwoColumnsLayout-content" {...props}>
        {aside ? <div className="TwoColumnsLayout-aside">{aside}</div> : null}
        {main ? <div className="TwoColumnsLayout-main">{main}</div> : null}
      </div>
    </Container>
  );
}

export default TwoColumnsLayout;
