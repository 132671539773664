import Node, { NodeProps } from 'components/tree/Node';
import FooterSocials from 'components/ui/Footer/FooterSocials';
import useMessages from 'components/hooks/useMessages';
import SocialLinkView from 'components/tree/behavior/link/SocialLinkView';

export type FooterSocialsZoneProps = NodeProps;

function FooterSocialsZone({ content }: FooterSocialsZoneProps): JSX.Element {
  const messages = useMessages((t) => ({ findUs: t('footer.findUs') }), []);

  return (
    <FooterSocials {...messages}>
      {content.map((node) => (
        <Node key={node.identifier} identifier={node.identifier} view={SocialLinkView} />
      ))}
    </FooterSocials>
  );
}

export default FooterSocialsZone;
