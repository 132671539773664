import classNames from 'classnames';
import { Override } from 'types';
import './Toaster.scss';

export type ToasterProps = Override<
  React.ComponentPropsWithoutRef<'div'>,
  {
    sticky?: boolean;
  }
>;

function Toaster({ sticky, className, ...props }: ToasterProps): JSX.Element {
  return (
    <div
      className={classNames('Toaster', className, {
        'is-sticky': sticky,
      })}
      {...props}
    />
  );
}

export default Toaster;
