import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgCervoprint(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 -63 512 512"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M496.207 72.543h-55.043v-56.75C441.164 7.07 434.094 0 425.371 0H15.793C7.07 0 0 7.07 0 15.793v282.754c0 8.719 7.07 15.789 15.793 15.789h55.043v56.75c0 8.723 7.07 15.793 15.793 15.793h409.578c8.723 0 15.793-7.07 15.793-15.793V88.332c0-8.719-7.07-15.789-15.793-15.789zM31.582 31.582h377.996v251.172H31.582zm448.836 323.715H102.422v-40.961h322.95c8.722 0 15.792-7.07 15.792-15.79V104.126h39.254zm0 0" />
      <path d="M203.148 157.168v-86.07c0-8.72-7.07-15.79-15.793-15.79H72.965c-8.723 0-15.793 7.07-15.793 15.79v86.07c0 8.723 7.07 15.793 15.793 15.793h114.39c8.723 0 15.793-7.07 15.793-15.793zm-31.582-15.79H88.754V86.892h82.812zm0 0M269.5 254.988h97.445c8.723 0 15.793-7.07 15.793-15.793 0-8.718-7.07-15.789-15.793-15.789H269.5c-8.719 0-15.79 7.07-15.79 15.79 0 8.722 7.071 15.792 15.79 15.792zm0 0M269.5 204.953h44.219c8.719 0 15.789-7.07 15.789-15.793 0-8.719-7.07-15.789-15.79-15.789H269.5c-8.719 0-15.79 7.07-15.79 15.79 0 8.722 7.071 15.792 15.79 15.792zm0 0" />
    </svg>
  );
}

export default forwardRef(SvgCervoprint);
