import { ErrorModel, PagerModel } from 'models';
import { ActivityListParams, ActivityModel } from 'models/activity';

// LIST

export function requestActivityList(scope: string, filters: ActivityListParams) {
  return {
    type: requestActivityList.type,
    payload: {
      scope,
      filters,
    },
  } as const;
}

requestActivityList.type = 'activity/list/request' as const;

export function receiveActivityList(scope: string, pager: PagerModel<ActivityModel>) {
  return {
    type: receiveActivityList.type,
    payload: {
      scope,
      pager,
    },
  } as const;
}

receiveActivityList.type = 'activity/list/receive' as const;

export function errorActivityList(scope: string, error: ErrorModel) {
  return {
    type: errorActivityList.type,
    payload: {
      scope,
      error,
    },
  } as const;
}

errorActivityList.type = 'activity/list/error' as const;

// DETAIL

export function requestActivityDetail(activityId: number) {
  return {
    type: requestActivityDetail.type,
    payload: {
      activityId,
    },
  } as const;
}

requestActivityDetail.type = 'activity/detail/request' as const;

export function receiveActivityDetail(activityId: number, activity: ActivityModel) {
  return {
    type: receiveActivityDetail.type,
    payload: {
      activityId,
      activity,
    },
  } as const;
}

receiveActivityDetail.type = 'activity/detail/receive' as const;

export function errorActivityDetail(activityId: number, error: ErrorModel) {
  return {
    type: errorActivityDetail.type,
    payload: {
      activityId,
      error,
    },
  } as const;
}

errorActivityDetail.type = 'activity/detail/error' as const;

// CREATE

export function requestActivityCreate(activity: Partial<ActivityModel>) {
  return {
    type: requestActivityCreate.type,
    payload: {
      activity,
    },
  } as const;
}

requestActivityCreate.type = 'activity/create/request' as const;

export function receiveActivityCreate(activity: ActivityModel, subscriptions: number[]) {
  return {
    type: receiveActivityCreate.type,
    payload: {
      activity,
      subscriptions,
    },
  } as const;
}

receiveActivityCreate.type = 'activity/create/receive' as const;

export function errorActivityCreate(error: ErrorModel) {
  return {
    type: errorActivityCreate.type,
    payload: {
      error,
    },
  } as const;
}

errorActivityCreate.type = 'activity/create/error' as const;

export function resetActivityCreate() {
  return {
    type: resetActivityCreate.type,
    payload: {},
  } as const;
}

resetActivityCreate.type = 'activity/create/reset' as const;

// UPDATE

export function requestActivityUpdate(activityId: number, activity: Partial<ActivityModel>) {
  return {
    type: requestActivityUpdate.type,
    payload: {
      activityId,
      activity,
    },
  } as const;
}

requestActivityUpdate.type = 'activity/update/request' as const;

export function receiveActivityUpdate(activityId: number, activity: ActivityModel) {
  return {
    type: receiveActivityUpdate.type,
    payload: {
      activityId,
      activity,
    },
  } as const;
}

receiveActivityUpdate.type = 'activity/update/receive' as const;

export function errorActivityUpdate(activityId: number, error: ErrorModel) {
  return {
    type: errorActivityUpdate.type,
    payload: {
      activityId,
      error,
    },
  } as const;
}

errorActivityUpdate.type = 'activity/update/error' as const;

// DELETE

export function requestActivityDelete(activityId: number) {
  return {
    type: requestActivityDelete.type,
    payload: {
      activityId,
    },
  } as const;
}

requestActivityDelete.type = 'activity/delete/request' as const;

export function receiveActivityDelete(activityId: number) {
  return {
    type: receiveActivityDelete.type,
    payload: {
      activityId,
    },
  } as const;
}

receiveActivityDelete.type = 'activity/delete/receive' as const;

export function errorActivityDelete(activityId: number, error: ErrorModel) {
  return {
    type: errorActivityDelete.type,
    payload: {
      activityId,
      error,
    },
  } as const;
}

errorActivityDelete.type = 'activity/delete/error' as const;

// SUBSCRIBE

export function requestActivitySubscribe(activityId: number, subscribe: boolean) {
  return {
    type: requestActivitySubscribe.type,
    payload: {
      activityId,
      subscribe,
    },
  } as const;
}

requestActivitySubscribe.type = 'activity/subscribe/request' as const;

export function receiveActivitySubscribe(
  activityId: number,
  activity: ActivityModel,
  subscriptions: number[],
) {
  return {
    type: receiveActivitySubscribe.type,
    payload: {
      activityId,
      activity,
      subscriptions,
    },
  } as const;
}

receiveActivitySubscribe.type = 'activity/subscribe/receive' as const;

export function errorActivitySubscribe(activityId: number, error: ErrorModel) {
  return {
    type: errorActivitySubscribe.type,
    payload: {
      activityId,
      error,
    },
  } as const;
}

errorActivitySubscribe.type = 'activity/subscribe/error' as const;
