import React from 'react';
import classnames from 'classnames';
import { OverridableComponentProps } from 'components/OverridableComponent';
import Icon from '../Icon/Icon';
import SrOnly from '../SrOnly/SrOnly';
import './Cta.scss';

type BaseProps = {
  /**
   * Is this the principal call to action on the page?
   */
  kind?: 'primary' | 'secondary' | 'default';
  /**
   * How large should the button be?
   */
  size?: 'sm' | 'lg';
  /**
   * If button has only the icon visible
   */
  iconOnly?: boolean;
  /**
   * If icon is right side of text
   */
  iconRight?: boolean;
  /**
   *
   * The icon name
   */
  icon?: React.ComponentPropsWithoutRef<typeof Icon>['as'];
  /**
   * If button has disabled state
   */
  disabled?: boolean;
  /**
   *
   */
  children?: React.ReactNode;
};

export type CtaProps<A> = OverridableComponentProps<A, 'button', BaseProps>;

/**
 * Primary UI component for user interaction
 */
export default function Cta<A>({
  as: As = 'button',
  kind = 'default',
  size,
  iconOnly = false,
  iconRight = false,
  icon,
  disabled,
  children = null,
  className,
  ...props
}: CtaProps<A>): JSX.Element {
  return (
    <As
      className={classnames('Cta', `is-${kind}`, className, {
        'is-disabled': disabled,
        'is-iconOnly': iconOnly,
        'is-iconLeft': !iconOnly && !iconRight,
        'is-iconRight': !iconOnly && iconRight,
        [`is-${size}`]: size,
      })}
      disabled={disabled}
      {...props}
    >
      {icon && !iconRight ? <Icon as={icon} className="Cta-icon" /> : null}
      {iconOnly ? <SrOnly>{children}</SrOnly> : children}
      {icon && iconRight ? <Icon as={icon} className="Cta-icon" /> : null}
    </As>
  );
}
