import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import {
  setToast,
  toggleChatbot,
  openDialog,
  closeDialog,
  activateProgress,
  deactivateProgress,
  updateToast,
  DIALOG_NAME,
  updateFilters,
  putSettings,
} from '../actions/uiActions';
import { addSteps, removeSteps, skipSteps } from '../actions/helpActions';
import { HelpStep, ToastType } from '../models';
import { createReducer, createPersistConfig } from './reducerUtils';

// FILTERS REDUCER

export type FiltersState = Readonly<{
  term?: string;
  tag?: string;
  title?: string;
  campus?: string;
  contract?: string;
  domain?: string;
  application?: string;
  page?: number;
  perPage?: number;
  active?: number;
}>;

export function isFilterName(name: any): name is keyof FiltersState {
  return name && typeof name === 'string';
}

export type FiltersListsState = Readonly<{
  [scope in string]?: FiltersState;
}>;

export const initialFiltersState: FiltersState = {};

export const initialFiltersListsState: FiltersListsState = {
  lists: {},
};

export function filtersEventReducer(
  state: FiltersListsState,
  action: ReturnType<typeof updateFilters>,
) {
  const filters = {
    ...initialFiltersState,
    ...state[action.payload.scope],
    [action.payload.name]: action.payload.value,
  };

  if (action.payload.value === undefined) {
    delete filters[action.payload.name];
  }

  return {
    ...state,
    [action.payload.scope]: filters,
  };
}
const filtersReducer = createReducer(initialFiltersListsState, {
  [updateFilters.type]: filtersEventReducer,
});

// HELP REDUCERS

export type HelperState = Readonly<{
  steps: { [id: string]: HelpStep };
  skipped: string[];
}>;

const initialHelperState: HelperState = {
  steps: {},
  skipped: [],
};

export function addStepsReducer(state: HelperState, action: ReturnType<typeof addSteps>) {
  const steps = { ...state.steps };

  action.payload.steps.forEach(function stepIterator(step) {
    steps[step.id] = {
      ...steps[step.id],
      ...step,
    };
  });

  return { ...state, steps };
}

export function removeStepsReducer(state: HelperState, action: ReturnType<typeof removeSteps>) {
  const steps = { ...state.steps };

  action.payload.steps.forEach(function stepIterator(step) {
    delete steps[step.id];
  });

  return { ...state, steps };
}

export function skipStepsReducer(state: HelperState, action: ReturnType<typeof skipSteps>) {
  const skipped = [...state.skipped];

  action.payload.steps.forEach(function stepIterator(step) {
    if (skipped.indexOf(step) < 0) {
      skipped.push(step);
    }
  });

  return { ...state, skipped };
}

export const helpPersistConfig = createPersistConfig('help');

const helperReducer = persistReducer(
  helpPersistConfig,
  createReducer(initialHelperState, {
    [addSteps.type]: addStepsReducer,
    [removeSteps.type]: removeStepsReducer,
    [skipSteps.type]: skipStepsReducer,
  }),
);

// SETTINGS REDUCER

export type SettingsState = Readonly<{
  showAppBanner: boolean;
  messagingToken?: string;
}>;

const initialSettingsState: SettingsState = {
  showAppBanner: true,
  messagingToken: undefined,
};

export function putSettingsReducer(state: SettingsState, action: ReturnType<typeof putSettings>) {
  return { ...state, ...action.payload };
}

export const settingsPersistConfig = createPersistConfig('settings');

const settingsReducer = persistReducer(
  settingsPersistConfig,
  createReducer(initialSettingsState, {
    [putSettings.type]: putSettingsReducer,
  }),
);

// TOASTER REDUCER

export type ToasterState = Readonly<{
  toasts: ToastType[];
}>;

const initialToasterState: ToasterState = {
  toasts: [],
};

export function setToastReducer(state: ToasterState, action: ReturnType<typeof setToast>) {
  const toasts = [...state.toasts];

  const index = toasts.findIndex(function toastFinder(toast) {
    return toast.uid === action.payload.toast.uid;
  });

  if (index >= 0) {
    const toast = toasts[index];
    toasts[index] = {
      ...toast,
      ...action.payload.toast,
    };
  } else {
    toasts.push(action.payload.toast);
  }

  return {
    ...state,
    toasts,
  };
}

export function updateToastReducer(state: ToasterState, action: ReturnType<typeof updateToast>) {
  const toasts = [...state.toasts];

  const index = toasts.findIndex(function toastFinder(toast) {
    return toast.uid === action.payload.toast.uid;
  });

  if (index >= 0) {
    const toast = toasts[index];
    toasts[index] = {
      ...toast,
      ...action.payload.toast,
    };
  }

  return {
    ...state,
    toasts,
  };
}

const toasterReducer = createReducer(initialToasterState, {
  [setToast.type]: setToastReducer,
  [updateToast.type]: updateToastReducer,
});

// PROGRESS REDUCER

export type ProgressState = Readonly<{
  active: boolean;
}>;

const initialProgressState: ProgressState = {
  active: false,
};

export function activateProgressReducer(state: ProgressState) {
  return {
    ...state,
    active: true,
  };
}

export function deactivateProgressReducer(state: ProgressState) {
  return {
    ...state,
    active: false,
  };
}

const progressReducer = createReducer(initialProgressState, {
  [activateProgress.type]: activateProgressReducer,
  [deactivateProgress.type]: deactivateProgressReducer,
});

// CHATBOT REDUCER

export type ChatbotState = Readonly<{
  active: boolean;
  title?: string;
}>;

const initialChatbotState: ChatbotState = {
  active: false,
};

export function toggleChatbotReducer(
  state: ChatbotState,
  action: ReturnType<typeof toggleChatbot>,
) {
  return {
    ...state,
    active: undefined === action.payload.active ? !state.active : action.payload.active,
  };
}

const chatbotReducer = createReducer(initialChatbotState, {
  [toggleChatbot.type]: toggleChatbotReducer,
});

// DIALOG REDUCER

export type DialogState = Readonly<{
  [name in DIALOG_NAME]?: {
    [k: string]: unknown;
    open: boolean;
  };
}>;

const initialDialogState: DialogState = {};

export function openDialogReducer(state: DialogState, action: ReturnType<typeof openDialog>) {
  return {
    ...state,
    [action.payload.dialog]: { ...action.payload.props, open: action.payload.open },
  };
}

export function closeDialogReducer(state: DialogState, action: ReturnType<typeof closeDialog>) {
  return {
    ...state,
    [action.payload.dialog]: { ...state[action.payload.dialog], open: action.payload.open },
  };
}

const dialogReducer = createReducer(initialDialogState, {
  [openDialog.type]: openDialogReducer,
  [closeDialog.type]: closeDialogReducer,
});

export default combineReducers({
  helper: helperReducer,
  toaster: toasterReducer,
  progress: progressReducer,
  chatbot: chatbotReducer,
  dialog: dialogReducer,
  filters: filtersReducer,
  settings: settingsReducer,
});
