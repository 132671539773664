import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgGraduation(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 16 13"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M9.026 9.565A2 2 0 018 9.833a2 2 0 01-1.026-.268l-4.65-2.827s-.419-.255-.419.323v2.823c0 1.486 2.729 3.09 6.095 3.09 3.367 0 6.096-1.604 6.096-3.09V6.901c0-.463-.299-.237-.299-.237l-4.77 2.9z" />
      <path d="M15.73 4.49c.358-.218.358-.573 0-.79L8.65.189c-.357-.218-.942-.218-1.3 0L.269 3.699c-.357.218-.357.573 0 .79L7.35 8.796c.357.217.942.217 1.299 0m6.789 2.076V6.116s.003-.225-.131-.15c-.109.06-.374.206-.467.286-.108.093-.084.302-.084.302v4.318c0 .061-.053.09-.078.105a.819.819 0 00.42 1.53.82.82 0 10.417-1.531c-.026-.014-.077-.043-.077-.104z" />
    </svg>
  );
}

export default forwardRef(SvgGraduation);
