import {
  requestNotificationList,
  receiveNotificationList,
  errorNotificationList,
  receiveNotificationDetail,
  NotificationListName,
} from 'actions/notificationAction';
import { NotificationModel, NotificationListParams } from 'models/notification';
import { ErrorModel, PagerModel } from 'models';
import { createReducer } from './reducerUtils';

export type NotificationListState = Readonly<{
  data?: PagerModel<NotificationModel>;
  params?: NotificationListParams;
  error?: ErrorModel;
  getting: boolean;
}>;

export type NotificationDetailState = Readonly<{
  data?: NotificationModel;
  error?: ErrorModel;
  getting: boolean;
  reading: boolean;
}>;

export type NotificationState = Readonly<{
  lists: Record<NotificationListName, NotificationListState>;
  details: Record<NotificationModel['uuid'], NotificationDetailState | undefined>;
}>;

export const initialNotificationListState: NotificationListState = {
  data: undefined,
  params: undefined,
  error: undefined,
  getting: false,
};

export const initialNotificationDetailState: NotificationDetailState = {
  data: undefined,
  error: undefined,
  getting: false,
  reading: false,
};

export const initialNotificationState: NotificationState = {
  lists: {
    simple: initialNotificationListState,
    full: initialNotificationListState,
  },
  details: {},
};

function requestNotificationListReducer(
  state: NotificationState,
  action: ReturnType<typeof requestNotificationList>,
): NotificationState {
  const { list, params } = action.payload;

  return {
    ...state,
    lists: {
      ...state.lists,
      [list]: {
        ...state.lists[list],
        params: params ?? state.lists[list]?.params,
        error: undefined,
        getting: true,
      },
    },
  };
}

function receiveNotificationListReducer(
  state: NotificationState,
  action: ReturnType<typeof receiveNotificationList>,
): NotificationState {
  const { list, data } = action.payload;

  const details = {
    ...state.details,
  };

  data.items.forEach((notification) => {
    details[notification.uuid] = {
      ...initialNotificationDetailState,
      ...state.details[notification.uuid],
      data: notification,
    };
  });

  return {
    ...state,
    lists: {
      ...state.lists,
      [list]: {
        ...state.lists[list],
        data,
        error: undefined,
        getting: false,
      },
    },
    details,
  };
}

function errorNotificationListReducer(
  state: NotificationState,
  action: ReturnType<typeof errorNotificationList>,
): NotificationState {
  const { list, error } = action.payload;

  return {
    ...state,
    lists: {
      ...state.lists,
      [list]: {
        ...state.lists[list],
        data: undefined,
        error,
        getting: false,
      },
    },
  };
}

function receiveNotificationDetailReducer(
  state: NotificationState,
  action: ReturnType<typeof receiveNotificationDetail>,
): NotificationState {
  const { data } = action.payload;

  return {
    ...state,
    details: {
      ...state.details,
      [data.uuid]: {
        ...initialNotificationDetailState,
        ...state.details[data.uuid],
        data,
        error: undefined,
        getting: false,
        reading: false,
      },
    },
  };
}

export default createReducer(initialNotificationState, {
  [String(requestNotificationList)]: requestNotificationListReducer,
  [String(receiveNotificationList)]: receiveNotificationListReducer,
  [String(errorNotificationList)]: errorNotificationListReducer,
  [String(receiveNotificationDetail)]: receiveNotificationDetailReducer,
});
