import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserFavoriteServices } from 'selectors/userSelectors';
import { updateUser } from 'actions/userActions';

type ServiceListContextValue = {
  favoriteServices: number[];
  isFavorite(identifier: number): boolean;
  onFavoriteChange(identifier: number, favorite: boolean): void;
};

export const ServiceListContext = createContext<ServiceListContextValue>({
  favoriteServices: [],
  isFavorite: () => false,
  onFavoriteChange: () => {},
});

export default ServiceListContext;

export function useServiceListContext(): ServiceListContextValue {
  return useContext(ServiceListContext);
}

export function useServiceList(): ServiceListContextValue {
  const dispatch = useDispatch();

  const initialFavoriteServices = useSelector(selectUserFavoriteServices);
  const favoriteServicesRef = useRef(initialFavoriteServices);
  const [favoriteServices, setFavoriteServices] = useState(favoriteServicesRef.current);

  // Sets local favorite services when its loaded
  useEffect(() => {
    favoriteServicesRef.current = initialFavoriteServices;
    setFavoriteServices(initialFavoriteServices);
  }, [initialFavoriteServices]);

  // Saves favorite services when it changes
  useEffect(() => {
    if (favoriteServicesRef.current !== favoriteServices) {
      dispatch(
        updateUser({
          favoriteServices,
        }),
      );
    }
  }, [dispatch, favoriteServices]);

  // Updates a favorite service
  const onFavoriteChange = useCallback<ServiceListContextValue['onFavoriteChange']>(
    (identifier, favorite) => {
      const alreadyFavorite = favoriteServices.includes(identifier);

      if (favorite && !alreadyFavorite) {
        setFavoriteServices((favoriteServices) => [...favoriteServices, identifier]);
      } else if (!favorite && alreadyFavorite) {
        setFavoriteServices((favoriteServices) =>
          favoriteServices.filter((favoriteService) => favoriteService !== identifier),
        );
      }
    },
    [favoriteServices],
  );

  // Checks if a service is a favorite
  const isFavorite = useCallback<ServiceListContextValue['isFavorite']>(
    (identifier) => {
      return favoriteServices.includes(identifier);
    },
    [favoriteServices],
  );

  return { isFavorite, onFavoriteChange, favoriteServices };
}
