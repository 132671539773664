import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgAdeBooking(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 64 64"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M56 40.105V12c0-2.757-2.243-5-5-5h-2V5c0-1.654-1.346-3-3-3s-3 1.346-3 3v2h-5V5c0-1.654-1.346-3-3-3s-3 1.346-3 3v2h-6V5c0-1.654-1.346-3-3-3s-3 1.346-3 3v2h-5V5c0-1.654-1.346-3-3-3S9 3.346 9 5v2H7c-2.757 0-5 2.243-5 5v40c0 2.757 2.243 5 5 5h33.623c2.08 3.58 5.947 6 10.377 6 6.617 0 12-5.383 12-12 0-4.831-2.876-8.994-7-10.895zM45 5a1 1 0 012 0v6a1 1 0 01-2 0zM34 5a1 1 0 012 0v6a1 1 0 01-2 0zM22 5a1 1 0 012 0v6a1 1 0 01-2 0zM11 5a1 1 0 012 0v6a1 1 0 01-2 0zM7 9h2v2c0 1.654 1.346 3 3 3s3-1.346 3-3V9h5v2c0 1.654 1.346 3 3 3s3-1.346 3-3V9h6v2c0 1.654 1.346 3 3 3s3-1.346 3-3V9h5v2c0 1.654 1.346 3 3 3s3-1.346 3-3V9h2c1.654 0 3 1.346 3 3v5H4v-5c0-1.654 1.346-3 3-3zm0 46c-1.654 0-3-1.346-3-3V19h50v20.395A11.943 11.943 0 0051 39c-.683 0-1.348.07-2 .18V34a1 1 0 00-1-1h-6a1 1 0 00-1 1v6a1 1 0 001 1h2.38C41.143 43.151 39 46.828 39 51c0 1.404.255 2.747.7 4zm40-16h-4v-4h4zm4 22c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10z" />
      <path d="M52 49.277V43h-2v6.277c-.595.347-1 .985-1 1.723 0 .101.015.198.03.296l-3.655 2.923 1.25 1.562 3.652-2.921c.225.088.467.14.723.14a2 2 0 002-2c0-.738-.405-1.376-1-1.723zM15 22H9a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4zm12-6h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4zm12-6h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4zm6 2h6a1 1 0 001-1v-6a1 1 0 00-1-1h-6a1 1 0 00-1 1v6a1 1 0 001 1zm1-6h4v4h-4zm-28 9H9a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4zm12-6h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4zm12-6h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4zm-21 5H9a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4zm12-6h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4zm12-6h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 6h-4v-4h4z" />
    </svg>
  );
}

export default forwardRef(SvgAdeBooking);
