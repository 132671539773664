import { useCallback, useState } from 'react';
import { Override } from 'types';
import AccordionPanel from 'components/ui/Accordion/AccordionPanel';
import Badge from 'components/ui/Badge';
import classNames from 'classnames';
import './NotificationCard.scss';

export type NotificationCardMessages = {
  read: string;
  notRead: string;
};

export type NotificationCardProps = Override<
  React.ComponentPropsWithoutRef<typeof AccordionPanel>,
  {
    read?: boolean;
    important?: boolean;
    full?: boolean;
    title: React.ReactNode;
    date?: React.ReactNode;
    location?: React.ReactNode;
    text?: React.ReactNode;
    alert?: React.ReactNode;
    children?: React.ReactNode;
    onRead?(): void;
    messages?: Partial<NotificationCardMessages>;
  }
>;

function NotificationCard({
  title,
  date,
  location,
  text,
  alert,
  children,
  important,
  read,
  onRead,
  initialOpen,
  messages = {},
  className,
  ...props
}: NotificationCardProps): JSX.Element {
  const full = Boolean(children);
  const [open, setOpen] = useState(initialOpen);

  const handleToggle = useCallback(
    (open: boolean) => {
      setOpen(open);

      if (open && !read && onRead) {
        onRead();
      }
    },
    [read, onRead],
  );

  const resume = (
    <div className="NotificationCard-resume">
      <div className="NotificationCard-header">
        <div className="NotificationCard-badge">
          <Badge value={read ? undefined : null} label={read ? messages.read : messages.notRead} />
        </div>
        <div className="NotificationCard-title">{title}</div>
      </div>
      <div className="NotificationCard-content">
        {date ? <div className="NotificationCard-date">{date}</div> : null}
        {location ? <div>{location}</div> : null}
        {text ? <div className="NotificationCard-text">{text}</div> : null}
        {alert ? <div>{alert}</div> : null}
      </div>
    </div>
  );

  return (
    <AccordionPanel
      toggable={full}
      title={resume}
      className={classNames('NotificationCard', className, {
        'is-read': !open && read,
        'is-important': important,
        'is-full': full,
      })}
      onToggle={handleToggle}
      initialOpen={initialOpen}
      {...props}
    >
      {full ? <div className="NotificationCard-children">{children}</div> : null}
    </AccordionPanel>
  );
}

export default NotificationCard;
