import Overlay from 'components/ui/Overlay';
import image from 'assets/img/login.jpg';
import imageMobile from 'assets/img/login-mobile.jpg';
import imageTablet from 'assets/img/login-tablet.jpg';
import { Override } from 'types';
import classNames from 'classnames';
import Title from 'components/ui/Title';
import './LandingLayout.scss';
import logoEm from 'assets/svg/eml-logo.svg';
import useTranslate from 'components/hooks/useTranslate';

export type LandingLayoutSide = 'right' | 'left';

export type LandingLayoutProps = Override<
  React.ComponentPropsWithRef<'div'>,
  {
    side?: LandingLayoutSide;
    titleProps?: React.ComponentPropsWithRef<'h1'>;
    messages: {
      baseline: string;
      home?: string;
      title?: string;
      caption?: string;
    };
  }
>;

function LandingLayout({
  messages,
  children,
  titleProps,
  className,
  side,
  ...props
}: LandingLayoutProps): JSX.Element {
  const t = useTranslate();

  return (
    <>
      <div
        className={classNames('LandingLayout', className, {
          [`is-${side}`]: Boolean(side),
        })}
        {...props}
      >
        {messages.title ? (
          <Title className="LandingLayout-title" {...titleProps}>
            {messages.title}
          </Title>
        ) : null}
        {messages.caption ? (
          <div className="LandingLayout-caption">
            <p>{messages.caption}</p>
          </div>
        ) : null}
        <div className="LandingLayout-content">{children}</div>
      </div>
      <Overlay>
        <img
          className={classNames('Logo-em', className)}
          src={logoEm}
          alt={t('identity.baseline')}
        />
        <img
          src={image}
          srcSet={`${imageMobile} 480w, ${imageTablet} 768w, ${image} 1280w`}
          alt="Login"
        />
      </Overlay>
    </>
  );
}

export default LandingLayout;
