import { useHeaderContext } from 'components/ui/Header/HeaderProvider';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type SearchContextValue = {
  searchText: string;
  onSearchTextChange(searchText: string): void;
  toggled: boolean;
  onToggledChange(toggled: boolean): void;
  locked: boolean;
  onLockedChange(locked: boolean): void;
};

const defaultSearchContext: SearchContextValue = {
  searchText: '',
  onSearchTextChange() {},
  toggled: false,
  onToggledChange() {},
  locked: false,
  onLockedChange() {},
};

export const SearchContext = React.createContext<SearchContextValue>(defaultSearchContext);

export type SearchProviderProps = {
  children: React.ReactNode;
};

function SearchProvider({ children }: SearchProviderProps): JSX.Element {
  const [searchText, setSearchText] = useState(defaultSearchContext.searchText);
  const [toggled, setToggled] = useState(defaultSearchContext.toggled);
  const [locked, setLocked] = useState(defaultSearchContext.locked);

  // Locked state is only used through a ref to prevent unwanted behaviors.
  const lockedRef = useRef(locked);
  useEffect(() => {
    lockedRef.current = locked;
  }, [locked]);

  const onToggledChange = useCallback<SearchContextValue['onToggledChange']>((toggled) => {
    if (!lockedRef.current) {
      setToggled(toggled);
    }
  }, []);

  const onLockedChange = useCallback<SearchContextValue['onLockedChange']>((locked) => {
    setLocked(locked);
    if (locked) {
      setToggled(false);
    }
  }, []);

  const searchContextValue: SearchContextValue = useMemo((): SearchContextValue => {
    return {
      searchText,
      onSearchTextChange: setSearchText,
      toggled,
      onToggledChange,
      locked,
      onLockedChange,
    };
  }, [searchText, setSearchText, toggled, onToggledChange, locked, onLockedChange]);

  // Open search results when a search text is entered
  useEffect(() => {
    if (searchText && !lockedRef.current) {
      onToggledChange(true);
    }
  }, [searchText, onToggledChange]);

  // Header style
  const { setTransparent } = useHeaderContext();
  useEffect(() => {
    if (toggled) {
      return setTransparent(false);
    }
  }, [setTransparent, toggled]);

  return <SearchContext.Provider value={searchContextValue}>{children}</SearchContext.Provider>;
}

export default SearchProvider;
