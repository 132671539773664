import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NodeModel, isTokenModel, TranslateModel } from 'models';
import cdmApi from 'services/cdmApi';
import { selectIdentity } from 'selectors/authSelectors';
import DialogLoginAs from 'components/dialogs/DialogLoginAs';
import DialogDisabledApp from 'components/dialogs/DialogDisabledApp';
import { logoutWithAll } from 'actions/authActions';
import { NodeProps } from 'components/tree/Node';
import useRedirectUrl from 'components/hooks/useRedirectUrl';
import DialogProfileSwitch from 'components/dialogs/DialogProfileSwitch';
import { selectHasSeenProfileSwitcher } from 'selectors/userSelectors';
import { updateUser } from '../../../../actions/userActions';

export type LinkViewProps = {
  identifier: number;
  to?: string;
  onClick?(event: React.MouseEvent): void;
  title?: string;
  titleLong?: string;
  description?: string;
  disabled?: boolean;
  icon?: string;
  content?: NodeModel[];
  type?: string;
  children?: React.ReactNode;
};

export type LinkBehaviorModel = {
  title: string;
  icon?: string;
  titleLong?: string;
  description?: string;
  textDisabled?: string;
  linkType: string;
  url?: string;
  page?: { title: string; slug?: string };
  systemType?: string;
  switchableProfiles?: TranslateModel[];
};

export type LinkBehaviorProps = NodeProps<LinkBehaviorModel, LinkViewProps>;

function LinkBehavior(props: LinkBehaviorProps): JSX.Element | null {
  const {
    identifier,
    content,
    view: View,
    behavior: {
      title,
      url,
      titleLong,
      textDisabled,
      linkType,
      systemType,
      icon,
      description,
      switchableProfiles,
    },
  } = props;
  const dispatch = useDispatch();
  const [openDialogLoginAs, setOpenDialogLoginAs] = useState(false);
  const [openDialogDisabledApp, setOpenDialogDisabledApp] = useState(false);
  const [openDialogProfileSwitch, setOpenDialogProfileSwitch] = useState(false);

  const identity = useSelector(selectIdentity);
  const loggedAs = Boolean(identity.from_sub);
  const hasSeenProfileSwitcher = useSelector(selectHasSeenProfileSwitcher);

  useEffect(() => {
    if (false === hasSeenProfileSwitcher) {
      setOpenDialogProfileSwitch(true);
      dispatch(updateUser({ hasSeenProfileSwitcher: true }));
    }
  }, [hasSeenProfileSwitcher, dispatch]);

  // Use a redirect URL when the link is an external SSO
  const [redirectUrl] = useRedirectUrl(url || undefined);

  // Callback for logout button
  const handleLogout = useCallback(() => {
    dispatch(logoutWithAll());
  }, [dispatch]);

  // Callback for impersonation button, to cancel or to open the impersonation dialog
  const handleLoginAs = useCallback(async () => {
    if (loggedAs) {
      const response = await cdmApi.post('/loginas/cancel');
      if (isTokenModel(response.data)) {
        const codeToken = response.data.token;
        window.location.href = `/?codeToken=${codeToken}`;
      }
    } else {
      setOpenDialogLoginAs(true);
    }
  }, [loggedAs, setOpenDialogLoginAs]);

  // Callback for closing impersonation dialog
  const handleExitDialogLoginAs = useCallback(() => {
    setOpenDialogLoginAs(false);
  }, [setOpenDialogLoginAs]);

  const handleProfileSwitch = useCallback(async () => {
    setOpenDialogProfileSwitch(true);
  }, [setOpenDialogProfileSwitch]);

  const handleExitDialogProfileSwitch = useCallback(
    function handleExitDialogProfileSwitch() {
      setOpenDialogProfileSwitch(false);
    },
    [setOpenDialogProfileSwitch],
  );

  const options = {
    handleLogout,
    handleLoginAs,
    handleProfileSwitch,
    redirectUrl,
  };

  const typeProps = buildProps(props, options);

  if (View && (typeof typeProps.to === 'string' || typeProps.onClick)) {
    const disabled = Boolean(textDisabled);

    const props = {
      identifier,
      description,
      icon,
      title,
      titleLong,
      disabled,
      content,
      type: linkType,
      ...typeProps,
    };

    // Disabled links should just be a button opening the disabled app dialog
    if (disabled) {
      delete props.to;
      props.onClick = () => setOpenDialogDisabledApp(true);
    }

    return (
      <>
        <View {...props} />
        {systemType === 'loginAs' ? (
          <DialogLoginAs open={openDialogLoginAs} onExit={handleExitDialogLoginAs} />
        ) : null}
        {systemType === 'profileSwitch' ? (
          <DialogProfileSwitch
            title={title}
            open={openDialogProfileSwitch}
            onExit={handleExitDialogProfileSwitch}
            switchableProfiles={switchableProfiles}
          />
        ) : null}
        {disabled && textDisabled ? (
          <DialogDisabledApp
            open={openDialogDisabledApp}
            title={title}
            text={textDisabled}
            onExit={() => setOpenDialogDisabledApp(false)}
          />
        ) : null}
      </>
    );
  }

  return <>{title}</>;
}

export default LinkBehavior;

type LinkBuilderOptions = {
  handleLogout(): void;
  handleLoginAs(): void;
  handleProfileSwitch(): void;
  redirectUrl: string | null;
};

type LinkBuilder = (
  props: LinkBehaviorProps,
  options: LinkBuilderOptions,
) => Partial<LinkViewProps>;

type LinkBuilderRecord = {
  default: LinkBuilder;
  [k: string]: LinkBuilder;
};

const buildDefault: LinkBuilder = ({ behavior: { url } }) => {
  return {
    to: url,
  };
};

const buildInternal: LinkBuilder = ({ behavior: { title, page } }) => {
  if (page && page.slug) {
    const props = {
      title: title || page.title,
      to: `/${page.slug}`,
    };

    return props;
  }

  return {};
};

const buildSystem: LinkBuilder = (props, options) => {
  const { systemType } = props.behavior;

  switch (systemType) {
    case 'logout': {
      return { onClick: options.handleLogout };
    }
    case 'loginAs': {
      return { onClick: options.handleLoginAs };
    }
    case 'profileSwitch': {
      return { onClick: options.handleProfileSwitch };
    }

    default:
      return buildDefault(props, options);
  }
};

const buildSso: LinkBuilder = (props, { redirectUrl }) => {
  if (redirectUrl) {
    return {
      to: redirectUrl,
    };
  }

  return {};
};

const BUILDERS: LinkBuilderRecord = {
  internal: buildInternal,
  external_sso: buildSso,
  system: buildSystem,
  default: buildDefault,
};

function buildProps(props: LinkBehaviorProps, options: LinkBuilderOptions): Partial<LinkViewProps> {
  const { linkType } = props.behavior;

  if (linkType && typeof BUILDERS[linkType] === 'function') {
    return BUILDERS[linkType](props, options);
  }

  return BUILDERS.default(props, options);
}
