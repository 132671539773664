import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Override } from 'types';

export function isExternalUrl(url: string): boolean {
  // Case 1 : URLs starting with double slash, ie '//google.fr'
  if (/^\/\//.test(url)) {
    return true;
  }

  // Case 2 : URLs starting with a protocol and a column, ie 'http://google.fr' or 'mailto:bouh@bouh.bouh'
  if (/^[0-9a-zA-Z-]+:/.test(url)) {
    return true;
  }

  // All other cases seem to be internal URLs
  return false;
}

type RouterLinkProps = React.ComponentPropsWithRef<typeof RouterLink>;
type AnchorProps = React.ComponentPropsWithRef<'a'>;

export type LinkProps = Override<
  RouterLinkProps | AnchorProps,
  {
    to?: string;
  }
>;

function isAnchor(
  to: string,
  props: Omit<RouterLinkProps | AnchorProps, 'to'>,
): props is Omit<AnchorProps, 'to'> {
  return isExternalUrl(to);
}

function isRouterLink(
  to: string,
  props: Omit<RouterLinkProps | AnchorProps, 'to'>,
): props is Omit<RouterLinkProps, 'to'> {
  return !isExternalUrl(to);
}

const hostRegex = new RegExp(`^http(s?)://${window.location.host}`);

/**
 * Remove current domain from given URL to allow internal router
 */
function transformTo(to: string): string {
  return to.replace(hostRegex, '');
}

function Link({ to: rawTo = '', ...props }: LinkProps): JSX.Element | null {
  const to = transformTo(rawTo);

  if (isAnchor(to, props)) {
    return (
      <a href={to} target="_blank" rel="external noopener noreferrer" {...props}>
        {props.children}
      </a>
    );
  }

  if (isRouterLink(to, props)) {
    return <RouterLink to={to} aria-current="page" {...props} />;
  }

  return null;
}

export default Link;
