import { ConfigModel } from '../models';

export function setConfig(config: ConfigModel) {
  return {
    type: setConfig.type,
    payload: {
      config,
    },
  } as const;
}

setConfig.type = 'config/set' as const;
