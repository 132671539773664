import { ErrorModel, AuthCredentialsModel, Identity } from 'models';

export function resetAuth() {
  return {
    type: resetAuth.type,
  } as const;
}

resetAuth.type = 'auth/reset' as const;

export function setIdentity(identity: Identity) {
  return {
    type: setIdentity.type,
    payload: {
      identity,
    },
  } as const;
}

setIdentity.type = 'auth/identity/set' as const;

export function resetIdentity() {
  return {
    type: resetIdentity.type,
  } as const;
}

resetIdentity.type = 'auth/identity/reset' as const;

export function authRequest(credentials: AuthCredentialsModel) {
  return {
    type: authRequest.type,
    payload: { credentials },
  } as const;
}

authRequest.type = 'auth/request' as const;

export function authReceive() {
  return {
    type: authReceive.type,
  } as const;
}

authReceive.type = 'auth/receive' as const;

export function authError(error: ErrorModel) {
  return {
    type: authError.type,
    payload: {
      error,
    },
  } as const;
}

authError.type = 'auth/error' as const;

export function loginWithPopin() {
  return {
    type: loginWithPopin.type,
    payload: {},
  } as const;
}

loginWithPopin.type = 'auth/login/popin' as const;

export function loginWithAdfs() {
  return {
    type: loginWithAdfs.type,
    payload: {},
  } as const;
}

loginWithAdfs.type = 'auth/login/adfs' as const;

export function loginWithAccessToken(accessToken: string) {
  return {
    type: loginWithAccessToken.type,
    payload: { accessToken },
  };
}

loginWithAccessToken.type = 'auth/login/accessToken' as const;

export function loginWithCredentials(credentials: AuthCredentialsModel) {
  return {
    type: loginWithCredentials.type,
    payload: { credentials },
  } as const;
}

loginWithCredentials.type = 'auth/login/credentials' as const;

export function logoutWithAll() {
  return {
    type: logoutWithAll.type,
    payload: {},
  } as const;
}

logoutWithAll.type = 'auth/logout/all' as const;

export function authRefreshStart() {
  return {
    type: authRefreshStart.type,
    payload: {},
  } as const;
}

authRefreshStart.type = 'auth/refresh/start' as const;

export function authRefreshStop() {
  return {
    type: authRefreshStop.type,
    payload: {},
  } as const;
}

authRefreshStop.type = 'auth/refresh/stop' as const;
