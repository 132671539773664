import { useMemo } from 'react';
import useTranslate from './useTranslate';
import { ErrorType, OptionsType, formatError } from './useErrorMessage';

export type ErrorTranslator = (
  error: ErrorType,
  options?: OptionsType,
) => ReturnType<ReturnType<typeof useTranslate>> | null;

export type MessagesFactory<T> = (t: ReturnType<typeof useTranslate>, e: ErrorTranslator) => T;

const DEFAULT_DEPS: React.DependencyList = [];

function useMessages<T>(
  messagesFactory: MessagesFactory<T>,
  deps: React.DependencyList = DEFAULT_DEPS,
): T {
  const t = useTranslate();

  return useMemo(
    () => {
      const e: ErrorTranslator = (error, options) => {
        return error ? formatError(t, error, options) : null;
      };

      return messagesFactory(t, e);
    },
    // messagesFactory is not in the dependencies to avoid the need of a memo
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, ...deps],
  );
}

export default useMessages;
