import { ApiParams } from 'services/cdmApi';
import { ErrorModel, DataModel } from 'models';

export function requestData(keys: (keyof DataModel)[], params?: ApiParams) {
  return {
    type: requestData.type,
    payload: {
      keys,
      params,
    },
  } as const;
}

requestData.type = 'data/request' as const;

export function receiveData(data: DataModel) {
  return {
    type: receiveData.type,
    payload: {
      data,
    },
  } as const;
}

receiveData.type = 'data/receive' as const;

export function errorData(error: ErrorModel) {
  return {
    type: errorData.type,
    payload: {
      error,
    },
  } as const;
}

errorData.type = 'data/error' as const;
