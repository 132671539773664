import { DateTime, Info } from 'luxon';
import browserLocale from '../lib/browser-locale';
import 'intl-pluralrules';
import '@formatjs/intl-listformat';
import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/dist/locale-data/fr';
import '@formatjs/intl-listformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';

export type LocaleCode = 'en' | 'fr';

type LocaleDefinition = {
  messages: Record<string, string>;
  loaded: boolean;
  loader: () => Promise<Record<string, string>>;
};

export const FALLBACK_LOCALE: LocaleCode = 'en';

export const LOCALES: { [key in LocaleCode]: LocaleDefinition } = {
  en: {
    messages: {},
    loaded: false,
    async loader() {
      const [messages] = [
        await import(/* webpackChunkName: "intl-en" */ '../translations/en.json'),
      ];

      return messages.default;
    },
  },
  fr: {
    messages: {},
    loaded: false,
    async loader() {
      const [messages] = [
        await import(/* webpackChunkName: "intl-fr" */ '../translations/fr.json'),
      ];

      return messages.default;
    },
  },
};

/*
 * Returns all available locales codes
 */
export function getLocaleCodes(): Array<LocaleCode> {
  return Object.keys(LOCALES) as Array<LocaleCode>;
}

/*
 * Type guard locale
 */
export function isLocale(locale: string): locale is LocaleCode {
  return locale in LOCALES;
}

/*
 * Returns normalized locale in ISO 639-1 format if it's supported, or default from browser if supported, or the fallback
 */
export function resolveLocale(locale?: string): LocaleCode {
  if (locale) {
    const resolvedLocale = locale.substring(0, 2).toLowerCase() as LocaleCode;

    if (LOCALES[resolvedLocale]) {
      return resolvedLocale;
    }

    return FALLBACK_LOCALE;
  }

  const defaultLocale = browserLocale();

  return resolveLocale(defaultLocale || FALLBACK_LOCALE);
}

/**
 * Ensure Intl API is available by providing a polyfill if necessary
 */
export async function ensureIntl() {
  if (!global.Intl) {
    return Promise.all([
      import(/* webpackChunkName: "intl" */ 'intl'),
      import(/* webpackChunkName: "intl" */ 'intl/locale-data/jsonp/en'),
      import(/* webpackChunkName: "intl" */ 'intl/locale-data/jsonp/fr'),
    ]);
  }
}

/**
 * Ensure given locale has been loaded (ie. messages are available)
 */
export async function ensureLocale(locale: LocaleCode) {
  await ensureIntl();

  if (LOCALES[locale] && !LOCALES[locale].loaded) {
    const messages = await LOCALES[locale].loader();

    LOCALES[locale].messages = messages;
    LOCALES[locale].loaded = true;
  }

  return LOCALES[locale].messages;
}

/**
 * Returns the current timezone or null if not supported.
 */
export function findDefaultTimezone(): string | null {
  if (Info.features().zones) {
    return DateTime.local().zoneName;
  }

  return null;
}
