import React, { useCallback, useState, useMemo } from 'react';
import cdmApi, { resolveError } from 'services/cdmApi';
import Dialog from 'components/ui/Dialog';
import Button from 'components/ui/Button';
import useTranslate from 'components/hooks/useTranslate';
import useErrorMessage from 'components/hooks/useErrorMessage';
import { ErrorModel, TranslateModel } from 'models';
import Form from 'components/form/Form';
import FormField from 'components/form/FormField';
import SelectInput from '../form/SelectInput';
import { ValueChangeEventHandler } from '../form/useFormInput';
import FormError from '../form/FormError';
import Spinner from '../ui/Spinner';

type DialogProfileSwitchProps = {
  title: string;
  open: boolean;
  onExit: () => void;
  switchableProfiles?: TranslateModel[];
};

function DialogProfileSwitch({
  title,
  open,
  onExit,
  switchableProfiles,
}: DialogProfileSwitchProps) {
  const t = useTranslate();
  const messages = { label: title, close: t(`label.cancel`) };
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorModel | null>(null);
  const errorMessage = useErrorMessage(error);
  const [selectedProfile, setSelectedProfile] = useState<string | null>(null);

  const handleValueChange: ValueChangeEventHandler<
    string | null,
    HTMLInputElement | HTMLSelectElement
  > = useCallback(
    (value) => {
      setSelectedProfile(value);
    },
    [setSelectedProfile],
  );

  const handleSubmit = useCallback(
    async (event?: React.FormEvent) => {
      if (event) {
        event.preventDefault();
      }
      if (selectedProfile) {
        try {
          setLoading(true);
          const response = await cdmApi.patch(`/user`, {
            profile: selectedProfile,
            candidate: {},
          });
          if (response.status === 200) {
            window.location.href = '/';
          }
        } catch (error) {
          setError(resolveError(error));
        } finally {
          setLoading(false);
        }
      }
    },
    [selectedProfile, setLoading],
  );

  const profileOptions = useMemo(
    () =>
      switchableProfiles
        ? switchableProfiles.map((profile) => ({
            value: profile.code,
            label: profile.label,
          }))
        : [],
    [switchableProfiles],
  );

  return (
    <Dialog
      as={Form}
      onSubmit={handleSubmit}
      open={open}
      width="md"
      messages={messages}
      title={messages.label}
      onExit={onExit}
      actions={
        <>
          {loading ? (
            <Spinner aria-label={t('label.loading')} style={{ marginRight: '1em' }} />
          ) : null}
          <Button color="primary" type="submit">
            {t('dialog.profileSwitch.submit.button')}
          </Button>
        </>
      }
    >
      <FormField label={t('dialog.profileSwitch.profile')}>
        <SelectInput
          placeholder=""
          name="profile"
          value={selectedProfile}
          onValueChange={handleValueChange}
          options={profileOptions}
        />
      </FormField>
      {errorMessage ? <FormError>{errorMessage}</FormError> : null}
    </Dialog>
  );
}

export default DialogProfileSwitch;
