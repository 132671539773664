import { cancelled, takeLatest, takeEvery, call, put, fork, select } from 'redux-saga/effects';
import qs from 'qs';
import cdmApi, { createCancelSource, resolveError } from '../services/cdmApi';
import {
  requestPage,
  receivePage,
  errorPage,
  requestBlock,
  receiveBlock,
  errorBlock,
} from '../actions/treeActions';
import { selectLocale, selectCampusCode } from '../selectors/userSelectors';

export function* requestPageTask(action: ReturnType<typeof requestPage>): any {
  const cancelSource = createCancelSource();
  const { slug, parameters, locale, campus } = action.payload;

  try {
    const query = qs.stringify({
      parameters,
      locale,
      campus,
    });

    const response = yield call(cdmApi.get, `/page/${slug}?${query}`, {
      cancelToken: cancelSource.token,
    });

    yield put(receivePage(response.data.tree));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorPage(err));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

export function* requestBlockTask(action: ReturnType<typeof requestBlock>): any {
  const { identifier, parameters } = action.payload;

  const locale = yield select(selectLocale);
  const campus = yield select(selectCampusCode);

  try {
    const query = qs.stringify({
      locale,
      campus,
      parameters,
    });

    const response = yield call(cdmApi.get, `/block/${identifier}?${query}`);
    yield put(receiveBlock(identifier, parameters, response.data.tree));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorBlock(identifier, parameters, err));
  }
}

export function* watchRequestPage() {
  yield takeLatest(requestPage.type, requestPageTask);
}

export function* watchRequestBlock() {
  yield takeEvery(requestBlock.type, requestBlockTask);
}

export default function* treeSaga() {
  yield fork(watchRequestPage);
  yield fork(watchRequestBlock);
}
