import { Children, ReactElement, ReactNode } from 'react';
import Overtitle from '../Title/Overtitle';

export type MenuEntrySecondProps = {
  title: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  link?: ReactElement<{
    children: ReactNode;
    className: string;
  }>;
};

function MenuEntrySecond({
  title,
  description,
  children,
  link,
}: MenuEntrySecondProps): JSX.Element {
  return (
    <>
      <Overtitle element={link} label={title} />
      {children ? (
        <ul className="MenuEntrySecond">
          {Children.map(children, (child, i) => (
            <li key={i} className="MenuEntrySecond-item">
              {child}
            </li>
          ))}
        </ul>
      ) : null}
      {description ? <div className="MenuEntrySecond-description">{description}</div> : null}
    </>
  );
}

export default MenuEntrySecond;
