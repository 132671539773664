import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { AzureSearchResponse } from 'services/azureSearchApi';
import useAzureSearch, { UseAzureSearchParams } from 'components/hooks/useAzureSearch';
import useDebounce from 'components/hooks/useDebounce';
import { AzureContentDocument } from 'models/search';
import { useSelector } from 'react-redux';
import { selectUser } from 'selectors/userSelectors';

export type FacetConfiguration = {
  name: 'family' | 'tag' | 'campus';
  facetName: string;
  makeFilterClause(values: string[]): string;
};

export const FACET_CONFIGURATIONS: FacetConfiguration[] = [
  {
    name: 'family',
    facetName: 'family',
    makeFilterClause: (values) => `search.in(family, '${values.join(', ')}')`,
  },
  {
    name: 'tag',
    facetName: 'tags/slug',
    makeFilterClause: (values) => `tags/any(t: search.in(t/slug, '${values.join(', ')}'))`,
  },
  {
    name: 'campus',
    facetName: 'facets/campus',
    makeFilterClause: (values) => `facets/any(f: search.in(f/campus, '${values.join(', ')}'))`,
  },
];

function joinClauses(operator: 'or' | 'and', clauses: (string | null)[]): string {
  return clauses.filter((a) => Boolean(a)).join(` ${operator} `);
}

function makeFilterClause(
  serviceOnly: boolean,
  searchContext: Record<string, string | undefined>,
  searchFacets: SearchFacet[] = [],
): string {
  const filtersFromServiceOnly = [
    serviceOnly ? `search.in(family, 'Block')` : `not search.in(family, 'Block')`,
  ];
  const filtersFromContext = Object.entries(searchContext).map(
    // Should look like something like this:
    // (f/campus eq null)
    // Or like this:
    // (f/profile eq null or f/profile eq 'prestataire')
    ([key, value]) =>
      `(${joinClauses('or', [`f/${key} eq null`, value ? `f/${key} eq '${value}'` : null])})`,
  );

  const filtersFromFacets = searchFacets.map(({ name, value }): string => {
    const facetConfiguration = FACET_CONFIGURATIONS.find((f) => f.name === name);

    return facetConfiguration?.makeFilterClause(value) ?? '';
  });

  // Should look like something like this:
  // facets/any(f: f/campus eq null) and facets/any(f: ((f/program eq null) and (f/profile eq null or f/profile eq 'prestataire') and (f/status eq null or f/status eq 'vacataire_salaire') and (f/studyYear eq null)))
  const filterFromContext = filtersFromContext
    ? `facets/any(f: ${joinClauses('and', filtersFromContext)})`
    : null;

  return joinClauses('and', [...filtersFromServiceOnly, filterFromContext, ...filtersFromFacets]);
}

export type OrderConfiguration = {
  name: 'score' | 'recent' | 'old';
  orderClause: string[];
};

export const ORDER_CONFIGURATIONS: OrderConfiguration[] = [
  {
    name: 'score',
    orderClause: ['search.score() desc', 'date desc', 'updatedAt desc'],
  },
  {
    name: 'recent',
    orderClause: ['date desc', 'updatedAt desc', 'search.score() desc'],
  },
  {
    name: 'old',
    orderClause: ['date asc', 'updatedAt asc', 'search.score() asc'],
  },
];

function makeOrderClause(searchOrder: SearchOrder): string[] {
  const orderConfiguration = ORDER_CONFIGURATIONS.find((o) => o.name === searchOrder);

  return orderConfiguration?.orderClause ?? [];
}

export type SearchFacet = { name: FacetConfiguration['name']; value: string[] };

export type SearchOrder = OrderConfiguration['name'];

export type AzureSearchContextValue = {
  configuration?: {
    searchText: string;
    host: string;
    queryKey: string;
    environment: string;
    indexName: string;
    perPage?: number;
  };
  /** Response for the teaser list, like Page, Event and Article families */
  teaserResponse: AzureSearchResponse<AzureContentDocument> | undefined;
  /** Response for the service list, like Block families */
  serviceResponse: AzureSearchResponse<AzureContentDocument> | undefined;
  /** Response for the facet selection, only applying to the teaser request */
  facetResponse?: AzureSearchResponse<AzureContentDocument> | undefined;
  loading: boolean;
  error: Error | undefined;
  page: number;
  onPageChange(page: number): void;
  searchFacets: SearchFacet[];
  onSearchFacetsChange(name: SearchFacet['name'], value: SearchFacet['value']): void;
  onSearchFacetsReset(): void;
  searchOrder: SearchOrder;
  onSearchOrderChange(order: SearchOrder): void;
};

const defaultAzureSearchContext: AzureSearchContextValue = {
  configuration: undefined,
  teaserResponse: undefined,
  serviceResponse: undefined,
  facetResponse: undefined,
  loading: true,
  error: undefined,
  page: 1,
  onPageChange: () => {},
  searchFacets: [],
  onSearchFacetsChange: () => {},
  onSearchFacetsReset: () => {},
  searchOrder: 'score',
  onSearchOrderChange: () => {},
};

export const AzureSearchContext =
  React.createContext<AzureSearchContextValue>(defaultAzureSearchContext);

export function useAzureSearchContext(): AzureSearchContextValue {
  return useContext(AzureSearchContext);
}

export type AzureSearchProviderProps = {
  configuration: AzureSearchContextValue['configuration'];
  children: React.ReactNode;
};

function AzureSearchProvider({ configuration, children }: AzureSearchProviderProps): JSX.Element {
  const user = useSelector(selectUser);
  const program = user.program?.code;
  const profile = user.profile ?? undefined;
  const status = user.status ?? undefined;
  const studyYear = user.studyYear?.code;
  const searchContext = useMemo(
    () => ({
      program,
      profile,
      status,
      studyYear,
    }),
    [program, profile, status, studyYear],
  );

  const [page, setPage] = useState(defaultAzureSearchContext.page);
  const [searchFacets, setSearchFacets] = useState(defaultAzureSearchContext.searchFacets);
  const [searchOrder, setSearchOrder] = useState(defaultAzureSearchContext.searchOrder);
  const { searchText, host, queryKey, environment, indexName, perPage } = configuration ?? {};
  const debouncedSearchText = useDebounce(searchText, 400);

  // Reset page when searchText changes
  useEffect(() => {
    setPage(defaultAzureSearchContext.page);
  }, [searchText]);

  // Normal documents search
  const params = useMemo((): UseAzureSearchParams | undefined => {
    if (host && queryKey && environment && indexName && page && perPage) {
      return {
        searchText: debouncedSearchText ?? '',
        filter: makeFilterClause(false, searchContext, searchFacets),
        host,
        queryKey,
        environment,
        indexName,
        top: perPage,
        skip: page && perPage ? perPage * (page - 1) : undefined,
        highlightFields: 'content',
        highlightPreTag: '<strong>',
        highlightPostTag: '</strong>',
        queryType: 'full',
        searchMode: 'all',
        includeTotalCount: true,
        orderBy: makeOrderClause(searchOrder),
      };
    }
  }, [
    debouncedSearchText,
    searchFacets,
    searchContext,
    searchOrder,
    host,
    queryKey,
    environment,
    indexName,
    page,
    perPage,
  ]);

  const [teaserResponse, teaserLoading, teaserError] = useAzureSearch<AzureContentDocument>(params);

  // Service documents search
  const serviceParams = useMemo((): UseAzureSearchParams | undefined => {
    if (host && queryKey && environment && indexName) {
      return {
        searchText: debouncedSearchText ?? '',
        filter: makeFilterClause(true, searchContext),
        host,
        queryKey,
        environment,
        indexName,
        top: 50,
        queryType: 'full',
        searchMode: 'all',
        includeTotalCount: true,
        orderBy: ['title asc', 'search.score() desc', 'date desc'],
      };
    }
  }, [debouncedSearchText, searchContext, host, queryKey, environment, indexName]);

  const [serviceResponse, serviceLoading, serviceError] =
    useAzureSearch<AzureContentDocument>(serviceParams);

  // Facets search
  const facetParams = useMemo((): UseAzureSearchParams | undefined => {
    if (host && queryKey && environment && indexName) {
      return {
        searchText: debouncedSearchText ?? '',
        filter: makeFilterClause(false, searchContext),
        host,
        queryKey,
        environment,
        indexName,
        facets: FACET_CONFIGURATIONS.map((facet) => facet.facetName),
        queryType: 'full',
        searchMode: 'all',
        top: 0,
        skip: 0,
      };
    }
  }, [debouncedSearchText, searchContext, host, queryKey, environment, indexName]);

  const [facetResponse, facetLoading, facetError] =
    useAzureSearch<AzureContentDocument>(facetParams);

  const handleSearchFacetsChange = useCallback<AzureSearchContextValue['onSearchFacetsChange']>(
    (name, value) => {
      setSearchFacets((searchFacets) => {
        const newSearchFacets = searchFacets.filter((sf) => sf.name !== name);

        if (value.length) {
          newSearchFacets.push({ name, value });
        }

        return newSearchFacets;
      });
    },
    [],
  );

  const handleSearchFacetsReset = useCallback(() => {
    setSearchFacets([]);
  }, []);

  const loading =
    debouncedSearchText !== searchText || teaserLoading || serviceLoading || facetLoading;
  const error = teaserError ?? serviceError ?? facetError;

  const contextValue: AzureSearchContextValue = useMemo((): AzureSearchContextValue => {
    return {
      configuration,
      teaserResponse,
      serviceResponse,
      facetResponse,
      loading,
      error,
      page,
      onPageChange: setPage,
      searchFacets,
      onSearchFacetsChange: handleSearchFacetsChange,
      onSearchFacetsReset: handleSearchFacetsReset,
      searchOrder,
      onSearchOrderChange: setSearchOrder,
    };
  }, [
    configuration,
    teaserResponse,
    serviceResponse,
    facetResponse,
    loading,
    error,
    page,
    searchFacets,
    handleSearchFacetsChange,
    handleSearchFacetsReset,
    searchOrder,
    setSearchOrder,
  ]);

  return <AzureSearchContext.Provider value={contextValue}>{children}</AzureSearchContext.Provider>;
}

export default AzureSearchProvider;
