import FormError from 'components/form/FormError';
import useMessages from 'components/hooks/useMessages';
import ContainerRow from 'components/ui/Container/ContainerRow';
import MiniTeaser from 'components/ui/MiniTeaser/MiniTeaser';
import Placeloader from 'components/ui/Placeloader';
import SearchResultEmpty from 'components/ui/Search/SearchResultEmpty';
import Spacer from 'components/ui/Spacer';
import Anchor from 'components/ui/Anchor';
import SrOnly from 'components/ui/SrOnly/SrOnly';
import Teaser from 'components/ui/Teaser/Teaser';
import Typography from 'components/ui/Typography';
import { useAzureSearchContext } from './AzureSearchProvider';

export type SearchStatusProps = {
  shrunk?: boolean;
};

function SearchStatus({ shrunk }: SearchStatusProps): JSX.Element {
  const { teaserResponse, serviceResponse, loading, error, configuration, onSearchFacetsReset } =
    useAzureSearchContext();

  const messages = useMessages(
    (t) => ({
      loading: t('label.loading'),
      noResult: t('search.panel.no_result'),
      noResultTitle: t('search.result.no_result_title'),
      noResultText: t('search.result.no_result_text', {
        button: (chunks: any) => (
          <Anchor as={<button type="button" onClick={onSearchFacetsReset} />}>{chunks}</Anchor>
        ),
      }),
    }),
    [],
  );

  return (
    <>
      {renderLoading()}
      {renderNoResult()}
      {renderError()}
    </>
  );

  function renderLoading(): JSX.Element | null {
    if (loading) {
      return shrunk ? (
        <>
          {renderMiniPlaceloader()}
          {renderMiniPlaceloader()}
        </>
      ) : (
        <>
          {renderPlaceloader()}
          {renderPlaceloader()}
        </>
      );
    }

    return null;
  }

  function renderNoResult(): JSX.Element | null {
    const count =
      (teaserResponse?.documents?.length ?? 0) + (serviceResponse?.documents?.length ?? 0);

    if (configuration?.searchText && !loading && !error && count === 0) {
      return shrunk ? (
        <>
          <ContainerRow separator />
          <Spacer block="md">
            <Typography as="p">{messages.noResult}</Typography>
          </Spacer>
        </>
      ) : (
        <Spacer block="xxl">
          <SearchResultEmpty title={messages.noResultTitle}>
            <Typography as="p">{messages.noResultText}</Typography>
          </SearchResultEmpty>
        </Spacer>
      );
    }

    return null;
  }

  function renderError(): JSX.Element | null {
    if (!loading && error) {
      <FormError>{error.message}</FormError>;
    }

    return null;
  }

  function renderPlaceloader(): JSX.Element {
    return (
      <Teaser
        media={<Placeloader width="2em" height="2em" />}
        title={
          <>
            <Placeloader width={100} label={messages.loading} />
          </>
        }
        subtitle={<Placeloader width={66} />}
        description={
          <>
            <Placeloader width={100} />
            <Placeloader width={50} />
          </>
        }
      />
    );
  }

  function renderMiniPlaceloader(): JSX.Element {
    return (
      <MiniTeaser title={<Placeloader width={100} />} subtitle={<Placeloader width={66} />}>
        <SrOnly>{messages.loading}</SrOnly>
      </MiniTeaser>
    );
  }
}

export default SearchStatus;
