import classNames from 'classnames';
import { Override } from 'types';
import './Placeloader.scss';

export type PlaceloaderProps = Override<
  React.ComponentPropsWithoutRef<'span'>,
  {
    width?: number | string;
    height?: number | string;
    label?: React.ReactNode;
  }
>;

function Placeloader({
  width,
  height,
  label,
  children,
  className,
  style,
  ...props
}: PlaceloaderProps): JSX.Element {
  const rootStyle = {
    ['--placeloader-width' as any]: typeof width === 'number' ? `${width}%` : width,
    ['--placeloader-height' as any]: typeof height === 'number' ? `${height}%` : height,
    ...style,
  };

  return (
    <span className={classNames('Placeloader', className)} style={rootStyle} {...props}>
      {children}
      {label ? <span className="Placeloader-label">{label}</span> : null}
    </span>
  );
}

export default Placeloader;
