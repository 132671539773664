import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgNews({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 36 29"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.75.25H6.25v27H4A2.25 2.25 0 011.75 25V5H.25v20A3.75 3.75 0 004 28.75h29A2.75 2.75 0 0035.75 26V.25zM8 27.25h25A1.25 1.25 0 0034.25 26V1.75H7.75v25.5H8zm22.5-20.5h-6v1.5h6v-1.5zm0 5h-6v1.5h6v-1.5zm0 5H12v1.5h18.5v-1.5zM12 23.25h18.5v-1.5H12v1.5zM3.25 5v19h1.5V5h-1.5zm18.5 1.75h-10.5v6.5h10.5v-6.5zm-9 5v-3.5h7.5v3.5h-7.5z"
      />
    </svg>
  );
}

export default forwardRef(SvgNews);
