import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { requestUser, resetUser } from 'actions/userActions';
import { selectIdentity } from 'selectors/authSelectors';
import { selectUser, selectLocale } from 'selectors/userSelectors';

export type UserProviderProps = {
  children: React.ReactNode;
};

function UserProvider({ children }: UserProviderProps): JSX.Element {
  const identity = useSelector(selectIdentity);
  const user = useSelector(selectUser);
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();

  // Fetches the user when access token changes
  useEffect(() => {
    if (identity.accessToken) {
      dispatch(requestUser());
    } else {
      dispatch(resetUser());
    }
  }, [dispatch, identity.accessToken]);

  // Update Google Tag manager data layer
  useEffect(
    function datalayerEffect() {
      if (user.userId && window.dataLayer && window.dataLayer.push instanceof Function) {
        window.dataLayer.push({
          event: 'connection',
          visitorProfile: user.profile,
          visitorStatus: user.status,
          visitorCampus: user.campus ? user.campus.code : null,
          visitorProgram: user.program ? user.program.code : null,
          language: locale,
        });
      }
    },
    [locale, user.campus, user.profile, user.program, user.status, user.userId],
  );

  return <>{children}</>;
}

export default UserProvider;
