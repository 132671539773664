import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgVia({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 94.3 54.3"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M31.7 54.3h13L60.4 0H49.8L36 46.2c-1 3.3-1.8 5.5-2.6 6.5-.4.6-1 1.1-1.7 1.6m30.8-.1l10.2-37.8 9.8 37.8h11.8l-12.9-45c-.9-3.3-2.1-5.6-3.4-7.1C76.5.7 74.8 0 72.6 0c-2.5 0-4.5 1-6 2.9-.7 1-1.6 3.1-2.6 6.5L50.9 54.2h11.6zM31.8 0L21.6 37.8 11.8 0H0l12.9 45c.9 3.3 2.1 5.6 3.4 7.1 1.4 1.4 3.1 2.1 5.3 2.1 2.5 0 4.5-1 6-2.9.7-1 1.6-3.1 2.6-6.5L43.3 0H31.8z" />
    </svg>
  );
}

export default forwardRef(SvgVia);
