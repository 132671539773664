import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { requestNotificationList, NotificationListName } from 'actions/notificationAction';
import { NotificationListParams } from 'models/notification';
import { AppStore } from 'store';
import { makeSelectNotificationList } from 'selectors/notificationSelectors';
import { NotificationListState } from 'reducers/notificationReducer';

function useNotificationList(
  name: NotificationListName,
  params: NotificationListParams,
): NotificationListState {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestNotificationList(name, params));
  }, [dispatch, name, params]);

  const selectNotificationList = useMemo(makeSelectNotificationList, []);

  return useSelector((store: AppStore) => selectNotificationList(store, name));
}

export default useNotificationList;
