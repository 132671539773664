import classnames from 'classnames';
import { OverridableComponentProps } from 'components/OverridableComponent';
import { FontFamily, FontSize, FontWeight, Kind, TextAlign } from '../types.d';
import './Typography.scss';

export type BaseProps = {
  nowrap?: boolean;
  kind?: Kind;
  family?: FontFamily;
  weight?: FontWeight;
  size?: FontSize;
  align?: TextAlign;
};
export type TypographyProps<A> = OverridableComponentProps<A, 'div', BaseProps>;

function Typography<A>({
  as: As = 'div',
  nowrap,
  kind,
  family,
  weight,
  size,
  align,
  children,
  className,
  ...props
}: TypographyProps<A>): JSX.Element {
  const rootClasses = classnames('Typography', className, {
    'is-nowrap': nowrap,
    [`is-font-${family}`]: family,
    [`is-font-${family}`]: family,
    [`is-${weight}`]: weight,
    [`is-${size}`]: size,
    [`is-${kind}`]: kind,
    [`is-${align}`]: align,
  });

  return (
    <As className={rootClasses} {...props}>
      {children}
    </As>
  );
}

export default Typography;
