import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDisclosureState, Disclosure, DisclosureContent } from 'ariakit';
import Overtitle from 'components/ui/Title/Overtitle';
import Icon from 'components/ui/Icon/Icon';
import ChevronDown from 'components/ui/Icon/actions/ChevronDown';
import { Override } from 'types';
import './SidebarSection.scss';

type BaseProps = {
  title?: string;
  children?: React.ReactNode;
};

export type SidebarSectionProps = Override<React.ComponentPropsWithoutRef<'div'>, BaseProps>;

function SidebarSection({ title, children, ...props }: SidebarSectionProps): JSX.Element {
  const disclosure = useDisclosureState({ defaultOpen: false, animated: true });
  const isSmall = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className="SidebarSection" {...props}>
      {title && isSmall ? (
        <>
          <Disclosure state={disclosure} className="SidebarSection-disclosureButton">
            <Overtitle label={title} />
            <Icon as={ChevronDown} />
          </Disclosure>
          <DisclosureContent state={disclosure} className="SidebarSection-disclosureContent">
            {children}
          </DisclosureContent>
        </>
      ) : (
        <>
          {title ? <Overtitle label={title} /> : null}
          {children}
        </>
      )}
    </div>
  );
}

export default SidebarSection;
