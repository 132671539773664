import Node, { NodeProps } from 'components/tree/Node';
import FooterLinks from 'components/ui/Footer/FooterLinks';
import DefaultLinkView from '../behavior/link/DefaultLinkView';

export type FooterLinksZoneProps = NodeProps;

function FooterLinksZone({ content }: FooterLinksZoneProps): JSX.Element {
  return (
    <FooterLinks>
      {content.map((node) => (
        <Node key={node.identifier} identifier={node.identifier} view={DefaultLinkView} />
      ))}
    </FooterLinks>
  );
}

export default FooterLinksZone;
