import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgWooclap({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 18.736 18.736"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#030104"
        d="M0 .458h5.026c.149 3.174.632 5.418.896 8.363.351-2.199.609-5.638.932-8.363h5.026c.068 3.262.334 5.02.465 8.317.527-2.462.965-5.497 1.361-8.317h5.03c-.839 5.865-1.378 12.026-2.285 17.82h-5.939c-.278-2.07-.632-4.268-.938-6.819-.442 2.243-.88 4.665-1.348 6.819h-5.94C1.369 12.492.533 6.624 0 .458z"
      />
    </svg>
  );
}

export default forwardRef(SvgWooclap);
