import {
  sendDocumentRequest,
  receiveDocumentRequest,
  errorDocumentRequest,
  resetDocumentRequest,
} from 'actions/documentRequestActions';
import { ErrorModel, DocumentRequestModel } from 'models';
import { createReducer } from './reducerUtils';

export type DocumentRequestState = Readonly<{
  documentRequest: DocumentRequestModel | null;
  status: number | null;
  error: ErrorModel | null;
  sending: boolean;
}>;

const initialState: DocumentRequestState = {
  documentRequest: null,
  status: null,
  error: null,
  sending: false,
};

function sendDocumentRequestReducer(
  state: DocumentRequestState,
  action: ReturnType<typeof sendDocumentRequest>,
) {
  return {
    ...state,
    documentRequest: action.payload.documentRequest,
    status: null,
    error: null,
    sending: true,
  };
}

function receiveDocumentRequestReducer(
  state: DocumentRequestState,
  action: ReturnType<typeof receiveDocumentRequest>,
) {
  return {
    ...state,
    status: action.payload.status,
    sending: false,
  };
}

function errorDocumentRequestReducer(
  state: DocumentRequestState,
  action: ReturnType<typeof errorDocumentRequest>,
) {
  return {
    ...state,
    error: action.payload.error,
    sending: false,
  };
}

function resetDocumentRequestReducer(state: DocumentRequestState) {
  return {
    ...state,
    ...initialState,
  };
}

export default createReducer(initialState, {
  [sendDocumentRequest.type]: sendDocumentRequestReducer,
  [receiveDocumentRequest.type]: receiveDocumentRequestReducer,
  [errorDocumentRequest.type]: errorDocumentRequestReducer,
  [resetDocumentRequest.type]: resetDocumentRequestReducer,
});
