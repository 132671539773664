import { useCallback, useMemo, useRef } from 'react';
import useMessages from 'components/hooks/useMessages';
import useSearchContext from 'components/hooks/useSearchContext';
import { NodeProps } from 'components/tree/Node';
import SearchPanel from 'components/ui/Search/SearchPanel';
import { PageModel } from 'models';
import { useNavigate } from 'react-router';
import Spacer from 'components/ui/Spacer';
import Button from 'components/ui/Button';
import Link from 'components/utils/Link';
import ChevronRight from 'components/ui/Icon/actions/ChevronRight';
import { useMediaQuery } from 'react-responsive';
import SearchForm from 'components/ui/Search/SearchForm';
import NewContainer from 'components/ui/Container/NewContainer';
import ContainerRow from 'components/ui/Container/ContainerRow';
import SearchStatus from './SearchStatus';
import AzureSearchProvider, { useAzureSearchContext } from './AzureSearchProvider';
import SearchTeaserList from './SearchTeaserList';
import SearchServiceList from './SearchServiceList';
import SearchTitle from './SearchTitle';

export type SearchBarBehaviorProps = NodeProps<{
  title: string | null;
  host: string;
  queryKey: string;
  environment: string;
  indexName: string;
  allPage: PageModel | null;
}>;

function SearchBarBehavior({
  behavior: { title, host, queryKey, environment, indexName, allPage },
}: SearchBarBehaviorProps): JSX.Element {
  const navigate = useNavigate();
  const messages = useMessages(
    (t) => ({
      searchLabel: t('search.bar.searchLabel'),
      submitButton: t('search.bar.submitButton'),
      cancelButton: t('search.bar.cancelButton'),
      openButton: t('search.bar.openButton'),
      closeButton: t('search.bar.closeButton'),
      searchPlaceholder: title ?? undefined,
    }),
    [],
  );

  const searchContext = useSearchContext();
  const { searchText, onSearchTextChange, toggled, onToggledChange } = searchContext;
  const allPageUrl = allPage ? `/${allPage.slug}/${searchText}` : undefined;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = useCallback(() => {
    onToggledChange(false);

    if (allPageUrl) {
      navigate(allPageUrl);
    }
  }, [onToggledChange, navigate, allPageUrl]);

  // Media queries
  const isSmall = useMediaQuery({ query: '(max-width: 768px)' });
  const searchForm = (
    <SearchForm
      inputRef={inputRef}
      value={searchText}
      onCancel={() => onSearchTextChange('')}
      onChange={(value) => onSearchTextChange(value)}
      onFocus={() => onToggledChange(true)}
      onSubmit={handleSubmit}
      messages={messages}
    />
  );

  const handleOpen = useCallback(() => {
    onToggledChange(!toggled);
    inputRef.current?.focus();
  }, [toggled, onToggledChange]);

  const handleClose = useCallback(() => {
    onToggledChange(false);
  }, [onToggledChange]);

  return (
    <SearchPanel
      showButton={isSmall}
      showPanel={toggled}
      onOpen={handleOpen}
      onClose={handleClose}
      form={!isSmall ? searchForm : null}
      messages={messages}
    >
      <AzureSearchProvider
        configuration={{
          searchText,
          host,
          queryKey,
          environment,
          indexName,
          perPage: 5,
        }}
      >
        <NewContainer>
          {isSmall ? <Spacer block="lg">{searchForm}</Spacer> : null}
          <SearchTitle shrunk />
          <ContainerRow column="full">
            <Spacer block="sm">
              <SearchServiceList shrunk />
            </Spacer>
          </ContainerRow>
          <SearchStatus shrunk />
          <Spacer block="md">
            <SearchTeaserList shrunk />
          </Spacer>
          {allPageUrl ? (
            <SearchMoreButton allPageUrl={allPageUrl} onClick={() => onToggledChange(false)} />
          ) : null}
        </NewContainer>
      </AzureSearchProvider>
    </SearchPanel>
  );
}

type SearchMoreButtonProps = {
  allPageUrl: string;
  onClick(): void;
};

function SearchMoreButton({ allPageUrl, onClick }: SearchMoreButtonProps): JSX.Element | null {
  const messages = useMessages(
    (t) => ({
      showMore: t('search.panel.showMore'),
    }),
    [],
  );

  const { teaserResponse, loading } = useAzureSearchContext();
  const { count, documents } = teaserResponse ?? {};

  const hasMore = useMemo(() => {
    return count !== undefined && documents !== undefined && count > documents.length;
  }, [count, documents]);

  if (!loading || teaserResponse) {
    return (
      <div>
        <Button
          disabled={!hasMore}
          as={Link}
          to={allPageUrl}
          kind="secondary"
          icon={ChevronRight}
          onClick={onClick}
        >
          {messages.showMore}
        </Button>
      </div>
    );
  }

  return null;
}

export default SearchBarBehavior;
