import React from 'react';
import useAutoId from 'components/hooks/useAutoId';
import { Override } from 'types';
import classNames from 'classnames';
import './CheckboxInput.scss';

type InputProps = React.ComponentPropsWithoutRef<'input'>;
type LabelProps = React.ComponentPropsWithoutRef<'label'>;

type BaseProps = {
  name?: InputProps['name'];
  value?: InputProps['value'];
  checked?: InputProps['checked'];
  onChange?: InputProps['onChange'];
  disabled?: boolean;
  required?: boolean;
  label?: React.ReactNode;
  startLabel?: React.ReactNode;
  endLabel?: React.ReactNode;
  labelProps?: LabelProps;
  inputProps?: InputProps;
  kind?: 'default' | 'primary' | 'secondary' | 'white';
  size?: 'sm' | 'md';

  srOnly?: boolean;
};

export type CheckboxInputProps = Override<React.ComponentPropsWithoutRef<'span'>, BaseProps>;

function CheckboxInput({
  name,
  value,
  checked,
  onChange,
  disabled,
  required,
  label,
  kind = 'secondary',
  size,
  inputProps,
  labelProps,
  children,
  srOnly,
  ...props
}: CheckboxInputProps): JSX.Element {
  const inputId = useAutoId(inputProps?.id, props.id);

  const rootClasses = classNames('CheckboxInput', props.className, {
    [`is-${kind}`]: Boolean(kind),
    [`is-${size}`]: Boolean(size),
    'is-srOnly': srOnly,
  });

  return (
    <span {...props} className={rootClasses}>
      <input
        {...inputProps}
        value={value}
        checked={checked}
        onChange={onChange}
        type="checkbox"
        name={name}
        id={inputId}
        disabled={disabled}
        required={required}
      />
      {label || children ? (
        <label {...labelProps} htmlFor={inputId}>
          <span className="CheckboxInput-labelContent">
            {label}
            {children}
          </span>
        </label>
      ) : null}
    </span>
  );
}

export default CheckboxInput;
