import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgProfile({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 43 30"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.495 8.495a4.25 4.25 0 116.01 6.01 4.25 4.25 0 01-6.01-6.01zm3.005.255a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm-1.65 10c-1.78 0-3.1 1.297-3.1 2.75V24h-1.5v-2.5c0-2.413 2.129-4.25 4.6-4.25h3.3c2.471 0 4.6 1.837 4.6 4.25V24h-1.5v-2.5c0-1.453-1.32-2.75-3.1-2.75h-3.3z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.25.25h42.5V25A4.75 4.75 0 0138 29.75H.25V.25zm1.5 1.5v26.5H38A3.25 3.25 0 0041.25 25V1.75H1.75zm21.75 9h14v1.5h-14v-1.5zm0 4h8v1.5h-8v-1.5zm8 5.5h-8v-1.5h8v1.5z"
      />
    </svg>
  );
}

export default forwardRef(SvgProfile);
