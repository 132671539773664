import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgFlagEn({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 640 480"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M-160.64 0h962.579v481.29H-160.64z" fill="#006" />
      <path
        d="M-160.64 0v53.81L694.323 481.29h107.616v-53.807L-53.024 0H-160.64zm962.579 0v53.808L-53.024 481.29H-160.64v-53.808L694.323 0h107.616z"
        fill="#fff"
      />
      <path
        d="M240.435 0v481.29h160.43V0h-160.43zM-160.64 160.43v160.43h962.579V160.43H-160.64z"
        fill="#fff"
      />
      <path
        d="M-160.64 192.516v96.258h962.579v-96.258H-160.64zM272.521 0v481.29h96.256V0h-96.256zM-160.64 481.29l320.86-160.43h71.744l-320.86 160.43h-71.744zm0-481.29l320.86 160.43H88.475L-160.64 35.874V0zm569.975 160.43L730.195 0h71.744l-320.86 160.43h-71.744zm392.604 320.86l-320.86-160.43h71.745l249.115 124.557v35.872z"
        fill="#c00"
      />
    </svg>
  );
}

export default forwardRef(SvgFlagEn);
