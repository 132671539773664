import React from 'react';
import Node, { NodeProps } from 'components/tree/Node';
import SideMenu from 'components/ui/SideMenu/SideMenu';
import { Untrustable } from 'types';
import SideMenuItem from 'components/ui/SideMenu/SideMenuItem';
import { useParams } from 'react-router';
import MenuEntryFirst from 'components/ui/Header/MenuEntryFirst';
import MenuEntrySecond from 'components/ui/Header/MenuEntrySecond';
import MenuEntryThird from 'components/ui/Header/MenuEntryThird';
import Link from 'components/ui/Icon/actions/Link';
import { NodeModel } from 'models';
import { LinkViewProps } from '../link/LinkBehavior';
import DefaultLinkView from '../link/DefaultLinkView';

export type MenuItem = {
  identifier: number;
  to?: string;
  title: string;
  description: string;
  content?: NodeModel[];
  items?: MenuItem[] | null;
  type: string;
};

const RENDERER_MAPPING = {
  navmenu: MenuEntryFirstRenderer,
  sidemenu: SideMenuRenderer,
};

type Renderers = typeof RENDERER_MAPPING;
type RendererName = keyof Renderers;

export function isRenderer(renderer: any): renderer is RendererName {
  return renderer in RENDERER_MAPPING;
}

export type MenuBehaviorProps = NodeProps<
  Untrustable<{
    title: string;
    items: MenuItem[];
    renderer: RendererName;
  }>
>;

function MenuBehavior(props: MenuBehaviorProps): JSX.Element {
  const Renderer = isRenderer(props.behavior.renderer)
    ? RENDERER_MAPPING[props.behavior.renderer]
    : undefined;

  if (!Renderer) {
    throw new Error(`Missing menu renderer for block ${props.identifier}`);
  }

  return <Renderer {...props} />;
}

function MenuEntryFirstRenderer({
  identifier,
  content,
  behavior: { title, items },
}: MenuBehaviorProps): JSX.Element {
  if (!title) {
    throw new Error(`Missing title for block ${identifier}`);
  }

  return (
    <MenuEntryFirst title={title}>
      {items
        ? items.map((item, i) => <MenuItemSecondView key={i} {...item} />)
        : content
        ? content.map((node) => (
            <Node key={node.identifier} identifier={node.identifier} view={MenuItemSecondView} />
          ))
        : null}
    </MenuEntryFirst>
  );
}

type MenuItemSecondViewProps = LinkViewProps & { items?: MenuItem[] | null };

function MenuItemSecondView(props: MenuItemSecondViewProps): JSX.Element {
  const { title, description, items, content } = props;

  return (
    <MenuEntrySecond title={title} description={description}>
      {items
        ? items.map((item) => <MenuItemThirdView key={item.identifier} {...item} />)
        : content
        ? content.map((node) => (
            <Node key={node.identifier} identifier={node.identifier} view={MenuItemThirdView} />
          ))
        : null}
    </MenuEntrySecond>
  );
}

function MenuItemThirdView(props: LinkViewProps): JSX.Element | null {
  return <MenuEntryThird title={props.title} link={<DefaultLinkView {...props} />} />;
}

function SideMenuRenderer({ behavior: { title, items } }: MenuBehaviorProps): JSX.Element {
  const { slug } = useParams();

  return <SideMenu aria-label={title ?? undefined}>{items?.map(renderItem) ?? null}</SideMenu>;

  function renderItem(item: MenuItem, i: number): JSX.Element {
    const Component = item.to ? SideMenuItemLink : SideMenuItemButton;

    return (
      <Component key={item.identifier} item={item} currentSlug={slug}>
        {item.items?.map(renderItem) ?? null}
      </Component>
    );
  }
}

type SideMenuItemCommonProps = {
  item: MenuItem;
  currentSlug?: string;
  children: React.ReactNode;
};

function isExpanded(item: MenuItem, to: string | undefined): boolean {
  return item.to === to || (item.items?.some((i) => isExpanded(i, to)) ?? false);
}

function SideMenuItemLink({ item, children, currentSlug }: SideMenuItemCommonProps): JSX.Element {
  const expanded = isExpanded(item, currentSlug);

  return (
    <SideMenuItem
      title={item.title}
      link={item.to ? <Link to={item.to} /> : undefined}
      active={expanded}
    >
      {children}
    </SideMenuItem>
  );
}

function SideMenuItemButton({ item, children, currentSlug }: SideMenuItemCommonProps): JSX.Element {
  const expanded = isExpanded(item, currentSlug);

  return (
    <SideMenuItem title={item.title} active={expanded}>
      {children}
    </SideMenuItem>
  );
}

export default MenuBehavior;
