import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgArpege({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 897.673 897.673"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M859.877 855.354L606.396 23.221C602.195 9.427 589.469 0 575.049 0H322.625c-14.42 0-27.146 9.426-31.347 23.221L37.795 855.354c-6.413 21.055 9.338 42.318 31.347 42.318h181.071c15.246 0 28.479-10.515 31.922-25.367l38.312-165.216h256.78l38.312 165.216c3.443 14.853 16.676 25.367 31.922 25.367h181.071c22.007.001 37.759-21.263 31.345-42.318zM448.836 523.413h-85.578l15.191-62.147c22.096-85.624 44.192-194.726 66.29-284.493h8.194c22.097 89.767 44.193 198.869 66.289 284.493l15.191 62.147h-85.577z" />
    </svg>
  );
}

export default forwardRef(SvgArpege);
