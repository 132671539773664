import { useCallback } from 'react';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import useMessages from 'components/hooks/useMessages';
import { Override } from 'types';
import './WidgetPagination.scss';
import classNames from 'classnames';

export type WidgetPaginationMessages = {
  first: string;
  previous: string;
  next: string;
  last: string;
  of(currentPage: number, pageCount: number): string;
};

export type WidgetPaginationProps = Override<
  React.ComponentPropsWithoutRef<'div'>,
  {
    pageCount?: number;
    currentPage?: number;
    onChange?(page: number): void;
    messages?: Partial<WidgetPaginationMessages>;
  }
>;

function WidgetPagination({
  pageCount = 1,
  currentPage = 1,
  onChange,
  messages: overrideMessages,
  className,
  ...props
}: WidgetPaginationProps) {
  const messages: WidgetPaginationMessages = useMessages(
    (t) => ({
      first: t('pagination.first'),
      previous: t('pagination.previous'),
      next: t('pagination.next'),
      last: t('pagination.last'),
      of(currentPage: number, pageCount: number) {
        return t('pagination.of', { currentPage, pageCount });
      },
      ...overrideMessages,
    }),
    [],
  );

  const handlePrevious = useCallback(() => {
    if (onChange) {
      onChange(currentPage > 0 ? currentPage - 1 : currentPage);
    }
  }, [currentPage, onChange]);

  const handleNext = useCallback(() => {
    if (onChange) {
      onChange(currentPage + 1);
    }
  }, [currentPage, onChange]);

  return (
    <div className={classNames('WidgetPagination', className)} {...props}>
      <span>{messages.of(currentPage, pageCount)}</span>
      &nbsp;&nbsp;
      <Button title={messages.previous} disabled={currentPage <= 1} onClick={handlePrevious}>
        <Icon name="left-arrow" size="xs" />
      </Button>
      &nbsp;
      <Button title={messages.next} disabled={currentPage >= pageCount} onClick={handleNext}>
        <Icon name="right-arrow" size="xs" />
      </Button>
    </div>
  );
}

export default WidgetPagination;
