import './EditorialHeader.scss';

export type EditorialHeaderProps = {
  children: React.ReactNode;
};

function EditorialHeader({ children, ...props }: EditorialHeaderProps): JSX.Element {
  return (
    <div className="EditorialHeader" {...props}>
      {children}
    </div>
  );
}

export default EditorialHeader;
