import {
  requestCertificates,
  receiveCertificates,
  errorCertificates,
} from 'actions/certificatesActions';
import { ErrorModel, CertificateModel } from 'models';
import { createReducer } from './reducerUtils';

export type CertificatesState = Readonly<{
  certificates: CertificateModel[];
  error?: ErrorModel;
}>;

export const initialState: CertificatesState = {
  certificates: [],
  error: undefined,
};

export function requestCertificatesReducer(
  state: CertificatesState,
  action: ReturnType<typeof requestCertificates>,
): CertificatesState {
  return {
    ...state,
    error: undefined,
  };
}

export function receiveCertificatesReducer(
  state: CertificatesState,
  action: ReturnType<typeof receiveCertificates>,
): CertificatesState {
  return {
    ...state,
    certificates: action.payload.certificates,
  };
}

export function errorCertificatesReducer(
  state: CertificatesState,
  action: ReturnType<typeof errorCertificates>,
): CertificatesState {
  return {
    ...state,
    error: action.payload.error,
  };
}

export default createReducer(initialState, {
  [requestCertificates.type]: requestCertificatesReducer,
  [receiveCertificates.type]: receiveCertificatesReducer,
  [errorCertificates.type]: errorCertificatesReducer,
});
