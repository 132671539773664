import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgPage({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 24"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M.834.726h14.588c1.066 0 1.931.865 1.931 1.931v6.674l2.8-2.799h.217c.668 0 1.292.328 1.74.775.446.446.775 1.07.775 1.738v.217l-5.532 5.532v8.779H6.075l-.189-.175h-.131v-.122L1.53 19.353h-.17v-.158l-.526-.488V.726zm2.239 18.627l2.682 2.49v-2.49H3.073zm3.732 3.17v-4.22H1.942l-.058-.054V1.776h13.538c.486 0 .881.395.881.881v7.724l-4.91 4.91v2.732h2.731l2.179-2.178v6.678H6.805zM20.568 7.601l-8.125 8.125v1.247h1.247l8.126-8.126a1.585 1.585 0 00-.449-.797 1.588 1.588 0 00-.799-.449zm-7.959.151H4.875v-1.05h7.734v1.05zm0 2.812H4.875v-1.05h7.734v1.05zm-4.218 2.813H4.875v-1.05h3.516v1.05z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default forwardRef(SvgPage);
