import classNames from 'classnames';
import { ComponentPropsWithoutRef, ReactElement, ReactNode } from 'react';
import { useTooltipState, Tooltip as AriaTooltip, TooltipArrow, TooltipAnchor } from 'ariakit';
import { Override } from 'types';
import Icon from '../Icon';
import Close from '../Icon/common/Close';
import SrOnly from '../SrOnly/SrOnly';
import './Tooltip.scss';

export type TooltipProps = Override<
  ComponentPropsWithoutRef<'div'>,
  {
    large?: boolean;
    children: ReactNode;
    element?: ReactElement;
    title?: ReactNode;
    closeText?: ReactNode;
  }
>;

function Tooltip({ large, children, element, title, closeText }: TooltipProps): JSX.Element {
  const tooltip = useTooltipState({ placement: 'top', animated: true });

  return (
    <>
      {element ? <TooltipAnchor state={tooltip}>{element}</TooltipAnchor> : element}
      <AriaTooltip state={tooltip} className={classNames('Tooltip', { 'is-large': large })}>
        <TooltipArrow {...tooltip} className="Tooltip-arrow" />
        {title ? <div className="Tooltip-title">{title}</div> : null}
        <div className="Tooltip-content">{children}</div>
        {closeText ? (
          <button className="Tooltip-close" onClick={() => tooltip.hide()}>
            <Icon as={Close} className="Tooltip-closeIcon" />
            <SrOnly>{closeText}</SrOnly>
          </button>
        ) : null}
      </AriaTooltip>
    </>
  );
}

export default Tooltip;
