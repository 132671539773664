import { put, call, fork, select, take, takeLatest } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import jwt_decode from 'jwt-decode';
import { purgeStoredState } from 'redux-persist';
import { persistConfig as tamponPersistConfig } from 'reducers/tamponReducer';
import { settingsPersistConfig } from 'reducers/uiReducer';
import tokenize from 'lib/tokenize';
import cdmApi, { resolveError } from 'services/cdmApi';
import { selectApiBaseUrl } from 'selectors/configSelectors';
import {
  setIdentity,
  resetIdentity,
  authError,
  authRequest,
  authReceive,
  loginWithPopin,
  loginWithAdfs,
  loginWithAccessToken,
  loginWithCredentials,
  logoutWithAll,
  authRefreshStart,
  authRefreshStop,
} from 'actions/authActions';
import { errorPage, errorBlock } from 'actions/treeActions';
import { errorUser } from 'actions/userActions';
import { errorRoomRequest } from 'actions/roomRequestActions';
import { errorDocumentRequest } from 'actions/documentRequestActions';
import {
  createEventError,
  deleteEventError,
  createBookingError,
  deleteBookingError,
} from 'actions/eventActions';
import { errorCandidacies, errorCandidateContent } from 'actions/candidateActions';
import { persistConfig as authPersistConfig } from 'reducers/authReducer';
import { persistConfig as userPersistConfig } from 'reducers/userReducer';
import { helpPersistConfig } from 'reducers/uiReducer';
import { isIdentity, isTokenModel } from 'models';
import { deleteMessagingToken } from 'services/firebase';
import { superviewerPersistConfig } from 'reducers/candidateReducer';

async function purgeStore() {
  return Promise.all([
    await purgeStoredState(authPersistConfig),
    await purgeStoredState(userPersistConfig),
    await purgeStoredState(helpPersistConfig),
    await purgeStoredState(tamponPersistConfig),
    await purgeStoredState(settingsPersistConfig),
    await purgeStoredState(superviewerPersistConfig),
  ]);
}

export function* authRefreshTask(action: any) {
  // Only start auth refresh when there is a specific error
  if (action?.payload?.error?.error === 'auth:access_token_expired') {
    yield put(authRefreshStart());
  }
}

export function* watchAuthRefresh() {
  yield takeLatest(
    [
      errorUser,
      errorPage,
      errorBlock,
      errorRoomRequest,
      errorDocumentRequest,
      createEventError,
      deleteEventError,
      createBookingError,
      deleteBookingError,
      errorCandidacies,
      errorCandidateContent,
    ].map((actionCreator) => actionCreator.type),
    authRefreshTask,
  );
}

function createPopinChannel(popinWindow: Window) {
  return eventChannel(function startPopinChannel(emitter) {
    const interval = setInterval(function popinInterval() {
      if (popinWindow.closed) {
        emitter(END);
      } else {
        emitter({ open: true });
      }
    }, 250);

    return function stopPopinChannel() {
      clearInterval(interval);
    };
  });
}

export function* loginWithPopinTask(): any {
  // Purge the storage in order to show the login page before automatically closing the popin
  yield call(purgeStore);

  const loginUrl = `${window.location.protocol}//${window.location.host}/close`;

  const loginWindow = yield call(
    window.open,
    loginUrl,
    'MakersboardLoginPopup',
    'height=600,width=500,scrollbars=yes,status=yes,location=no,toolbar=no',
  );

  if (loginWindow) {
    const channel = yield call(createPopinChannel, loginWindow);

    try {
      while (true) {
        yield take(channel);
      }
    } finally {
      channel.close();

      // @todo reload store from storage
      // For now redux-persist can't do it...
      yield put(authRefreshStop());
    }
  }
}

export function* watchLoginWithPopin() {
  yield takeLatest(loginWithPopin.type, loginWithPopinTask);
}

export function* loginWithAdfsTask(): any {
  yield put(resetIdentity());

  const apiBaseUrl = yield select(selectApiBaseUrl);
  const callbackUrl = `${tokenize(
    window.location.href,
    'TOKEN_VALUE',
    'codeToken',
  )}&error=ERROR_VALUE`;
  const tokenUrl = `${apiBaseUrl}/token/create?callbackUrl=${encodeURIComponent(callbackUrl)}`;

  window.location.href = tokenUrl;
}

export function* watchLoginWithAdfs() {
  yield takeLatest(loginWithAdfs.type, loginWithAdfsTask);
}

export function* loginWithAccessTokenTask(action: ReturnType<typeof loginWithAccessToken>) {
  yield put(resetIdentity());

  const { accessToken } = action.payload;

  if (accessToken) {
    const decoded = jwt_decode(accessToken);
    if (decoded && typeof decoded === 'object') {
      const identity = { accessToken, ...decoded };

      if (isIdentity(identity)) {
        yield put(setIdentity(identity));
      }
    }
  }
}

export function* watchLoginWithAccessToken() {
  yield takeLatest(loginWithAccessToken.type, loginWithAccessTokenTask);
}

export function* logoutWithAllTask(): any {
  // Clear saved store
  yield call(purgeStore);

  // Clear the Firebase Messaging token
  try {
    yield call(deleteMessagingToken);
  } catch (error) {
    // We do not care at this point if there was an error
  }

  // No need to purge store in memory as we load another page

  // Also log out from ADFS
  const apiBaseUrl = yield select(selectApiBaseUrl);

  const callbackUrl = window.location.href;
  const logoutUrl = `${apiBaseUrl}/auth/logout?callbackUrl=${encodeURIComponent(callbackUrl)}`;

  window.location.href = logoutUrl;
}

export function* watchLogoutWithAll() {
  yield takeLatest(logoutWithAll.type, logoutWithAllTask);
}

export function* loginWithCredentialsTask(action: ReturnType<typeof loginWithCredentials>): any {
  yield put(resetIdentity());

  const { credentials } = action.payload;

  try {
    yield put(authRequest(credentials));
    const response = yield call(cdmApi.post, '/token/create', {
      ...credentials,
      continueUrl: credentials.continueUrl ?? window.location.href,
    });

    if (isTokenModel(response.data)) {
      const accessToken = response.data.token;
      yield put(loginWithAccessToken(accessToken));
      yield put(authReceive());
    }
  } catch (error) {
    const err = resolveError(error);
    yield put(authError(err));
  }
}

export function* watchLoginWithCredentials() {
  yield takeLatest(loginWithCredentials.type, loginWithCredentialsTask);
}

export default function* authSaga() {
  yield fork(watchAuthRefresh);
  yield fork(watchLoginWithAccessToken);
  yield fork(watchLoginWithAdfs);
  yield fork(watchLoginWithCredentials);
  yield fork(watchLoginWithPopin);
  yield fork(watchLogoutWithAll);
}
