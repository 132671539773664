import {
  createEvent,
  createEventSuccess,
  createEventError,
  deleteEvent,
  deleteEventSuccess,
  deleteEventError,
  resetEvent,
} from 'actions/eventActions';
import { EventModel } from 'models/event';
import { ErrorModel } from 'models';
import { createReducer } from './reducerUtils';

export type EventState = {
  [uid: string]: EventModel & {
    status: number | null;
    error: ErrorModel | null;
    loading: boolean;
  };
};

export const initialState: EventState = {};

type Actions =
  | typeof createEvent
  | typeof createEventSuccess
  | typeof createEventError
  | typeof deleteEvent
  | typeof deleteEventSuccess
  | typeof deleteEventError
  | typeof resetEvent;

function singleEventReducer(state: EventState, action: ReturnType<Actions>) {
  const { event } = action.payload;

  return {
    ...state,
    [event.uid]: { ...state[event.uid], ...action.payload },
  };
}

export default createReducer(initialState, {
  [createEvent.type]: singleEventReducer,
  [createEventSuccess.type]: singleEventReducer,
  [createEventError.type]: singleEventReducer,
  [deleteEvent.type]: singleEventReducer,
  [deleteEventSuccess.type]: singleEventReducer,
  [deleteEventError.type]: singleEventReducer,
  [resetEvent.type]: singleEventReducer,
});
