import { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import Container from '../Container';
import './Banner.scss';

export type BannerProps = {
  children: ReactNode;
  img?: ReactElement;
  size?: 'big';
  className?: string;
};

/**
 * If the banner is not given any child, it will have a negative marginBottom to have the content pass over the banner.
 */
function Banner({ children, size, img, className }: BannerProps): JSX.Element {
  const rootClasses = classnames('Banner', className, {
    [`is-${size}`]: Boolean(size),
    [`has-offset`]: !children,
  });

  return (
    <Container className={rootClasses}>
      {img ? <div className="Banner-img">{img}</div> : null}
      {children ? <div className="Banner-content">{children}</div> : null}
    </Container>
  );
}

export default Banner;
