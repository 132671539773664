import React from 'react';
import classNames from 'classnames';
import { Override } from 'types';
import Icon from 'components/ui/Icon/Icon';
import Arrowleft from 'components/ui/Icon/common/Arrowleft';
import Separator from 'components/ui/Icon/common/Separator';
import BreadcrumbItem from './BreadcrumbItem';
import './Breadcrumb.scss';

export type BreadcrumbProps = Override<
  React.ComponentPropsWithoutRef<'div'>,
  {
    items?: React.ComponentPropsWithoutRef<typeof BreadcrumbItem>[];
  }
>;

function Breadcrumb({ items, children, className, ...props }: BreadcrumbProps): JSX.Element {
  return (
    <div className={classNames('Breadcrumb', className)} {...props}>
      <Icon className="" as={Arrowleft} />
      <span className="Breadcrumb-list">
        {items
          ? items.map((item, i) => (
              <>
                {i !== 0 ? <Icon as={Separator} /> : null}
                <BreadcrumbItem {...item} />
              </>
            ))
          : React.Children.count(children) > 0
          ? React.Children.map(children, (child, i) =>
              child ? (
                <>
                  {i !== 0 ? <Icon as={Separator} /> : null}
                  {child}
                </>
              ) : null,
            )
          : null}
      </span>
    </div>
  );
}

export default Breadcrumb;
