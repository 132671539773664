import { combineReducers } from 'redux';
import config from './configReducer';
import user from './userReducer';
import tree from './treeReducer';
import nodes from './nodesReducer';
import event from './eventReducer';
import bookings from './bookingsReducer';
import documentRequest from './documentRequestReducer';
import roomRequest from './roomRequestReducer';
import ui from './uiReducer';
import candidate from './candidateReducer';
import tampon from './tamponReducer';
import auth from './authReducer';
import certificates from './certificatesReducer';
import activity from './activityReducer';
import data from './dataReducer';
import notification from './notificationReducer';

const rootReducer = combineReducers({
  config,
  auth,
  user,
  tree,
  nodes,
  event,
  bookings,
  documentRequest,
  roomRequest,
  ui,
  candidate,
  tampon,
  certificates,
  activity,
  data,
  notification,
});

export default rootReducer;
