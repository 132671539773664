import { takeLatest, call, put } from 'redux-saga/effects';
import { sendRoomRequest, receiveRoomRequest, errorRoomRequest } from 'actions/roomRequestActions';
import cdmApi, { resolveError } from 'services/cdmApi';

export function* sendRoomRequestTask(action: ReturnType<typeof sendRoomRequest>): any {
  try {
    const response = yield call(cdmApi.post, '/book-room', action.payload.roomRequest);
    yield put(receiveRoomRequest(response.status));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorRoomRequest(err));
  }
}

export default function* treeSaga() {
  yield takeLatest(sendRoomRequest.type, sendRoomRequestTask);
}
