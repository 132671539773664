import { ErrorModel, NodeModel, PageBehaviorModel } from 'models';

export function requestPage(
  slug: string,
  parameters: object,
  locale: string,
  campus: string | null = null,
) {
  return {
    type: requestPage.type,
    payload: {
      slug,
      parameters,
      locale,
      campus,
    },
  } as const;
}

requestPage.type = 'tree/page/request' as const;

export function receivePage(tree: NodeModel<PageBehaviorModel>) {
  return {
    type: receivePage.type,
    payload: {
      tree,
    },
  } as const;
}

receivePage.type = 'tree/page/receive' as const;

export function errorPage(error: ErrorModel) {
  return {
    type: errorPage.type,
    payload: {
      error,
    },
  } as const;
}

errorPage.type = 'tree/page/error' as const;

export function requestBlock(identifier: number, parameters?: object) {
  return {
    type: requestBlock.type,
    payload: {
      identifier,
      parameters,
    },
  } as const;
}

requestBlock.type = 'tree/block/request' as const;

export function receiveBlock(identifier: number, parameters: object | undefined, node: NodeModel) {
  return {
    type: receiveBlock.type,
    payload: {
      identifier,
      parameters,
      node,
    },
  } as const;
}

receiveBlock.type = 'tree/block/receive' as const;

export function errorBlock(identifier: number, parameters: object | undefined, error: ErrorModel) {
  return {
    type: errorBlock.type,
    payload: {
      identifier,
      parameters,
      error,
    },
  } as const;
}

errorBlock.type = 'tree/block/error' as const;
