import { ReactNode } from 'react';
import classnames from 'classnames';
import './ServiceSlider.scss';

export type ServiceSliderProps = {
  shrunk?: boolean;
  className?: string;
  children: ReactNode;
};

function ServiceSlider({ shrunk, className, children }: ServiceSliderProps): JSX.Element {
  return (
    <div
      className={classnames('ServiceSlider', className, {
        'ServiceSlider--shrunk': shrunk,
      })}
    >
      {children}
    </div>
  );
}

export default ServiceSlider;
