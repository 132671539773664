import { useSelector } from 'react-redux';
// import Button from 'components/ui/Button';
// import Link from 'components/utils/Link';
import { selectIdentity } from 'selectors/authSelectors';
import { selectUser } from 'selectors/userSelectors';
// import { LinkViewProps } from 'components/tree/behavior/link/LinkBehavior';
import UserMenu from 'components/ui/Header/UserMenu';
import useMessages from 'components/hooks/useMessages';
import Node from 'components/tree/Node';
import DefaultLinkView from '../link/DefaultLinkView';
import { ToolMenuBehaviorProps } from './ToolMenuBehavior';

function UserMenuRenderer({ behavior: { title }, content }: ToolMenuBehaviorProps): JSX.Element {
  const user = useSelector(selectUser);
  const identity = useSelector(selectIdentity);
  const loggedAs = Boolean(identity.from_sub);
  const username = user.firstName ?? user.lastName ?? user.upn;
  const messages = useMessages(
    (t) => ({
      hello: (username: string) => t('header.hello', { username }),
    }),
    [],
  );

  return (
    <UserMenu special={loggedAs} name={messages.hello(username ?? '')} title={title}>
      {content.map((node) => (
        <Node key={node.identifier} identifier={node.identifier} view={DefaultLinkView} />
      ))}
    </UserMenu>
  );
}

export default UserMenuRenderer;
