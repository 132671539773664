export default function browserLocale(): string | null {
  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    return navigator.languages[0];
  }
  if ('userLanguage' in navigator) {
    // IE only
    return (navigator as any).userLanguage as string;
  }

  // latest versions of Chrome, Firefox, and Safari set this correctly
  return navigator.language || null;
}
