import useMessages from 'components/hooks/useMessages';
import Icon from 'components/ui/Icon';
import Search from 'components/ui/Icon/service/Search';
import Title from 'components/ui/Title';
import { useAzureSearchContext } from './AzureSearchProvider';

export type SearchTitleProps = {
  shrunk?: boolean;
};

function SearchTitle({ shrunk }: SearchTitleProps): JSX.Element | null {
  const messages = useMessages(
    (t) => ({
      title: (count: number) => t('search.panel.title', { count }),
    }),
    [],
  );

  const { teaserResponse, serviceResponse } = useAzureSearchContext();

  if (teaserResponse && serviceResponse) {
    const count = (teaserResponse.count ?? 0) + (serviceResponse.count ?? 0);
    const title = messages.title(count);

    if (shrunk) {
      return <Title as="h5">{title}</Title>;
    } else {
      return (
        <Title kind="primary" as="h1" icon={<Icon as={Search} />}>
          {title}
        </Title>
      );
    }
  }

  return null;
}

export default SearchTitle;
