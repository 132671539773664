import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { OverridableComponentProps } from 'components/OverridableComponent';
import { FontSize, FontWeight, Kind } from 'components/ui/types.d';
import { useFormFieldContext } from './FormField';
import './FormLabel.scss';

type BaseProps = {
  invisible?: boolean;
  hidden?: boolean;
  required?: boolean;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  kind?: Kind | 'inherit';
  label?: React.ReactNode;
};

export type FormLabelProps<A> = OverridableComponentProps<A, 'label', BaseProps>;

function FormLabel<A>({
  as: As = 'label',
  htmlFor,
  label,
  required,
  children,
  fontSize,
  fontWeight,
  kind,
  className,
  style,
  hidden,
  ...props
}: FormLabelProps<A>): JSX.Element {
  const { id, required: requiredFromField } = useFormFieldContext();
  const rootStyle = {
    '--formLabel-fontSize': fontSize ? `var(--fontSize-${fontSize})` : undefined,
    '--formLabel-fontWeight': fontWeight ? `var(--fontWeight-${fontWeight})` : undefined,
    ...style,
  } as CSSProperties;

  return (
    <As
      className={classNames('FormLabel', className, { 'u-srOnly': hidden, [`is-${kind}`]: kind })}
      htmlFor={htmlFor || id}
      style={rootStyle}
      {...props}
    >
      <>
        {children ?? label}
        {required || requiredFromField ? <em>*</em> : null}
      </>
    </As>
  );
}

export default FormLabel;
