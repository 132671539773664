import { setConfig } from '../actions/configActions';
import { ConfigModel } from '../models';
import { createReducer } from './reducerUtils';

export type ConfigState = Readonly<Partial<ConfigModel>>;

export const initialState: ConfigState = {};

export function setConfigReducer(state: ConfigState, action: ReturnType<typeof setConfig>) {
  return action.payload.config;
}

export default createReducer(initialState, {
  [setConfig.type]: setConfigReducer,
});
