import React from 'react';
import classnames from 'classnames';
import { OverridableComponentProps } from 'components/OverridableComponent';
import './Container.scss';

export type ContainerProps<As> = OverridableComponentProps<
  As,
  'div',
  {
    withBackground?: boolean;
    className?: string;
    small?: boolean;
    padding?: 'none' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    children?: React.ReactNode;
  }
>;

function Container<C>({
  children = null,
  className,
  withBackground,
  padding,
  small,
  as: As = 'div',
  ...props
}: ContainerProps<C>): JSX.Element {
  const rootClasses = classnames(
    'Container',
    {
      'has-background': withBackground,
      'is-small': small,
      [`is-padding-${padding}`]: Boolean(padding),
    },
    className,
  );

  return (
    <As className={rootClasses} {...props}>
      <div className="Container-inner">{children}</div>
    </As>
  );
}

export default Container;
