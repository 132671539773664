import classNames from 'classnames';
import { Override } from 'types';
import './Spinner.scss';

export type SpinnerProps = Override<
  React.ComponentPropsWithoutRef<'div'>,
  {
    'aria-label': string;
    kind?: 'primary' | 'secondary';
  }
>;

function Spinner({ kind, className, ...props }: SpinnerProps): JSX.Element {
  return (
    <div
      {...props}
      className={classNames('Spinner', className, { [`is-${kind}`]: Boolean(kind) })}
    />
  );
}

export default Spinner;
