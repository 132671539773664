import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import './Subtitle.scss';

type SubtitleProps = {
  children?: ReactNode;
  label?: ReactNode;
  element?: ReactElement<{
    children: ReactNode;
    className: string;
  }>;
};

function Subtitle({ label, children, element = <span /> }: SubtitleProps): JSX.Element {
  return (
    <element.type {...element.props} className={classNames('Subtitle', element.props.className)}>
      {label}
      {children}
    </element.type>
  );
}

export default Subtitle;
