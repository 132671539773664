import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgImpressions(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 512 512"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M475.934 108.229H380.35V54.285c0-5.995-4.862-10.857-10.857-10.857H142.507c-5.995 0-10.857 4.862-10.857 10.857v53.945H36.066C16.179 108.229 0 124.409 0 144.296v172.837C0 337.02 16.179 353.2 36.066 353.2h95.584v104.515c0 5.995 4.862 10.857 10.857 10.857h226.987c5.995 0 10.857-4.862 10.857-10.857V353.2h95.584c19.886 0 36.066-16.179 36.066-36.067V144.296c-.001-19.887-16.181-36.067-36.067-36.067zm-95.585 21.713h25.897v30.385h-25.897v-30.385zm-226.986-64.8h205.273v95.186H153.363V65.142zm-47.61 64.801h25.897v30.385h-25.897v-30.385zm252.884 316.915H153.363V303.879h205.273v142.979zm131.651-129.725h-.001c0 7.915-6.439 14.354-14.352 14.354H380.35V303.88h19.805c5.995 0 10.857-4.862 10.857-10.857s-4.862-10.857-10.857-10.857H111.846c-5.995 0-10.857 4.862-10.857 10.857s4.862 10.857 10.857 10.857h19.805v27.607H36.066c-7.913 0-14.352-6.439-14.352-14.354V144.296c0-7.914 6.439-14.354 14.352-14.354H84.04v41.241c0 5.995 4.861 10.857 10.857 10.857h322.207c5.995 0 10.857-4.862 10.857-10.857v-41.241h47.974c7.913 0 14.353 6.439 14.353 14.354v172.837z" />
      <circle cx={68.799} cy={219.261} r={13.602} />
      <path d="M252.849 386.456h-50.68c-5.995 0-10.857 4.86-10.857 10.857 0 5.995 4.861 10.857 10.857 10.857h50.68c5.995 0 10.857-4.862 10.857-10.857 0-5.997-4.862-10.857-10.857-10.857zm56.982-54.973H202.169c-5.995 0-10.857 4.862-10.857 10.857 0 5.996 4.861 10.857 10.857 10.857H309.83c5.995 0 10.857-4.861 10.857-10.857 0-5.995-4.861-10.857-10.856-10.857z" />
    </svg>
  );
}

export default forwardRef(SvgImpressions);
