import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgMybenefeat(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 31 22"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path
          d="M9 10c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2zm0-2.857a.858.858 0 010 1.714.858.858 0 010-1.714zM9 11c-2.206 0-4 1.538-4 3.429 0 .315.298.571.667.571h6.666c.369 0 .667-.256.667-.571C13 12.539 11.206 11 9 11zm-3 3c.345-1.149 1.56-2 3-2s2.655.851 3 2H6zm19.321-3h-9.642c-.375 0-.679.224-.679.5s.304.5.679.5h9.642c.375 0 .679-.224.679-.5s-.304-.5-.679-.5zm-8.071 3h-1.5c-.414 0-.75.224-.75.5s.336.5.75.5h1.5c.414 0 .75-.224.75-.5s-.336-.5-.75-.5zm8 0h-1.5c-.414 0-.75.224-.75.5s.336.5.75.5h1.5c.414 0 .75-.224.75-.5s-.336-.5-.75-.5zm-4 0h-1.5c-.414 0-.75.224-.75.5s.336.5.75.5h1.5c.414 0 .75-.224.75-.5s-.336-.5-.75-.5zm-5.48-5h5.46c.426 0 .77-.224.77-.5s-.344-.5-.77-.5h-5.46c-.426 0-.77.224-.77.5s.344.5.77.5z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M4 1.25h23A2.75 2.75 0 0129.75 4v14A2.75 2.75 0 0127 20.75H4A2.75 2.75 0 011.25 18V4A2.75 2.75 0 014 1.25z"
          stroke="#FFF"
          strokeWidth={1.5}
        />
      </g>
    </svg>
  );
}

export default forwardRef(SvgMybenefeat);
