import { fork } from 'redux-saga/effects';
import treeSaga from './treeSaga';
import progressSaga from './progressSaga';
import authSaga from './authSaga';
import userSaga from './userSaga';
import roomRequestSaga from './roomRequestSaga';
import documentRequestSaga from './documentRequestSaga';
import eventSaga from './eventSaga';
import candidateSaga from './candidateSaga';
import logSaga from './logSaga';
import certificatesSaga from './certificatesSaga';
import activitySaga from './activitySaga';
import dataSaga from './dataSaga';
import notificationSaga from './notificationSaga';

export default function* rootSaga() {
  yield fork(progressSaga);
  yield fork(authSaga);
  yield fork(treeSaga);
  yield fork(userSaga);
  yield fork(documentRequestSaga);
  yield fork(roomRequestSaga);
  yield fork(eventSaga);
  yield fork(candidateSaga);
  yield fork(logSaga);
  yield fork(certificatesSaga);
  yield fork(activitySaga);
  yield fork(dataSaga);
  yield fork(notificationSaga);
}
