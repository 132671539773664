import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import './MiniTeaser.scss';

type MiniTeaserProps = {
  title: ReactNode;
  children?: ReactNode;
  link?: ReactElement<{ className: string }>;
  subtitle?: ReactNode;
  media?: ReactNode;
};

function MiniTeaser({ title, link, media, subtitle, children }: MiniTeaserProps): JSX.Element {
  return (
    <div className="MiniTeaser">
      {media ? <div className="MiniTeaser-media">{media}</div> : null}
      <div className="MiniTeaser-content">
        {link ? (
          <link.type
            {...link.props}
            className={classNames('MiniTeaser-title', link.props.className)}
          >
            {title}
          </link.type>
        ) : (
          <div className="MiniTeaser-title">{title}</div>
        )}
        {subtitle ? <div className="MiniTeaser-subtitle">{subtitle}</div> : null}
        {children ? <div className="MiniTeaser-description">{children}</div> : null}
      </div>
    </div>
  );
}

export default MiniTeaser;
