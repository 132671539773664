import React from 'react';
import classnames from 'classnames';
import { OverridableComponentProps } from 'components/OverridableComponent';
import './NewContainer.scss';

export type NewContainerProps<As> = OverridableComponentProps<
  As,
  'div',
  {
    className?: string;
    shrunk?: boolean;
    paddingX?: 'lg' | 'xxl';
    children?: React.ReactNode;
  }
>;

function NewContainer<C>({
  children = null,
  className,
  shrunk,
  paddingX,
  as: As = 'div',
  ...props
}: NewContainerProps<C>): JSX.Element {
  const rootClasses = classnames(
    'NewContainer',
    {
      'NewContainer--shrunk': shrunk,
      [`NewContainer--paddingX-${paddingX}`]: shrunk,
    },
    className,
  );

  return (
    <As className={rootClasses} {...props}>
      {children}
    </As>
  );
}

export default NewContainer;
