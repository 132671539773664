import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgMail({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 13"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M.25.25h17.5v12.5H.25V.25zm1.5 1.5v9.5h14.5v-9.5H1.75z" />
      <path d="M3.531 4.586l.938-1.172L9 7.04l4.532-3.626.936 1.172L9 8.96 3.531 4.586z" />
    </svg>
  );
}

export default forwardRef(SvgMail);
