import { useCallback } from 'react';
import Editorial from 'components/ui/Editorial';
import NotificationCard from 'components/ui/NotificationCard/NotificationCard';
import DateAgo from 'components/utils/DateAgo';
import Link from 'components/utils/Link';
import Nl2br from 'components/utils/Nl2br';
import cdmApi from 'services/cdmApi';
import { useDispatch } from 'react-redux';
import { receiveNotificationDetail } from 'actions/notificationAction';
import useMessages from 'components/hooks/useMessages';
import { NotificationModel, isNotificationModel } from 'models/notification';

export type NotificationItemProps = {
  notification: NotificationModel;
  full?: boolean;
  to?: string;
  active?: boolean;
  onClick?(): void;
};

function NotificationItem({
  notification,
  full,
  to,
  active,
  onClick,
}: NotificationItemProps): JSX.Element {
  const dispatch = useDispatch();
  const messages = useMessages(
    (t) => ({
      read: t('notification.detail.read'),
      notRead: t('notification.detail.not_read'),
    }),
    [],
  );

  const handleRead = useCallback(async () => {
    try {
      const response = await cdmApi.post(`/notification/${notification.uuid}/read`);

      if (isNotificationModel(response.data)) {
        dispatch(receiveNotificationDetail(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, notification.uuid]);

  return (
    <NotificationCard
      key={notification.uuid}
      title={notification.title}
      initialOpen={full && active}
      element={to ? <Link to={to} onClick={onClick} /> : undefined}
      date={notification.sentAt ? <DateAgo date={notification.sentAt} /> : null}
      text={
        notification.shortDescription ? (
          <Editorial>
            <p>{notification.shortDescription}</p>
          </Editorial>
        ) : null
      }
      important={Boolean(notification.important)}
      read={Boolean(notification.read)}
      messages={messages}
      onRead={handleRead}
    >
      {full && notification.longDescription ? (
        <Editorial>
          <p>
            <Nl2br text={notification.longDescription} />
          </p>
        </Editorial>
      ) : null}
    </NotificationCard>
  );
}

export default NotificationItem;
