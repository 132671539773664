import classNames from 'classnames';
import React from 'react';
import { Override } from 'types';
import './PageLoader.scss';

export type PropsType = Override<
  React.ComponentPropsWithRef<'div'>,
  {
    full?: boolean;
    label?: React.ReactNode;
  }
>;

function PageLoader({ full, label, children, className, ...props }: PropsType): JSX.Element {
  return (
    <div
      className={classNames('PageLoader', className, {
        'is-full': full,
      })}
      {...props}
    >
      <div className="PageLoader-icon">
        <div />
        <div />
        <div />
        <div />
      </div>
      {label ? <div className="PageLoader-label">{label}</div> : null}
      {children ? <div className="PageLoader-text">{children}</div> : null}
    </div>
  );
}

export default PageLoader;
