import React, { useState, useContext } from 'react';
import useAutoId from 'components/hooks/useAutoId';
import FormError from './FormError';
import FormControl from './FormControl';

type BaseContext<V> = {
  value?: V;
  touched?: boolean;
  error?: string | null;
  required?: boolean;
  id?: string;
};

export type FormFieldContextType<V> = {
  setContext?: React.Dispatch<React.SetStateAction<BaseContext<V>>>;
} & BaseContext<V>;

const defaultContext: FormFieldContextType<unknown> = {};
export const FormFieldContext = React.createContext(defaultContext);

export function useFormFieldContext(): typeof defaultContext {
  return useContext(FormFieldContext);
}

export type FormFieldProps = React.ComponentPropsWithoutRef<typeof FormControl>;

function FormField({ children, ...props }: FormFieldProps): JSX.Element {
  const inputId = useAutoId();
  const initialContext: BaseContext<unknown> = { id: inputId };
  const [context, setContext] = useState(initialContext);

  return (
    <FormFieldContext.Provider value={{ ...context, setContext }}>
      <FormControl {...props}>
        {children}
        <FormError error={context.touched && context.error ? context.error : undefined} />
      </FormControl>
    </FormFieldContext.Provider>
  );
}

export default FormField;
