import { HelpStep } from '../models';

export function addSteps(steps: HelpStep[]) {
  return {
    type: addSteps.type,
    payload: {
      steps,
    },
  } as const;
}

addSteps.type = 'ui/help/add' as const;

export function removeSteps(steps: HelpStep[]) {
  return {
    type: removeSteps.type,
    payload: {
      steps,
    },
  } as const;
}

removeSteps.type = 'ui/help/remove' as const;

export function skipSteps(steps: HelpStep['id'][]) {
  return {
    type: skipSteps.type,
    payload: {
      steps,
    },
  } as const;
}

skipSteps.type = 'ui/help/skip' as const;
