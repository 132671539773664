import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgArrowleft(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M15.552 18L9.53 12l6.022-6 .978.975L11.486 12l5.044 5.025z" />
    </svg>
  );
}

export default forwardRef(SvgArrowleft);
