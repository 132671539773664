import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import MessagePage from 'components/pages/MessagePage';
import Button from 'components/ui/Button';
import useTranslate from 'components/hooks/useTranslate';
import { logoutWithAll } from 'actions/authActions';
import ChevronRight from 'components/ui/Icon/actions/ChevronRight';
import Container from 'components/ui/Container';
import Spacer from 'components/ui/Spacer';

export type PropsType = {
  children: React.ReactNode;
};

function ErrorPage({ children }: PropsType): JSX.Element {
  const t = useTranslate();
  const dispatch = useDispatch();

  const handleLogout = useCallback(
    function handleLogout() {
      dispatch(logoutWithAll());
    },
    [dispatch],
  );

  return (
    <MessagePage>
      <Helmet>
        <title>
          {t('error.title')}
          {' | makersboard'}
        </title>
      </Helmet>
      <Container>
        <Spacer block="md">{children}</Spacer>
        <Spacer block="md">
          <Button onClick={handleLogout} icon={ChevronRight}>
            {t('error.retry')}
          </Button>
        </Spacer>
      </Container>
    </MessagePage>
  );
}

export default ErrorPage;
