import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './assets/css/styles.scss';
import './assets/css/print.scss';
import StoreProvider from 'components/provider/StoreProvider';
import AppContextProvider from 'components/provider/AppContextProvider';
import ConfigProvider from 'components/provider/ConfigProvider';
import LocaleProvider from 'components/provider/LocaleProvider';
import TimezoneProvider from 'components/provider/TimezoneProvider';
import AuthProvider from 'components/provider/AuthProvider';
import UserProvider from 'components/provider/UserProvider';
import TamponProvider from 'components/provider/TamponProvider';
import ThemeProvider from 'components/provider/ThemeProvider';
// import NotificationProvider from 'components/provider/NotificationProvider';
import Main from 'components/Main';
import AuthRefresh from 'components/AuthRefresh';
import HeaderProvider from 'components/ui/Header/HeaderProvider';
import SearchProvider from 'components/provider/SearchProvider';

function Loading(): JSX.Element | null {
  return null;
}

const StaticRouter = lazy(
  () => import('./components/static/StaticRouter' /* webpackChunkName: "static" */),
);

const ParcoursupPage = lazy(
  () => import('./components/parcoursup/ParcoursupPage' /* webpackChunkName: "parcoursup" */),
);

function renderStatic(): JSX.Element {
  return (
    <Suspense fallback={<Loading />}>
      <StaticRouter />
    </Suspense>
  );
}

function renderParcoursup(): JSX.Element {
  return (
    <Suspense fallback={<Loading />}>
      <ParcoursupPage />
    </Suspense>
  );
}

function App(): JSX.Element {
  const providers = [
    StoreProvider,
    ConfigProvider,
    HelmetProvider,
    AppContextProvider,
    ThemeProvider,
    LocaleProvider,
    TamponProvider,
    AuthProvider,
    UserProvider,
    TimezoneProvider,
    // NotificationProvider, // Not in production yet...
    HeaderProvider,
    SearchProvider,
  ];

  const app = providers.reduceRight(
    (children, Component) => <Component>{children}</Component>,
    <Routes>
      <Route path="/parcoursup/:secret/*" element={renderParcoursup()} />
      <Route
        path="*"
        element={
          <>
            <Main />
            <AuthRefresh />
          </>
        }
      />
    </Routes>,
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/static/*" element={renderStatic()} />
        <Route path="*" element={app} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
