import classNames from 'classnames';
import './SrOnly.scss';

export type SrOnlyProps = React.ComponentPropsWithoutRef<'span'>;

function SrOnly({ className, ...props }: SrOnlyProps): JSX.Element {
  return <span className={classNames('SrOnly', className)} {...props} />;
}

export default SrOnly;
