import React, { useCallback, forwardRef } from 'react';
import classNames from 'classnames';
import { Override, Untrustable } from 'types';
import useFormInput, { FormInputProps } from './useFormInput';
import './FormInput.scss';

type SelectProps = React.ComponentPropsWithoutRef<'select'>;
type OptionProps = React.ComponentPropsWithoutRef<'option'>;

export type SelectOption<V = string> = {
  label: string;
  value: V;
} & Omit<OptionProps, 'label' | 'value'>;

export function isSelectOption<V>(option: Untrustable<SelectOption<V>>): option is SelectOption<V> {
  return option.value !== undefined && option.value !== null && typeof option.label === 'string';
}

export type ValueAccessor<V> = (value: V) => string;

type BaseProps<V> = FormInputProps<V | null, HTMLSelectElement> & {
  size?: 'md' | 'lg';
  inline?: boolean;
  placeholder?: string;
  options: SelectOption<V | null>[];
  valueAccessor?: V extends string ? undefined : ValueAccessor<V>;
};

export type SelectInputProps<V> = Override<SelectProps, BaseProps<V>>;

export const defaultValueAccessor: ValueAccessor<any> = function (value: any) {
  return String(value);
};

function SelectInput<V = string>(
  props: SelectInputProps<V>,
  ref: React.Ref<HTMLSelectElement>,
): JSX.Element {
  const {
    size,
    inline,
    value,
    touched,
    error,
    onValueChange,
    options,
    placeholder,
    valueAccessor = defaultValueAccessor,
    className,
    ...otherProps
  } = useFormInput(props);
  const resolvedValue = value !== undefined && value !== null ? valueAccessor(value) : null;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (onValueChange) {
        const { value } = event.target;

        const option = options.find(
          (option) => option.value !== null && valueAccessor(option.value) === value,
        );
        const newValue = option === undefined ? null : option.value;

        onValueChange(newValue, event.target.name, event);
      }
    },
    [onValueChange, options, valueAccessor],
  );

  function renderOption({ value, label, ...props }: SelectOption<V | null>): JSX.Element {
    const resolvedValue = value === null ? '' : valueAccessor(value);

    return (
      <option key={`${resolvedValue}--${label}`} value={resolvedValue} {...props}>
        {label}
      </option>
    );
  }

  return (
    <select
      className={classNames('FormInput', className, {
        [`is-${size}`]: Boolean(size),
        'is-inline': inline,
      })}
      ref={ref}
      value={resolvedValue === null ? '' : resolvedValue}
      onChange={handleChange}
      {...otherProps}
    >
      {placeholder !== undefined ? <option value="">{placeholder}</option> : null}
      {options.map(renderOption)}
    </select>
  );
}

// 'as' needed because forwardRef does not keep the generic type...
export default forwardRef(SelectInput) as <V>(
  props: SelectInputProps<V> & { ref?: React.Ref<HTMLSelectElement> },
) => JSX.Element;
