import { Children, ReactElement, ReactNode, useEffect } from 'react';
import useAutoId from 'components/hooks/useAutoId';
import useClickOutside from 'components/hooks/useClickOutside';
import useToggable from 'components/hooks/useToggable';
import useEscapeKey from 'components/hooks/useEscapeKey';
import Icon from '../Icon/Icon';
import Caret from '../Icon/actions/ChevronDown';
import Backdrop from '../Backdrop';
import { useHeaderContext } from './HeaderProvider';

export type MenuEntryFirstProps = {
  title: ReactNode;
  id?: string;
  children?: ReactNode;
  link?: ReactElement<{
    children: ReactNode;
    className: string;
  }>;
};

function MenuEntryFirst({
  id: defaultId,
  title,
  children,
  link,
}: MenuEntryFirstProps): JSX.Element {
  const [open, handleToggle, , onClose] = useToggable();
  const containerRef = useClickOutside<HTMLDivElement>(onClose);
  useEscapeKey(onClose);

  const { setTransparent } = useHeaderContext();

  useEffect(() => {
    if (open) {
      return setTransparent(false);
    }
  }, [open, setTransparent]);
  useEffect(() => {
    if (open) {
      const timeout = setTimeout(() => {
        containerRef.current?.scrollIntoView(true);
      }, 50);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [open, containerRef]);

  const id = useAutoId(defaultId);
  const contentId = `${id}-submenu`;

  return (
    <div id={id} className="MenuEntryFirst" ref={containerRef}>
      <button
        type="button"
        onClick={handleToggle}
        className="MenuEntryFirst-button"
        aria-expanded={open}
        aria-controls={contentId}
      >
        {title}
        <Icon as={Caret} />
      </button>
      <div id={contentId} className="MenuEntryFirst-content" aria-hidden={!open}>
        {children ? (
          <>
            {link ? <link.type {...link.props}>{title}</link.type> : null}
            <ul className="MenuEntryFirst-list">
              {Children.map(children, (child, i) => (
                <li key={i} className="MenuEntryFirst-item">
                  {child}
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
      <Backdrop aria-hidden={!open} />
    </div>
  );
}

export default MenuEntryFirst;
