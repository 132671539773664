import { DataModel } from 'models';
import { AppStore } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { requestData } from 'actions/dataActions';
import { ApiParams } from 'services/cdmApi';

function selectData(store: AppStore) {
  return store.data.data;
}

/**
 * Loads data not yet loaded.
 */
function useData(keys: (keyof DataModel)[], params?: ApiParams) {
  const dispatch = useDispatch();
  const data = useSelector(selectData);
  const { locale, campus } = params ?? {};

  useEffect(
    () => {
      if (keys.length > 0) {
        dispatch(requestData(keys, { locale, campus }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, locale, campus, ...keys],
  );

  return data;
}

export default useData;
