import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgPlay({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 10 32"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M10.286 16c0 .304-.125.589-.339.804l-8 8c-.214.214-.5.339-.804.339A1.151 1.151 0 010 24V8c0-.625.518-1.143 1.143-1.143.304 0 .589.125.804.339l8 8c.214.214.339.5.339.804z" />
    </svg>
  );
}

export default forwardRef(SvgPlay);
