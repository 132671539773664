import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgPlus({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12.75 7v4.25H17v1.5h-4.25V17h-1.5v-4.25H7v-1.5h4.25V7h1.5z" />
    </svg>
  );
}

export default forwardRef(SvgPlus);
