import Dialog from 'components/ui/Dialog';
import Editorial from 'components/ui/Editorial';
import Button from 'components/ui/Button';
import useMessages from 'components/hooks/useMessages';

type DialogDisabledAppProps = {
  open: boolean;
  title: string;
  text: string;
  onExit: () => void;
};

function DialogDisabledApp({ title, text, open, onExit }: DialogDisabledAppProps): JSX.Element {
  const messages = useMessages((t) => ({ label: title, close: t(`label.close`) }), [title]);

  return (
    <Dialog
      open={open}
      width="md"
      messages={messages}
      title={messages.label}
      onExit={onExit}
      actions={
        <Button onClick={onExit}>
          <span>{messages.close}</span>
        </Button>
      }
    >
      <Editorial>
        <p>{text}</p>
      </Editorial>
    </Dialog>
  );
}

export default DialogDisabledApp;
