import { NodeProps } from 'components/tree/Node';
import { CampusModel, PageModel } from 'models';
import MainMenuRenderer from './MainMenuRenderer';
import UserMenuRenderer from './UserMenuRenderer';
import NotificationMenuRenderer from './NotificationMenuRenderer';

export type ToolMenuBehaviorProps = NodeProps<{
  title: string;
  renderer?: string;
  campuses?: CampusModel[];
  referencePage?: PageModel;
}>;

const RENDERER_MAPPING = {
  main_menu: MainMenuRenderer,
  user_menu: UserMenuRenderer,
  notification_menu: NotificationMenuRenderer,
};

type Renderer = keyof typeof RENDERER_MAPPING;

function isRenderer(renderer?: string): renderer is Renderer {
  return renderer !== undefined && renderer in RENDERER_MAPPING;
}

function ToolMenuBehavior(props: ToolMenuBehaviorProps): JSX.Element | null {
  const {
    behavior: { renderer },
  } = props;
  if (isRenderer(renderer)) {
    const Renderer = RENDERER_MAPPING[renderer];

    return <Renderer {...props} />;
  }

  console.error(`Renderer '${renderer}' is not a valid header menu renderer`);

  return null;
}

export default ToolMenuBehavior;
