import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgRoombooking(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 17.016 17.016"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M16.122 1.578h-2.051v2.529c0 .566-.508 1.027-1.133 1.027h-.773c-.625 0-1.133-.46-1.133-1.027V1.578H5.984v2.529c0 .566-.509 1.027-1.133 1.027h-.774c-.625 0-1.133-.46-1.133-1.027V1.578H.892a.618.618 0 00-.618.618v14.201c0 .34.277.619.618.619h15.23a.62.62 0 00.619-.619V2.196a.618.618 0 00-.619-.618zm-.216 14.569a.195.195 0 01-.195.193H1.305a.195.195 0 01-.195-.193V7.393c0-.107.087-.195.195-.195H15.71c.107 0 .195.088.195.195l.001 8.754z" />
      <path d="M4.077 4.121h.773c.284 0 .515-.183.515-.408V.408C5.365.182 5.134 0 4.85 0h-.773c-.285 0-.516.182-.516.408v3.306c0 .224.231.407.516.407zM12.165 4.121h.773c.285 0 .516-.183.516-.408V.408c0-.226-.23-.408-.516-.408h-.773c-.285 0-.516.182-.516.408v3.306c.002.224.231.407.516.407zM12.047 8.406a.344.344 0 00-.486 0l-4.03 4.032-2.076-2.092a.343.343 0 00-.484 0l-.728.727a.343.343 0 000 .484l3.043 3.064a.345.345 0 00.485 0l5.003-5.004a.342.342 0 000-.486l-.727-.725z" />
    </svg>
  );
}

export default forwardRef(SvgRoombooking);
