import NodeIterator from 'components/tree/NodeIterator';
import { NodeProps } from 'components/tree/Node';
import { PageModel } from 'models';
import ServiceList from 'components/ui/ServiceList/ServiceList';
import useMessages from 'components/hooks/useMessages';
import { useEffect, useRef, useState } from 'react';
import ServiceLinkView from './ServiceLinkView';
import ServiceListContext, { useServiceList } from './ServiceListContext';

export type MosaicBehaviorProps = NodeProps<{
  title: string;
  allPage: PageModel | null;
}>;

function MosaicBehavior({ content, behavior: { title } }: MosaicBehaviorProps): JSX.Element {
  const [showFavorite, setShowFavorite] = useState(false);
  const messages = useMessages(
    (t) => ({
      all: t('service_list.all'),
      favorite: t('service_list.favorite'),
      more: t('feed.more'),
    }),
    [],
  );
  const serviceList = useServiceList();

  // Show all favorites initially if there is at least one favorite.
  const favoriteServicesRef = useRef(serviceList.favoriteServices);
  useEffect(() => {
    if (favoriteServicesRef.current.length) {
      setShowFavorite(true);
    }
  }, []);

  const shownServices = showFavorite
    ? content.filter((service) => serviceList.isFavorite(service.identifier))
    : content;

  // const more = allPage ? (
  //   <Button as={Link} kind="secondary" to={allPage.slug}>
  //     {messages.more}
  //   </Button>
  // ) : null;

  return (
    <ServiceListContext.Provider value={serviceList}>
      <ServiceList
        title={title}
        messages={messages}
        showFavorite={showFavorite}
        onShowFavoriteChange={setShowFavorite}
      >
        {/* {more} */}
        <NodeIterator nodes={shownServices} view={ServiceLinkView} />
      </ServiceList>
    </ServiceListContext.Provider>
  );
}

export default MosaicBehavior;
