import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgElearning2(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 396.48 396.48"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M378.96 23.28H17.52C7.862 23.324.044 31.142 0 40.8v249.28c.044 9.658 7.862 17.476 17.52 17.52h123.2v49.6h-27.2a8 8 0 000 16h156.32a8 8 0 000-16h-14.08v-49.6h123.2c9.658-.044 17.476-7.862 17.52-17.52V40.8c-.044-9.658-7.862-17.476-17.52-17.52zm-139.2 334h-83.04v-49.6h83.04v49.6zm140.72-67.12a1.52 1.52 0 01-1.52 1.52H17.52a1.52 1.52 0 01-1.52-1.52V40.8c0-.839.681-1.52 1.52-1.52h361.44c.839 0 1.52.68 1.52 1.52v249.36z" />
      <path d="M302.88 246.24V97.6a7.998 7.998 0 00-3.44-6.56 98.083 98.083 0 00-52.4-14.8 105.595 105.595 0 00-48 12.24 101.037 101.037 0 00-48-12.24 106.72 106.72 0 00-52.72 14.4 8 8 0 00-4.08 6.96v148.64a8 8 0 0011.92 6.96 90.878 90.878 0 0144.08-12 83.443 83.443 0 0143.28 11.92l1.2.48h.64c.912.39 1.889.607 2.88.64a8.008 8.008 0 001.92 0h.48l1.28-.48a90.958 90.958 0 0144.88-12.32 83.443 83.443 0 0143.28 11.92 8 8 0 0012.8-7.12zm-112.64-12.8a101.996 101.996 0 00-40-8 104.006 104.006 0 00-40.64 8.32V102.24a89.509 89.509 0 0140.64-10 85.833 85.833 0 0140 10v131.2zm96.64.32a101.996 101.996 0 00-40-8 103.995 103.995 0 00-40.64 8V102.24a89.524 89.524 0 0140.8-10.16 85.832 85.832 0 0140 9.92l-.16 131.76z" />
    </svg>
  );
}

export default forwardRef(SvgElearning2);
