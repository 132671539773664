import { useEffect, useState } from 'react';

export type UseResizeState = {
  width: number;
  height: number;
  ratiowh: number;
  ratiohw: number;
  rect?: {
    width: number;
    height: number;
    left: number;
    right: number;
    top: number;
    bottom: number;
  };
};

function useResize(
  elementRef: React.RefObject<HTMLDivElement>,
  onResize?: (state: UseResizeState) => void,
): UseResizeState {
  let [state, setState] = useState<UseResizeState>({
    width: 0,
    height: 0,
    ratiowh: 0,
    ratiohw: 0,
    rect: undefined,
  });

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const handleResize = (): void => {
      if (!elementRef.current) {
        return;
      }

      const clientRect = elementRef.current.getBoundingClientRect();
      const state: UseResizeState = {
        width: window.innerWidth,
        height: window.innerHeight,
        ratiowh: window.innerWidth / window.innerHeight,
        ratiohw: window.innerHeight / window.innerWidth,

        // DOM API does not allow for a shallow copy, so we have to manually set them
        rect: {
          width: clientRect.width,
          height: clientRect.height,
          left: clientRect.left,
          right: clientRect.right,
          top: clientRect.top,
          bottom: clientRect.bottom,
        },
      };

      setState(state);
    };

    handleResize();

    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize, false);
    };
  }, [elementRef]);

  useEffect(() => {
    if (onResize) {
      onResize(state);
    }
  }, [state, onResize]);

  return state;
}

export default useResize;
