import React, { useCallback, useEffect, useState, useRef } from 'react';
import cdmApi, { createCancelSource, resolveError, isCancel } from 'services/cdmApi';
import Dialog from 'components/ui/Dialog';
import Editorial from 'components/ui/Editorial';
import Button from 'components/ui/Button';
import Popover from 'components/ui/Popover';
import Spinner from 'components/ui/Spinner';
import TextInput from 'components/form/TextInput';
import useTranslate from 'components/hooks/useTranslate';
import useErrorMessage from 'components/hooks/useErrorMessage';
import useDebounce from 'components/hooks/useDebounce';
import { ErrorModel, isTokenModel } from 'models';
import Form from 'components/form/Form';
import FormField from 'components/form/FormField';
import Spacer from 'components/ui/Spacer';
import Anchor from 'components/ui/Anchor';

type AccountModel = {
  id: string;
  text: string | null;
};

type AccountProps = {
  onSelect: (account: AccountModel) => void;
  selected: boolean;
  account: AccountModel | null;
};

function Account({ onSelect, selected, account }: AccountProps): JSX.Element | null {
  return account !== null ? (
    <li role="option" aria-selected={selected}>
      <Anchor as={<button type="button" onClick={() => onSelect(account)} />}>
        <span> {account.text ? account.text : account.id}</span>
      </Anchor>
    </li>
  ) : null;
}

type DialogLoginAsProps = {
  open: boolean;
  onExit: () => void;
};

function DialogLoginAs({ open, onExit }: DialogLoginAsProps): JSX.Element {
  const t = useTranslate();
  const messages = {
    label: t('dialog.loginAs.title'),
    close: t('label.cancel'),
    loading: t('label.loading'),
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorModel | null>(null);
  const errorMessage = useErrorMessage(error);
  const [accounts, setAccounts] = useState<AccountModel[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [term, setTerm] = useState<string | null>(null);
  const debouncedTerm = useDebounce(term);

  // Fetch accounts, and cancel previous call if needed
  useEffect(() => {
    const cancelSource = createCancelSource();

    async function fetch(): Promise<void> {
      if (debouncedTerm) {
        try {
          setLoading(true);

          const response = await cdmApi.get(`/loginas/search`, {
            cancelToken: cancelSource.token,
            params: { term: debouncedTerm },
          });

          setAccounts(response.data.results);
          setSelectedIndex(0);
          setLoading(false);
        } catch (error) {
          if (!isCancel(error)) {
            setError(resolveError(error));
          }
        } finally {
          setLoading(false);
        }
      }
    }

    fetch();

    return cancelSource.cancel;
  }, [debouncedTerm, setLoading]);

  // Navigate through accoungts with arrows and submit with enter
  const inputElement = inputRef.current;
  useEffect(() => {
    if (inputElement) {
      const onKeydown = function (event: KeyboardEvent): void {
        switch (event.key) {
          case 'Down':
          case 'ArrowDown':
            event.preventDefault();
            setSelectedIndex((index) => (index === accounts.length - 1 ? 0 : ++index));

            break;
          case 'Up':
          case 'ArrowUp':
            event.preventDefault();
            setSelectedIndex((index) => (index <= 0 ? accounts.length - 1 : --index));
            break;
        }
      };

      inputElement.addEventListener('keydown', onKeydown);

      return () => {
        inputElement.removeEventListener('keydown', onKeydown);
      };
    }
  }, [inputElement, accounts.length]);

  const handleSelectAccount = useCallback(async (account: AccountModel) => {
    if (account.id) {
      try {
        const response = await cdmApi.post(`/loginas/do/${account.id}`);
        if (isTokenModel(response.data)) {
          const codeToken = response.data.token;
          window.location.href = `/?codeToken=${codeToken}`;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const handleSubmit = useCallback(
    (event?: React.FormEvent) => {
      if (event) {
        event.preventDefault();
      }

      const account = accounts[selectedIndex];
      if (account) {
        handleSelectAccount(account);
      }
    },
    [accounts, handleSelectAccount, selectedIndex],
  );

  return (
    <Dialog
      as={Form}
      onSubmit={handleSubmit}
      open={open}
      width="md"
      messages={messages}
      title={messages.label}
      onExit={onExit}
    >
      <FormField label={t(`dialog.loginAs.label`)}>
        <TextInput
          ref={inputRef}
          autoFocus
          value={term}
          onValueChange={setTerm}
          placeholder={t(`dialog.loginAs.placeholder`)}
        />
      </FormField>
      {debouncedTerm ? (
        <Spacer block="lg">
          {loading ? (
            <Spinner aria-label={messages.loading} kind="secondary" />
          ) : accounts.length === 0 ? (
            <Editorial>
              <p>{errorMessage ?? t('dialog.loginAs.empty')}</p>
            </Editorial>
          ) : (
            <ul>
              {accounts.map((account, index) => (
                <Account
                  onSelect={handleSelectAccount}
                  account={account}
                  selected={index === selectedIndex}
                />
              ))}
            </ul>
          )}
        </Spacer>
      ) : null}
    </Dialog>
  );
}

export default DialogLoginAs;
