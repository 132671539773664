import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgPlane({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M30.39 2.21A4.27 4.27 0 0129.73 8l-5.62 5 1.31 14.51a1.45 1.45 0 01-.44 1.2l-1.15 1.15a1.48 1.48 0 01-2.13 0 1 1 0 01-.27-.38l-4.91-9.82-4.53 4 .82 5.62a1.77 1.77 0 01-1.42 2 1.69 1.69 0 01-1.85-1l-2.6-5.22-5.24-2.62a1.73 1.73 0 01-.76-2.34 1.76 1.76 0 011.8-.93l5.72.83 4-4.53-9.83-4.92a1.47 1.47 0 01-.65-2 1 1 0 01.27-.38L3.39 7a1.45 1.45 0 011.2-.44L19.1 7.88l5-5.78a4.07 4.07 0 015.78-.38 2.08 2.08 0 01.51.49z" />
    </svg>
  );
}

export default forwardRef(SvgPlane);
