import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgSearch({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 35 37"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M9 1.95A7.25 7.25 0 001.75 9.2v3H.25v-3A8.75 8.75 0 019 .45h3v1.5H9zm17 0h-3V.45h3a8.75 8.75 0 018.75 8.75v3h-1.5v-3A7.25 7.25 0 0026 1.95zm-9.2 10a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zm-6.75 5.25a6.75 6.75 0 1111.595 4.7l4.124 4.812-1.138.976-4.143-4.833A6.75 6.75 0 0110.05 17.2zm-8.3 8v3A7.25 7.25 0 009 35.45h3v1.5H9A8.75 8.75 0 01.25 28.2v-3h1.5zm31.5 3v-3h1.5v3A8.75 8.75 0 0126 36.95h-3v-1.5h3a7.25 7.25 0 007.25-7.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default forwardRef(SvgSearch);
