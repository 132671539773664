import Link from 'components/utils/Link';
import useTranslate from 'components/hooks/useTranslate';
import { ErrorModel } from 'models';
import Title from 'components/ui/Title/Title';
import Editorial from 'components/ui/Editorial';
import Button from 'components/ui/Button';
import { formatError } from 'components/hooks/useErrorMessage';
import BrightspaceIcon from 'components/ui/Icon/service/Brightspace';
import LearningHubIcon from 'components/ui/Icon/service/LearningHub';
import OutlookIcon from 'components/ui/Icon/service/Outlook';

export type UserNotFoundProps = {
  error: ErrorModel;
};

function UserNotFound({ error }: UserNotFoundProps): JSX.Element {
  const t = useTranslate();

  const outlookButton = (
    <Button
      as={Link}
      to="https://outlook.office365.com"
      icon={OutlookIcon}
      iconOnly
      kind="secondary"
      title="Outlook"
    >
      Outlook
    </Button>
  );

  const brightspaceButton = (
    <Button
      as={Link}
      to="https://emlyon.brightspace.com"
      icon={BrightspaceIcon}
      iconOnly
      kind="secondary"
      title="Brightspace"
    >
      Brightspace
    </Button>
  );

  const learninghubButton = (
    <Button
      as={Link}
      to="https://learninghub.em-lyon.com/EXPLOITATION"
      icon={LearningHubIcon}
      iconOnly
      kind="secondary"
      title="Learning Hub"
    >
      Learning Hub
    </Button>
  );

  const toolbar = (
    <>
      {outlookButton}
      &nbsp;
      {brightspaceButton}
      &nbsp;
      {learninghubButton}
    </>
  );

  const values = {
    title: (chunks: any) => <Title size={2}>{chunks}</Title>,
    p: (chunks: any) => <p>{chunks}</p>,
    strong: (chunks: any) => <strong>{chunks}</strong>,
    home: (chunks: any) => <Link to="/">{chunks}</Link>,
    support: () => <Link to="https://support.em-lyon.com">https://support.em-lyon.com</Link>,
    brightspace: () => brightspaceButton,
    learninghub: () => learninghubButton,
  };

  return (
    <>
      <Editorial>{formatError(t, error, values)}</Editorial>
      {toolbar}
    </>
  );
}

export default UserNotFound;
