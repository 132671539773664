import { memo } from 'react';
import { DurationUnit } from 'luxon';
import { ParsableDate, dateFormat, dateParse, DateFormatPreset } from './DateFormat';

export type DateIntervalProps = {
  start: ParsableDate;
  end: ParsableDate;
  diff?: DurationUnit;
  fullPreset?: DateFormatPreset;
  diffPreset?: DateFormatPreset;
  setZone?: boolean;
};

function DateInterval({
  diff = 'day',
  fullPreset = 'DATETIME_SHORT',
  diffPreset = 'TIME_SIMPLE',
  setZone,
  ...props
}: DateIntervalProps): JSX.Element {
  const start = dateParse(props.start, { setZone });
  const end = dateParse(props.end, { setZone });

  return (
    <>
      <time title={dateFormat(start, 'DATETIME_SHORT')} dateTime={String(start)} {...props}>
        {dateFormat(start, fullPreset)}
      </time>
      &nbsp;-&nbsp;
      <time title={dateFormat(end, 'DATETIME_SHORT')} dateTime={String(end)} {...props}>
        {dateFormat(end, start.hasSame(end, diff) ? diffPreset : fullPreset)}
      </time>
    </>
  );
}

export default memo(DateInterval);
