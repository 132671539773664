import ServiceLinkView from 'components/tree/behavior/link/ServiceLinkView';
import ServiceSlider from 'components/ui/ServiceList/ServiceSlider';
import ServiceListContext, { useServiceList } from '../link/ServiceListContext';
import { useAzureSearchContext } from './AzureSearchProvider';

export type SearchServiceListProps = {
  shrunk?: boolean;
};

function SearchServiceList({ shrunk }: SearchServiceListProps): JSX.Element {
  const serviceList = useServiceList();
  const { serviceResponse } = useAzureSearchContext();

  return (
    <>
      {serviceResponse?.documents?.length ? (
        <ServiceListContext.Provider value={serviceList}>
          <ServiceSlider shrunk={shrunk}>
            {serviceResponse.documents.map((document) => (
              <ServiceLinkView
                key={document.id}
                identifier={document.id}
                to={document.url ?? undefined}
                title={document.title}
                titleLong={document.longTitle ?? undefined}
                description={document.alternativeDescription ?? document.description ?? undefined}
                disabled={Boolean(document.alternativeDescription)}
                icon={document.icon ?? undefined}
                shrunk={shrunk}
              />
            ))}
          </ServiceSlider>
        </ServiceListContext.Provider>
      ) : null}
    </>
  );
}

export default SearchServiceList;
