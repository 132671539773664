import { Children, ReactElement, ReactNode } from 'react';
import { Menu, MenuButton, MenuItem, useMenuState } from 'ariakit';
import classNames from 'classnames';
import Icon from 'components/ui/Icon/Icon';
import UserIcon from 'components/ui/Icon/actions/User';
import './UserMenu.scss';

type UserMenuProps = {
  name?: ReactNode;
  title?: string;
  special?: boolean;
  children?: ReactElement[];
};

function UserMenu({ name, title, special, children }: UserMenuProps): JSX.Element {
  const menu = useMenuState({ animated: true, placement: 'bottom-end' });

  return (
    <div className={classNames('UserMenu', { 'is-special': special })}>
      <MenuButton state={menu} className="UserMenu-button">
        <span className="UserMenu-name">{name}</span>
        <Icon as={UserIcon} />
      </MenuButton>
      <Menu state={menu} className="UserMenu-content" aria-label={title}>
        {Children.map(children, (child, i) =>
          child ? <MenuItem {...menu} as={child.type} key={i} {...child.props} /> : null,
        )}
      </Menu>
    </div>
  );
}

export default UserMenu;
