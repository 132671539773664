import { ReactNode } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Disclosure, DisclosureContent, useDisclosureState } from 'ariakit';
import SwitchField from 'components/form/SwitchField';
import Container from '../Container';
import ChevronUp from '../Icon/actions/ChevronUp';
import Icon from '../Icon';
import './ServiceList.scss';

export type ServiceListProps = {
  children: ReactNode;
  title?: ReactNode;
  showFavorite?: boolean;
  onShowFavoriteChange?(value: boolean): void;
  messages?: {
    all?: ReactNode;
    favorite?: ReactNode;
  };
};

function ServiceList({
  children,
  title,
  showFavorite,
  onShowFavoriteChange,
  messages,
}: ServiceListProps): JSX.Element {
  const disclosure = useDisclosureState({ animated: 300 });
  const isSmall = useMediaQuery({ query: '(max-width: 768px)' });

  const switcher = onShowFavoriteChange ? (
    <span className="ServiceList-switcher">
      <SwitchField
        value={showFavorite}
        onValueChange={onShowFavoriteChange}
        label={messages?.all}
        endLabel={messages?.favorite}
        kind="primary"
        variant="simple"
      />
    </span>
  ) : null;

  const panel = (
    <div className="ServiceList-panel">
      <Container withBackground>
        <div className="ServiceList-grid">
          {title && !isSmall ? (
            <div className="ServiceList-highlight">
              <span className="ServiceList-highlightTitle">{title}</span>
              {switcher}
            </div>
          ) : null}
          {children}
        </div>
      </Container>
    </div>
  );

  return (
    <div className={classnames('ServiceList', { 'is-open': disclosure.open })}>
      {title && isSmall ? (
        <>
          <div className="ServiceList-sticky">
            <Disclosure state={disclosure} as="button" type="button" className="ServiceList-button">
              <span className="ServiceList-title">
                <Icon as={ChevronUp} className="ServiceList-chevron" />
                {title}
              </span>
            </Disclosure>
            {switcher}
          </div>
          <DisclosureContent state={disclosure} as={panel.type} {...panel.props} />
        </>
      ) : (
        panel
      )}
    </div>
  );
}

export default ServiceList;
