import HeaderNavigation from 'components/ui/Header/HeaderNavigation';
import NodeIterator from '../NodeIterator';
import { NodeProps } from '../Node';

export type HeaderNavigationZoneProps = NodeProps;

function HeaderNavigationZone({ content }: HeaderNavigationZoneProps): JSX.Element {
  return (
    <HeaderNavigation>
      <NodeIterator nodes={content} />
    </HeaderNavigation>
  );
}

export default HeaderNavigationZone;
