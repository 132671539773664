import React, { useCallback, forwardRef } from 'react';
import classNames from 'classnames';
import { Override } from 'types';
import useFormInput, { FormInputProps } from './useFormInput';
import './FormInput.scss';

type TextType<T> = T extends 'number' ? number : string;

type BaseProps<T> = {
  type?: T;
  size?: 'md' | 'lg';
} & FormInputProps<TextType<T> | null, HTMLInputElement>;

export type TextInputProps<T> = Override<React.ComponentPropsWithoutRef<'input'>, BaseProps<T>>;

function TextInput<T extends string>(
  props: TextInputProps<T>,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  const {
    type,
    value,
    touched,
    error,
    className,
    size,
    placeholder,
    onValueChange,
    ...otherProps
  } = useFormInput(props);
  const resolvedValue = value === null ? '' : value;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onValueChange) {
        const { value, name } = event.target;
        onValueChange(
          value === ''
            ? null
            : type === 'number'
            ? (parseInt(value, 10) as TextType<T>)
            : (value as TextType<T>),
          name,
          event,
        );
      }
    },
    [onValueChange, type],
  );

  return (
    <input
      ref={ref}
      className={classNames('FormInput', className, { [`is-${size}`]: Boolean(size) })}
      value={resolvedValue}
      onChange={handleChange}
      placeholder={placeholder}
      type={type}
      {...otherProps}
    />
  );
}

// 'as' needed because forwardRef does not keep the generic type...
export default forwardRef(TextInput) as <T extends string>(
  props: TextInputProps<T> & { ref?: React.Ref<HTMLInputElement> },
) => JSX.Element;
