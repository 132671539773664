import { cancelled, takeLeading, call, put, fork } from 'redux-saga/effects';
import isArrayOf from 'types/isArrayOf';
import {
  requestCertificates,
  receiveCertificates,
  errorCertificates,
} from 'actions/certificatesActions';
import { CertificateModel } from 'models';
import cdmApi, { createCancelSource, resolveError } from '../services/cdmApi';

function isCertificate(certificate: unknown): certificate is CertificateModel {
  return typeof certificate === 'object' && certificate !== null && 'code' in certificate;
}

export function* requestCertificatesTask(action: ReturnType<typeof requestCertificates>): any {
  const cancelSource = createCancelSource();

  try {
    const response = yield call(cdmApi.get, '/certificates', {
      cancelToken: cancelSource.token,
    });

    const certificates = response && response.data ? response.data.certificates : undefined;

    if (isArrayOf(certificates, isCertificate)) {
      yield put(receiveCertificates(certificates));
    } else {
      throw new Error();
    }
  } catch (error) {
    const err = resolveError(error);
    yield put(errorCertificates(err));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

export function* watchRequestCertificates() {
  yield takeLeading(requestCertificates.type, requestCertificatesTask);
}

export default function* treeSaga() {
  yield fork(watchRequestCertificates);
}
