/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classnames from 'classnames';
import { Override } from 'types';
import { Kind } from '../types.d';
import './Anchor.scss';

export type BaseProps = {
  as?: React.ReactElement;
  kind?: Kind;
};

export type AnchorProps = Override<React.ComponentPropsWithoutRef<'a'>, BaseProps>;

function Anchor({ as = <a />, children, className, kind, ...props }: AnchorProps): JSX.Element {
  return (
    <as.type
      {...as.props}
      {...props}
      className={classnames('Anchor', className, as.props.className, {
        [`is-${kind}`]: kind,
      })}
    >
      {children}
    </as.type>
  );
}

export default Anchor;
