import { useEffect } from 'react';
import useAutoId from 'components/hooks/useAutoId';
import useToggable from 'components/hooks/useToggable';
import Icon from 'components/ui/Icon';
import { Override } from 'types';
import classNames from 'classnames';
import Caret from '../Icon/actions/ChevronDown';
import { useAccordionItem } from './AccordionGroup';
import './AccordionPanel.scss';

export type AccordionPanelProps = Override<
  React.ComponentPropsWithoutRef<'div'>,
  {
    toggable?: boolean;
    element?: React.ReactElement;
    id?: string;
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    actions?: React.ReactNode;
    initialOpen?: boolean;
    onToggle?(open: boolean): void;
    children?: React.ReactNode;
    headerProps?: React.ComponentPropsWithoutRef<'dt'>;
    contentProps?: React.ComponentPropsWithoutRef<'dd'>;
    messages?: {
      open?: string;
    };
  }
>;

function AccordionPanel({
  id: defaultId,
  title,
  subtitle,
  children,
  actions,
  initialOpen = false,
  toggable = true,
  onToggle,
  headerProps,
  contentProps,
  className,
  messages,
  ...props
}: AccordionPanelProps): JSX.Element {
  const id = useAutoId(defaultId, 'accordionPanel');
  const idButton = `${id}-control`;

  const [open, handleToggle, handleOpen, handleClose, setOpen] = useToggable(initialOpen);
  useAccordionItem(open, handleOpen, handleClose);

  useEffect(() => {
    setOpen(initialOpen);
  }, [initialOpen, setOpen]);

  useEffect(() => {
    if (onToggle) {
      onToggle(open);
    }
  }, [onToggle, open]);

  return (
    <div
      className={classNames('AccordionPanel', className, {
        'is-toggable': toggable,
        'is-open': open,
      })}
      {...props}
    >
      <dt {...headerProps}>
        <button
          id={idButton}
          onClick={toggable ? handleToggle : undefined}
          aria-expanded={open ? 'true' : 'false'}
          aria-controls={id}
          aria-label={messages?.open}
          type="button"
          className="AccordionPanel-titleButton"
        >
          <span className="AccordionPanel-title">{title}</span>
          {subtitle ? <span className="AccordionPanel-subtitle">{subtitle}</span> : null}
        </button>
        {actions ? <div className="AccordionPanel-actions">{actions}</div> : null}
        <button
          onClick={toggable ? handleToggle : undefined}
          aria-expanded={open ? 'true' : 'false'}
          aria-controls={id}
          aria-label={messages?.open}
          type="button"
          className="AccordionPanel-iconButton"
        >
          {toggable ? <Icon as={Caret} className="AccordionPanel-icon" /> : null}
        </button>
      </dt>
      <dd
        hidden={!open || !toggable}
        id={id}
        role="region"
        aria-labelledby={idButton}
        {...contentProps}
      >
        {children}
      </dd>
    </div>
  );
}

export default AccordionPanel;
