import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgStar({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82.5L5.403 5.398 0 6.18l3.91 3.814-.924 5.382 4.834-2.54 4.834 2.54-.924-5.382 3.91-3.809-5.403-.786L7.82.5z"
      />
    </svg>
  );
}

export default forwardRef(SvgStar);
