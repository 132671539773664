import React from 'react';
import NodeIterator from './NodeIterator';
import { NodeProps } from './Node';

export type BlockProps = NodeProps;

function Block({ content = [], help, rootProps }: BlockProps): JSX.Element {
  const children = <NodeIterator nodes={content} />;

  if (help) {
    return <div {...rootProps}>{children}</div>;
  }

  return children;
}

export default Block;
