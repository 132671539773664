import {
  requestActivityList,
  receiveActivityList,
  errorActivityList,
  requestActivityDetail,
  receiveActivityDetail,
  errorActivityDetail,
  requestActivitySubscribe,
  receiveActivitySubscribe,
  errorActivitySubscribe,
  receiveActivityUpdate,
  errorActivityUpdate,
  requestActivityUpdate,
  errorActivityCreate,
  receiveActivityCreate,
  requestActivityCreate,
  resetActivityCreate,
  receiveActivityDelete,
  errorActivityDelete,
  requestActivityDelete,
} from 'actions/activityActions';
import { ErrorModel, PagerModel } from 'models';
import { ActivityModel, ActivityListParams } from 'models/activity';
import { createReducer } from './reducerUtils';

export type ActivityListState = Readonly<{
  activities?: ActivityModel[];
  pager?: PagerModel<ActivityModel>;
  filters: ActivityListParams;
  error?: ErrorModel;
  fetching: boolean;
}>;

export type ActivityDetailState = Readonly<{
  activity?: ActivityModel;
  error?: ErrorModel;
  fetching: boolean;
  subscribing: boolean;
  updating: boolean;
  deleting: boolean;
}>;

export type ActivityState = Readonly<{
  lists: {
    [scope in string]?: ActivityListState;
  };
  details: {
    [identifier in number]?: ActivityDetailState;
  };
  create: {
    data?: Partial<ActivityModel>;
    activity?: ActivityModel;
    creating: boolean;
    error?: ErrorModel;
  };
}>;

export const initialListState: ActivityListState = {
  activities: undefined,
  pager: undefined,
  filters: {},
  error: undefined,
  fetching: false,
};

export const initialDetailState: ActivityDetailState = {
  activity: undefined,
  error: undefined,
  fetching: false,
  subscribing: false,
  updating: false,
  deleting: false,
};

export const initialState: ActivityState = {
  lists: {},
  details: {},
  create: {
    data: undefined,
    activity: undefined,
    creating: false,
    error: undefined,
  },
};

// LIST

export function requestActivityListReducer(
  state: ActivityState,
  action: ReturnType<typeof requestActivityList>,
): ActivityState {
  return {
    ...state,
    lists: {
      ...state.lists,
      [action.payload.scope]: {
        ...initialListState,
        ...state.lists[action.payload.scope],
        error: undefined,
        fetching: true,
      },
    },
  };
}

export function receiveActivityListReducer(
  state: ActivityState,
  action: ReturnType<typeof receiveActivityList>,
): ActivityState {
  return {
    ...state,
    lists: {
      ...state.lists,
      [action.payload.scope]: {
        ...initialListState,
        ...state.lists[action.payload.scope],
        activities: action.payload.pager.items,
        pager: action.payload.pager,
        fetching: false,
      },
    },
  };
}

export function errorActivityListReducer(
  state: ActivityState,
  action: ReturnType<typeof errorActivityList>,
): ActivityState {
  return {
    ...state,
    lists: {
      ...state.lists,
      [action.payload.scope]: {
        ...initialListState,
        ...state.lists[action.payload.scope],
        error: action.payload.error,
        fetching: false,
      },
    },
  };
}

// CREATE

export function requestActivityCreateReducer(
  state: ActivityState,
  action: ReturnType<typeof requestActivityCreate>,
): ActivityState {
  return {
    ...state,
    create: {
      ...state.create,
      data: action.payload.activity,
      activity: undefined,
      error: undefined,
      creating: true,
    },
  };
}

export function receiveActivityCreateReducer(
  state: ActivityState,
  action: ReturnType<typeof receiveActivityCreate>,
): ActivityState {
  return {
    ...state,
    create: {
      ...state.create,
      activity: action.payload.activity,
      creating: false,
    },
  };
}

export function errorActivityCreateReducer(
  state: ActivityState,
  action: ReturnType<typeof errorActivityCreate>,
): ActivityState {
  return {
    ...state,
    create: {
      ...state.create,
      error: action.payload.error,
      creating: false,
    },
  };
}

export function resetActivityCreateReducer(
  state: ActivityState,
  action: ReturnType<typeof resetActivityCreate>,
): ActivityState {
  return {
    ...state,
    create: {
      ...initialState.create,
    },
  };
}

// DETAIL

export function requestActivityDetailReducer(
  state: ActivityState,
  action: ReturnType<typeof requestActivityDetail>,
): ActivityState {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.activityId]: {
        ...initialDetailState,
        ...state.details[action.payload.activityId],
        error: undefined,
        fetching: true,
      },
    },
  };
}

export function receiveActivityDetailReducer(
  state: ActivityState,
  action: ReturnType<typeof receiveActivityDetail>,
): ActivityState {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.activityId]: {
        ...initialDetailState,
        ...state.details[action.payload.activityId],
        activity: action.payload.activity,
        fetching: false,
      },
    },
  };
}

export function errorActivityDetailReducer(
  state: ActivityState,
  action: ReturnType<typeof errorActivityDetail>,
): ActivityState {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.activityId]: {
        ...initialDetailState,
        ...state.details[action.payload.activityId],
        error: action.payload.error,
        fetching: false,
      },
    },
  };
}

// UPDATE

export function requestActivityUpdateReducer(
  state: ActivityState,
  action: ReturnType<typeof requestActivityUpdate>,
): ActivityState {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.activityId]: {
        ...initialDetailState,
        ...state.details[action.payload.activityId],
        error: undefined,
        updating: true,
      },
    },
  };
}

export function receiveActivityUpdateReducer(
  state: ActivityState,
  action: ReturnType<typeof receiveActivityUpdate>,
): ActivityState {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.activityId]: {
        ...initialDetailState,
        ...state.details[action.payload.activityId],
        activity: action.payload.activity,
        updating: false,
      },
    },
  };
}

export function errorActivityUpdateReducer(
  state: ActivityState,
  action: ReturnType<typeof errorActivityUpdate>,
): ActivityState {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.activityId]: {
        ...initialDetailState,
        ...state.details[action.payload.activityId],
        error: action.payload.error,
        updating: false,
      },
    },
  };
}

// SUBSCRIBE

export function requestActivitySubscribeReducer(
  state: ActivityState,
  action: ReturnType<typeof requestActivitySubscribe>,
): ActivityState {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.activityId]: {
        ...initialDetailState,
        ...state.details[action.payload.activityId],
        error: undefined,
        subscribing: true,
      },
    },
  };
}

export function receiveActivitySubscribeReducer(
  state: ActivityState,
  action: ReturnType<typeof receiveActivitySubscribe>,
): ActivityState {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.activityId]: {
        ...initialDetailState,
        ...state.details[action.payload.activityId],
        activity: action.payload.activity,
        subscribing: false,
      },
    },
  };
}

export function errorActivitySubscribeReducer(
  state: ActivityState,
  action: ReturnType<typeof errorActivitySubscribe>,
): ActivityState {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.activityId]: {
        ...initialDetailState,
        ...state.details[action.payload.activityId],
        error: action.payload.error,
        subscribing: false,
      },
    },
  };
}

// DELETE

export function requestActivityDeleteReducer(
  state: ActivityState,
  action: ReturnType<typeof requestActivityDelete>,
): ActivityState {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.activityId]: {
        ...initialDetailState,
        ...state.details[action.payload.activityId],
        error: undefined,
        deleting: true,
      },
    },
  };
}

export function receiveActivityDeleteReducer(
  state: ActivityState,
  action: ReturnType<typeof receiveActivityDelete>,
): ActivityState {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.activityId]: {
        ...initialDetailState,
        ...state.details[action.payload.activityId],
        activity: undefined,
        deleting: false,
      },
    },
  };
}

export function errorActivityDeleteReducer(
  state: ActivityState,
  action: ReturnType<typeof errorActivityDelete>,
): ActivityState {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.activityId]: {
        ...initialDetailState,
        ...state.details[action.payload.activityId],
        error: action.payload.error,
        deleting: false,
      },
    },
  };
}

export default createReducer(initialState, {
  [requestActivityList.type]: requestActivityListReducer,
  [receiveActivityList.type]: receiveActivityListReducer,
  [errorActivityList.type]: errorActivityListReducer,
  [requestActivityCreate.type]: requestActivityCreateReducer,
  [receiveActivityCreate.type]: receiveActivityCreateReducer,
  [errorActivityCreate.type]: errorActivityCreateReducer,
  [resetActivityCreate.type]: resetActivityCreateReducer,
  [requestActivityDetail.type]: requestActivityDetailReducer,
  [receiveActivityDetail.type]: receiveActivityDetailReducer,
  [errorActivityDetail.type]: errorActivityDetailReducer,
  [requestActivityUpdate.type]: requestActivityUpdateReducer,
  [receiveActivityUpdate.type]: receiveActivityUpdateReducer,
  [errorActivityUpdate.type]: errorActivityUpdateReducer,
  [requestActivitySubscribe.type]: requestActivitySubscribeReducer,
  [receiveActivitySubscribe.type]: receiveActivitySubscribeReducer,
  [errorActivitySubscribe.type]: errorActivitySubscribeReducer,
  [requestActivityDelete.type]: requestActivityDeleteReducer,
  [receiveActivityDelete.type]: receiveActivityDeleteReducer,
  [errorActivityDelete.type]: errorActivityDeleteReducer,
});
