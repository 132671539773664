import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgMakerslab(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 278.75 273.75"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M35.356 241.553c-.369-.369-.671-46.526-.671-102.572v-101.9l68.475 43.134.123-43.316 68.391 42.316-.035-41.74 70.05 44.24-.252 80-.252 80-102.579.255c-56.418.14-102.88-.048-103.25-.417z" />
    </svg>
  );
}

export default forwardRef(SvgMakerslab);
