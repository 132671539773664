import { takeEvery, call, put, fork } from 'redux-saga/effects';
import cdmApi, { resolveError } from 'services/cdmApi';
import { requestData, receiveData, errorData } from 'actions/dataActions';

export function* requestDataTask(action: ReturnType<typeof requestData>): any {
  const { keys, params } = action.payload;

  try {
    const response = yield call(cdmApi.get, `/data?${keys.join('&')}`, {
      params,
    });

    yield put(receiveData(response.data));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorData(err));
  }
}

export function* watchRequestData() {
  yield takeEvery(requestData.type, requestDataTask);
}

export default function* dataSaga() {
  yield fork(watchRequestData);
}
