import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import FlexCell from 'components/grid/FlexCell';
import { Gutter } from 'components/ui/types.d';
import { Override } from 'types';
import FormLabel from './FormLabel';
import './FormControl.scss';

export type RootProps = {
  label?: React.ReactNode;
  labelProps?: Omit<React.ComponentPropsWithoutRef<typeof FormLabel>, 'label'>;
  row?: boolean;
  inline?: boolean;
  outline?: boolean;
  indent?: boolean;
  flat?: boolean;
  gap?: Gutter;
  wrap?: boolean;
};

export type FormControlProps = Override<React.ComponentPropsWithRef<typeof FlexCell>, RootProps>;

function FormControl({
  children,
  label,
  labelProps,
  className,
  row,
  inline,
  outline,
  indent,
  flat,
  gap,
  wrap,
  style,
  ...props
}: FormControlProps): JSX.Element {
  const rootStyle = {
    '--formControl-gutter': gap ? `var(--t-gutter-${gap})` : undefined,
    ...style,
  } as CSSProperties;

  return (
    <FlexCell
      className={classNames('FormControl', className, {
        'is-row': row,
        'is-inline': inline,
        'is-outline': outline,
        'is-indent': indent,
        'is-flat': flat,
        'is-wrap': wrap,
      })}
      style={rootStyle}
      {...props}
    >
      {label ? <FormLabel label={label} {...labelProps} /> : null}
      {children}
    </FlexCell>
  );
}

export default FormControl;
