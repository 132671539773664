import { useEffect, useMemo } from 'react';
import { useFormikContext, FieldValidator, FieldInputProps } from 'formik';
import { useFormFieldContext } from './FormField';

export type ValueChangeEventHandler<V, E = HTMLInputElement | null> = (
  value: V,
  name?: string | null,
  originalEvent?: E extends null ? null : React.ChangeEvent<E>,
) => void;

export type FormInputProps<V, E> = {
  id?: string;
  name?: string;
  value?: V;
  onValueChange?: ValueChangeEventHandler<V, E>;
  required?: boolean;
  touched?: boolean;
  error?: string | null;
  validate?: FieldValidator;
} & Partial<Omit<FieldInputProps<V>, 'value' | 'name' | 'checked'>>;

function useFormInput<V, E, Rest>({
  id,
  name,
  required,
  validate,
  ...props
}: FormInputProps<V, E> & Rest) {
  // Form field context used to share input state with its label and error components
  const { id: defaultId, setContext } = useFormFieldContext();

  // Optionnally, handle a Formik context if name is provided
  const { getFieldProps, getFieldMeta, registerField, unregisterField } = useFormikContext() ?? {};

  const _props = props as any;
  const { value, ...formikProps } = useMemo(() => {
    const field =
      name && getFieldProps
        ? getFieldProps({ name, value: _props.value, type: _props.type, multiple: _props.multiple })
        : undefined;

    if (field) {
      return field;
    }

    return { value: _props.value };
  }, [getFieldProps, name, _props.multiple, _props.type, _props.value]);

  const { error, touched } = useMemo(() => {
    const meta = name && getFieldMeta ? getFieldMeta(name) : undefined;
    const error = meta?.error ?? props.error;
    const touched = meta?.touched ?? props.touched;

    return { error, touched };
  }, [getFieldMeta, name, props.error, props.touched]);

  // Register the custom validation if any was provided
  useEffect(() => {
    // @ts-ignore
    if (registerField && unregisterField && name && validate) {
      registerField(name, {
        validate,
      });

      return () => {
        unregisterField(name);
      };
    }
  }, [name, registerField, unregisterField, validate]);

  useEffect(() => {
    if (setContext) {
      setContext((previous) => ({
        id: id ?? previous.id,
        value,
        touched,
        error,
        required,
      }));
    }
  }, [value, touched, error, required, id, setContext]);

  return {
    id: id ?? defaultId,
    name,
    value,
    required,
    ...props,
    ...formikProps,
    touched,
    error,
  };
}

export default useFormInput;
