import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export type HeaderContextValue = {
  transparent: boolean;

  /**
   * Returns a function to set the original value
   */
  setTransparent(transparent: boolean): () => void;
};

export const HeaderContext = createContext<HeaderContextValue>({
  transparent: false,
  setTransparent() {
    return () => {};
  },
});

export function useHeaderContext(): HeaderContextValue {
  return useContext(HeaderContext);
}

export function useTransparentHeader(transparent: boolean = true): void {
  const { setTransparent } = useHeaderContext();

  useEffect(() => {
    if (transparent) {
      return setTransparent(true);
    }
  }, [setTransparent, transparent]);
}

export type HeaderProviderProps = {
  children: ReactNode;
};

function HeaderProvider({ children }: HeaderProviderProps): JSX.Element {
  const [transparent, setTransparent] = useState(false);
  const transparentRef = useRef(transparent);

  useEffect(() => {
    transparentRef.current = transparent;
  }, [transparent]);

  const handleTransparentChange = useCallback((transparent: boolean) => {
    const previousTransparent = transparentRef.current;
    setTransparent(transparent);

    return () => {
      setTransparent(previousTransparent);
    };
  }, []);

  return (
    <HeaderContext.Provider value={{ transparent, setTransparent: handleTransparentChange }}>
      {children}
    </HeaderContext.Provider>
  );
}

export default HeaderProvider;
