import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from 'components/ui/Dialog';
import useTranslate from 'components/hooks/useTranslate';
import { updateUser } from 'actions/userActions';
import CheckboxField from 'components/form/CheckboxField';
import Spinner from 'components/ui/Spinner';
import useData from 'components/hooks/useData';
import useMessages from 'components/hooks/useMessages';
import Editorial from 'components/ui/Editorial';
import Button from 'components/ui/Button';
import Form from 'components/form/Form';

export type DialogPolicyProps = {
  open: boolean;
  onAccept?(): void;
  onClose?(): void;
};

function DialogPolicy({ open, onAccept, onClose }: DialogPolicyProps): JSX.Element {
  const t = useTranslate();
  const dispatch = useDispatch();
  const { privacyPolicy = undefined } = useData(['privacyPolicy']);
  const messages = useMessages(
    (t) => ({
      label: t('dialog.policy.title'),
      close: t('label.cancel'),
      loading: t('label.loading'),
    }),
    [],
  );
  const [accepted, setAccepted] = useState(false);

  const handleValueChange = useCallback((value: boolean) => {
    setAccepted(value);
  }, []);

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      if (accepted) {
        if (onAccept) {
          onAccept();
        } else {
          dispatch(updateUser({ hasReadPolicy: true }));
        }
      }
    },
    [accepted, dispatch, onAccept],
  );

  const actions = privacyPolicy ? (
    <>
      <div>
        <CheckboxField
          label={<div dangerouslySetInnerHTML={{ __html: t('dialog.policy.label') }} />}
          value={accepted}
          onValueChange={handleValueChange}
          kind="secondary"
        />
      </div>
      <div>
        <Button type="submit" disabled={!accepted} kind="secondary">
          {t('label.submit')}
        </Button>
      </div>
    </>
  ) : null;

  const loader = (
    <Spinner style={{ margin: '1em auto', display: 'block' }} aria-label={messages.loading} />
  );

  return (
    <Dialog
      as={Form}
      onSubmit={handleSubmit}
      open={open}
      width="md"
      actions={actions}
      messages={messages}
      title={messages.label}
      onExit={onClose}
    >
      {privacyPolicy === undefined ? (
        loader
      ) : null === privacyPolicy ? (
        <Editorial>
          <p style={{ textAlign: 'center' }}>{t('dialog.policy.missing')}</p>
        </Editorial>
      ) : (
        <Editorial dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
      )}
    </Dialog>
  );
}

export default DialogPolicy;
