import { ToastType } from 'models';
import { FiltersState, ChatbotState, SettingsState } from 'reducers/uiReducer';

/**
 * Set event filter
 */
export function updateFilters<T extends keyof FiltersState>(
  scope: string,
  name: T,
  value: FiltersState[T],
) {
  return {
    type: updateFilters.type,
    payload: {
      scope,
      name,
      value,
    },
  } as const;
}

updateFilters.type = 'ui/filters/update' as const;

/**
 * Set a toast
 */
export function setToast(toast: ToastType) {
  return {
    type: setToast.type,
    payload: {
      toast,
    },
  } as const;
}

setToast.type = 'ui/toast/set' as const;

/**
 * Update a toast
 */
export function updateToast(toast: Partial<ToastType>) {
  return {
    type: updateToast.type,
    payload: {
      toast,
    },
  } as const;
}

updateToast.type = 'ui/toast/update' as const;

/**
 * Activate progress
 */
export function activateProgress() {
  return {
    type: activateProgress.type,
    payload: {},
  } as const;
}

activateProgress.type = 'ui/progress/activate' as const;

/**
 * Deactivate progress
 */
export function deactivateProgress() {
  return {
    type: deactivateProgress.type,
    payload: {},
  } as const;
}

deactivateProgress.type = 'ui/progress/deactivate' as const;

/**
 * Toggle chatbot
 */
export function toggleChatbot(payload: ChatbotState) {
  return {
    type: toggleChatbot.type,
    payload,
  } as const;
}

toggleChatbot.type = 'ui/chatbot/toggle' as const;

export const DIALOG_EVENT_REGISTER = 'dialog/makerslab';
export const DIALOG_EVENT_SUBSCRIBE = 'dialog/event/subscribe';
export const DIALOG_ACTIVITY_DELETE = 'dialog/activity/delete';

export type DIALOG_NAME =
  | typeof DIALOG_EVENT_REGISTER
  | typeof DIALOG_ACTIVITY_DELETE
  | typeof DIALOG_EVENT_SUBSCRIBE;

/**
 * Open dialog
 */
export function openDialog(dialog: DIALOG_NAME, props: object) {
  return {
    type: openDialog.type,
    payload: { dialog, open: true, props },
  } as const;
}

openDialog.type = 'ui/dialog/open' as const;

/**
 * Close dialog
 */
export function closeDialog(dialog: DIALOG_NAME) {
  return {
    type: closeDialog.type,
    payload: { dialog, open: false },
  } as const;
}

closeDialog.type = 'ui/dialog/close' as const;

/**
 * Update settings
 */
export function putSettings(settings: Partial<SettingsState>) {
  return {
    type: putSettings.type,
    payload: settings,
  } as const;
}

putSettings.type = 'ui/settings/put' as const;
