import React, { useEffect } from 'react';
import classnames from 'classnames';
import Button from 'components/ui/Button';
import Link from 'components/utils/Link';
import { Override } from 'types';
import Close from '../Icon/actions/Close';
import './Toast.scss';

type BaseProps = {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  message: React.ReactNode;
  to?: string;
  close?: string;
  autoclose?: number;
  onClose?(): void;
};

export type ToastProps = Override<React.ComponentPropsWithoutRef<'div'>, BaseProps>;

function Toast({
  icon,
  title,
  message,
  to,
  close,
  onClose,
  className,
  autoclose,
  ...props
}: ToastProps): JSX.Element {
  useEffect(() => {
    if (autoclose && onClose) {
      const timeout = setTimeout(() => {
        onClose();
      }, autoclose * 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [autoclose, onClose]);

  return (
    <div role="status" aria-live="polite" className={classnames('Toast', className)} {...props}>
      {icon ? <div className="Toast-image">{icon}</div> : null}
      <div className="Toast-text">
        {title ? <div className="Toast-title">{title}</div> : null}
        {to ? <Link to={to}>{message}</Link> : <div className="Toast-message">{message}</div>}
      </div>
      {onClose ? (
        <div className="Toast-close">
          <Button tabIndex={0} title={close} onClick={onClose} icon={Close} iconOnly>
            Close
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default Toast;
