import React from 'react';
import Link from 'components/utils/Link';
import Anchor from '../Anchor/Anchor';
import './Breadcrumb.scss';

type BaseProps = {
  label: React.ReactNode;
  to?: string;
};

export type BreadcrumbItemProps<T> = BaseProps &
  Omit<React.ComponentPropsWithoutRef<T extends string ? typeof Link : 'span'>, keyof BaseProps>;

function BreadcrumbItem<T>({ label, to, ...props }: BreadcrumbItemProps<T>): JSX.Element {
  return (
    <span className="BreadcrumItem">
      {to ? (
        <Anchor as={<Link to={to} />} {...props}>
          {label}
        </Anchor>
      ) : (
        label
      )}
    </span>
  );
}

export default BreadcrumbItem;
