import React from 'react';
import Flex from 'components/grid/Flex';

export type AccordionProps = React.ComponentPropsWithoutRef<typeof Flex>;

function Accordion(props: AccordionProps): JSX.Element {
  const asProp = { as: 'dl' } as any; // Weird TypeScript behavior, no time to find out what's happening

  return <Flex {...asProp} role="presentation" direction="column" gap="md" gutter {...props} />;
}

export default Accordion;
