import { Untrustable } from 'types';

export type NotificationListParams = {
  page?: number;
  readAt?: 1 | 0;
};

export type NotificationModel = {
  uuid: string;
} & Untrustable<{
  title: string;
  shortDescription: string;
  longDescription: string;
  important: boolean;
  read: boolean;
  sentAt: string;
  channels: string[];
}>;

export function isNotificationModel(model: unknown): model is NotificationModel {
  return typeof model === 'object' && null !== model && 'uuid' in model;
}
