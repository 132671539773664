import React from 'react';
import { Override } from 'types';
import './SideMenu.scss';

type MenuItem = {
  label?: string;
  url?: string;
  child?: MenuItem[];
};

type BaseProps = {
  startAdornement?: React.ReactNode;
  children?: React.ReactNode;
  items?: MenuItem[];
};

export type SideMenuProps = Override<React.ComponentPropsWithoutRef<'ul'>, BaseProps>;

function SideMenu({ children, ...props }: SideMenuProps): JSX.Element {
  return (
    <ul className="SideMenu" {...props}>
      {children}
    </ul>
  );
}

export default SideMenu;
