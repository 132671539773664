import { ErrorModel, BookingModel } from 'models';
import { EventModel } from 'models/event';

export function createEvent(event: EventModel) {
  return {
    type: createEvent.type,
    payload: {
      event,
      error: null,
      status: null,
      loading: true,
    },
  } as const;
}

createEvent.type = 'event/create/request' as const;

export function createEventSuccess(event: EventModel, status: number) {
  return {
    type: createEventSuccess.type,
    payload: {
      event,
      status,
      error: null,
      loading: false,
    },
  } as const;
}

createEventSuccess.type = 'event/create/success' as const;

export function createEventError(event: EventModel, error: ErrorModel) {
  return {
    type: createEventError.type,
    payload: {
      event,
      error,
      status: null,
      loading: false,
    },
  } as const;
}

createEventError.type = 'event/create/error' as const;

export function deleteEvent(event: EventModel) {
  return {
    type: deleteEvent.type,
    payload: {
      event,
      error: null,
      status: null,
      loading: true,
    },
  } as const;
}

deleteEvent.type = 'event/delete/request' as const;

export function deleteEventSuccess(event: EventModel, status: number) {
  return {
    type: deleteEventSuccess.type,
    payload: {
      event,
      status,
      error: null,
      loading: false,
    },
  } as const;
}

deleteEventSuccess.type = 'event/delete/success' as const;

export function deleteEventError(event: EventModel, error: ErrorModel) {
  return {
    type: deleteEventError.type,
    payload: {
      event,
      error,
      status: null,
      loading: false,
    },
  } as const;
}

deleteEventError.type = 'event/delete/error' as const;

export function resetEvent(event: EventModel) {
  return {
    type: resetEvent.type,
    payload: {
      event,
      error: null,
      status: null,
      loading: false,
    },
  } as const;
}

resetEvent.type = 'event/reset' as const;

export function createBooking(booking: BookingModel) {
  return {
    type: createBooking.type,
    payload: {
      booking,
      error: null,
      status: null,
      loading: true,
    },
  } as const;
}

createBooking.type = 'booking/create/request' as const;

export function createBookingSuccess(booking: BookingModel, status: number) {
  return {
    type: createBookingSuccess.type,
    payload: {
      booking,
      status,
      error: null,
      loading: false,
    },
  } as const;
}

createBookingSuccess.type = 'booking/create/success' as const;

export function createBookingError(booking: BookingModel, error: ErrorModel) {
  return {
    type: createBookingError.type,
    payload: {
      booking,
      error,
      status: null,
      loading: false,
    },
  } as const;
}

createBookingError.type = 'booking/create/error' as const;

export function deleteBooking(booking: BookingModel) {
  return {
    type: deleteBooking.type,
    payload: {
      booking,
      error: null,
      status: null,
      loading: true,
    },
  } as const;
}

deleteBooking.type = 'booking/delete/request' as const;

export function deleteBookingSuccess(booking: BookingModel, status: number) {
  return {
    type: deleteBookingSuccess.type,
    payload: {
      booking,
      status,
      error: null,
      loading: false,
    },
  } as const;
}

deleteBookingSuccess.type = 'booking/delete/success' as const;

export function deleteBookingError(booking: BookingModel, error: ErrorModel) {
  return {
    type: deleteBookingError.type,
    payload: {
      booking,
      error,
      status: null,
      loading: false,
    },
  } as const;
}

deleteBookingError.type = 'booking/delete/error' as const;

export function resetBooking(roomId: string) {
  return {
    type: resetBooking.type,
    payload: {
      roomId,
    },
  } as const;
}

resetBooking.type = 'booking/reset' as const;
