import { cancelled, takeEvery, call, put, all, fork } from 'redux-saga/effects';
import { isActivity } from 'models/activity';
import isArrayOf from 'types/isArrayOf';
import cdmApi, { createCancelSource, resolveError } from '../services/cdmApi';
import {
  requestActivityList,
  receiveActivityList,
  errorActivityList,
  requestActivityDetail,
  receiveActivityDetail,
  errorActivityDetail,
  requestActivityCreate,
  requestActivityDelete,
  requestActivitySubscribe,
  requestActivityUpdate,
  receiveActivityCreate,
  errorActivityCreate,
  receiveActivityUpdate,
  errorActivityUpdate,
  errorActivityDelete,
  receiveActivityDelete,
  receiveActivitySubscribe,
  errorActivitySubscribe,
} from '../actions/activityActions';

export function* requestActivityListTask(action: ReturnType<typeof requestActivityList>): any {
  const cancelSource = createCancelSource();
  const { scope, filters } = action.payload;

  try {
    const response = yield call(cdmApi.get, `/activity`, {
      params: filters,
      cancelToken: cancelSource.token,
    });

    yield put(receiveActivityList(scope, response.data));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorActivityList(scope, err));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

export function* receiveActivityListTask(action: ReturnType<typeof receiveActivityList>) {
  yield all(
    action.payload.pager.items.map(function activityMap(activity) {
      return put(receiveActivityDetail(activity.identifier, activity));
    }),
  );
}

export function* requestActivityDetailTask(action: ReturnType<typeof requestActivityDetail>): any {
  const { activityId } = action.payload;

  try {
    const response = yield call(cdmApi.get, `/activity/${activityId}`);

    yield put(receiveActivityDetail(activityId, response.data.activity));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorActivityDetail(activityId, err));
  }
}

export function* requestActivityCreateTask(action: ReturnType<typeof requestActivityCreate>): any {
  const { activity } = action.payload;

  try {
    const response = yield call(cdmApi.post, '/activity', { activity });

    yield put(receiveActivityCreate(response.data.activity, response.data.subscriptions));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorActivityCreate(err));
  }
}

export function* requestActivityUpdateTask(action: ReturnType<typeof requestActivityUpdate>): any {
  const { activityId, activity } = action.payload;

  try {
    const response = yield call(cdmApi.patch, `/activity/${activityId}`, { activity });

    yield put(receiveActivityUpdate(activityId, response.data.activity));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorActivityUpdate(activityId, err));
  }
}

export function* requestActivityDeleteTask(action: ReturnType<typeof requestActivityDelete>): any {
  const { activityId } = action.payload;

  try {
    yield call(cdmApi.delete, `/activity/${activityId}`);

    yield put(receiveActivityDelete(activityId));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorActivityDelete(activityId, err));
  }
}

function isNumber(value: any): value is number {
  return typeof value === 'number';
}

export function* requestActivitySubscribeTask(
  action: ReturnType<typeof requestActivitySubscribe>,
): any {
  const { activityId, subscribe } = action.payload;

  try {
    const method = subscribe ? cdmApi.post : cdmApi.delete;
    const response = yield call(method, `/activity/${activityId}/subscribe`);

    const { subscriptions, activity } = response.data;

    if (isArrayOf(subscriptions, isNumber) && isActivity(activity)) {
      yield put(receiveActivitySubscribe(activityId, activity, subscriptions));
    } else {
      throw new Error();
    }
  } catch (error) {
    const err = resolveError(error);
    yield put(errorActivitySubscribe(activityId, err));
  }
}

export function* watchRequestActivityList() {
  yield takeEvery(requestActivityList.type, requestActivityListTask);
}

export function* watchReceiveActivityList() {
  yield takeEvery(receiveActivityList.type, receiveActivityListTask);
}

export function* watchRequestActivityDetail() {
  yield takeEvery(requestActivityDetail.type, requestActivityDetailTask);
}

export function* watchRequestActivityCreate() {
  yield takeEvery(requestActivityCreate.type, requestActivityCreateTask);
}

export function* watchRequestActivityUpdate() {
  yield takeEvery(requestActivityUpdate.type, requestActivityUpdateTask);
}

export function* watchRequestActivityDelete() {
  yield takeEvery(requestActivityDelete.type, requestActivityDeleteTask);
}

export function* watchRequestActivitySubscribe() {
  yield takeEvery(requestActivitySubscribe.type, requestActivitySubscribeTask);
}

export default function* treeSaga() {
  yield fork(watchRequestActivityList);
  yield fork(watchReceiveActivityList);
  yield fork(watchRequestActivityDetail);
  yield fork(watchRequestActivityCreate);
  yield fork(watchRequestActivityUpdate);
  yield fork(watchRequestActivityDelete);
  yield fork(watchRequestActivitySubscribe);
}
