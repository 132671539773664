import { Children, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import classnames from 'classnames';
import useToggable from 'components/hooks/useToggable';
import useEscapeKey from 'components/hooks/useEscapeKey';
import Icon from 'components/ui/Icon/Icon';
import Hamburger from 'components/ui/Icon/actions/Hamburger';
import Close from 'components/ui/Icon/actions/Close';
import Home from 'components/ui/Icon/actions/Home';
import useAutoId from 'components/hooks/useAutoId';
import SrOnly from 'components/ui/SrOnly/SrOnly';
import Backdrop from 'components/ui/Backdrop';
import './MainMenu.scss';

export type MainMenuProps = {
  children: ReactNode;
  homeLink: ReactElement<{
    children: ReactNode;
    className: string;
  }>;
};

function MainMenu({ homeLink, children }: MainMenuProps): JSX.Element {
  const navId = useAutoId(undefined, 'main-menu');
  const [open, handleToggle, , onClose] = useToggable();
  useEscapeKey(onClose);

  return (
    <div className="MainMenu-wpr">
      <button
        className="MainMenu-openBtn"
        aria-controls={navId}
        onClick={handleToggle}
        aria-expanded={open}
      >
        <Icon as={open ? Close : Hamburger} />
        <SrOnly>Open Menu</SrOnly>
      </button>
      <nav id={navId} className={classnames('MainMenu', { 'is-open': open })} aria-hidden={!open}>
        <ul className="MainMenu-list">
          <li className="MainMenu-item is-home">
            <homeLink.type
              {...homeLink.props}
              className={classNames('MenuEntryFirst-button', homeLink.props.className)}
            >
              <Icon as={Home} />
              <SrOnly>{homeLink.props.children}</SrOnly>
            </homeLink.type>
          </li>
          {Children.map(children, (child, i) => (
            <li key={i} className="MainMenu-item">
              {child}
            </li>
          ))}
        </ul>
      </nav>
      <Backdrop aria-hidden={!open} />
    </div>
  );
}

export default MainMenu;
