import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgHelp({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 33 30"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.745.25h4.462c1.312-.022 2.413.939 2.657 2.224v.002l.42 2.173c.233.129.463.266.689.412l1.974-.698c.277-.096.57-.144.864-.141a2.65 2.65 0 012.304 1.39l2.25 4c.66 1.128.457 2.593-.504 3.482l-.002.002-1.836 1.685-1.014-1.106 1.834-1.683a1.325 1.325 0 00.226-1.626l-.004-.006L23.8 6.335l-.004-.007a1.15 1.15 0 00-1-.606 1.082 1.082 0 00-.36.058l-.002.001-2.682.949-.316-.222c-.37-.258-.76-.491-1.165-.699l-.324-.167-.557-2.885-.001-.003c-.117-.617-.625-1.015-1.161-1.004h-4.506c-.536-.01-1.044.386-1.161 1.005v.002l-.558 2.888-.328.166c-.405.205-.798.44-1.175.7l-.324.224-2.606-.987a1.078 1.078 0 00-.331-.028l-.027.002h-.027c-.396 0-.782.223-.997.614l-.003.006-2.256 4.01a1.326 1.326 0 00.245 1.6l.006.005 2.043 1.961v2.18l-2.055 1.917a1.324 1.324 0 00-.244 1.625l.004.006 2.26 4.018c.215.392.6.614.997.614h.007c.12.001.242-.018.36-.058l2.684-.95.316.222c.37.258.76.491 1.165.699l.325.166.557 2.889c.117.617.625 1.015 1.161 1.004h4.529v1.5h-4.507c-1.312.022-2.413-.94-2.657-2.224v-.002l-.419-2.172c-.234-.13-.464-.267-.69-.413l-1.974.698a2.584 2.584 0 01-.86.141 2.646 2.646 0 01-2.306-1.387L.623 20.385c-.651-1.136-.435-2.601.537-3.48l1.564-1.46v-.887l-1.571-1.509C.21 12.176-.004 10.746.619 9.622l.002-.004 2.256-4.01a2.648 2.648 0 012.282-1.386c.282-.017.565.012.838.087l.034.009 1.95.738c.224-.144.454-.28.688-.41l.42-2.171v-.002C9.332 1.19 10.43.228 11.744.25z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.626 11.073A4.25 4.25 0 1014 19.25h.008a4.2 4.2 0 004.242-4.242V15a4.25 4.25 0 00-2.624-3.927zM12.878 9.36a5.75 5.75 0 016.872 5.636 5.7 5.7 0 01-5.754 5.754 5.75 5.75 0 01-1.118-11.39zm18.652 9.17L22 28.06l-4.53-4.53 1.06-1.06L22 25.94l8.47-8.47 1.06 1.06zm-6.06 8.94l6-6 1.06 1.06-6 6-1.06-1.06z"
      />
    </svg>
  );
}

export default forwardRef(SvgHelp);
