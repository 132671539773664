import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestUser } from 'actions/userActions';
import { selectUser } from 'selectors/userSelectors';
import tokenize from 'lib/tokenize';
import { selectConfig } from 'selectors/configSelectors';

type RedirectHandler = () => void;

type RedirectUrl = string | null;

type RedirectToken = string | null;

function makeUrl(url: string, redirectToken: string, baseUrl: string): string {
  const absoluteUrl = url.startsWith(baseUrl) ? url : baseUrl + url;

  return tokenize(absoluteUrl, redirectToken ?? '', 'redirectToken');
}

/**
 * Used to append the user redirect token to a URL, and refresh the token on click
 *
 * @param url
 */
function useRedirectUrl(
  url: string | null | undefined,
): [RedirectUrl, RedirectHandler, RedirectToken] {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const { redirectToken } = useSelector(selectUser);

  const handleRefreshRedirectUrl = useCallback(() => {
    setTimeout(() => {
      dispatch(requestUser());
    }, 500); // Wait a few moment for the opened page to be fully loaded.
  }, [dispatch]);

  const redirectUrl =
    url && redirectToken && config.apiBaseUrl
      ? makeUrl(url, redirectToken, config.apiBaseUrl)
      : undefined;

  // Automatically refresh redirect URL when a link with this value the previous redirect URL is clicked
  useEffect(() => {
    function onAnchorClick(event: any): void {
      const link = event.target?.closest('a');
      if (link && link.href.indexOf(redirectToken) !== -1) {
        handleRefreshRedirectUrl();
      }
    }

    document.addEventListener('click', onAnchorClick, false);

    return () => {
      document.removeEventListener('click', onAnchorClick);
    };
  }, [redirectToken, handleRefreshRedirectUrl]);

  return [redirectUrl ?? null, handleRefreshRedirectUrl, redirectToken ?? null];
}

export default useRedirectUrl;
