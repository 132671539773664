import React, { lazy } from 'react';
import Link from 'components/utils/Link';
import { LinkViewProps } from './LinkBehavior';

const SocialLinkIcon = lazy(
  () => import('./SocialLinkIcon' /* webpackChunkName: "social-icons" */),
);

export type SocialLinkViewProps = LinkViewProps & React.ComponentPropsWithRef<typeof Link>;

function SocialLinkView({
  to,
  title,
  titleLong,
  description,
  icon,
  content,
  type,
  children,
  ...props
}: SocialLinkViewProps): JSX.Element {
  return (
    <Link to={to} title={title} {...props}>
      <SocialLinkIcon name={icon} title={title} />
    </Link>
  );
}

export default SocialLinkView;
