import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgSport({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 33 36"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.154 3.674a11.213 11.213 0 00-5.204 6.692 10.247 10.247 0 018.264 5.11 10.242 10.242 0 01.606 9.02c2.516.446 5.198.041 7.584-1.336a11.204 11.204 0 004.945-5.89 10.246 10.246 0 01-7.52-5.037 10.248 10.248 0 01-.287-9.722 11.213 11.213 0 00-8.388 1.163zm-4.118 15.368a11.202 11.202 0 01-1.398-7.189 8.748 8.748 0 017.277 4.372 8.743 8.743 0 01.43 7.905 11.209 11.209 0 01-6.309-5.088zm19.486-11.25a11.209 11.209 0 011.25 8.017 8.746 8.746 0 01-6.644-4.326 8.747 8.747 0 01-.141-8.5 11.201 11.201 0 015.535 4.809zm-20.785 12c-3.52-6.098-1.431-13.896 4.667-17.417C19.502-1.145 27.3.944 30.82 7.042c3.52 6.098 1.431 13.896-4.667 17.417-5.522 3.188-12.438 1.776-16.31-3.047L1.31 29.947l-1.06-1.06 8.715-8.716a14.116 14.116 0 01-.227-.38zm1.511 6.095l-9 9 1.061 1.06 9-9-1.06-1.06z"
      />
    </svg>
  );
}

export default forwardRef(SvgSport);
