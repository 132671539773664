import React from 'react';

/** @deprecated */
export type PaletteName = string;

/** @deprecated */
export function isPaletteName(palette?: string | null): palette is PaletteName {
  return palette !== null && palette !== undefined;
}
/** @deprecated */
export type ColorName = 'primary' | 'secondary';

/** @deprecated */
export function getPaletteVars(palette?: PaletteName, color?: ColorName): string {
  return '';
}

/** @deprecated */
export const BREAKPOINTS = {
  xs: '20rem', // 320px
  sm: '30rem', // 480px
  md: '48rem', // 768px
  lg: '64rem', // 1024px
  xl: '80rem', // 1280px
};

/** @deprecated */
export type Breakpoint = keyof typeof BREAKPOINTS;

/** @deprecated */
export function isBreakpoint(subject: any): subject is Breakpoint {
  return subject in BREAKPOINTS;
}

export type ThemeProviderProps = {
  children?: React.ReactNode;
};

function ThemeProvider({ children }: ThemeProviderProps): JSX.Element {
  return <>{children}</>;
}

export default ThemeProvider;
