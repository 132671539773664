import { ChangeEvent, FormEvent, MutableRefObject } from 'react';
import Search from 'components/ui/Icon/actions/Search';
import Icon from 'components/ui/Icon';
import Close from 'components/ui/Icon/actions/Close';
import SrOnly from 'components/ui/SrOnly/SrOnly';
import './SearchForm.scss';

export type SearchFormProps = {
  value: string;
  onChange(value: string): void;
  onCancel(): void;
  onFocus?(): void;
  onSubmit?(): void;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  messages: {
    searchLabel: string;
    submitButton: string;
    searchPlaceholder?: string;
    cancelButton: string;
  };
};

function SearchForm({
  value,
  onChange,
  onCancel,
  onFocus,
  onSubmit,
  inputRef,
  messages,
}: SearchFormProps): JSX.Element {
  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    if (value && onSubmit) {
      onSubmit();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value);
  };

  const handleFocus = (): void => {
    if (value && onFocus) {
      onFocus();
    }
  };

  const handleCancel = (): void => {
    onChange('');
    onCancel();
  };

  return (
    <form className="SearchForm" onSubmit={handleSubmit}>
      <label htmlFor="search" className="SearchForm-label">
        {messages.searchLabel}
      </label>
      <input
        ref={inputRef}
        id="search"
        type="text"
        className="SearchForm-input"
        placeholder={messages.searchPlaceholder ?? messages.searchLabel}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        autoComplete="off"
      />
      {value ? (
        <button type="button" className="SearchForm-cancel" onClick={handleCancel}>
          <Icon as={Close} />
          <SrOnly>{messages.cancelButton}</SrOnly>
        </button>
      ) : (
        <button type="submit" className="SearchForm-submit">
          <Icon as={Search} />
          <SrOnly>{messages.submitButton}</SrOnly>
        </button>
      )}
    </form>
  );
}

export default SearchForm;
