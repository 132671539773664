import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgEspace454(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 25 44"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25.918l1.013.38c2.706 1.015 4.8 2.136 6.23 3.387 1.44 1.26 2.257 2.7 2.257 4.315v15.401l-3 2V41c0 1.519-1.448 2.75-3.25 2.75s-3.25-1.231-3.25-2.75V.918zM.25 13V1h1.5v12c0 .54.348.83 1.166 1.376l.077.051C3.68 14.884 4.75 15.593 4.75 17c0 .919-.203 5.064-.424 9.593l-.077 1.569C4 33.301 3.75 38.523 3.75 40c0 1.317.423 1.78.76 1.982.422.252.962.268 1.49.268s1.068-.016 1.49-.268c.337-.203.76-.665.76-1.982 0-1.476-.248-6.699-.5-11.838l-.076-1.569C7.453 22.064 7.25 17.92 7.25 17c0-1.407 1.07-2.117 1.757-2.573l.077-.051c.818-.545 1.166-.836 1.166-1.376V1h1.5v12c0 1.407-1.07 2.117-1.757 2.573l-.077.051c-.818.545-1.166.836-1.166 1.376 0 .879.201 4.998.425 9.568l.074 1.52c.25 5.11.501 10.388.501 11.912 0 1.683-.577 2.72-1.49 3.268-.804.483-1.733.483-2.218.482h-.084c-.485 0-1.414.001-2.219-.482C2.827 42.721 2.25 41.683 2.25 40c0-1.524.252-6.801.5-11.912l.075-1.52c.224-4.57.425-8.689.425-9.568 0-.54-.348-.83-1.166-1.376l-.077-.051C1.32 15.116.25 14.407.25 13zm3.5-1V1h1.5v11h-1.5zm3 0V1h1.5v11h-1.5zm10-8.9V41c0 .481.552 1.25 1.75 1.25s1.75-.769 1.75-1.25V25.599l3-2V9c0-1.052-.517-2.112-1.744-3.186-1.072-.937-2.646-1.847-4.756-2.714z"
      />
    </svg>
  );
}

export default forwardRef(SvgEspace454);
