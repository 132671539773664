import * as yup from 'yup';

yup.setLocale({
  mixed: {
    default: 'error.validation:default',
    required: 'error.validation:required',
    oneOf: 'error.validation:required',
  },
  string: {
    email: 'error.validation:required',
    min: 'error.validation:required',
    max: 'error.validation:required',
  },
  array: {
    min: 'error.validation:required',
    max: 'error.validation:required',
  },
  date: {
    min: 'error.validation:after',
  },
  boolean: {},
  number: {
    min: 'error.validation:required',
  },
  object: {},
});

export default yup;
