import { TagModel, CampusModel, UserProfile } from 'models';
import { Untrustable } from 'types';

export const ACTIVITY_LEVEL_ENUM = ['beginner', 'intermediate', 'confirmed', 'expert'] as const;
export type ActivityLevel = (typeof ACTIVITY_LEVEL_ENUM)[number];

export const ACTIVITY_TYPE_ENUM = ['personal', 'collective'] as const;
export type ActivityType = (typeof ACTIVITY_TYPE_ENUM)[number];

export const ACTIVITY_TO_ENUM = ['staff', 'student', 'staff_and_student'] as const;
export type ActivityTo = (typeof ACTIVITY_TO_ENUM)[number];

export const ACTIVITY_PERIOD_ENUM = ['daily', 'weekly', 'monthly', 'yearly'] as const;
export type ActivityPeriod = (typeof ACTIVITY_PERIOD_ENUM)[number];

export const ACTIVITY_REPEATABLE_ENUM = ['once', 'repeatable', 'multiple'] as const;
export type ActivityRepeatable = (typeof ACTIVITY_REPEATABLE_ENUM)[number];

export const ACTIVITY_WEEKDAY_ENUM = [1, 2, 3, 4, 5, 6, 7] as const;
export type ActivityWeekday = (typeof ACTIVITY_WEEKDAY_ENUM)[number];

export type UserRefModel = {
  userId: string;
  firstName: string;
  lastName: string;
  profile: string | null;
};

export type ActivitySessionModel<T = string> = {
  start: T;
  end: T;
};

export type ActivityModel = {
  identifier: number;
  title: string;
  link: string | null;
  description: string;
  htmlDescription: string;
  sport: TagModel;
  type: ActivityType;
  level: ActivityLevel;
  repeat: number;
  from: string;
  period: ActivityPeriod | null;
  weekdays: ActivityWeekday[];
  start: string;
  end: string;
  session: string;
  location: string;
  capacity: number | null;
  email: string | null;
  subscriptions: number;
  profile: UserProfile[];
  user?: UserRefModel;
  campus: CampusModel;
  subscribers?: UserRefModel[];
  sessions: ActivitySessionModel[];
};

export type ActivityListParams = Untrustable<{
  term: string;
  page: number;
  perPage: number;
  type: ActivityModel['type'] | string;
  level: ActivityModel['level'] | string;
  campus: CampusModel['code'];
  sport: TagModel['slug'];
  mine: 1 | 0;
  active: 1 | 0;
  free: 1 | 0;
  activity: string;
}>;

export function resolveTo(activity: ActivityModel): ActivityTo | null {
  if (
    activity.profile.includes('staff') &&
    activity.profile.includes('student') &&
    activity.profile.includes('exec')
  ) {
    return 'staff_and_student';
  }

  if (activity.profile.includes('student') && activity.profile.includes('exec')) {
    return 'student';
  }

  if (activity.profile.includes('staff')) {
    return 'staff';
  }

  return null;
}

export function isActivity(activity: any): activity is ActivityModel {
  return (
    typeof activity === 'object' &&
    'identifier' in activity &&
    'title' in activity &&
    'from' in activity &&
    'sport' in activity
  );
}

export function isActivitySession(session: any): session is ActivitySessionModel {
  return typeof session === 'object' && 'start' in session && 'end' in session;
}
