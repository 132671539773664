import { ComponentPropsWithoutRef } from 'react';
import Icon from 'components/ui/Icon';
import serviceIcons from 'components/ui/Icon/service';
import { Override } from 'types';

type ServiceLinkIconProps = Override<
  ComponentPropsWithoutRef<typeof Icon>,
  {
    name?: string;
  }
>;

function ServiceLinkIcon({ name, ...props }: ServiceLinkIconProps): JSX.Element {
  const as = isServiceIcon(name) ? serviceIcons[name] : undefined;

  if (as) {
    return (
      <>
        <Icon as={as} {...props} />
      </>
    );
  }

  return <>{name}</>;
}

function isServiceIcon(name: string | undefined): name is keyof typeof serviceIcons {
  return name !== undefined && name in serviceIcons;
}

export default ServiceLinkIcon;
