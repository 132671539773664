import { AppStore } from '../store';

export function selectAuth(store: AppStore) {
  return store.auth;
}

export function selectIdentity(store: AppStore) {
  return selectAuth(store).identity;
}

export function selectAccessToken(store: AppStore) {
  return selectIdentity(store).accessToken;
}

export function selectAuthRefreshing(store: AppStore) {
  return selectAuth(store).isRefreshing;
}
