import React from 'react';
import { Override } from 'types';
import RadioInput from 'components/ui/form/RadioInput';
import useFormInput from './useFormInput';

export type RadioFieldProps = Override<
  React.ComponentPropsWithoutRef<'input'>,
  {
    label: React.ReactNode;
    children?: React.ReactNode;
  }
>;

function RadioField(props: RadioFieldProps): JSX.Element {
  const { label, error, touched, onValueChange, ...otherProps } = useFormInput({
    ...props,
    type: 'radio',
  });

  return <RadioInput label={label} inputProps={otherProps} />;
}

export default RadioField;
