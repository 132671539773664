import { CampusModel } from 'models';
import { createSelector } from 'reselect';
import { resolveLocale } from 'services/localeHelper';
import { AppStore } from '../store';

export function selectUser(store: AppStore) {
  return store.user.user;
}

export function selectUserId(store: AppStore) {
  return store.user.user.userId;
}

export function selectUserEmail(store: AppStore) {
  return store.user.user.upn;
}

export const selectUserFavoriteServices = createSelector(selectUser, function selectSettings(user) {
  return user?.settings?.favoriteServices ?? [];
});

export function selectUserActivities(store: AppStore) {
  return store.user.user.activities;
}

export function selectLocale(store: AppStore) {
  return selectUser(store).settings.locale || resolveLocale();
}

export function selectProfile(store: AppStore) {
  return store.user.user.profile;
}

export function selectCampus(store: AppStore): CampusModel | null {
  const user = selectUser(store);

  // Return null if it is a string, should not happen usually...
  if (typeof user.settings.campus === 'string') {
    return user.campus ?? null;
  }

  return user.settings.campus ?? null;
}

export function selectCampusCode(store: AppStore): string | null {
  const user = selectUser(store);
  const campus = user.settings.campus ?? user.campus;

  return typeof campus === 'string' ? campus : campus?.code ?? null;
}

export function selectTimezone(store: AppStore) {
  return selectUser(store).settings.timezone;
}

export function selectHasSeenProfileSwitcher(store: AppStore) {
  return selectUser(store).settings.hasSeenProfileSwitcher;
}

export function selectUserError(store: AppStore) {
  return store.user.error;
}
