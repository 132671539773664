import React, { useCallback } from 'react';
import { Override } from 'types';
import SwitchInput from 'components/ui/form/SwitchInput';
import useFormInput, { FormInputProps } from './useFormInput';

export type SwitchFieldProps = Override<
  React.ComponentPropsWithoutRef<typeof SwitchInput>,
  FormInputProps<boolean, null>
>;

function SwitchField(props: SwitchFieldProps): JSX.Element {
  const { value, name, onValueChange, ...otherProps } = useFormInput({
    ...props,
    type: 'checkbox',
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onValueChange) {
        const { checked } = event.target;
        onValueChange(checked, name);
      }
    },
    [name, onValueChange],
  );

  return (
    <SwitchInput
      name={name}
      checked={value}
      value={typeof value === 'boolean' ? name : value}
      onChange={handleChange}
      {...otherProps}
    />
  );
}

export default SwitchField;
