import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgOnedrive(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 512 512"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M450.959 275.302c-4.8-31.303-25.794-57.333-54.127-69.272C396.095 140.822 342.829 88 277.45 88c-32.78 0-63.667 13.272-86.141 36.72a102.052 102.052 0 00-19.709-1.92c-51.554 0-94.297 38.443-101.066 88.169C30.558 217.688 0 252.543 0 294.4 0 341.048 37.952 379 84.6 379h28.681c11.597 26.461 38.027 45 68.719 45h255c41.355 0 75-33.645 75-75 0-36.587-26.331-67.139-61.041-73.698zM107 349H84.6C54.494 349 30 324.506 30 294.4s24.494-54.6 54.6-54.6h15v-15c0-39.701 32.299-72 72-72a72.08 72.08 0 0120.634 3.016l9.588 2.864 6.326-7.753C225.224 130.001 250.483 118 277.45 118c46.502 0 84.819 35.689 89.013 81.115A90.48 90.48 0 00362 199c-5.471 0-10.917.497-16.281 1.483C326.024 180.367 299.198 169 270.75 169c-57.897 0-105 47.103-105 105 0 .589.005 1.179.015 1.77C132.188 283.208 107 313.218 107 349zm330 45H182c-24.813 0-45-20.187-45-45s20.188-45 45.001-45h17.728l-2.935-17.483A75.85 75.85 0 01195.75 274c0-41.355 33.645-75 75-75 22.623 0 43.814 10.069 58.14 27.625l6.327 7.752 9.587-2.864A60.076 60.076 0 01362 229c33.084 0 60 26.916 60 60v15h15c24.813 0 45 20.187 45 45s-20.187 45-45 45z" />
    </svg>
  );
}

export default forwardRef(SvgOnedrive);
