import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgGrades({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 19 23"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M4.53 19.5h-3v-18h6V6a1.504 1.504 0 001.5 1.5h4.5v2.25h1.5V6a.68.68 0 00-.225-.525L9.555.225A.682.682 0 009.03 0h-7.5a1.504 1.504 0 00-1.5 1.5v18a1.504 1.504 0 001.5 1.5h3v-1.5zm4.5-17.7l4.2 4.2h-4.2V1.8z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.175l2.021 4.484 4.587.622-3.361 3.335.864 4.835L12 20.091l-4.111 2.36.864-4.835-3.36-3.335 4.586-.622L12 9.175zm-.785 5.392l-.001.002-.207.464-2.4.326 1.42 1.408.339.339-.084.479-.35 1.963 1.616-.928.452-.26.451.26 1.618.928-.352-1.966-.083-.476.342-.341 1.416-1.406-1.897-.258-.502-.068-.207-.464v-.002L12 12.825l-.785 1.742z"
      />
    </svg>
  );
}

export default forwardRef(SvgGrades);
