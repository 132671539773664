import * as React from 'react';
import { Children, ComponentPropsWithoutRef, ReactNode, useEffect } from 'react';
import { DisclosureContent, useDisclosureState } from 'ariakit';
import classNames from 'classnames';
import { Override } from 'types';
import { useMiniTeaserContext } from './MiniTeaserContext';
import './MiniTeaserList.scss';

export type MiniTeaserListProps = Override<
  ComponentPropsWithoutRef<'div'>,
  {
    maxVisible: number;
    gap?: 'sm' | 'md' | 'lg';
    children: ReactNode;
  }
>;

function MiniTeaserList({ maxVisible, gap, children, ...props }: MiniTeaserListProps): JSX.Element {
  const { registerDisclosure, unregisterDisclosure, visible } = useMiniTeaserContext();
  const disclosure = useDisclosureState({ open: visible });

  useEffect(() => {
    registerDisclosure(disclosure);

    return () => {
      unregisterDisclosure(disclosure);
    };
  }, [registerDisclosure, unregisterDisclosure, disclosure]);

  return (
    <div
      {...props}
      className={classNames('MiniTeaserList', props.className, {
        [`is-gap-${gap}`]: Boolean(gap),
      })}
    >
      <ul className="MiniTeaserList-list">
        {Children.map(children, (child, i) =>
          i < maxVisible ? (
            <li key={i} className="MiniTeaserList-item">
              {child}
            </li>
          ) : null,
        )}
      </ul>
      <DisclosureContent state={disclosure} as="ul" className="MiniTeaserList-list">
        {Children.map(children, (child, i) =>
          i >= maxVisible ? (
            <li key={i} className="MiniTeaserList-item">
              {child}
            </li>
          ) : null,
        )}
      </DisclosureContent>
    </div>
  );
}

export default MiniTeaserList;
