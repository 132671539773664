import React, { useMemo } from 'react';
import qs from 'qs';

const defaultAppContext = {
  isWebview: false,
};

export const AppContext = React.createContext(defaultAppContext);

export type AppContextProps = {
  children: React.ReactNode;
};

function AppContextProvider({ children }: AppContextProps): JSX.Element {
  const appContext = useMemo(() => {
    const params = qs.parse(window.location.search.replace('?', ''));
    const isWebview = params.zone === 'main';

    return {
      ...defaultAppContext,
      isWebview,
    };
  }, []);

  return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
}

export default AppContextProvider;
