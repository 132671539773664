import {
  createBooking,
  createBookingSuccess,
  createBookingError,
  deleteBooking,
  deleteBookingSuccess,
  deleteBookingError,
  resetBooking,
} from 'actions/eventActions';
import { ErrorModel, BookingModel } from 'models';
import { createReducer } from './reducerUtils';

export type BookingItemState = {
  booking: BookingModel | null;
  error: ErrorModel | null;
  status: number | null;
  loading: boolean;
};

export const initialItemState: BookingItemState = {
  booking: null,
  error: null,
  status: null,
  loading: false,
};

export type BookingState = {
  [roomId: string]: undefined | BookingItemState;
};

export const initialState: BookingState = {};

type Actions =
  | typeof createBooking
  | typeof createBookingSuccess
  | typeof createBookingError
  | typeof deleteBooking
  | typeof deleteBookingSuccess
  | typeof deleteBookingError;

function singleBookingReducer(state: BookingState, action: ReturnType<Actions>) {
  const { booking } = action.payload;

  return {
    ...state,
    [booking.room_id]: action.payload,
  };
}

function resetBookingReducer(state: BookingState, action: ReturnType<typeof resetBooking>) {
  const { roomId } = action.payload;

  return {
    ...state,
    [roomId]: { ...initialItemState },
  };
}

export default createReducer(initialState, {
  [resetBooking.type]: resetBookingReducer,
  [createBooking.type]: singleBookingReducer,
  [createBookingSuccess.type]: singleBookingReducer,
  [createBookingError.type]: singleBookingReducer,
  [deleteBooking.type]: singleBookingReducer,
  [deleteBookingSuccess.type]: singleBookingReducer,
  [deleteBookingError.type]: singleBookingReducer,
});
