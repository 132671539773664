import React from 'react';
import { Override } from 'types';
import './Sidebar.scss';

type BaseProps = {
  children?: React.ReactNode;
  afterChildren?: React.ReactNode;
};

export type SidebarProps = Override<React.ComponentPropsWithoutRef<'div'>, BaseProps>;

function Sidebar({ children, afterChildren, ...props }: SidebarProps): JSX.Element {
  return (
    <aside className="Sidebar" {...props}>
      <div className="Sidebar-content">
        <ul className="Sidebar-list">{children}</ul>
      </div>
      {afterChildren}
    </aside>
  );
}

export default Sidebar;
