import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgInfo2000(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 64 64"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M63.965 56.869c-.007-.046.002-.09-.011-.136l-1.948-6.868v-5.869a.98.98 0 00-.22-.6c-.007-.009-.012-.02-.02-.028a1.02 1.02 0 00-.188-.171 1.515 1.515 0 00-.093-.063.938.938 0 00-.193-.081c-.026-.009-.047-.025-.074-.031L31.22 36.021a.999.999 0 00-.455 0L.78 43.022a1 1 0 000 1.948l13.224 3.086V59.89c-.003.033-.02.063-.02.097A4.021 4.021 0 0018 64.003c.024 0 .044-.012.067-.014h25.871c.023.002.043.014.067.014a4.021 4.021 0 004.016-4.016c0-.048-.021-.09-.027-.136V48.055l12.012-2.803v4.611l-1.96 6.868c-.014.047-.005.093-.011.141-.007.046-.027.086-.027.134 0 .021.011.038.012.059a.997.997 0 00.041.204c.017.06.029.118.057.172.028.059.067.106.106.158.038.051.073.102.121.145.046.041.1.07.153.103.059.036.115.07.181.094.02.007.034.022.055.028.091.025.182.037.272.038h3.988c.09-.001.181-.012.271-.038.021-.006.034-.021.054-.027.068-.024.126-.06.187-.096.052-.032.104-.061.148-.101.049-.043.085-.095.124-.146.038-.051.077-.099.104-.155s.041-.115.058-.176a.9.9 0 00.04-.199c.002-.021.013-.04.013-.062-.001-.05-.021-.091-.028-.138zm-2.962-3.211l.666 2.348h-1.336zm-14.991 6.426a2.013 2.013 0 01-1.975 1.915c-.019-.001-.034-.011-.053-.011H18c-.016 0-.029.009-.045.009a2.01 2.01 0 01-1.963-1.938c.001-.021.012-.039.012-.061V48.522l14.761 3.445a.973.973 0 00.456 0l14.774-3.446v11.477c-.001.03.014.057.017.086zm-15.02-10.117L5.406 43.996l25.586-5.975 25.598 5.975z" />
      <path d="M43.008 58.008h-24a1 1 0 100 2h24a1 1 0 100-2zm.013-28.988C46.231 25.81 48 21.541 48 16.999s-1.769-8.811-4.979-12.021C39.811 1.767 35.542-.002 31-.002s-8.811 1.769-12.021 4.98C15.768 8.188 14 12.457 14 16.999s1.769 8.812 4.98 12.021a16.885 16.885 0 0012.021 4.979c4.541 0 8.81-1.769 12.02-4.979zM15.999 16.999c0-4.008 1.561-7.774 4.394-10.606 2.833-2.834 6.6-4.395 10.607-4.395s7.774 1.561 10.607 4.395C44.439 9.225 46 12.991 46 16.999s-1.561 7.774-4.393 10.606c-2.833 2.833-6.6 4.394-10.607 4.394s-7.773-1.561-10.606-4.394a14.894 14.894 0 01-4.395-10.606z" />
      <path d="M30.292 21.947l5.652 5.652a.997.997 0 001.414 0l4.242-4.242a.999.999 0 000-1.414l-2.119-2.119-.002-.003-.003-.002-2.112-2.112-.004-.006-.006-.004-1.406-1.406 2.823-2.823c.014-.014.021-.035.034-.05a.993.993 0 00.131-.193c.016-.029.039-.053.052-.083.005-.012.006-.023.01-.035.004-.011.012-.02.016-.03l1.416-4.242c.013-.038.006-.076.014-.114a.907.907 0 00.025-.201.98.98 0 00-.025-.203c-.008-.038-.001-.076-.014-.114-.008-.022-.025-.038-.034-.061a.991.991 0 00-.099-.173c-.037-.056-.072-.109-.117-.154-.041-.041-.088-.071-.136-.104a.937.937 0 00-.189-.109c-.021-.008-.035-.025-.057-.032-.036-.013-.072-.006-.109-.014a.954.954 0 00-.207-.026.998.998 0 00-.204.025c-.038.008-.075.001-.113.014l-4.244 1.413c-.011.004-.018.013-.028.017-.013.004-.025.005-.037.01-.057.023-.101.065-.151.098s-.104.06-.147.1c-.008.008-.02.012-.027.02l-2.823 2.823-5.652-5.652a.999.999 0 00-1.414 0L20.4 10.64a.999.999 0 000 1.414l5.652 5.652-4.947 4.947a2.007 2.007 0 000 2.834l1.412 1.412c.011.011.025.014.036.024a1.998 1.998 0 002.794-.028l2.117-2.117.006-.004.004-.006zm-3.532.704l-1.413-1.415.706-.706 1.413 1.415zm-2.829 2.829c-.005-.005-.013-.007-.018-.012l-1.395-1.395-.004-.006.002-.002 1.416-1.416 1.413 1.415zm3.536-6.364l6.771-6.771v.414a1 1 0 001 1h.414l-6.771 6.771zm9.879-7.356h-1.108v-1.107l1.662-.554zm-2.108 6.649a.999.999 0 00.708 1.706.998.998 0 00.706-.292l.706.706-.706.707a1.001 1.001 0 001.416 1.414l.705-.706.706.706-2.828 2.828-4.945-4.945 2.828-2.828zm-12.719-7.063l2.828-2.828.707.707a.999.999 0 101.414 1.414l.707.707-.707.707a.999.999 0 101.414 1.414l.707-.707.703.703-2.828 2.828z" />
    </svg>
  );
}

export default forwardRef(SvgInfo2000);
