import { Override } from 'types';
import './SidebarList.scss';

type SidebarListProps = Override<React.ComponentPropsWithoutRef<'ul'>, {}>;

function SidebarList({ children, ...props }: SidebarListProps): JSX.Element {
  return <ul {...props}>{children}</ul>;
}

export default SidebarList;
