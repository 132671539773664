import useHelpStep from '../hooks/useHelpStep';
import { HelpModel } from '../../models';

export type NodeHelpProps = {
  identifier: number;
  help: HelpModel;
};

function NodeHelp({ identifier, help }: NodeHelpProps): null {
  useHelpStep({
    id: `node-${identifier}`,
    selector: `[data-identifier='${identifier}']`,
    ...help,
  });

  return null;
}

export default NodeHelp;
