import { put, call, select, fork, takeEvery } from 'redux-saga/effects';
import cdmApi, { resolveError } from 'services/cdmApi';
import {
  requestNotificationList,
  receiveNotificationList,
  errorNotificationList,
} from 'actions/notificationAction';
import { AppStore } from 'store';
import { isPagerModelOf } from 'models';
import { isNotificationModel } from 'models/notification';

// function createOnMessageChannel() {
//   return eventChannel(function startAuthChannel(emitter) {
//     return onMessagingMessage(async function handleOnMessage(payload) {
//       if (payload) {
//         emitter({ payload });
//       } else {
//         emitter(END);
//       }
//     });
//   });
// }

// export function* subscribeOnMessage() {
//   const channel = yield call(createOnMessageChannel);

//   try {
//     while (true) {
//       const { payload } = yield take(channel);
//       try {
//         console.log(payload);
//         yield put(requestNotificationList());
//       } catch (error) {
//         console.error(error);
//       }
//     }
//   } finally {
//     channel.close();
//   }
// }

// function createRegisterUserChannel() {
//   return eventChannel(function startAuthChannel(emitter) {
//     return onMessagingTokenRefresh(async function handleTokenRefresh() {
//       emitter();
//     });
//   });
// }

// export function* subscribeRegisterUser() {
//   const channel = yield call(createRegisterUserChannel);

//   try {
//     while (true) {
//       yield take(channel);
//       try {
//         yield put(requestRegisterUser());
//       } catch (error) {
//         yield put(errorRegisterUser(error));
//       }
//     }
//   } finally {
//     channel.close();
//   }
// }

// export function* registerC2nUserTask(): any {
//   try {
//     const user = yield select(selectUser);
//     const token = yield call(getMessagingToken);
//     const {
//       data: { person },
//     } = yield call(registerUser, user, token);

//     yield put(receiveRegisterUser(person));
//   } catch (error) {
//     yield put(errorRegisterUser(error));
//   } finally {
//     if (yield cancelled()) {
//     }
//   }
// }

export function* requestNotificationListTask(
  action: ReturnType<typeof requestNotificationList>,
): any {
  const { list } = action.payload;

  try {
    const { params } = yield select((store: AppStore) => store.notification.lists[list]);
    const response = yield call(cdmApi.get, '/notification', {
      params,
    });

    if (isPagerModelOf(response.data, isNotificationModel)) {
      yield put(receiveNotificationList(list, response.data));
    } else {
      throw new Error('Invalid notification list response');
    }
  } catch (error) {
    yield put(errorNotificationList(list, resolveError(error)));
  }
}

export function* watchRequestNotificationList() {
  yield takeEvery(requestNotificationList, requestNotificationListTask);
}

// export function* readNotificationsTask(action) {
//   try {
//     const { uuid } = yield select(selectNotificationsPerson);

//     yield call(setReadMessage, uuid, action.payload, true);
//     yield put(receiveReadNotification());
//   } catch (error) {
//     yield put(errorReadNotification(error));
//   } finally {
//     if (yield cancelled()) {
//     }
//   }
// }

// export function* watchRegisterC2nUserTask(): any {
//   yield takeLatest(requestRegisterUser, registerC2nUserTask);
// }

// export function* watchReadNotifications() {
//   yield takeLatest(requestReadNotification, readNotificationsTask);
// }

export default function* notificationSaga() {
  // yield fork(subscribeOnMessage);
  // yield fork(subscribeRegisterUser);
  // yield fork(watchRegisterC2nUserTask);
  yield fork(watchRequestNotificationList);
  // yield fork(watchReadNotifications);
}
