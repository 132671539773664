import { ErrorModel, DocumentRequestModel } from 'models';

export function sendDocumentRequest(documentRequest: DocumentRequestModel) {
  return {
    type: sendDocumentRequest.type,
    payload: {
      documentRequest,
    },
  } as const;
}

sendDocumentRequest.type = 'documentRequest/send' as const;

export function receiveDocumentRequest(status: number) {
  return {
    type: receiveDocumentRequest.type,
    payload: {
      status,
    },
  } as const;
}

receiveDocumentRequest.type = 'documentRequest/receive' as const;

export function errorDocumentRequest(error: ErrorModel) {
  return {
    type: errorDocumentRequest.type,
    payload: {
      error,
    },
  } as const;
}

errorDocumentRequest.type = 'documentRequest/error' as const;

export function resetDocumentRequest() {
  return {
    type: resetDocumentRequest.type,
  } as const;
}

resetDocumentRequest.type = 'documentRequest/reset' as const;
