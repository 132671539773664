import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgPin({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 29 35"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 11C3.25 5.063 8.063.25 14 .25S24.75 5.063 24.75 11c0 2.976-1.606 6.384-3.608 9.7-.94 1.558-1.998 3.14-3.059 4.686 2.564.22 4.841.666 6.582 1.277 1.047.367 1.942.808 2.59 1.326.639.512 1.141 1.187 1.141 2.017 0 .914-.606 1.639-1.35 2.175-.763.548-1.817 1.01-3.053 1.387-2.48.755-5.861 1.21-9.564 1.21-3.703 0-7.084-.456-9.565-1.21-1.235-.377-2.29-.84-3.052-1.388C1.067 31.645.46 30.92.46 30.006c0-.812.481-1.477 1.1-1.983.625-.511 1.487-.947 2.496-1.312 1.634-.59 3.76-1.033 6.16-1.273l-.162-.247c-1.013-1.535-2.056-3.118-3.007-4.675-1.04-1.7-1.982-3.391-2.667-4.986-.68-1.585-1.131-3.132-1.131-4.53zm8.744 14.41l-.006-.11-.063.004-.338-.513-.278-.422c-1.015-1.54-2.044-3.101-2.982-4.635-1.023-1.675-1.924-3.296-2.568-4.796C5.112 13.43 4.75 12.102 4.75 11A9.25 9.25 0 0114 1.75 9.25 9.25 0 0123.25 11c0 2.524-1.394 5.616-3.392 8.925-1.3 2.154-2.8 4.314-4.25 6.403a312.74 312.74 0 00-1.57 2.275 123.771 123.771 0 00-2.044-3.194zm-.843 1.45c-2.642.209-4.928.663-6.584 1.262-.926.334-1.614.7-2.056 1.062-.448.367-.55.648-.55.822 0 .197.133.53.727.957.575.414 1.456.817 2.613 1.17 2.303.7 5.53 1.145 9.128 1.145 3.597 0 6.824-.444 9.128-1.145 1.156-.352 2.037-.756 2.612-1.17.593-.427.727-.76.727-.957 0-.178-.107-.468-.579-.845-.464-.371-1.184-.744-2.149-1.082-1.776-.623-4.24-1.083-7.073-1.263l-.298.429a218.4 218.4 0 00-2.173 3.171l-.666 1-.606-1.038c-.647-1.11-1.4-2.29-2.2-3.518zM14 6.75a4.25 4.25 0 100 8.5 4.25 4.25 0 000-8.5zM8.25 11a5.75 5.75 0 1111.5 0 5.75 5.75 0 01-11.5 0z"
      />
    </svg>
  );
}

export default forwardRef(SvgPin);
