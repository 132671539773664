import React, { CSSProperties, useEffect, useRef } from 'react';
import { useDialogState, Dialog as AriaDialog } from 'ariakit';
import classNames from 'classnames';
import { OverridableComponentProps } from 'components/OverridableComponent';
import { Width } from 'components/ui/types.d';
import Close from 'components/ui/Icon/common/Close';
import Cta from '../Cta/Cta';
import Title from '../Title';
import './Dialog.scss';

export type DialogProps<C> = OverridableComponentProps<
  C,
  'div',
  {
    open: boolean;
    width?: Width;
    overtitle?: React.ReactNode;
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    actions?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
    style?: CSSProperties;
    onExit?(): any;
    messages: {
      label: string;
      close?: string;
    };
    // actionListProps?: React.ComponentPropsWithoutRef<typeof ActionList>;
  }
>;

function Dialog<C>({
  as: Component = 'div',
  open: openProp,
  overtitle,
  title,
  subtitle,
  children,
  actions,
  onExit,
  messages,
  width,
  className,
  style,
  ...props
}: DialogProps<C>): JSX.Element | null {
  const dialog = useDialogState({ open: openProp, animated: true });
  const { open, setOpen } = dialog;

  // Update visible state when open value changes
  useEffect(() => {
    setOpen(openProp);
  }, [setOpen, openProp]);

  // Call onExit if no more visible
  useEffect(() => {
    if (onExit && !open) {
      onExit();
    }
  }, [open, onExit]);

  const popupRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (open && popupRef.current) {
      popupRef.current.scrollTo({
        top: 0,
      });
    }
  }, [open]);

  const rootStyle = {
    ['--dialog-width' as any]: width ? `var(--t-width-${width})` : undefined,
    ...style,
  };

  return (
    <AriaDialog
      state={dialog}
      ref={popupRef}
      aria-label={messages.label}
      hideOnEscape={onExit}
      hideOnInteractOutside={onExit}
      backdropProps={{ className: 'Dialog' }}
      className={classNames('Dialog-popup', className)}
      style={rootStyle}
    >
      <Component className="Dialog-component" {...props}>
        <>
          {title ? (
            <div className="Dialog-title">
              <Title as="h3" overtitle={overtitle} subtitle={subtitle}>
                {title}
              </Title>
            </div>
          ) : null}
          {children ? <div className="Dialog-content">{children}</div> : null}
          {actions ? <div className="Dialog-actionList">{actions}</div> : null}
          {messages.close && onExit ? (
            <div className="Dialog-closeButton">
              <Cta
                icon={Close}
                iconOnly
                children={messages.close}
                onClick={onExit}
                title={messages.close}
              />
            </div>
          ) : null}
        </>
      </Component>
    </AriaDialog>
  );
}

export default Dialog;
