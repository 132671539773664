import React from 'react';
import classNames from 'classnames';
import './FormFooter.scss';

export type FormFooterProps = React.ComponentPropsWithoutRef<'div'>;

function FormFooter({ className, ...props }: FormFooterProps): JSX.Element {
  return <div className={classNames('FormFooter', className)} {...props} />;
}

export default FormFooter;
