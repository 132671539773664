import { AppStore } from 'store';
import { createSelector } from 'reselect';
import { NotificationListName } from 'actions/notificationAction';
import { NotificationListState } from 'reducers/notificationReducer';
import { NotificationModel } from 'models/notification';

export function selectNotificationLists(store: AppStore) {
  return store.notification.lists;
}

export function selectNotificationListByName(store: AppStore, name: NotificationListName) {
  return selectNotificationLists(store)[name];
}

export function selectNotificationDetails(state: AppStore) {
  return state.notification.details;
}

export function makeSelectNotificationList() {
  return createSelector(
    selectNotificationLists,
    selectNotificationDetails,
    function selectName(_: AppStore, name: NotificationListName) {
      return name;
    },
    function selectNotificationList(
      lists: AppStore['notification']['lists'],
      details: AppStore['notification']['details'],
      name: NotificationListName,
    ): NotificationListState {
      const list = lists[name];

      // Replace the list data with up to date details from state
      if (list.data) {
        return {
          ...list,
          data: {
            ...list.data,
            items: list.data.items.map((notification: NotificationModel) => {
              const detail = details[notification.uuid];

              return detail?.data ?? notification;
            }),
          },
        };
      }

      return list;
    },
  );
}
