import NodeIterator from '../NodeIterator';
import { NodeProps } from '../Node';

export type PropsType = NodeProps;

function ZoneBehavior({ content }: PropsType): JSX.Element {
  return (
    <div className="Zone">
      <NodeIterator nodes={content} />
    </div>
  );
}

export default ZoneBehavior;
