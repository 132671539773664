import React from 'react';
import classnames from 'classnames';
import { OverridableComponentProps } from 'components/OverridableComponent';
import './ContainerRow.scss';

export type ContainerRowProps<As> = OverridableComponentProps<
  As,
  'div',
  {
    column?: 'default' | 'large' | 'full';
    overlay?: boolean;
    separator?: boolean;
    children?: React.ReactNode;
  }
>;

function ContainerRow<C>({
  column,
  overlay,
  separator,
  children,
  className,
  as: As = 'div',
  ...props
}: ContainerRowProps<C>): JSX.Element {
  const rootClasses = classnames(
    'ContainerRow',
    {
      [`ContainerRow-${column}`]: Boolean(column),
      'ContainerRow-overlay': overlay,
      'ContainerRow-separator': separator,
    },
    className,
  );

  return (
    <As className={rootClasses} {...props}>
      {children}
    </As>
  );
}

export default ContainerRow;
