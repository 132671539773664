import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgJobTeaser(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M31.1 16.91c.34-4.64 0-9.24 0-13.88A1.92 1.92 0 0029.43 1a63.17 63.17 0 00-9.81 0l-1.4 5.28c-.19 1 .24 1.53 1.29 1.53h3.73c.67.1.91.72.91 1.39L16 24.14c-.24.34-.38.77-.86.62s-.43-.62-.38-1.05l1.15-9C16 13.56 15.54 13 14.44 13H10.8c-1.2 0-1.53-.29-1.58-1.53 0-.34 0-.67.1-1.05.38-2.68.81-5.36 1.24-8 .14-.57 0-.57-.53-.38A14.15 14.15 0 004.2 6.47v.1A14.38 14.38 0 001 18.59a15.26 15.26 0 0016.7 12.59s10.53-1.1 12.78-10.34a6.16 6.16 0 00.19-.91s.39-2.25.43-3.02z" />
    </svg>
  );
}

export default forwardRef(SvgJobTeaser);
