import Header from 'components/ui/Header/Header';
import useTranslate from 'components/hooks/useTranslate';
import Link from 'components/utils/Link';
import { useHeaderContext } from 'components/ui/Header/HeaderProvider';
import NodeIterator from '../NodeIterator';
import { NodeProps } from '../Node';
// import { selectUser } from 'components/selectors/userSelectors';

export type PropsType = NodeProps;

function HeaderZone({ content }: PropsType): JSX.Element {
  const t = useTranslate();
  const { transparent } = useHeaderContext();

  // const user = useSelector(selectUser);

  return (
    <Header
      homeLink={<Link to="../">{t('identity.home')}</Link>}
      title={t('identity.baseline')}
      transparent={transparent}
      // secondaryLogoPath={user.program?.logo}
      // secondaryTitle={user.program?.name}
    >
      <NodeIterator nodes={content} />
    </Header>
  );
}

export default HeaderZone;
