import { ReactNode } from 'react';
import classNames from 'classnames';
import './DayDate.scss';

type DayDateProps = {
  month: ReactNode;
  day: ReactNode;
  dateTime: string;
  className?: string;
};

function DayDate({ month, day, dateTime, className, ...props }: DayDateProps): JSX.Element {
  const rootClasses = classNames('DayDate', className);

  return (
    <time className={rootClasses} dateTime={dateTime} {...props}>
      <span className="DayDate-month">{month}</span>
      <span className="DayDate-day">{day}</span>
    </time>
  );
}

export default DayDate;
