import { persistReducer } from 'redux-persist';
import { skipTampon } from '../actions/tamponActions';
import { createReducer, createPersistConfig } from './reducerUtils';

export type TamponState = {
  skipFrom: string | null;
  skipUntil: string | null;
};

const initialState: TamponState = {
  skipFrom: null,
  skipUntil: null,
};

export function skipTamponReducer(state: TamponState, action: ReturnType<typeof skipTampon>) {
  return {
    ...state,
    skipFrom: action.payload.skipFrom,
    skipUntil: action.payload.skipUntil,
  };
}

export const persistConfig = createPersistConfig('tampon');

export default persistReducer(
  persistConfig,
  createReducer(initialState, {
    [skipTampon.type]: skipTamponReducer,
  }),
);
