import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { selectNode } from 'selectors/treeSelectors';
import { NodeState } from 'reducers/nodesReducer';
import TreePage from './TreePage';
import Block from './Block';
import ZoneBehavior from './behavior/ZoneBehavior';
import BreadcrumbBehavior from './behavior/BreadcrumbBehavior';
import MenuBehavior from './behavior/menu/MenuBehavior';
import LinkBehavior from './behavior/link/LinkBehavior';
import DrupalContentBehavior from './behavior/DrupalContentBehavior';
import HeaderZone from './zone/HeaderZone';
import FooterZone from './zone/FooterZone';
import OverlayZone from './zone/OverlayZone';
import MainZone from './zone/MainZone';
import ToasterBehavior from './behavior/ToasterBehavior';
import MosaicBehavior from './behavior/link/ServiceMosaicBehavior';
import NodeHelp from './NodeHelp';
import FooterSocialsZone from './zone/FooterSocialsZone';
import FooterLinksZone from './zone/FooterLinksZone';
import HeaderNavigationZone from './zone/HeaderNavigationZone';
import ToolMenuBehavior from './behavior/menu/ToolMenuBehavior';
import SearchBarBehavior from './behavior/search/SearchBarBehavior';

function Loading(): JSX.Element | null {
  return null;
}

export type ViewType<V> = V extends {} ? React.ElementType<V> : V;

export type NodeProps<B = null, V = null> = NodeState<B> & {
  view?: ViewType<V>;
  rootProps: object;
};

type ComponentMapping = {
  [k: string]: React.ElementType<NodeProps<any, any>>;
};

const COMPONENT_MAPPING: ComponentMapping = {
  // Global
  Page: TreePage,
  Block,
  ZoneBehavior,
  MenuBehavior,
  LinkBehavior,
  DrupalContentBehavior,
  BreadcrumbBehavior,
  DrupalMediaBehavior: DrupalContentBehavior,
  header: HeaderZone,
  header_navigation: HeaderNavigationZone,
  main: MainZone,
  footer: FooterZone,
  footer_links: FooterLinksZone,
  footer_socials: FooterSocialsZone,
  overlay: OverlayZone,
  ToasterBehavior: ToasterBehavior,
  ToolMenuBehavior: ToolMenuBehavior,
  MosaicBehavior,
  SearchBarBehavior,

  // Search
  SearchResultsBehavior: lazy(
    () => import('./behavior/search/SearchResultsBehavior' /* webpackChunkName: "tree-search" */),
  ),

  // Page Accueil
  mini_teasers: lazy(() => import('./zone/MiniTeasersZone' /* webpackChunkName: "tree-home" */)),
  MySessionsBehavior: lazy(
    () => import('./behavior/event/MySessionsBehavior' /* webpackChunkName: "tree-home" */),
  ),
  MyWidgetsBehavior: lazy(function loader() {
    return import('./behavior/MyWidgetsBehavior' /* webpackChunkName: "tree-home" */);
  }),
  NativeFeedBehavior: lazy(function loader() {
    return import('./behavior/event/NativeFeedBehavior' /* webpackChunkName: "tree-home" */);
  }),
  JobTeaserFeedBehavior: lazy(function loader() {
    return import('./behavior/Feed' /* webpackChunkName: "tree-home" */);
  }),
  EventListBehavior: lazy(function loader() {
    return import('./behavior/EventListBehavior' /* webpackChunkName: "tree-home" */);
  }),
  ForeverFeedBehavior: lazy(function loader() {
    return import('./behavior/Feed' /* webpackChunkName: "tree-home" */);
  }),
  FeedBehavior: lazy(function loader() {
    return import('./behavior/FeedBehavior' /* webpackChunkName: "tree-home" */);
  }) as any,
  AffluenceWidgetBehavior: lazy(function loader() {
    return import('./behavior/AffluenceWidgetBehavior' /* webpackChunkName: "tree-home" */);
  }),
  CorpoWidgetBehavior: lazy(function loader() {
    return import('./behavior/CorpoWidgetBehavior' /* webpackChunkName: "tree-home" */);
  }) as any,
  ZoomWidgetBehavior: lazy(function loader() {
    return import('./behavior/ZoomWidgetBehavior' /* webpackChunkName: "tree-home" */);
  }),
  ArticleListBehavior: lazy(function loader() {
    return import('./behavior/article/ArticleListBehavior' /* webpackChunkName: "tree-home" */);
  }),
  JobWidgetBehavior: lazy(function loader() {
    return import('./behavior/job/JobWidgetBehavior' /* webpackChunkName: "tree-home" */);
  }),
  CantineWidgetBehavior: lazy(
    () => import('./behavior/cantine/CantineWidgetBehavior' /* webpackChunkName: "tree-home" */),
  ),
  CantineViewBehavior: lazy(
    () => import('./behavior/cantine/CantineViewBehavior' /* webpackChunkName: "cantine" */),
  ),
  CantineCameraBehavior: lazy(
    () => import('./behavior/cantine/CantineCameraBehavior' /* webpackChunkName: "cantine" */),
  ),
  EntityDetailBehavior: lazy(function loader() {
    return import('./behavior/EntityDetailBehavior' /* webpackChunkName: "tree-home" */);
  }),
  BookingWidgetBehavior: lazy(function loader() {
    return import('./behavior/BookingWidgetBehavior' /* webpackChunkName: "tree-home" */);
  }),

  // Page Jobs
  JobListBehavior: lazy(
    () => import('./behavior/job/JobListBehavior' /* webpackChunkName: "tree-job" */),
  ),

  ChatBehavior: lazy(
    () => import('./behavior/chatbot/ChatbotBehavior' /* webpackChunkName: "chatbot" */),
  ),

  // Page Mes Attestations
  DidocBehavior: lazy(function loader() {
    return import('./behavior/DidocBehavior' /* webpackChunkName: "tree-common" */);
  }),
  DocumentFormBehavior: lazy(function loader() {
    return import('./behavior/DocumentRequestFormBehavior' /* webpackChunkName: "tree-common" */);
  }),

  // Page Ma Finance
  MyFinanceBehavior: lazy(function loader() {
    return import('./behavior/finance/MyFinanceBehavior' /* webpackChunkName: "tree-common" */);
  }),

  // Page Bourses
  GrantsBehavior: lazy(function loader() {
    return import('./behavior/GrantsBehavior' /* webpackChunkName: "tree-grants" */);
  }),

  // Page Agenda
  AgendaBehavior: lazy(function loader() {
    return import('./behavior/event/AgendaBehavior' /* webpackChunkName: "tree-agenda" */);
  }),

  // Page Mes Notes
  MyNotesBehavior: lazy(
    () => import('./behavior/MyNotesBehavior' /* webpackChunkName: "tree-course" */),
  ),

  // Page Mes Notes
  CourseListBehavior: lazy(
    () => import('./behavior/CourseListBehavior' /* webpackChunkName: "tree-course" */),
  ),

  // Page Mon Profil
  UserProfileBehavior: lazy(function loader() {
    return import('./behavior/UserProfileBehavior' /* webpackChunkName: "tree-profile" */);
  }),

  // Page Map
  MapwizeBehavior: lazy(function loader() {
    return import('./behavior/MapwizeBehavior' /* webpackChunkName: "tree-map" */);
  }),

  // Page Maps People
  MapsPeopleBehavior: lazy(function loader() {
    return import('./behavior/MapsPeopleBehavior' /* webpackChunkName: "tree-map" */);
  }),

  // Page Flowise AI
  FlowiseAiBehavior: lazy(function loader() {
    return import('./behavior/FlowiseAiBehavior' /* webpackChunkName: "tree-map" */);
  }),

  // Pages Equipements
  RoomListBehavior: lazy(function loader() {
    return import('./behavior/room/RoomListBehavior' /* webpackChunkName: "tree-rooms" */);
  }),
  RoomAgendaBehavior: lazy(function loader() {
    return import('./behavior/room/RoomAgendaBehavior' /* webpackChunkName: "tree-rooms" */);
  }),
  RoomRequestFormBehavior: lazy(function loader() {
    return import('./behavior/room/RoomRequestFormBehavior' /* webpackChunkName: "tree-rooms" */);
  }),

  // Candidate behaviors
  CandidateDashboardBehavior: lazy(
    () =>
      import(
        './behavior/candidate/CandidateDashboardBehavior' /* webpackChunkName: "tree-candidate" */
      ),
  ),
  CandidateProgramBehavior: lazy(
    () =>
      import(
        './behavior/candidate/CandidateProgramBehavior' /* webpackChunkName: "tree-candidate" */
      ),
  ),

  // PGM Home
  VideoListBehavior: lazy(function loader() {
    return import('./behavior/VideoListBehavior' /* webpackChunkName: "tree-pgm" */);
  }),

  CertificateListBehavior: lazy(function loader() {
    return import('./behavior/CertificateListBehavior' /* webpackChunkName: "tree-pgm" */);
  }),

  TeamListBehavior: lazy(function loader() {
    return import('./behavior/TeamListBehavior' /* webpackChunkName: "tree-pgm" */);
  }),

  CertificateMenuBehavior: lazy(function loader() {
    return import('./behavior/CertificateMenuBehavior' /* webpackChunkName: "tree-pgm" */);
  }),

  SessionListBehavior: lazy(function loader() {
    return import('./behavior/SessionListBehavior' /* webpackChunkName: "tree-pgm" */);
  }),

  // Page Application liste
  ApplicationListBehavior: lazy(function loader() {
    return import('./behavior/ApplicationListBehavior' /* webpackChunkName: "tree-common" */);
  }),

  // Activities
  ActivityListBehavior: lazy(
    () =>
      import(
        './behavior/activity/ActivityListBehavior' /* webpackChunkName: "tree-activity-page" */
      ),
  ),
  ActivityViewBehavior: lazy(
    () =>
      import(
        './behavior/activity/ActivityViewBehavior' /* webpackChunkName: "tree-activity-page" */
      ),
  ),
  ActivityWidgetBehavior: lazy(
    () =>
      import(
        './behavior/activity/ActivityWidgetBehavior' /* webpackChunkName: "tree-activity-widget" */
      ),
  ),

  // Procedure
  ProcedureListBehavior: lazy(
    () =>
      import('./behavior/procedure/ProcedureListBehavior' /* webpackChunkName: "tree-procedure" */),
  ),

  // C2N
  C2nCampaignFormBehavior: lazy(
    () => import('./behavior/c2n/C2nCampaignFormBehavior') /* webpackChunkName: "tree-c2n" */,
  ),
  C2nCampaignListBehavior: lazy(
    () => import('./behavior/c2n/C2nCampaignListBehavior') /* webpackChunkName: "tree-c2n" */,
  ),

  // User
  UserDetailBehavior: lazy(
    () => import('./behavior/user/UserDetailBehavior') /* webpackChunkName: "tree-user" */,
  ),
  MyColleagueWidgetBehavior: lazy(
    () => import('./behavior/user/MyColleagueWidgetBehavior') /* webpackChunkName: "tree-user" */,
  ),
  ColleagueListBehavior: lazy(
    () => import('./behavior/user/ColleagueListBehavior') /* webpackChunkName: "tree-user" */,
  ),

  // Solidr
  SolidrFormBehavior: lazy(
    () => import('./behavior/SolidrFormBehavior') /* webpackChunkName: "tree-solidr" */,
  ),

  // Checklist
  ChecklistWidgetBehavior: lazy(
    () =>
      import(
        './behavior/checklist/ChecklistWidgetBehavior'
      ) /* webpackChunkName: "tree-checklist" */,
  ),

  // Custom script behavior
  ScriptBehavior: lazy(
    () => import('./behavior/ScriptBehavior') /* webpackChunkName: "tree-script" */,
  ),

  // Page notifications
  NotificationListBehavior: lazy(
    () =>
      import(
        './behavior/notification/NotificationListBehavior' /* webpackChunkName: "tree-notification" */
      ),
  ),

  // Prospect RH
  ProspectrhDashboardBehavior: lazy(
    () =>
      import(
        './behavior/prospectrh/ProspectrhDashboardBehavior' /* webpackChunkName: "tree-prospectrh" */
      ),
  ),

  // Layout
  FlexBehavior: lazy(
    () => import('./behavior/layout/FlexBehavior' /* webpackChunkName: "tree-layout" */),
  ),
  FlexCellBehavior: lazy(
    () => import('./behavior/layout/FlexCellBehavior' /* webpackChunkName: "tree-layout" */),
  ),
  ContainerBehavior: lazy(
    () => import('./behavior/layout/ContainerBehavior' /* webpackChunkName: "tree-layout" */),
  ),
};

export type PropsType<V> = {
  identifier: number;
  view?: ViewType<V>;
};

function Node<V>({ identifier, view, ...props }: PropsType<V>): JSX.Element | null {
  const node = useSelector(selectNode(identifier));

  if (!node || !node.enabled) {
    return null;
  }

  const Component = COMPONENT_MAPPING[node.code];

  if (!Component) {
    console.error(`Missing component for the code : ${node.code}`);

    return null;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Component
        {...node}
        view={view}
        {...props}
        rootProps={node.help ? { 'data-identifier': node.identifier } : {}}
      />
      {node.help ? <NodeHelp identifier={node.identifier} help={node.help} /> : null}
    </Suspense>
  );
}

export default Node;
