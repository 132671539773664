import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgWaze({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 128 128"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M94 13c-8.3 0-15 6.7-15 15 0 3.2 1 6.2 2.9 8.8L91.6 50c.6.8 1.5 1.2 2.4 1.2 1 0 1.8-.5 2.4-1.2l9.7-13.2c1.9-2.6 2.9-5.6 2.9-8.8 0-8.3-6.7-15-15-15zm0 6c5 0 9 4 9 9 0 1.9-.6 3.7-1.7 5.2L94 43.1l-7.3-9.9C85.6 31.7 85 29.9 85 28c0-5 4-9 9-9zM34 53C20.2 53 9 64.2 9 78c0 5.3 1.6 10.4 4.7 14.6.1.2.3.3.4.5l17.5 23.7c.6.7 1.4 1.2 2.4 1.2s1.8-.5 2.4-1.2l17.5-23.7.3-.4.1-.1C57.4 88.3 59 83.3 59 78c0-13.8-11.2-25-25-25zm54.664.707A3.08 3.08 0 0086.1 54.9l-1.8 2.4c-1 1.3-.8 3.2.6 4.2.5.4 1.2.6 1.8.6.9 0 1.8-.4 2.4-1.2l1.8-2.4c1-1.3.7-3.2-.6-4.2a2.878 2.878 0 00-1.636-.593zM34 59c10.5 0 19 8.5 19 19 0 4-1.2 7.9-3.6 11.1v.1L34 110 18.9 89.5c-.1-.1-.2-.2-.3-.4C16.2 85.9 15 82 15 78c0-10.5 8.5-19 19-19zm45.664 6.707A3.08 3.08 0 0077.1 66.9l-1.8 2.4c-1 1.3-.7 3.2.6 4.2.5.4 1.2.6 1.8.6.9 0 1.8-.4 2.4-1.2l1.8-2.4c1-1.3.7-3.2-.6-4.2a2.878 2.878 0 00-1.636-.593zM34 68a10 10 0 00-10 10 10 10 0 0010 10 10 10 0 0010-10 10 10 0 00-10-10zm42.338 9.537a2.941 2.941 0 00-2.139.764C72.9 79.3 72.8 81.2 74 82.5l2 2.2c.6.6 1.4 1 2.2 1 .7 0 1.5-.3 2-.8 1.2-1.1 1.3-3 .2-4.2l-2-2.2c-.55-.6-1.3-.925-2.062-.963zm10.1 11a2.937 2.937 0 00-2.137.764C83.1 90.4 83 92.3 84.1 93.5l2 2.2c.6.6 1.4 1 2.2 1 .7 0 1.5-.3 2-.8 1.2-1.1 1.3-3 .2-4.2l-2-2.2c-.55-.6-1.3-.925-2.063-.963zm10.199 11a2.937 2.937 0 00-2.137.764c-1.2 1.1-1.3 2.999-.2 4.199l2 2.2c.6.6 1.4 1 2.2 1 .7 0 1.5-.3 2-.8 1.2-1.1 1.3-3 .2-4.2l-2-2.2c-.55-.6-1.3-.925-2.063-.963zm10.2 11a2.941 2.941 0 00-2.138.764c-1 .9-1.2 2.4-.6 3.6V115c0 1.7 1.3 3 3 3h1c1.2 0 2.302-.7 2.702-1.8.5-1.1.3-2.4-.5-3.2l-1.4-1.5c-.55-.6-1.3-.925-2.063-.963zM47 112c-1.7 0-3 1.3-3 3s1.3 3 3 3h3c1.7 0 3-1.3 3-3s-1.3-3-3-3h-3zm15 0c-1.7 0-3 1.3-3 3s1.3 3 3 3h3c1.7 0 3-1.3 3-3s-1.3-3-3-3h-3zm15 0c-1.7 0-3 1.3-3 3s1.3 3 3 3h3c1.7 0 3-1.3 3-3s-1.3-3-3-3h-3zm15 0c-1.7 0-3 1.3-3 3s1.3 3 3 3h3c1.7 0 3-1.3 3-3s-1.3-3-3-3h-3z" />
    </svg>
  );
}

export default forwardRef(SvgWaze);
