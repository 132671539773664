import { useMemo } from 'react';
import Icon from 'components/ui/Icon';
import Badge from 'components/ui/Badge';
import NotificationList from 'components/tree/behavior/notification/NotificationList';
import { useSelector } from 'react-redux';
import { AppStore } from 'store';
import useNotificationContext from 'components/provider/useNotificationContext';
import { makeSelectNotificationList } from 'selectors/notificationSelectors';
import { ToolMenuBehaviorProps } from './ToolMenuBehavior';

function NotificationMenuRenderer({
  behavior: { title, referencePage },
}: ToolMenuBehaviorProps): JSX.Element {
  const selectNotificationList = useMemo(makeSelectNotificationList, []);

  const { data } = useSelector((store: AppStore) => selectNotificationList(store, 'simple'));
  const count = data?.items.filter((n) => !n.read).length;
  const { requestPermission } = useNotificationContext();

  return (
    <div title={title}>
      {/* value={null}
      icon=
      {
        <Badge value={count || undefined}>
          <Icon name="notif" size="md" />
        </Badge>
      }
      caret={false}
      role="listbox" popoverProps=
      {{ width: 'sm', anchor: { vertical: 'bottom', horizontal: 'right' } }}
      onClick={requestPermission} */}
      <NotificationList allPage={referencePage} onNotificationClick={() => {}} />
    </div>
  );
}

export default NotificationMenuRenderer;
