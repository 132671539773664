import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgLink({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M17.19 6.75H14v-1.5h5.75V11h-1.5V7.81l-6.72 6.72-1.06-1.06 6.72-6.72zm-10.94.5H10v1.5H7.75v8.5h8.5V15h1.5v3.75H6.25V7.25z" />
    </svg>
  );
}

export default forwardRef(SvgLink);
