import DayDate from 'components/ui/DayDate/DayDate';
import { dateFormat, dateParse, ParsableDate } from 'components/utils/DateFormat';

export type EventDayDateProps = {
  date: ParsableDate;
};

function EventDayDate({ date }: EventDayDateProps): JSX.Element {
  const start = dateParse(date);

  return (
    <DayDate
      dateTime={String(date)}
      month={dateFormat(start, { month: 'short' })}
      day={start.day}
    />
  );
}

export default EventDayDate;
