import { ReactElement, ReactNode } from 'react';

export type MenuEntryThirdProps = {
  title: ReactNode;
  children?: ReactNode;
  link?: ReactElement<{
    children: ReactNode;
    className: string;
  }>;
};

function MenuEntryThird({ title, children, link = <span /> }: MenuEntryThirdProps): JSX.Element {
  return (
    <>
      {link ? (
        <link.type {...link.props} className="MenuEntryThird-link">
          {title}
        </link.type>
      ) : (
        title
      )}
      {children}
    </>
  );
}

export default MenuEntryThird;
