import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgMysupport(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 48 30"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.75A1.25 1.25 0 006.75 3v22.25h35.5V3A1.25 1.25 0 0041 1.75H8zm35.75 23.5V3A2.75 2.75 0 0041 .25H8A2.75 2.75 0 005.25 3v22.25h-5V27A2.75 2.75 0 003 29.75h42A2.75 2.75 0 0047.75 27v-1.75h-4zm-42 1.5V27A1.25 1.25 0 003 28.25h42A1.25 1.25 0 0046.25 27v-.25H1.75z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.267 5.56l15.004 5.572-3.124 3.124 3.535 3.536-3.182 3.182-3.536-3.535-3.124 3.124-5.573-15.004zm2.546 2.545l3.62 9.744 2.53-2.532 3.537 3.535 1.06-1.06-3.535-3.536 2.532-2.532-9.744-3.62z"
      />
    </svg>
  );
}

export default forwardRef(SvgMysupport);
