import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgEvents({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 36 36"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 1.75C9.025 1.75 1.75 9.025 1.75 18S9.025 34.25 18 34.25 34.25 26.975 34.25 18 26.975 1.75 18 1.75zM.25 18C.25 8.197 8.197.25 18 .25S35.75 8.197 35.75 18 27.803 35.75 18 35.75.25 27.803.25 18z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 3.5v3h-1.5v-3h1.5zm8.78 7.53l-9.488 9.488-7.027-5.946.97-1.144 5.973 5.054L26.47 9.97l1.06 1.06zM7 18.25H4v-1.5h3v1.5zm25 0h-3v-1.5h3v1.5zM18.75 29.5v3h-1.5v-3h1.5z"
      />
    </svg>
  );
}

export default forwardRef(SvgEvents);
