import { useSelector } from 'react-redux';
import { Route, Navigate, Routes } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';
import PageProvider from 'components/tree/PageProvider';
import ErrorPage from 'components/pages/ErrorPage';
import LoginPage from 'components/pages/LoginPage';
import ResetPasswordPage from 'components/pages/ResetPasswordPage';
// import Help from 'components/Help';
import PageLoader from 'components/ui/PageLoader';
import UserNotFound from 'components/errors/UserNotFound';
import DialogEventRegister from 'components/dialogs/DialogEventRegister';
import PageProgress from 'components/ui/PageProgress/PageProgress';
import DialogPolicy from 'components/dialogs/DialogPolicy';
import DialogLocale from 'components/dialogs/DialogLocale';
import { selectAuth } from 'selectors/authSelectors';
import { selectConfig } from 'selectors/configSelectors';
import { selectUser, selectUserError } from 'selectors/userSelectors';
import { selectProgress } from 'selectors/uiSelectors';
import { getLocaleCodes } from 'services/localeHelper';
import DialogEventSubscribe from './dialogs/DialogEventSubscribe';
import useTranslate from './hooks/useTranslate';

function PageProgressing(): JSX.Element | null {
  const progressing = useSelector(selectProgress);
  const t = useTranslate();

  if (progressing) {
    return <PageProgress aria-label={t('label.loading')} aria-live="polite" aria-busy />;
  }

  return null;
}

function Main(): JSX.Element {
  const { identity, method, loading } = useSelector(selectAuth);
  const user = useSelector(selectUser);
  const error = useSelector(selectUserError);
  const availableLocales = getLocaleCodes();
  const location = useLocation();

  if (error && error.error !== 'auth:access_token_expired') {
    return (
      <ErrorPage>
        <UserNotFound error={error} />
      </ErrorPage>
    );
  }

  // Show login if no token was found
  const isNeedingToken = identity.accessToken === null;
  const isCreatingToken = loading && method === 'codeToken';

  if (isNeedingToken && !isCreatingToken && location.pathname !== '/password') {
    return <LoginPage />;
  }

  // Show locale dialog when user locale was not found to force locale saving
  if (
    user.userId &&
    (user.settings.locale === null || !availableLocales.includes(user.settings.locale))
  ) {
    return <DialogLocale open />;
  }

  // Show policy dialog if the user has not opt in yet
  if (user.userId && !user.settings.hasReadPolicy) {
    return <DialogPolicy open />;
  }

  return (
    <>
      <Routes>
        <Route path="oups" element={<OupsRoute />} />
        <Route path="close" element={<CloseRoute />} />
        <Route path="password" element={<ResetRoute />} />
        <Route path="" element={<HomeRoute />} />
        <Route path=":slug/*" element={<PageRoute />} />
      </Routes>
      {/* <Help /> */}
      <PageProgressing />
      <DialogEventSubscribe />
      <DialogEventRegister />
    </>
  );
}

function PageRoute(): JSX.Element | null {
  const params = useParams();

  if (params.slug) {
    return <PageProvider slug={params.slug} query={window.location.search.replace('?', '')} />;
  }

  return null;
}

function ResetRoute(): JSX.Element {
  return <ResetPasswordPage />;
}

function OupsRoute(): JSX.Element {
  return (
    <ErrorPage>
      <UserNotFound error={{ code: null }} />
    </ErrorPage>
  );
}

function HomeRoute(): JSX.Element {
  const config = useSelector(selectConfig);

  return <Navigate to={`/${config.home}`} />;
}

function CloseRoute(): JSX.Element {
  window.close();

  return <PageLoader />;
}

export default Main;
