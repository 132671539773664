import { DIALOG_NAME } from 'actions/uiActions';
import { initialFiltersState } from 'reducers/uiReducer';
import { AppStore } from '../store';

export function selectProgress(store: AppStore) {
  return store.ui.progress.active;
}

export function selectChatbot(store: AppStore) {
  return store.ui.chatbot;
}

export function selectToasts(store: AppStore) {
  return store.ui.toaster.toasts;
}

export function selectDialogByName(store: AppStore, name: DIALOG_NAME) {
  return store.ui.dialog[name];
}

export function selectFilters(store: AppStore) {
  return store.ui.filters;
}

export function selectFiltersByScope(store: AppStore, scope: string) {
  return store.ui.filters[scope] || initialFiltersState;
}

export function selectSettings(store: AppStore) {
  return store.ui.settings;
}
