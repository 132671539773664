import { sendRoomRequest, receiveRoomRequest, errorRoomRequest } from 'actions/roomRequestActions';
import { ErrorModel, RoomRequestModel } from 'models';
import { createReducer } from './reducerUtils';

export type RoomRequestState = Readonly<{
  roomRequest: RoomRequestModel | null;
  status: number | null;
  error: ErrorModel | null;
}>;

const initialState: RoomRequestState = {
  roomRequest: null,
  status: null,
  error: null,
};

function sendRoomRequestReducer(
  state: RoomRequestState,
  action: ReturnType<typeof sendRoomRequest>,
) {
  return {
    ...state,
    ...action.payload,
  };
}

function receiveRoomRequestReducer(
  state: RoomRequestState,
  action: ReturnType<typeof receiveRoomRequest>,
) {
  return {
    ...state,
    ...action.payload,
  };
}

function errorRoomRequestReducer(
  state: RoomRequestState,
  action: ReturnType<typeof errorRoomRequest>,
) {
  return {
    ...state,
    ...action.payload,
  };
}

export default createReducer(initialState, {
  [sendRoomRequest.type]: sendRoomRequestReducer,
  [receiveRoomRequest.type]: receiveRoomRequestReducer,
  [errorRoomRequest.type]: errorRoomRequestReducer,
});
