import { put, take, takeLeading } from 'redux-saga/effects';
import { activateProgress, deactivateProgress } from 'actions/uiActions';
import { requestPage, receivePage, errorPage } from 'actions/treeActions';
import { requestUser, receiveUser, errorUser } from 'actions/userActions';
import {
  requestCandidacies,
  receiveCandidacies,
  errorCandidacies,
  requestCandidateContent,
  receiveCandidateContent,
  errorCandidateContent,
} from 'actions/candidateActions';
import { sendRoomRequest, receiveRoomRequest, errorRoomRequest } from 'actions/roomRequestActions';

function* start(): any {
  yield put(activateProgress());
  yield take(
    [
      receiveUser,
      errorUser,
      receivePage,
      errorPage,
      receiveRoomRequest,
      errorRoomRequest,
      receiveCandidateContent,
      errorCandidateContent,
      receiveCandidacies,
      errorCandidacies,
    ].map((actionCreator) => actionCreator.type),
  );
  yield put(deactivateProgress());
}

export default function* progressSaga(): any {
  yield takeLeading(
    [requestUser, requestPage, sendRoomRequest, requestCandidacies, requestCandidateContent].map(
      (actionCreator) => actionCreator.type,
    ),
    start,
  );
}
