import { call, takeEvery } from 'redux-saga/effects';
import { errorPage } from 'actions/treeActions';
import { errorUser } from 'actions/userActions';

function* logError(action: ReturnType<typeof errorUser | typeof errorPage>): any {
  yield call(console.error, action.payload.error);
}

export default function* logSaga() {
  yield takeEvery([errorUser.type, errorPage.type], logError);
}
