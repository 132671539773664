import { useMemo } from 'react';
import shortid from 'shortid';

function useAutoId(defaultId?: string, prefix: string = '') {
  const generatedId = useMemo(() => shortid.generate(), []);

  return defaultId ?? prefix + generatedId;
}

export default useAutoId;
