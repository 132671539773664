import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgConnect({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 33 36"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.25.25h18.5v17.851l-4.021-3.351H.25V.25zm1.5 1.5v11.5h13.521l1.979 1.649V1.75H1.75zm21.745 14.745a4.25 4.25 0 116.01 6.01 4.25 4.25 0 01-6.01-6.01zm3.005.255a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM9.495 20.495a4.25 4.25 0 116.01 6.01 4.25 4.25 0 01-6.01-6.01zm3.005.255a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm12.35 6c-1.78 0-3.1 1.297-3.1 2.75V32h-1.5v-2.5c0-2.413 2.129-4.25 4.6-4.25h3.3c2.471 0 4.6 1.837 4.6 4.25V32h-1.5v-2.5c0-1.453-1.32-2.75-3.1-2.75h-3.3zm-14 4c-1.78 0-3.1 1.297-3.1 2.75V36h-1.5v-2.5c0-2.413 2.129-4.25 4.6-4.25h3.3c2.471 0 4.6 1.837 4.6 4.25V36h-1.5v-2.5c0-1.453-1.32-2.75-3.1-2.75h-3.3z"
      />
    </svg>
  );
}

export default forwardRef(SvgConnect);
