import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgInternship(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 36 41"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.549 15c0-7.318 5.932-13.25 13.25-13.25S31.049 7.682 31.049 15s-5.932 13.25-13.25 13.25S4.549 22.318 4.549 15zM17.799.25C9.653.25 3.049 6.854 3.049 15c0 3.527 1.238 6.764 3.302 9.302L.503 34.432l8.22-1.788 2.105 7.856 6.405-10.76a14.998 14.998 0 001.608-.026l6.118 10.843 2.12-7.913 8.221 1.788-5.934-10.279A14.687 14.687 0 0032.549 15c0-8.146-6.604-14.75-14.75-14.75zm10.52 25.09a14.718 14.718 0 01-7.869 4.172l4.01 7.109L26 30.874l6.306 1.372-3.987-6.907zm-12.74 4.244a14.71 14.71 0 01-8.17-4.114l-3.912 6.776 6.305-1.372 1.556 5.804 4.222-7.094zm5.174-18.282L17.827 4.81l-2.924 6.49-6.683.908 4.897 4.86-1.256 7.018 5.966-3.427 5.966 3.427-1.255-7.018 4.896-4.86-6.681-.907zm-4.596.867l1.67-3.708 1.667 3.7.001.001.229.51.552.079h.004l3.94.535-2.914 2.893-.002.002-.38.38.095.531.732 4.091-3.425-1.966-.5-.284-.497.284-3.425 1.966.732-4.091.095-.532-.38-.38-2.917-2.894 3.941-.536.547-.074.235-.507z"
      />
    </svg>
  );
}

export default forwardRef(SvgInternship);
