import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { receiveActivitySubscribe, receiveActivityCreate } from 'actions/activityActions';
import cdmApi, { resolveError } from '../services/cdmApi';
import { requestUser, receiveUser, errorUser, updateUser, patchUser } from '../actions/userActions';

export function* requestUserTask(): any {
  try {
    const response = yield call(cdmApi.get, '/user');
    yield put(receiveUser(response.data.user));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorUser(err));
  }
}

export function* updateUserTask(action: ReturnType<typeof updateUser>): any {
  try {
    const { settings } = action.payload;
    const response = yield call(cdmApi.put, '/user/settings', { settings });
    yield put(receiveUser(response.data.user));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorUser(err));
  }
}

export function* activitySubscribeTask(
  action: ReturnType<typeof receiveActivitySubscribe | typeof receiveActivityCreate>,
) {
  const { subscriptions } = action.payload;
  yield put(patchUser({ activities: subscriptions }));
}

export function* watchRequestUser() {
  yield takeLatest(requestUser.type, requestUserTask);
}

export function* watchUpdateUser() {
  yield takeLatest(updateUser.type, updateUserTask);
}

export function* watchActivitySubscribe() {
  yield takeLatest(
    [receiveActivitySubscribe.type, receiveActivityCreate.type],
    activitySubscribeTask,
  );
}

export default function* userSaga() {
  yield fork(watchRequestUser);
  yield fork(watchUpdateUser);
  yield fork(watchActivitySubscribe);
}
