import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgUnit4({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 772.148 183.993"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M120.41 2.413v106.5c0 26.525-15.841 42.361-42.373 42.361-26.704 0-42.645-15.836-42.645-42.361V2.413H0v102.463c0 47.322 31.361 79.116 78.037 79.116 46.67 0 78.027-31.794 78.027-79.116V2.413H120.41zM278.73 0c-46.676 0-78.035 31.79-78.035 79.104v103.308h35.666V75.082c0-26.532 15.839-42.372 42.369-42.372 26.701 0 42.643 15.84 42.643 42.372v107.33h35.397V79.104C356.77 31.79 325.408 0 278.73 0zm131.05 2.413h35.396v179.999H409.78zm82.672 0v33.513h53.013v146.486h35.389V35.926h53.015V2.413zM772.148 2.413h-38.296L647.81 122.922a16.265 16.265 0 00-2.693 12.241 16.253 16.253 0 006.75 10.558 16.267 16.267 0 009.376 2.95h.004l65.683-.03v33.771h32.758l.103-61.933h-70.33L772.148 2.413z" />
    </svg>
  );
}

export default forwardRef(SvgUnit4);
