/**
 * Append a JWT token to a URL as a query string parameter.
 * Used to build URL linking to CDM.
 *
 * @param url URL on which token sould be added
 * @param token Token to add to the URL
 * @param name Query parameter name for the token
 */
function tokenize(url: string, token: string, name: string = 'accessToken') {
  const index = url.indexOf('?');
  const separator = index === -1 ? '?' : '&';

  return `${url}${separator}${name}=${token}`;
}

export default tokenize;
