import { useState, useMemo, useCallback } from 'react';
import Editorial from 'components/ui/Editorial';
import Flex from 'components/grid/Flex';
import Title from 'components/ui/Title';
import Spinner from 'components/ui/Spinner';
import { PageModel } from 'models';
import FormFooter from 'components/form/FormFooter';
import Button from 'components/ui/Button';
import Link from 'components/utils/Link';
import useMessages from 'components/hooks/useMessages';
import Accordion from 'components/ui/Accordion/Accordion';
import WidgetPagination from 'components/ui/WidgetGrid/WidgetPagination';
import cdmApi from 'services/cdmApi';
import { useDispatch } from 'react-redux';
import { requestNotificationList } from 'actions/notificationAction';
import { NotificationModel } from 'models/notification';
import useNotificationList from './useNotificationList';
import NotificationItem from './NotificationItem';

type NotificationListProps = {
  active?: NotificationModel['uuid'];
  full?: boolean;
  allPage?: PageModel;
  onNotificationClick?(): void;
};

function NotificationList({
  active,
  full,
  allPage,
  onNotificationClick,
}: NotificationListProps): JSX.Element {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const {
    data: pager,
    getting,
    error,
  } = useNotificationList(
    full ? 'full' : 'simple',
    useMemo(
      () => ({
        page,
        readAt: full ? undefined : 0,
      }),
      [full, page],
    ),
  );

  const messages = useMessages(
    (t, e) => ({
      title: t('notification.list.title'),
      typeLabel: t('notification.list.type.label'),
      empty: t('notification.list.empty'),
      seeAll: t('notification.list.more'),
      readAll: t('notification.list.readAll'),
      loading: t('label.loading'),
      error: e,
    }),
    [error],
  );

  const handleReadAll = useCallback(async () => {
    try {
      await cdmApi.post('/notification/read');

      dispatch(requestNotificationList('full'));
      dispatch(requestNotificationList('simple'));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  const actions = (
    <>
      <Flex alignItems="center">
        {pager?.items && getting ? <Spinner aria-label={messages.loading} /> : null}
        {full && pager?.totalPages ? (
          <WidgetPagination pageCount={pager.totalPages} currentPage={page} onChange={setPage} />
        ) : null}
      </Flex>
    </>
  );

  return (
    <div>
      {full ? (
        <Title as="h1" size={1}>
          {messages.title}
          {actions}
        </Title>
      ) : null}
      {pager?.items.length ? (
        <Flex style={{ marginBottom: '1em' }}>
          <div style={{ marginLeft: 'auto', marginRight: full ? undefined : '1em' }}>
            <Button onClick={handleReadAll}>{messages.readAll}</Button>
          </div>
        </Flex>
      ) : null}
      {pager?.items.length ? (
        <Accordion gap={full ? 'sm' : undefined}>
          {pager.items.map((notification) => (
            <NotificationItem
              key={notification.uuid}
              notification={notification}
              full={full}
              active={active === notification.uuid}
              to={allPage ? `/${allPage.slug}/${notification.uuid}` : undefined}
              onClick={onNotificationClick}
            />
          ))}
        </Accordion>
      ) : getting ? (
        <Editorial>
          <Spinner aria-label={messages.loading} />
        </Editorial>
      ) : error ? (
        <Editorial style={{ margin: '1em' }}>{messages.error(error)}</Editorial>
      ) : (
        <Editorial style={{ margin: '1em' }}>{messages.empty}</Editorial>
      )}
      {allPage ? (
        <FormFooter style={{ margin: '1em' }}>
          <Button as={Link} to={`/${allPage.slug}`}>
            {messages.seeAll}
          </Button>
        </FormFooter>
      ) : null}
    </div>
  );
}

export default NotificationList;
