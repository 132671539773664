import classNames from 'classnames';
import './PageProgress.scss';

export type PageProgressProps = React.ComponentPropsWithoutRef<'div'>;

function PageProgress({ className, ...props }: PageProgressProps): JSX.Element {
  return <div className={classNames('PageProgress', className)} {...props} />;
}

export default PageProgress;
