import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgDefault({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 35 34"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 4.25h10.5v11.851l-4.021-3.351H5.25v-8.5zm1.5 1.5v5.5h5.521l1.979 1.649V5.75h-7.5z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.25.25h34V23h-1.5V1.75h-31v19.5H12v1.5H.25V.25z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 11.5c-.414 0-.789.167-1.06.44a1.494 1.494 0 00-.326 1.634c.075.182.185.347.325.486l.001.001a1.495 1.495 0 001.059.439A1.497 1.497 0 0023.5 13c0-.414-.167-.789-.44-1.06A1.494 1.494 0 0022 11.5zm-2.121-.621A2.993 2.993 0 0122 10c.828 0 1.58.337 2.121.879.542.542.879 1.293.879 2.121 0 .828-.337 1.58-.879 2.121a2.994 2.994 0 01-3.27.651 2.993 2.993 0 01-1.623-1.624 2.994 2.994 0 01.65-3.27zm-.654 8.046c-.72.134-1.085.34-1.263.554-.272.328-.579.903-.908 1.771h9.892c-.329-.868-.636-1.443-.908-1.77-.178-.215-.544-.42-1.263-.555-.705-.132-1.627-.175-2.775-.175s-2.07.043-2.775.175zm-.277-1.475c.852-.16 1.892-.2 3.052-.2 1.16 0 2.2.04 3.052.2.838.158 1.626.452 2.14 1.07.56.675 1.056 1.795 1.522 3.252l.313.978H14.973l.313-.979c.466-1.456.962-2.576 1.522-3.25m2.14-1.07c-.838.157-1.626.451-2.14 1.07zm.547 10.044a4.25 4.25 0 017.096 1.853 4.248 4.248 0 013.914 1.147A4.25 4.25 0 0131.75 33.5h-1.5a2.75 2.75 0 10-5.5 0h-1.5a4.25 4.25 0 011.94-3.568 2.75 2.75 0 00-5.38 0 4.25 4.25 0 011.94 3.568h-1.5a2.75 2.75 0 10-5.5 0h-1.5a4.25 4.25 0 011.94-3.568 2.749 2.749 0 00-5.38 0 4.249 4.249 0 011.94 3.568h-1.5a2.75 2.75 0 00-5.5 0h-1.5a4.25 4.25 0 015.159-4.152 4.25 4.25 0 018.182 0 4.248 4.248 0 011.818 0 4.25 4.25 0 011.086-1.853z"
      />
    </svg>
  );
}

export default forwardRef(SvgDefault);
