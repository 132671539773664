import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgNotilus({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 170 40"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M90.7 5.47v4h-5c-3 0-3-.23-3-3.21V2.15h4.65c3.25 0 3.39.34 3.39 3.32m-8 33.47V17.22h8v21.72z" />
      <path d="M125.12 5.26l-23.63 25.93-.22.24-1.54 1.9a3.93 3.93 0 00-1 1.57c-.27.66.27 1.32 1.09 1.32a5.3 5.3 0 002.28-.74 49 49 0 007.24-5 61.76 61.76 0 005.27-5.12c.65-.74.94-.9 1.22-.9a.39.39 0 01.37.57 4.58 4.58 0 01-.55.66l-1.4 1.57-.73.66-2.19 2c-7.61 6.72-13.13 9.08-17.01 8.95s-5.53-2.73-4.42-5.45a13.48 13.48 0 011.17-1.82l1.64-2 .65-.75L115.83 4.1c1.19-1.32 2.4-2.56 2.4-2.56h10.4z" />
      <path d="M144.89 21.86l-5.52 6.28c-1.41 1.65-2.26 2.47-2.79 3.05L135 32.85l-.11.16a3.77 3.77 0 00-1 1.49 1 1 0 00.88 1.48 4.42 4.42 0 002.19-.66 9.81 9.81 0 002.17-1.24 36.87 36.87 0 006.47-5.28l2.8-2.8.74-.74c.32-.33.61-.5.78-.42.36 0 .51.25.39.5a17.88 17.88 0 01-2.15 2.31c-.53.58-1.06 1.07-1.68 1.65-8.31 7.36-11.95 9.34-15.9 9.67-5 .33-7-2.07-5.5-5.45a4.75 4.75 0 01.69-1.08l-.92.75a26.85 26.85 0 01-6.66 4c-3.66 1.49-5.87 1.74-8.17 1.24-2.82-.58-3.81-3.22-2.1-5.7a30.2 30.2 0 012.42-3l2.71-3.06 5.28-5.95c1.75-2.06 2.67-3.38 2.67-3.38h10.52l-3.72 4-4.88 5.62-1.51 1.65-2.14 2.31-.64.66-1.4 1.57a4.91 4.91 0 00-1.23 1.76.87.87 0 00.83 1.24c.62.08 1-.08 2.55-.66a38.14 38.14 0 006.47-4.05 33 33 0 004.58-4.13l5.19-5.86c2.06-2.4 3.65-4.13 3.65-4.13h9.82z" />
      <path d="M156.58 17.63l8.42.13-.36 4.46v.33l-.5 3.52c-.89 6.31-4.89 10.41-11.87 12-4 .82-6.79.82-8.18.41a4.25 4.25 0 01-2.35-1.23c-.79-.82-.52-2 .87-2.95a7.3 7.3 0 011.16-.57c1.14-.41 1.4-.33 1.41.24a5 5 0 01-1 1.89c-.11.16-.06.41.22 1 .58 1.07 1.66 1.07 3.92-.24 1.08-.66 2.66-2.3 5.06-5.91a24.36 24.36 0 002.8-6.71 19.82 19.82 0 00.64-5.16l-.82.65-.63.66-1.9 2a22.51 22.51 0 01-2 2.08l-.62.64-.68.69c-.21.16 0-.22-.24-.05a3.47 3.47 0 01-.91-.08s1-1.18 3.3-3.45a17.25 17.25 0 001.9-2M63 17.12h3.27a13.11 13.11 0 012.6 8.26 13.67 13.67 0 01-3 8.8 4.22 4.22 0 001.82 3c2 1.35 2.21 2 7.52 1.84l7.79-.11V33.3h-4.66c-2.29 0-3.65-.15-4.1-.44s-.67-1-.67-2.19V17.11h7.06V9.62h-7.04v-5.5c0-2.17-.09-2.43-3.06-2.43s-4.61.11-4.61.11v7.76H63zm-.8 8.13a7.43 7.43 0 11-7.43-7.58 7.51 7.51 0 017.43 7.58m-21.2.3a13.76 13.76 0 013.49-9.38l.27-.29V4.39c-.13-2.17-.14-2.66-3.12-2.66h-5.82v26L19 1.73H6.37v33.53c0 3.25.24 3.56 3 3.56h5.23V10.36l18.48 28.46h11.59v-3.63l-.22-.19A13.65 13.65 0 0141 25.55" />
    </svg>
  );
}

export default forwardRef(SvgNotilus);
