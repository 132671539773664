import classnames from 'classnames';
import { Override } from 'types';
import './Badge.scss';

export type BadgeTheme = 'primary' | 'secondary' | 'white' | 'black';

export type BadgeProps = Override<
  React.ComponentPropsWithoutRef<'span'>,
  {
    value?: React.ReactNode;
    label?: React.ReactNode;
    kind?: BadgeTheme;
  }
>;

function Badge({ value, label, kind, children, ...props }: BadgeProps): JSX.Element {
  const rootClasses = classnames('Badge', {
    [`is-${kind}`]: Boolean(kind),
  });

  return (
    <span className={rootClasses} {...props}>
      {children}
      {label ? <span className="Badge-label">{label}</span> : null}
      {undefined === value ? null : (
        <span
          className={classnames('Badge-value', {
            'has-children': Boolean(children),
          })}
        >
          {value}
        </span>
      )}
    </span>
  );
}

export default Badge;
