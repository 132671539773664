import { useMemo } from 'react';
import { useIntl, MessageDescriptor } from 'react-intl';

type PrimitiveType = string | number | boolean | null | undefined | Date;
type FormatXMLElementFn = (...args: any[]) => string | object;

export type TranslatorId = MessageDescriptor['id'];
export type TranslatorPrimitiveValues = Record<string, PrimitiveType>;
export type TranslatorReactValues = Record<
  string,
  PrimitiveType | React.ReactElement | FormatXMLElementFn
>;

export interface Translator {
  formatMessage(id: MessageDescriptor['id'], values?: TranslatorPrimitiveValues): string;
  formatMessage(
    id: MessageDescriptor['id'],
    values?: TranslatorReactValues,
  ): string | React.ReactNodeArray;
}

export type TranslatorValues = Parameters<Translator['formatMessage']>[1];

function useTranslate() {
  const intl = useIntl();

  return useMemo(() => {
    const translate: Translator['formatMessage'] = (id: any, values: any) =>
      intl.formatMessage({ id }, values) || id;

    return translate;
  }, [intl]);
}

export default useTranslate;
