import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgConference(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g stroke="#E2001A" strokeWidth={1.5} fill="none">
        <path d="M37 38.2h-6c.462-1.444.923-2.444 1.385-3 .692-.833 2.307-1 4.615-1m0 4h6c-.462-1.444-.923-2.444-1.385-3-.692-.833-2.307-1-4.615-1m0-7.25c.621 0 1.184.252 1.591.659.407.407.659.97.659 1.591s-.252 1.184-.659 1.591c-.407.407-.97.659-1.591.659a2.243 2.243 0 01-1.591-.659 2.243 2.243 0 01-.659-1.591c0-.621.252-1.184.659-1.591.407-.407.97-.659 1.591-.659zM21 21.2h9v9.5l-3-2.5h-6z" />
        <path d="M27 38.2H16v-21h32.5v22M31 46.7a3.5 3.5 0 00-7 0" />
        <path d="M26 49.7a3.5 3.5 0 00-7 0m17 0a3.5 3.5 0 00-7 0m17 0a3.5 3.5 0 00-7 0M41 46.7a3.5 3.5 0 00-7 0" />
      </g>
    </svg>
  );
}

export default forwardRef(SvgConference);
