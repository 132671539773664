import { useSelector } from 'react-redux';
import PageLoader from 'components/ui/PageLoader';
import Main from 'components/ui/Main/Main';
import Translate from 'components/utils/Translate';
import { selectTreeRoot } from 'selectors/treeSelectors';
import NodeIterator from '../NodeIterator';
import { NodeProps } from '../Node';

export type PropsType = NodeProps;

function renderLoading(): JSX.Element {
  return <PageLoader full label={<Translate id="content.fetching" />} />;
}

function renderContent({ content }: PropsType): JSX.Element {
  return (
    <Main>
      <NodeIterator nodes={content} />
    </Main>
  );
}

type STATUS_RENDERER_MAPPING = {
  [k: string]: (props: PropsType) => JSX.Element;
};

const STATUS_RENDERERS: STATUS_RENDERER_MAPPING = {
  lazy: renderLoading,
  ok: renderContent,
};

function MainZone(props: PropsType): JSX.Element | null {
  const root = useSelector(selectTreeRoot);
  const statusRenderer = root ? STATUS_RENDERERS[root.behavior.status] : undefined;

  if (statusRenderer) {
    return statusRenderer(props);
  }

  return null;
}

export default MainZone;
