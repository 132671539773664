import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgSecurity(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 31 39"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.75.25H7.705L.25 7.173V34.75H12v-1.5H1.75v-25h7v-6.5h13.5V14h1.5V.25zM7.25 2.72L2.91 6.75h4.34V2.72zM17 13.75H8v1.5h9v-1.5zm0 4H8v1.5h9v-1.5zm-4 4H8v1.5h5v-1.5z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 27h-1.5v-4.5a4.501 4.501 0 00-8.657-1.722A4.501 4.501 0 0018.5 22.5V27H17a1.5 1.5 0 00-1.5 1.5v9A1.5 1.5 0 0017 39h12a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0029 27zm-9-4.5a3 3 0 016 0V27h-6v-4.5zm9 15H17v-9h12v9z"
      />
    </svg>
  );
}

export default forwardRef(SvgSecurity);
