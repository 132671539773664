import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgIael({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M8.03 23.3h11.3v2.11H8.03zm0-6.08h11.3v2.11H8.03zm0-6.13h11.3v2.11H8.03z" />
      <path d="M29.34.51H7.07V5H2.62v26.49h22.26V27h4.5V.52zm-6.56 28.87H4.72V7.12h18.06zm4.5-4.5h-2.4V5H9.18V2.62h18.05v22.27z" />
    </svg>
  );
}

export default forwardRef(SvgIael);
