import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgSolidr({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 595.28 841.89"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M248.745 500.194c0-5.25-5-6.125-5-6.125h-13.25v11.813h13.25c0-.001 5-.438 5-5.688z" />
      <path d="M345.511 530.612c-2.334-5-14.037-2.833-14.037-2.833s-42.333 4.666-46.833-6.001c-1.167-2.666-.932-12.104 1.166-14.999 3.482-4.806 44.574-60.343 48.333-65.667s-2.297-16.015-10.334-12.333c-8.035 3.68-42.333 46.333-47.333 48s-5.228-.622-5.333-2.667 21.615-59.207 23.333-63.333c4.305-7.981-8.921-17.175-16-9-6.266 10.71-25.415 62.151-29.333 63.333s-5.678.842-6.667-1.667c-.46-8.392 6.63-54.693 7.485-60.39s-11.883-14.379-17.111-1.647c-5.244 11.701-11.742 54.735-17.252 59.901 0 0-1.004.636-2.134.746-.853.083-2.275-.013-3.118-.497-5.91-5.773-6.979-30.605-13.422-38.437-1.37-1.665-3.938-2.561-5.781-2.343-8.859 1.047-6.416 9.76-6.416 9.76s4.082 38.24 1.749 58.906c-.753 6.664-2.163 13.12-3.473 19.401-2.751 13.199-4.056 25.629 1.14 37.599 7.666 17.666 38.258 23.668 47.166 25.667 8.91 1.999 22.5 1.333 33-1.667s73.761-25.705 77.792-27.149c4.034-1.444 5.745-7.683 3.413-12.683zm-89.954 1.105c-1.649.894-5.188-.023-6.02-2.259-7.549-14.686-12.611-16.716-12.611-16.716h-6.755v15.975s-.239 3-4.113 3c-3.875 0-4.453-3-4.453-3v-38.315c0-2.292 2.314-3.126 2.314-3.126h22.825c4.062 0 9.653 4.891 10.25 7.73.444 2.118 1 8.5-.688 10.438-2.474 3.764-10.335 5.862-10.335 5.862s10.335 7.575 11.612 16.052c.474 2.648-.376 3.466-2.026 4.359z" />
    </svg>
  );
}

export default forwardRef(SvgSolidr);
