function isArrayOf<T>(subject: unknown, isT: (item: unknown) => item is T): subject is T[] {
  return Array.isArray(subject) && subject.every(isT);
}

export function makeIsArrayOf<T>(isT: (item: any) => item is T) {
  return (subject: any): subject is T[] => {
    return isArrayOf(subject, isT);
  };
}

export default isArrayOf;
