import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';

export type AccordionItemRef = {
  open: boolean;
  handleOpen(): void;
  handleClose(): void;
};

export type AccordionGroupContextType = {
  register(ref: AccordionItemRef): () => void;
  update(ref: AccordionItemRef): void;
};

const defaultContext: AccordionGroupContextType = {
  register: (ref) => {
    return () => {};
  },
  update: (ref) => {},
};

export const AccordionGroupContext = React.createContext(defaultContext);

export function useAccordionItem(
  open: AccordionItemRef['open'],
  handleOpen: AccordionItemRef['handleOpen'],
  handleClose: AccordionItemRef['handleClose'],
): React.Ref<AccordionItemRef> {
  const ref = useRef<AccordionItemRef>({
    open,
    handleOpen,
    handleClose,
  });

  const { register, update } = useContext(AccordionGroupContext);

  useEffect(() => {
    return register(ref.current);
  }, [register]);

  useEffect(() => {
    ref.current.open = open;
    update(ref.current);
  }, [open, update]);

  return ref;
}

export type AccordionGroupProps = {
  children: React.ReactNode;
};

function AccordionGroup({ children }: AccordionGroupProps): JSX.Element {
  const [refs, setRefs] = useState<AccordionItemRef[]>([]);

  const handleRegister = useCallback((ref: AccordionItemRef) => {
    let mounted = true;

    setRefs((refs) => {
      const index = refs.indexOf(ref);

      if (mounted && index === -1) {
        return [...refs, ref];
      }

      return refs;
    });

    return () => {
      mounted = false;
      setRefs((refs) => {
        const index = refs.indexOf(ref);
        if (index >= 0) {
          return [...refs].splice(index, 1);
        }

        return refs;
      });
    };
  }, []);

  const handleUpdate = useCallback(
    (ref: AccordionItemRef) => {
      const index = refs.indexOf(ref);

      if (ref.open && index >= 0) {
        refs.forEach((ref, i) => {
          if (i !== index) {
            ref.handleClose();
          }
        });
      }
    },
    [refs],
  );

  return (
    <AccordionGroupContext.Provider value={{ register: handleRegister, update: handleUpdate }}>
      {children}
    </AccordionGroupContext.Provider>
  );
}

export default AccordionGroup;
