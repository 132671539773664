import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Settings } from 'luxon';
import { ensureLocale, LocaleCode } from 'services/localeHelper';
import { selectLocale } from 'selectors/userSelectors';

export type LocaleProviderProps = {
  children: React.ReactNode;
};

function LocaleProvider({ children }: LocaleProviderProps): JSX.Element | null {
  const locale = useSelector(selectLocale);
  const [resolvedLocale, setResolvedLocale] = useState<LocaleCode | null>(null);
  const [messages, setMessages] = useState<Record<string, string> | null>(null);

  useEffect(() => {
    async function handleChange(locale: LocaleCode): Promise<void> {
      const messages = await ensureLocale(locale);

      Settings.defaultLocale = locale;
      setMessages(messages);
      setResolvedLocale(locale);
    }

    handleChange(locale);
  }, [locale]);

  if (resolvedLocale && messages) {
    return (
      <IntlProvider locale={resolvedLocale} messages={messages}>
        {children}
      </IntlProvider>
    );
  }

  return null;
}

export default LocaleProvider;
