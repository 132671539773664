import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgCourses({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 44 32"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.385.042l18.584 10.593h1.666v7.576a3.751 3.751 0 013 3.674v7.75h-7.5v-7.75a3.75 3.75 0 013-3.674v-6.076h-.166l-5.834 3.325v8.425c0 2.272-1.405 4.23-3.567 5.585-2.165 1.357-5.16 2.165-8.583 2.165-3.422 0-6.698-.807-9.145-2.142-2.41-1.315-4.205-3.262-4.205-5.608V15.46l-7.15-4.075L20.386.042zm0 1.726l15.555 8.867H21.885v1.5H35.94l-15.555 8.867-16.87-9.617 16.87-9.617zM9.135 16.315v7.57c0 1.52 1.19 3.073 3.424 4.291 2.197 1.199 5.22 1.959 8.426 1.959 3.204 0 5.909-.759 7.787-1.936 1.882-1.179 2.863-2.72 2.863-4.314v-7.57l-11.25 6.413-11.25-6.413zm29.159 3.979a2.25 2.25 0 013.841 1.591v6.25h-4.5v-6.25c0-.597.237-1.169.659-1.591z"
      />
    </svg>
  );
}

export default forwardRef(SvgCourses);
