import classnames from 'classnames';
import { OverridableComponentProps } from 'components/OverridableComponent';
import { Kind } from '../types.d';
import './Label.scss';

export type BaseProps = {
  wrap?: boolean;
  variant?: 'fill';
  kind?: Kind;
};
export type LabelProps<A> = OverridableComponentProps<A, 'span', BaseProps>;

function Label<A>({
  as: As = 'span',
  wrap,
  variant,
  kind,
  children,
  className,
  ...props
}: LabelProps<A>): JSX.Element {
  const rootClasses = classnames('Label', className, {
    'is-wrap': wrap,
    [`is-${variant}`]: variant,
    [`is-${kind}`]: kind,
  });

  return (
    <As className={rootClasses} {...props}>
      {children}
    </As>
  );
}

export default Label;
