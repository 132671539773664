import classnames from 'classnames';
import { Override } from 'types';
import './Icon.scss';

export const ICON_SIZE = {
  default: null,
  xxs: 'is-xxs',
  xs: 'is-xs',
  sm: 'is-sm',
  md: 'is-md',
  lg: 'is-lg',
  xl: 'is-xl',
};

export type IconSize = keyof typeof ICON_SIZE;

type BaseProps = {
  as?: React.ElementType;
  size?: IconSize;
  spin?: boolean;
  pause?: boolean;
  title?: React.ReactNode;
  name?: string;
};

export type IconProps = Override<React.SVGAttributes<SVGElement>, BaseProps>;

function Icon({
  as: As,
  size = 'default',
  spin,
  pause,
  className,
  name,
  ...props
}: IconProps): JSX.Element | null {
  if (name) {
    console.warn(
      `An Icon element is still using the 'name' prop with value '${name}', please use the 'as' prop.`,
    );
  }

  if (As) {
    const classes = classnames('Icon', className, ICON_SIZE[size], {
      'is-spinning': spin,
      'is-paused': pause,
    });

    return <As className={classes} {...props} />;
  }

  return null;
}

export default Icon;
