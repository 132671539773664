import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgTeams({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 2381.4 2354.5"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M2015.6 899.2c19.5 19.5 42.5 35 67.9 45.8 53 22.2 112.7 22.2 165.8 0 51.2-21.8 92-62.5 113.7-113.7 22.2-53 22.2-112.7 0-165.8-21.8-51.2-62.5-92-113.7-113.7-53-22.2-112.7-22.2-165.8 0-51.2 21.8-92 62.5-113.7 113.7-22.2 53-22.2 112.7 0 165.8 10.8 25.3 26.4 48.4 45.8 67.9zm-62.4 197.8v642.1h107c36.8-.2 73.4-3.6 109.5-10.4 36.3-6.4 71.3-18.6 103.7-36.2 30.6-16.6 57-40 77.3-68.2 21.3-31.3 32-68.6 30.5-106.5V1097h-428zm-346.8-269.2c28.4.2 56.6-5.5 82.8-16.7 51.2-21.8 91.9-62.5 113.6-113.7 22.2-53 22.2-112.7-.1-165.8-21.8-51.2-62.5-92-113.7-113.7-26.2-11.2-54.4-16.9-82.9-16.7-28.3-.2-56.3 5.5-82.3 16.7-19.4 8.3-25.5 19.1-52.2 32.1v329c26.8 13.1 32.8 23.8 52.2 32.1 26.1 11.3 54.2 16.9 82.6 16.7zm-134.8 1081.1c26.8 5.8 36.4 10.3 55.4 12.9 20.8 3 41.8 4.5 62.8 4.6 32.4-.2 64.8-3.6 96.5-10.4 32.3-6.5 63.3-18.6 91.5-35.7 27.7-17 51-40.2 68.2-67.7 19-32.1 28.3-69.1 26.9-106.4v-743h-401.3v945.7zM0 240.8v1872.9l1391.3 240.8V0L0 240.8zm1016.7 642.1l-240.8 10.7V1596l-160.5-9.4V899.9l-240.8 9.7V761.5l642.1-39.1v160.5z" />
    </svg>
  );
}

export default forwardRef(SvgTeams);
