import { requestPage, receivePage, errorPage } from 'actions/treeActions';
import { ErrorModel, NodeModel, PageBehaviorModel } from 'models';
import { createReducer, Reducer } from './reducerUtils';

export type TreeState = Readonly<{
  tree: NodeModel<PageBehaviorModel> | null;
  slug: string | null;
  error: ErrorModel | null;
  parameters: object;
  isFetching: boolean;
}>;

export const initialState: TreeState = {
  tree: null,
  slug: null,
  error: null,
  parameters: {},
  isFetching: false,
};

export const requestPageReducer: Reducer<TreeState, ReturnType<typeof requestPage>> = function (
  state,
  action,
) {
  return {
    ...state,
    slug: action.payload.slug,
    parameters: action.payload.parameters,
    isFetching: true,
    error: null,
  };
};

export const receivePageReducer: Reducer<TreeState, ReturnType<typeof receivePage>> = function (
  state,
  action,
) {
  return {
    ...state,
    tree: action.payload.tree,
    isFetching: false,
  };
};

export const errorPageReducer: Reducer<TreeState, ReturnType<typeof errorPage>> = function (
  state,
  action,
) {
  return {
    ...state,
    error: action.payload.error,
  };
};

export default createReducer(initialState, {
  [requestPage.type]: requestPageReducer,
  [receivePage.type]: receivePageReducer,
  [errorPage.type]: errorPageReducer,
});
