import { takeLatest, call, put } from 'redux-saga/effects';
import cdmApi, { resolveError } from 'services/cdmApi';
import {
  sendDocumentRequest,
  receiveDocumentRequest,
  errorDocumentRequest,
} from 'actions/documentRequestActions';

export function* sendDocumentRequestTask(action: ReturnType<typeof sendDocumentRequest>): any {
  try {
    const response = yield call(cdmApi.post, '/document/request', {
      data: action.payload.documentRequest,
    });
    yield put(receiveDocumentRequest(response.status));
  } catch (error) {
    const err = resolveError(error);
    yield put(errorDocumentRequest(err));
  }
}

export default function* treeSaga() {
  yield takeLatest(sendDocumentRequest.type, sendDocumentRequestTask);
}
