import useTranslate, { TranslatorValues } from 'components/hooks/useTranslate';
import { ErrorModel } from 'models';

const DEFAULT_MESSAGE = 'error.default';

export type ErrorType = ErrorModel | null | undefined;
export type OptionsType = {
  defaultMessage?: string;
} & TranslatorValues;

export function formatError(
  t: ReturnType<typeof useTranslate>,
  error: ErrorModel,
  { defaultMessage = DEFAULT_MESSAGE, ...otherValues }: OptionsType = {},
) {
  const values = {
    ...error.values,
    ...otherValues,
  };

  // Try to find a message from the error code
  const code = error.error ? error.error : error.code;
  const id = `error.${code}`;
  const message = t(id, values);

  if (message !== id) {
    // If a translation was found for the error code ID, use it
    return message;
  }

  // Try to find a message from the error status
  const status = error.status ?? 500;
  const statusId = `error.status.${status}`;
  const statusMessage = t(statusId, values);

  if (statusMessage !== statusId) {
    // If a translation was found for the error status ID, use it
    return statusMessage;
  }

  return t(defaultMessage, values);
}

function useErrorMessage(error: ErrorType, options?: OptionsType) {
  const t = useTranslate();

  return error ? formatError(t, error, options) : null;
}

export default useErrorMessage;
