import React, { lazy, useCallback, useMemo } from 'react';
import Link from 'components/utils/Link';
import ServiceTile from 'components/ui/ServiceList/ServiceTile';
import useMessages from 'components/hooks/useMessages';
import { useServiceListContext } from './ServiceListContext';
import { LinkViewProps } from './LinkBehavior';

const ServiceLinkIcon = lazy(
  () => import('./ServiceLinkIcon' /* webpackChunkName: "service-icons" */),
);

export type ServiceLinkViewProps = LinkViewProps &
  React.ComponentPropsWithRef<typeof Link> & { shrunk?: boolean };

function ServiceLinkView({
  identifier,
  to,
  title,
  titleLong,
  description,
  icon,
  content,
  type,
  shrunk,
  children,
  ...props
}: ServiceLinkViewProps): JSX.Element {
  const messages = useMessages(
    (t) => ({
      descriptionTitle: t('service_list.descriptionTitle'),
      addFavorite: t('service_list.addFavorite'),
      removeFavorite: t('service_list.removeFavorite'),
      showDescription: t('service_list.showDescription'),
      closeDescription: t('service_list.closeDescription'),
    }),
    [],
  );

  const { isFavorite, onFavoriteChange } = useServiceListContext();

  const favorite = useMemo(() => {
    return isFavorite(identifier);
  }, [identifier, isFavorite]);

  const handleFavoriteChange = useCallback(
    (favorite: boolean) => {
      onFavoriteChange(identifier, favorite);
    },
    [identifier, onFavoriteChange],
  );

  return (
    <ServiceTile
      shrunk={shrunk}
      title={title}
      subtitle={titleLong}
      description={description}
      icon={<ServiceLinkIcon name={icon} />}
      link={
        <Link to={to} title={title} {...props}>
          {title}
        </Link>
      }
      favorite={favorite}
      onFavoriteChange={handleFavoriteChange}
      messages={messages}
    />
  );
}

export default ServiceLinkView;
