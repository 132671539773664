import { Children, ReactNode } from 'react';
import './Footer.scss';

export type FooterLinksProps = {
  children?: ReactNode;
};

function FooterLinks({ children }: FooterLinksProps): JSX.Element {
  return (
    <div className="Footer-links">
      <ul className="Footer-linksList">
        {Children.map(children, (child, i) => (
          <li className="Footer-linkItem" key={i}>
            <div className={`Footer-divider ${i === 0 ? 'Footer-firstDivider' : ''}`}></div>
            {child}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FooterLinks;
