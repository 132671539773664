import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { skipTampon } from '../../actions/tamponActions';
import { selectTampon } from '../../selectors/tamponSelectors';
import TamponPage from '../pages/TamponPage';
import { dateParse } from '../utils/DateFormat';
import { resolveLocale, FALLBACK_LOCALE, LocaleCode } from '../../services/localeHelper';
import tampon from '../../data/tampon.json';

type TamponModel = {
  title: string;
  start: string;
  end: string;
  updatedAt: string;
  content: string;
};

function isObject(obj: unknown): obj is object {
  return tampon !== null && typeof tampon === 'object';
}

function isTampon(tampon: unknown): tampon is TamponModel {
  return (
    isObject(tampon) &&
    'title' in tampon &&
    'start' in tampon &&
    'end' in tampon &&
    'updatedAt' in tampon &&
    'content' in tampon
  );
}

const now = DateTime.local();

function resolveTampon(locale: LocaleCode): TamponModel | null {
  if (isObject(tampon)) {
    if (locale in tampon) {
      const resolvedTampon = tampon[locale];

      if (isTampon(resolvedTampon)) {
        return resolvedTampon;
      }
    }

    // Call again this function to find the fallback tampon
    if (locale !== FALLBACK_LOCALE) {
      return resolveTampon(FALLBACK_LOCALE);
    }
  }

  return null;
}

export type TamponProviderProps = {
  children: React.ReactNode;
};

function TamponProvider({ children }: TamponProviderProps): JSX.Element {
  const dispatch = useDispatch();
  const skip = useSelector(selectTampon);
  const locale = resolveLocale();
  const tampon = resolveTampon(locale);

  const handleSkip = useCallback(
    function handleSkip() {
      if (tampon) {
        dispatch(skipTampon(now.toISO(), tampon.end));
      }
    },
    [dispatch, tampon],
  );

  function showTampon(): boolean {
    // Don't show if no tampon was specified
    if (!tampon) {
      return false;
    }

    const start = dateParse(tampon.start);
    const end = dateParse(tampon.end);

    // Don't show if current date is not in tampon interval
    if (now < start || now > end) {
      return false;
    }

    // Show if not skipped
    if (!skip.skipFrom || !skip.skipUntil) {
      return true;
    }

    const updatedAt = dateParse(tampon.updatedAt);
    const skipFrom = dateParse(skip.skipFrom);
    const skipUntil = dateParse(skip.skipUntil);

    // Show if tampon was modified after previous skip
    return updatedAt > skipFrom && now <= skipUntil;
  }

  const show = useMemo(showTampon, [skip.skipFrom, skip.skipUntil, tampon]);

  if (show && tampon) {
    return (
      <TamponPage title={tampon.title} onSkip={handleSkip}>
        <div dangerouslySetInnerHTML={{ __html: tampon.content }} />
      </TamponPage>
    );
  }

  return <>{children}</>;
}

export default TamponProvider;
