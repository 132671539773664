import axios from 'axios';
import { CampusModel, ErrorModel } from 'models';
import { LocaleCode } from './localeHelper';

export type ApiParams = {
  locale?: LocaleCode | string | null;
  campus?: CampusModel['code'] | null;
};

const cdmApi = axios.create();

export default cdmApi;

export function isCancel(error: any) {
  return axios.isCancel(error);
}

export function createCancelSource() {
  return axios.CancelToken.source();
}

export function setBaseUrl(baseUrl: string) {
  cdmApi.defaults.baseURL = baseUrl;
}

export function setAccessToken(accessToken?: string | null) {
  if (accessToken) {
    cdmApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    delete cdmApi.defaults.headers.common.Authorization;
  }
}

export function resolveStatus(err: any) {
  return err?.response?.status ?? undefined;
}

export function resolveCode(err: any) {
  return err?.response?.data?.code ?? null;
}

export function resolveContext(err: any) {
  return err?.response?.data?.context ?? {};
}

export function resolveError(err: any): ErrorModel {
  const data = err?.response?.data;
  const code = data?.code ?? null;
  const context = data?.context ?? {};
  const violations = data?.violations ?? [];
  const status = data?.status ?? err?.response?.status ?? undefined;

  return {
    code,
    status,
    error: context.error,
    violations: violations,
    values: context,
    original: err,
  };
}
