import Node from 'components/tree/Node';
import MainMenu from 'components/ui/Header/MainMenu';
import Link from 'components/utils/Link';
import { ToolMenuBehaviorProps } from './ToolMenuBehavior';

function MainMenuRenderer({ behavior: { title }, content }: ToolMenuBehaviorProps): JSX.Element {
  return (
    <MainMenu homeLink={<Link to="/">{title}</Link>}>
      {content.map((node) => (
        <Node key={node.identifier} identifier={node.identifier} />
      ))}
    </MainMenu>
  );
}

export default MainMenuRenderer;
