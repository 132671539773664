import { Helmet } from 'react-helmet-async';
import { PageBehaviorModel } from 'models';
import Page from 'components/ui/Page/Page';
import NodeIterator from './NodeIterator';
import { NodeProps } from './Node';

export type PropsType = NodeProps<PageBehaviorModel>;

function TreePage({ content = [], behavior: { title } }: PropsType): JSX.Element {
  return (
    <Page>
      <Helmet>
        <title>{`${title} | makersboard`}</title>
      </Helmet>
      <NodeIterator nodes={content} />
    </Page>
  );
}

export default TreePage;
