import classnames from 'classnames';
import { OverridableComponentProps } from 'components/OverridableComponent';
import './Separator.scss';

export type BaseProps = {
  className?: string;
  position?: string;
  inline?: boolean;
};

export type SeparatorProps<As> = OverridableComponentProps<As, 'div', BaseProps>;

function Separator<S>({
  as: As = 'div',
  className,
  position,
  inline,
  ...props
}: SeparatorProps<S>): JSX.Element {
  const rootClasses = classnames(className, 'Separator', {
    [`is-${position}`]: position,
    'is-inline': inline,
  });

  return <As className={rootClasses} {...props} />;
}

export default Separator;
