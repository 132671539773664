import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgWireless(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 46.47 46.35"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M24.53 0c5.93 5.06 11.58 10.33 15.52 17.22A48.88 48.88 0 0146.47 42c0 2.05-.13 4.39-2.9 4.26-2.57-.12-2.22-2.41-2.29-4.26-.5-14.61-5.89-26.8-17.87-35.61C20.73 4.44 20 2.52 22.53 0z" />
      <path
        className="wireless_svg__cls-1"
        d="M33.59 40.57v2.5c-.11 1.83-.78 3.42-2.88 3.28-1.82-.12-2.28-1.62-2.29-3.29a40.59 40.59 0 00-.24-5.47 32.65 32.65 0 00-12.66-21.9c-1.6-1.25-4.35-2.37-2.11-5 1.75-2 3.59-.46 5.23.73a37.48 37.48 0 0114.95 29.15z"
      />
      <path
        className="wireless_svg__cls-1"
        d="M22.64 40.51v2c0 1.78-.38 3.52-2.55 3.57s-2.65-1.61-2.64-3.44a22.66 22.66 0 00-3.26-12.32 18.57 18.57 0 00-5.34-5.81c-1.69-1.15-3.61-2.63-2-4.8 1.87-2.51 3.86-.62 5.5.71 6.39 5.16 10.02 11.77 10.29 20.09zM12.54 40.88c-.27 2.32 0 5-3 5.07-2.74 0-2.76-2.44-2.7-4.44a10.19 10.19 0 00-4.89-9.23c-1.5-1-2.66-2.26-1.42-4.1C2 26 3.67 27 5.25 28.11a16.41 16.41 0 017.29 12.77z"
      />
    </svg>
  );
}

export default forwardRef(SvgWireless);
