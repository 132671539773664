import { ErrorModel, RoomRequestModel } from 'models';

export function sendRoomRequest(roomRequest: RoomRequestModel) {
  return {
    type: sendRoomRequest.type,
    payload: {
      roomRequest,
      status: null,
      error: null,
    },
  } as const;
}

sendRoomRequest.type = 'roomRequest/send' as const;

export function receiveRoomRequest(status: number) {
  return {
    type: receiveRoomRequest.type,
    payload: {
      status,
    },
  } as const;
}

receiveRoomRequest.type = 'roomRequest/receive' as const;

export function errorRoomRequest(error: ErrorModel) {
  return {
    type: errorRoomRequest.type,
    payload: {
      error,
    },
  } as const;
}

errorRoomRequest.type = 'roomRequest/error' as const;
