import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import SrOnly from '../SrOnly/SrOnly';
import Tooltip from '../Tooltip/Tooltip';
import Star from '../Icon/actions/Star';
import Infos from '../Icon/actions/Infos';
import './ServiceTile.scss';

export type ServiceTileProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  description?: ReactNode;
  link?: ReactElement;
  icon?: ReactElement<{ className: string }>;
  image?: ReactNode;
  favorite?: boolean;
  onFavoriteChange?(favorite: boolean): void;
  shrunk?: boolean;
  messages?: {
    descriptionTitle?: string;
    addFavorite?: string;
    removeFavorite?: string;
    showDescription?: string;
    closeDescription?: string;
  };
};

const DEFAULT_MESSAGES = {};

function ServiceTile({
  title,
  subtitle,
  children,
  description,
  link = <div />,
  icon,
  image,
  favorite,
  shrunk,
  messages = DEFAULT_MESSAGES,
  onFavoriteChange,
}: ServiceTileProps): JSX.Element {
  const actions = [
    messages.removeFavorite && messages.addFavorite ? (
      <Tooltip
        key="favorite"
        element={
          <button
            type="button"
            className="ServiceTile-action ServiceTile-favoriteToggle"
            onClick={onFavoriteChange ? () => onFavoriteChange(!favorite) : undefined}
          >
            <Icon as={Star} />
            <SrOnly>{favorite ? messages.removeFavorite : messages.addFavorite}</SrOnly>
          </button>
        }
      >
        {favorite ? messages.removeFavorite : messages.addFavorite}
      </Tooltip>
    ) : null,
    description && messages.showDescription ? (
      <Tooltip
        key="description"
        large
        title={messages.descriptionTitle ?? messages.showDescription}
        closeText={messages.closeDescription}
        element={
          <button type="button" className="ServiceTile-action ServiceTile-descriptionToggle">
            <Icon as={Infos} />
            <SrOnly>{messages.showDescription}</SrOnly>
          </button>
        }
      >
        {description}
      </Tooltip>
    ) : null,
  ].filter((action) => Boolean(action));

  return (
    <div
      className={classNames('ServiceTile', {
        'is-favorite': favorite,
        'ServiceTile--shrunk': shrunk,
      })}
    >
      <link.type
        {...link.props}
        className={classNames('ServiceTile-content', link.props.className)}
      >
        {icon ? (
          <icon.type
            {...icon.props}
            className={classNames('ServiceTile-icon', icon.props.className)}
          />
        ) : null}
        {image ? <div className="ServiceTile-image">{image}</div> : null}
        <span className="ServiceTile-title">{title}</span>
        {subtitle ? <span className="ServiceTile-subtitle">{subtitle}</span> : null}
        {children}
      </link.type>
      {actions.length ? <div className="ServiceTile-footer">{actions}</div> : null}
    </div>
  );
}

export default ServiceTile;
