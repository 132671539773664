import { useMemo } from 'react';
import { Duration } from 'luxon';
import DateFormat, { DateFormatProps, dateParse } from './DateFormat';
import DateAgo, { DateAgoProps } from './DateAgo';

type DateFormatOrRelativeProps = DateFormatProps &
  DateAgoProps & {
    max: string;
  };

function DateFormatOrRelative({
  date,
  preset,
  format,
  setZone,
  max,
  ...props
}: DateFormatOrRelativeProps): JSX.Element {
  const relative = useMemo((): boolean => {
    const datetime = dateParse(date);
    const duration = Duration.fromISO(max);

    return duration.isValid && duration.as('second') >= Math.abs(datetime.diffNow().as('second'));
  }, [date, max]);

  return relative ? (
    <DateAgo date={date} {...props} />
  ) : (
    <DateFormat date={date} preset={preset} format={format} setZone={setZone} />
  );
}

export default DateFormatOrRelative;
