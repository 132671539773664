import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgUser({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 3.25a4.25 4.25 0 100 8.5 4.25 4.25 0 000-8.5zm0 1.5a2.75 2.75 0 110 5.5 2.75 2.75 0 010-5.5zm1.65 8.5c2.453 0 4.481 1.78 4.595 4.049l.005.201V20h-1.5v-2.5c0-1.448-1.277-2.66-2.916-2.745l-.184-.005h-3.3c-1.666 0-3.001 1.16-3.095 2.59l-.005.16V20h-1.5v-2.5c0-2.296 1.958-4.142 4.385-4.245l.215-.005h3.3z" />
    </svg>
  );
}

export default forwardRef(SvgUser);
