import React, { useEffect, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'components/ui/Icon';
import Button from 'components/ui/Button';
import Toaster from 'components/ui/Toaster/Toaster';
import Toast from 'components/ui/Toaster/Toast';
import { NodeProps } from 'components/tree/Node';
import { ToastModel, CampusModel, ToastType } from 'models';
import useTimezone from 'components/hooks/useTimezone';
import useTranslate from 'components/hooks/useTranslate';
import { setToast, updateToast } from 'actions/uiActions';
import { updateUser } from 'actions/userActions';
import { selectToasts } from 'selectors/uiSelectors';
import { findDefaultTimezone } from 'services/localeHelper';

const TOAST_DEFAULT_AUTOCLOSE = 5;

function isSticky(toast: ToastType): boolean {
  return toast.show && toast.sticky;
}

function isNotSticky(toast: ToastType): boolean {
  return toast.show && !toast.sticky;
}

function renderTimezone(toast: ToastType, { dispatch }: RendererOptions): JSX.Element {
  function handleTimezone(): void {
    dispatch(
      updateUser({
        timezone: toast.referenceTimezone,
      }),
    );
  }

  return (
    <Fragment>
      <p>
        <FormattedMessage
          id="toaster.timezone.message"
          values={{
            systemTimezone: <strong>{toast.timezone}</strong>,
            campusTimezone: <strong>{toast.referenceTimezone}</strong>,
          }}
        />
      </p>
      {findDefaultTimezone() ? (
        <Button onClick={handleTimezone} style={{ marginTop: '1em' }}>
          <FormattedMessage id="toaster.timezone.button" />
        </Button>
      ) : null}
    </Fragment>
  );
}

function renderHtml(toast: ToastType, options: RendererOptions): JSX.Element | null {
  return toast.message ? <div dangerouslySetInnerHTML={{ __html: toast.message }} /> : null;
}

function renderDefault(toast: ToastType, options: RendererOptions): JSX.Element | null | string {
  return toast.message ? toast.message : null;
}

type RendererOptions = {
  dispatch: ReturnType<typeof useDispatch>;
};

type Renderers = {
  [R in string]: (toast: ToastType, options: RendererOptions) => React.ReactNode;
};

const RENDERERS: Renderers = {
  html: renderHtml,
  timezone: renderTimezone,
  default: renderDefault,
};

type RendererName = keyof typeof RENDERERS;

function isRendererName(rendererName?: string): rendererName is RendererName {
  return rendererName !== undefined && rendererName in RENDERERS;
}

function renderMessage(toast: ToastType, options: RendererOptions): React.ReactNode {
  const renderer = isRendererName(toast.messageRenderer)
    ? RENDERERS[toast.messageRenderer]
    : RENDERERS.default;

  return renderer(toast, options);
}

export type ToasterBehaviorProps = NodeProps<{
  toasts: ToastModel[];
  campus: CampusModel;
}>;

function ToasterBehavior({ behavior: { toasts, campus } }: ToasterBehaviorProps): JSX.Element {
  const dispatch = useDispatch();
  const storedToasts = useSelector(selectToasts);
  const timezone = useTimezone();

  function checkTimezoneEffect(): void {
    if (campus && campus.timezone && timezone && timezone !== campus.timezone) {
      dispatch(
        setToast({
          kind: 'primary',
          uid: 'locale_not_matching',
          messageRenderer: 'timezone',
          icon: 'info',
          show: true,
          sticky: true,
          timezone,
          referenceTimezone: campus.timezone,
        }),
      );
    } else {
      dispatch(
        updateToast({
          uid: 'locale_not_matching',
          show: false,
        }),
      );
    }
  }

  function setToastsEffect(): void {
    function toastIterator(toast: ToastModel): void {
      dispatch(
        setToast({
          kind: 'secondary',
          title: toast.title,
          message: toast.message,
          uid: toast.uid,
          icon: 'info',
          messageRenderer: 'html',
          show: true,
          sticky: true,
        }),
      );
    }

    toasts.forEach(toastIterator);
  }

  useEffect(checkTimezoneEffect, [dispatch, timezone, campus]);
  useEffect(setToastsEffect, [dispatch, toasts]);

  const t = useTranslate();
  const close = t('toaster.close');
  const options = { dispatch };

  function renderToast(toast: ToastType, i: number): JSX.Element {
    function handleClose(): void {
      dispatch(updateToast({ ...toast, show: false }));
    }

    // Stick toasts should have a default autoclose to not cover the whole screen
    const autoclose = toast.autoclose ?? (toast.sticky ? TOAST_DEFAULT_AUTOCLOSE : undefined);

    return (
      <Toast
        key={i}
        icon={toast.icon ? <Icon name={toast.icon} size="sm" /> : undefined}
        message={renderMessage(toast, options)}
        to={toast.to}
        title={toast.title}
        close={toast.uid ? close : undefined}
        onClose={toast.uid ? handleClose : undefined}
        autoclose={autoclose}
      />
    );
  }

  return (
    <Fragment>
      <Toaster sticky>{storedToasts.filter(isSticky).map(renderToast)}</Toaster>
      <Toaster>{storedToasts.filter(isNotSticky).map(renderToast)}</Toaster>
    </Fragment>
  );
}

export default ToasterBehavior;
