import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgSportEquipment(
  { title, titleId, ...props }: Props,
  svgRef?: Ref<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      viewBox="0 0 121.5 88.59"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M121.39 70.67L91.08.83A1.38 1.38 0 0089.82 0H31.68a1.38 1.38 0 00-1.26.83L.11 70.67a1.38 1.38 0 001.27 1.93h64.1c0-.52-.08-1-.08-1.58a20.9 20.9 0 01.26-3.11H21.43L28 46.42h65.48l1.78 5.81a20.91 20.91 0 017.37 5.91L92.13 26H97l17.32 41.88h-7.49a20.9 20.9 0 01.26 3.11c0 .53 0 1.05-.08 1.58h13.1a1.38 1.38 0 001.28-1.9zM41.25 3.17h39.14L82 8.68H39.6zm-7.4 0h3l-6.3 19.28h-4.66zM15.67 67.91H7.16L24.48 26h4.89zm43.16-25.48H29.27l5-16.39h24.68zM59 22.59H35.33l3.16-10.47H59v10.47zM63.38 12H83l3.16 10.47H63.49zm28.85 30.43H63.76L63.53 26h23.75zM91 22.45L84.69 3.17h3l8 19.29H91z" />
      <path d="M86.25 59a12.06 12.06 0 11-12.06 12 12.08 12.08 0 0112.06-12m0-5.51A17.57 17.57 0 10103.82 71a17.57 17.57 0 00-17.57-17.55z" />
      <path d="M86.66 62.39a2.74 2.74 0 00-2.74 2.74V71a2.74 2.74 0 002.74 2.74h4.45a2.74 2.74 0 100-5.47h-1.72v-3.14a2.74 2.74 0 00-2.73-2.74z" />
    </svg>
  );
}

export default forwardRef(SvgSportEquipment);
