import { createContext, useContext } from 'react';

export type NotificationContextValue = {
  requestPermission(): void;
  registerMessagingToken(): void;
};

export const NotificationContext = createContext<NotificationContextValue>({
  requestPermission() {},
  registerMessagingToken() {},
});

function useNotificationContext(): NotificationContextValue {
  return useContext(NotificationContext);
}

export default useNotificationContext;
