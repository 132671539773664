import { ErrorModel, CertificateModel } from 'models';

export function requestCertificates() {
  return {
    type: requestCertificates.type,
    payload: {},
  } as const;
}

requestCertificates.type = 'certificates/request' as const;

export function receiveCertificates(certificates: CertificateModel[]) {
  return {
    type: receiveCertificates.type,
    payload: {
      certificates,
    },
  } as const;
}

receiveCertificates.type = 'certificates/receive' as const;

export function errorCertificates(error: ErrorModel) {
  return {
    type: errorCertificates.type,
    payload: {
      error,
    },
  } as const;
}

errorCertificates.type = 'certificates/error' as const;
